import styled from '@emotion/styled';
import { HORIZONTAL_PADDING } from '../../../../atoms/Table/TableRow';

const FillRightTableMargin = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -${HORIZONTAL_PADDING}px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default FillRightTableMargin;
