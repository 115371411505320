import { GRAY_2, YELLOW } from '../../../camtool-styles';

interface IColorMapping {
  [key: string]: string;
}

export const colorMapping: IColorMapping = {
  CT: '#CBCBCB',
  Chat: YELLOW,
  GuestChat: '#AF7821',
  Message: '#208FE1',
  GuestMessage: '#0C4E7E',
  Photos: '#E66559',
  GuestPhotos: '#872A22',
  Videos: '#1F9A85',
  GuestVideos: '#005E4E',
  MessengerGifts: '#F90386',
  GuestMessengerGifts: '#7E2D9F',
};

export const defaultColor = GRAY_2;
