import React, { FC, forwardRef, ReactNode } from 'react';
import styled from '@emotion/styled';
import { BLACK_1 } from '../../../camtool-styles';

const Container = styled.div`
  padding: 32px 60px;
  border-radius: 2px;
  width: 100%;
  background-color: ${BLACK_1};
  justify-content: center;
  align-items: center;
  background-size: cover;
`;

export type GetBackgroundImageCallback = () => string | undefined;

interface IProps {
  className?: string;
  getBackgroundImage?: GetBackgroundImageCallback;
  children?: ReactNode;
}

const Slide = forwardRef<HTMLDivElement, IProps>(
  ({ className, children, getBackgroundImage }, ref) => {
    const backgroundImageUrl = getBackgroundImage?.();
    const backgroundImage = backgroundImageUrl ? `url(${backgroundImageUrl})` : undefined;

    return (
      <div ref={ref} className={className}>
        <Container css={{ backgroundImage }}>{children}</Container>
      </div>
    );
  }
);

const withSlideContainer = <ComponentProps extends {}>(
  Component: FC<ComponentProps>,
  getBackgroundImage?: GetBackgroundImageCallback
) =>
  forwardRef<HTMLDivElement, ComponentProps & IProps>((props, ref) => {
    const { className } = props;

    return (
      <Slide ref={ref} className={className} getBackgroundImage={getBackgroundImage}>
        <Component {...props} />
      </Slide>
    );
  });

export default withSlideContainer;
