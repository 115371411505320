import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { addDays, formatISO, subMonths, subSeconds } from 'date-fns';
import { QUERY_MODEL_VXPAGES_STATS_CREDITS_USAGE } from '../../../graphql/VXModels/queries';
import { EmptyContent, Spinner } from '../../../components';
import { VXPagesStatsCreditsUsage } from '../../../graphql/VXModels/types';
import { DatePicker, Section } from '../../../atoms';
import { DARK_GRAY, WHITE_3 } from '../../../camtool-styles';
import { ICreditsUsageDisplayData } from './types';
import CreditsUsagesTable from './CreditsUsagesTable';
import CreditsUsagesChart from './CreditsUsagesChart';
import { colorMapping, defaultColor } from './colors';
import { _ } from '../../../util/translate';

const MAX_WIDTH_BREAKPOINT = '1400px';

const sectionStyles = css`
  padding: 47px 50px 50px;
  background-color: ${WHITE_3};
  color: ${DARK_GRAY};
`;

const Container = styled.div`
  flex-direction: column;
`;

const ColumnsContainer = styled.div`
  @media (max-width: ${MAX_WIDTH_BREAKPOINT}) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex-direction: column;
  flex: 1 1 0;

  &:not(:first-of-type) {
    margin-left: 25px;
  }

  &:not(:last-of-type) {
    margin-right: 25px;
  }

  @media (max-width: ${MAX_WIDTH_BREAKPOINT}) {
    width: 100%;
    flex: 0 0 auto;

    &:not(:first-of-type) {
      margin-left: 0;
    }

    &:not(:last-of-type) {
      margin-right: 0;
    }
  }
`;

const RightColumn = styled(Column)`
  align-items: center;

  @media (max-width: ${MAX_WIDTH_BREAKPOINT}) {
    padding-top: 50px;
  }
`;

const SpinnerContainer = styled.div`
  position: relative;
  min-height: 150px;
  flex-grow: 1;
  z-index: 0; // Display Spinner below DatePicker
`;

const Header = styled.h3`
  margin-bottom: 28px;
  font-size: 18px;
`;

const DatePickerLabel = styled.label`
  flex-direction: column;
  font-size: 16px;
  width: 100%;
  pointer-events: none;
`;

const DatePickerLabelText = styled.span`
  margin-left: 3px;
  margin-bottom: 2px;
`;

const formatIsoDate = (date: Date): string => formatISO(date, { representation: 'date' });

const getCreditsUsagesDisplayData = (
  creditsUsages: VXPagesStatsCreditsUsage[]
): ICreditsUsageDisplayData[] =>
  creditsUsages.map(({ type, amount }) => {
    return {
      label: _(`statistics:section.credits.itemLabel.${type}`),
      amount,
      amountString: _('statistics:section.credits.creditsAmount', {
        sprintf: [amount.toLocaleString()],
      }),
      color: colorMapping[type] || defaultColor,
    };
  });

const CreditsStatistics: FC = () => {
  const currentDate = new Date();
  const currentDayStart = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const [start, setStart] = useState<string>(() => formatIsoDate(subMonths(currentDayStart, 1)));
  const [end, setEnd] = useState<string>(() => formatIsoDate(currentDayStart));

  const { data, loading, error } = useQuery(QUERY_MODEL_VXPAGES_STATS_CREDITS_USAGE, {
    variables: {
      timeframe: {
        start: new Date(start),
        end: subSeconds(addDays(new Date(end), 1), 1),
      },
    },
  });

  const creditsUsages: VXPagesStatsCreditsUsage[] = data?.model?.vxpages?.stats?.creditsUsage || [];
  const creditsUsagesDisplayData: ICreditsUsageDisplayData[] = getCreditsUsagesDisplayData(
    creditsUsages
  );
  const hasCreditsUsages = creditsUsagesDisplayData.length > 0;

  const onFromChangeWithoutTime = (value: string): void => setStart(value);
  const onToChangeWithoutTime = (value: string): void => setEnd(value);

  return (
    <Section title={_('statistics:section.credits.title')} styles={sectionStyles}>
      <Container>
        <Header>{_('statistics:section.credits.subtitle')}</Header>
        <ColumnsContainer>
          <Column>
            <DatePickerLabel>
              <DatePickerLabelText>{_('statistics:section.credits.timeframe')}</DatePickerLabelText>
              <DatePicker
                from={start}
                to={end}
                onFromChangeWithoutTime={onFromChangeWithoutTime}
                onToChangeWithoutTime={onToChangeWithoutTime}
                showBorder={true}
                css={{ marginBottom: 35 }}
              />
            </DatePickerLabel>
            {loading ? (
              <SpinnerContainer>
                <Spinner noBackground={true} />
              </SpinnerContainer>
            ) : error ? (
              <EmptyContent icon="icon-warning-sign" title={_('statistics:error.title')} />
            ) : !hasCreditsUsages ? (
              <EmptyContent
                icon="icon-coins"
                title={_('statistics:section.credits.noCreditsSpent')}
              />
            ) : (
              <CreditsUsagesTable creditsUsagesDisplayData={creditsUsagesDisplayData} />
            )}
          </Column>
          {hasCreditsUsages && (
            <RightColumn>
              <CreditsUsagesChart creditsUsagesDisplayData={creditsUsagesDisplayData} />
            </RightColumn>
          )}
        </ColumnsContainer>
      </Container>
    </Section>
  );
};

export default CreditsStatistics;
