import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import { VXPagesUser } from '../../../../../graphql/VXModels/types';
import { MUTATION_MODEL_VXPAGES_USER_SET_IS_FORCED_PAYMENT_DISABLED } from '../../../../../graphql/VXModels/mutations';
import { BLACK_2, DARK_GRAY, GRAY_2, RED } from '../../../../../camtool-styles';
import { _ } from '../../../../../util/translate';
import RadioCircleButton from '../../../../../atoms/RadioCircleButton/RadioCircleButton';

const Container = styled.div`
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 30px;
`;

const Header = styled.h3`
  font-weight: normal;
  font-size: 16px;
  color: ${BLACK_2};
  margin-bottom: 15px;
  text-align: center;
`;

const InputRow = styled.div`
  flex-direction: row;
  justify-content: center;
`;

const StyledRadioCircleButton = styled(RadioCircleButton)`
  font-weight: 300;
  font-size: 16px;
  align-items: center;
  margin: 0 30px;
`;

const ErrorLabel = styled.div`
  display: block !important;
  color: ${RED};
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
`;

interface IProps {
  user: VXPagesUser;
}

const ForcedPaymentToggle: FC<IProps> = ({ user }) => {
  const { id, isPayingCustomer, isForcedPaymentDisabled } = user;

  const [setIsForcedPaymentDisabled, { loading, error }] = useMutation(
    MUTATION_MODEL_VXPAGES_USER_SET_IS_FORCED_PAYMENT_DISABLED
  );

  const onForcedPaymentDisabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const disabled = e.target.value === 'disabled';

    setIsForcedPaymentDisabled({
      variables: {
        vxPagesUserId: id,
        disabled,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        model: {
          __typename: 'ModelMutation',
          vxpages: {
            __typename: 'VXPagesMutation',
            user: {
              __typename: 'VXPagesUserMutation',
              setIsForcedPaymentDisabled: {
                __typename: 'VXPagesUser',
                id,
                isPayingCustomer,
                isForcedPaymentDisabled: disabled,
              },
            },
          },
        },
      },
    });
  };

  return (
    <Container>
      <Header>{_('userManagement:details.forcedPayment.title')}</Header>
      <InputRow>
        <div css={{ width: '40%', justifyContent: 'space-evenly' }}>
          <StyledRadioCircleButton
            value="enabled"
            checked={!isForcedPaymentDisabled}
            name="forcedPayment"
            onChange={onForcedPaymentDisabledChange}
            disabled={loading}
          >
            {_('common:onOff.enabled')}
          </StyledRadioCircleButton>

          <StyledRadioCircleButton
            value="disabled"
            checked={isForcedPaymentDisabled}
            name="forcedPayment"
            onChange={onForcedPaymentDisabledChange}
            disabled={loading}
          >
            {_('common:onOff.disabled')}
          </StyledRadioCircleButton>
        </div>
      </InputRow>
      {error && <ErrorLabel>{_('userManagement:details.forcedPayment.error')}</ErrorLabel>}
    </Container>
  );
};

export default ForcedPaymentToggle;
