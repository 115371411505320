import React, { Fragment, memo } from 'react';
import HelpCenterSections from '../../../../components/HelpCenterSections/HelpCenterSections';
import Spinner from '../../../../components/Spinner/Spinner';
import HelpCenterBreadcrumbs from '../../../../components/HelpCenterBreadcrumbs/HelpCenterBreadcrumbs';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_HELPCENTER_CATEGORY } from '../../../../graphql/VXModels/queries';
import { Redirect } from 'react-router';

const Sections = memo(
  ({
    match: {
      params: { categoryId },
      path,
    },
  }) => {
    const { loading, data } = useQuery(QUERY_HELPCENTER_CATEGORY, {
      variables: { categoryId },
      fetchPolicy: 'cache-and-network',
    });
    const category = data?.helpcenter?.category;
    return (
      <div className="grid__box__row">
        <div className="grid__column spinner-container" css={{ minHeight: 256 }}>
          {loading ? (
            <Spinner />
          ) : category ? (
            <Fragment>
              <HelpCenterBreadcrumbs category={category} />
              <header className="grid__box__row helpcenter__title">{category.name}</header>
              <div className="grid__box__row helpcenter__description">{category.description}</div>
              <HelpCenterSections category={category} />
            </Fragment>
          ) : (
            <Redirect to={path + '/../..'} />
          )}
        </div>
      </div>
    );
  }
);

export default Sections;
export { Sections };
