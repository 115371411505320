import { useVXModelsClient } from '../../../graphql';
import { Query as MaterialTableQuery, QueryResult } from 'material-table';
import { QUERY_MODEL_VXPAGES_USERS } from '../../../graphql/VXModels/queries';
import { VXPagesUser } from '../../../graphql/VXModels/types';

type AsyncQueryResolver = (
  query: MaterialTableQuery<VXPagesUser>
) => Promise<QueryResult<VXPagesUser>>;

export const useAsyncCustomerListQuery = (): AsyncQueryResolver => {
  const client = useVXModelsClient();
  return (query): Promise<QueryResult<VXPagesUser>> =>
    new Promise<QueryResult<VXPagesUser>>((resolve) => {
      const { page, pageSize, search, orderBy, orderDirection } = query;
      const order = { field: orderBy?.field, direction: orderDirection || undefined };

      client
        .query({
          query: QUERY_MODEL_VXPAGES_USERS,
          variables: {
            limit: pageSize,
            offset: pageSize * page,
            filter: { search },
            order,
          },
          fetchPolicy: 'cache-first',
          errorPolicy: 'all',
        })
        .then(({ data }) =>
          resolve({
            data: data?.model?.vxpages?.users?.items || [],
            page: page,
            totalCount: data?.model?.vxpages?.users?.total || 0,
          })
        );
    });
};
