import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import React from 'react';
import update from 'immutability-helper';
import ActionTypes from '../../util/constants/ActionTypes';
import EventEmitter from 'events';

let CHANGE_EVENT = 'AccountStore.change';

let storeData = {
  nav: {
    showMenuItemActors: false,
  },
  personaldata: {
    countries: [],
    whatsappCountries: [],
    isAccountVerified: false,
    accountBirthdate: '',
    address: {
      isCompany: '',
      firstname: '',
      lastname: '',
      companyname: '',
      street: '',
      streetnbr: '',
      zip: '',
      city: '',
      country: '',
      savingResult: {},
    },
    email: {
      address: '',
      verificationStatus: 'check',
    },
    fixedPhone: {
      number: '',
      verificationStatus: 'check',
    },
    mobilePhone: {
      number: '',
      verificationStatus: 'check',
    },
    language: '',
    contact: {
      skype: '',
      facebook: '',
      whatsappCountry: '',
      whatsappNumber: '',
      instagram: '',
      twitter: '',
    },
    isNameEditable: false,
    isAddressEditable: false,
    isFormEditable: false,
  },
  actorlist: {
    actors: [],
  },
  payoutdata: {
    countries: [],
    allowedPayoutMethods: [],
    status: '', // '', 'rejected', 'delayed', 'checking', 'new'
    savingResult: {},
    minAmounts: {
      bankDE: '',
      bankEU: '',
      bank3L: '',
      cheque: '',
      paxum: '',
    },
    fees: {
      bankDE: '',
      bankEU: '',
      bank3L: '',
      cheque: '',
      paxum: '',
      underMinAmount: '',
    },
    hasNewPayoutData: false,
    newPayoutData: {
      method: '',
      bankAccount: {
        accountOwner: '',
        iban: '',
        bic: '',
      },
      chequeAddress: {
        firstname: '',
        lastname: '',
        street: '',
        streetnbr: '',
        zip: '',
        city: '',
        country: '',
      },
      paxumAccount: {
        emailAddress: '',
      },
    },
    hasCurrentPayoutData: false,
    currentPayoutData: {
      method: '',
      bankAccount: {
        accountOwner: '',
        iban: '',
        bic: '',
      },
      chequeAddress: {
        firstname: '',
        lastname: '',
        street: '',
        streetnbr: '',
        zip: '',
        city: '',
        country: '',
      },
      paxumAccount: {
        emailAddress: '',
      },
    },
  },
  documents: {
    doclist: {},
  },
  passwords: {
    emptyPassValue: '',
  },
};

class AccountStoreClass extends EventEmitter {
  emitChange(store) {
    this.emit(CHANGE_EVENT + '.' + store);
  }
  addChangeListener(store, callback) {
    this.on(CHANGE_EVENT + '.' + store, callback);
  }
  removeChangeListener(store, callback) {
    this.removeListener(CHANGE_EVENT + '.' + store, callback);
  }
  get(store) {
    if (!storeData[store]) {
      throw new InvalidStoreException(store);
    }
    return storeData[store];
  }
}

function InvalidStoreException(store) {
  this.store = store;
  this.message = 'Invalid store';
  this.toString = function () {
    return this.message + ': ' + this.store;
  };
}

const AccountStore = new AccountStoreClass();

AppDispatcher.register(function (payload) {
  switch (payload.type) {
    case ActionTypes.ACCOUNT_RECEIVE_DATA:
      let store = payload.store;
      storeData[store] = update(storeData[store], { $merge: payload.data });
      AccountStore.emitChange(store);
      break;
    default:
  }
});

export { AccountStore };
