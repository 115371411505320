import React, { FC, useEffect, useState } from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { ICreditsUsageDisplayData } from './types';

interface IProps {
  creditsUsagesDisplayData: ICreditsUsageDisplayData[];
}

const CreditsUsagesChart: FC<IProps> = ({ creditsUsagesDisplayData }) => {
  // We need a consistent sort order to make the animations work properly
  const sortedDisplayData = [
    ...creditsUsagesDisplayData,
  ].sort(({ label: label1 }, { label: label2 }) => (label1 > label2 ? 1 : -1));

  // Animate only on updates, not when displaying the first set of data
  const [animate, setAnimate] = useState<boolean>(false);

  useEffect(() => {
    const timeout = (setTimeout(() => setAnimate(true), 2000) as any) as number;

    return () => clearTimeout(timeout);
  }, []);

  return (
    <PieChart width={395} height={395} css={{ margin: -5, overflow: 'visible' }}>
      <Tooltip
        formatter={(value, name) =>
          sortedDisplayData.find(({ label }) => label === name)?.amountString || ''
        }
      />
      <Pie
        data={sortedDisplayData}
        nameKey="label"
        dataKey="amount"
        outerRadius="100%"
        animationBegin={0}
        animationDuration={animate ? 1500 : 0}
      >
        {sortedDisplayData.map(({ color }, index) => (
          <Cell fill={color} key={index} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default CreditsUsagesChart;
