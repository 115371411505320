import React, { FC } from 'react';
import { VXPagesUser } from '../../../../../../graphql/VXModels/types';
import { Field, FieldLabel, FieldValue } from './Base';
import { _ } from '../../../../../../util/translate';

interface IProps {
  user: VXPagesUser;
}

const LanguageField: FC<IProps> = ({ user }) => {
  const { language } = user;

  const languageKeyVariable = `common.languages.${language}`;
  const languageKey = `enum:${languageKeyVariable}`;
  const languageKeyTranslation = _(languageKey);
  const languageTranslation =
    languageKeyTranslation !== languageKeyVariable ? languageKeyTranslation : language;

  return (
    <Field>
      <FieldLabel>{_('userManagement:field.language')}</FieldLabel>
      <FieldValue>{languageTranslation}</FieldValue>
    </Field>
  );
};

export default LanguageField;
