import React, { FC } from 'react';
import styled from '@emotion/styled';
import UserBalanceLabel from '../../components/UserBalanceLabel';
import { _ } from '../../../../../util/translate';
import { VXPagesUser } from '../../../../../graphql/VXModels/types';
import { GREEN } from '../../../../../camtool-styles';

interface IProps {
  user: VXPagesUser;
}

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const Balance = styled.div`
  color: ${GREEN};
  font-weight: bold;
  font-size: 3.85rem;
  font-family: 'Roboto Condensed', sans-serif;
`;

const CurrentCredits: FC<IProps> = ({ user }) => {
  const { balance } = user;

  return (
    <Container>
      <UserBalanceLabel user={user} />
      <Balance>{_('userManagement:balance.value', { sprintf: [balance] })}</Balance>
    </Container>
  );
};

export default CurrentCredits;
