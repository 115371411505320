import React, { FC, useEffect, useState } from 'react';
import { Form, FormikProps } from 'formik';
import { Article, FieldMailingsRecipientsFilter } from '../index';

import { ActionEnum } from '../../types';
import { Mailing, MailingStatusEnum } from '../../../../graphql/VXModels/types';
import { Spinner } from '../../../../components';

import StatusBoxDetailed from '../StatusBoxDetailed';
import { FormValues } from './FormWrapper';
import { _ } from '../../../../util/translate';
import {
  FieldEmailSubject,
  FieldEmailEditor,
  FieldSendTestEmail,
  FieldPublishScheduled,
  FieldEmailTemplate,
} from './Fields/Email';
import { SendNotice, SendSection } from './Email';

interface Errors {
  recipient?: string;
  subject?: string;
  textHtml?: string;
}

export const validate = ({ action, recipient, subject, textHtml }: FormValues) => {
  const errors: Errors = {};

  if (action === ActionEnum.TEST && !recipient) {
    errors.recipient = _('mailings:error.testRecipient');
  }

  if ([ActionEnum.PUBLISH, ActionEnum.SAVE].includes(action) && !subject) {
    errors.subject = _('mailings:error.subject');
  }

  if (action === ActionEnum.PUBLISH && !textHtml) {
    errors.textHtml = _('mailings:error.emptyBody');
  }

  return errors;
};

const FormEmail: FC<FormikProps<FormValues> & { mailing: Mailing }> = ({
  isSubmitting,
  setStatus,
  status,
  mailing,
  values,
}) => {
  const { publishScheduled, type, recipientsGroup } = values;
  const { editable = true, modified, shipmentRecipientsCount = 0 } = mailing;
  const mailingstatus = mailing.status;
  const disabled = !editable;
  const [recipientsCount, setRecipientsCount] = useState(0);

  const displaySendNotice = [
    MailingStatusEnum.DRAFT,
    MailingStatusEnum.SHIPMENT_PENDING,
    MailingStatusEnum.SHIPMENT_PROGRESS,
  ].includes(status);

  useEffect(() => setStatus(mailingstatus), [mailingstatus]);

  return (
    <Form>
      {isSubmitting && <Spinner />}
      <Article>
        <StatusBoxDetailed modified={modified} status={status} />

        <FieldMailingsRecipientsFilter
          name="recipientsGroupFilters"
          disabled={disabled}
          type={type}
          recipientsGroup={recipientsGroup}
          recipientsCount={status !== MailingStatusEnum.DRAFT ? shipmentRecipientsCount : 0}
          onUpdateCount={setRecipientsCount}
        />
        <FieldEmailTemplate disabled={disabled} />
        <FieldEmailSubject disabled={disabled} />
        <FieldEmailEditor disabled={disabled} />
        <FieldSendTestEmail />
        <FieldPublishScheduled
          disabled={disabled || !publishScheduled}
          checkboxDisabled={disabled}
        />
        {displaySendNotice && <SendNotice />}
        <SendSection
          isSubmitting={isSubmitting}
          publishScheduled={publishScheduled}
          recipientsCount={recipientsCount}
          status={status}
        />
      </Article>
    </Form>
  );
};

export default FormEmail;
