import React from 'react';
import vxmodelsLogo from '../../assets/images/vxmodels_logo_2018.svg';
import vxpagesLogo from '../../assets/images/vxpages_logo.png';

export const VXModelsLogo = () => <img src={vxmodelsLogo} style={{ width: 156, height: 54 }} />;
export const VXPagesLogo = () => (
  <img src={vxpagesLogo} style={{ padding: '16px 0', height: 22 }} />
);

export { default as VXLogo } from './VXLogo';
