import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button } from '../../../atoms';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';
import withSlideContainer from './withSlideContainer';
import { logoWelcomeToVXPages } from './assets';
import { WHITE_2, WHITE_3 } from '../../../camtool-styles';
import { useHistory } from 'react-router';

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  color: ${WHITE_2};
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 8px;
`;

const Logo = styled.img`
  margin-bottom: 16px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: ${WHITE_3} !important;
  margin-bottom: 24px;
`;

const BUTTON_ROUTE = `${APP_BASE_PATH}/newscenter/`;

const SlideWelcomeToVXPages: FC = () => {
  const history = useHistory();

  return (
    <Container>
      <Title>{_('dashboard:newsticker.slides.welcomeVXPages.title')}</Title>
      <Logo src={logoWelcomeToVXPages} />
      <Subtitle>{_('dashboard:newsticker.slides.welcomeVXPages.subTitle')}</Subtitle>
      <Button theme="blue" size="lg" onClick={() => history.push(BUTTON_ROUTE)}>
        {_('dashboard:newsticker.slides.welcomeVXPages.button')}
      </Button>
    </Container>
  );
};

export default withSlideContainer(SlideWelcomeToVXPages);
