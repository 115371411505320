import React, { memo } from 'react';

import { describeSVGArc, polarToCartesian } from './charts-utils';
import { BLACK_2, WHITE_3 } from '../../camtool-styles';

interface IProps { size: number; data: number; labels: [string, string]; } // prettier-ignore

const Cake42 = memo((props: IProps) => {
  if (typeof props.data !== 'number') return console.error('Cake42 `data` prop must be a number.');

  const { size, data, labels } = props;
  const start = { x: size / 2, y: size / 2 };

  const START = 70;
  const greenPercent = data;
  const greenPercentInGrads = greenPercent * (360 / 100);
  const bluePercent = 100 - greenPercent;
  const bluePercentInGrads = bluePercent * (360 / 100);

  const blueCenter = polarToCartesian(start.x, start.y, 96, (START + START + bluePercentInGrads) / 2); // prettier-ignore
  const greenCenter = polarToCartesian(start.x, start.y, -96, (START + START + bluePercentInGrads) / 2); // prettier-ignore

  return (
    <svg width={size + 128} height={size}>
      <circle cx={start.x} cy={start.y} r="170" fill={WHITE_3} />
      <text
        x={start.x - 80 / 2}
        y={start.x + (170 - 16)}
        fill={BLACK_2}
        style={{ fontWeight: 'bold' }}
      >
        10 % Payment
      </text>

      <path
        fill="none"
        stroke="#1f93e9" // blue
        strokeWidth="60"
        d={describeSVGArc(start.x, start.y, 96, START, START + bluePercentInGrads)}
      />

      <path
        fill="none"
        stroke="#4dad33" // green
        strokeWidth="70"
        d={describeSVGArc(start.x, start.y, 100, START + bluePercentInGrads, START + bluePercentInGrads + greenPercentInGrads)} // prettier-ignore
      />

      <g transform={`translate(${size},${size / 2 - 45})`}>
        <rect x="0" y="25" fill="#4dad33" width="16" height="16" />
        <text x="25" y="39" style={{ fill: '#4dad33', fontSize: 16 }} className="small">
          {labels[0]}
        </text>

        <rect x="0" y="50" fill="#1f93e9" width="16" height="16" />
        <text x="25" y="64" style={{ fill: '#1f93e9', fontSize: 16 }} className="small">
          {labels[1]}
        </text>
      </g>

      <text x={blueCenter.x - 46 / 2} y={blueCenter.y + 8} style={{ fill: '#fff', fontSize: 24 }}>
        {bluePercent}%
      </text>

      <text x={greenCenter.x - 46 / 2} y={greenCenter.y + 8} style={{ fill: '#fff', fontSize: 24 }}>
        {greenPercent}%
      </text>
    </svg>
  );
});

export default Cake42;
