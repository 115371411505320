import { useEffect, useState } from 'react';
import { ZENDESK_CHAT_TOKEN } from '../../../config';
import { useScript } from '../../../hooks';
import { ScriptStatus } from '../../../hooks/useScript';
import { WindowWithZopim, Zopim } from '../types';

const SCRIPT_ID = 'ze-snippet';

type ZopimStatus = 'init' | 'loading' | 'ready' | 'error';

type ZopimHook = {
  zopim: Zopim | null;
  loading: boolean;
  error?: Error;
};

function waitForZopim(timeout: number) {
  const start = Date.now();
  return new Promise(waitForInner);

  function waitForInner(resolve: (zopim: Zopim) => void, reject: (err: Error) => void) {
    if ((window as WindowWithZopim).$zopim) {
      resolve((window as WindowWithZopim).$zopim!);
    } else if (timeout && Date.now() - start >= timeout) {
      reject(new Error('Timeout while loading $zopim'));
    } else {
      setTimeout(waitForInner.bind(this, resolve, reject), 30);
    }
  }
}

const timeout = 5000;

const useZopim = (): ZopimHook => {
  const src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_CHAT_TOKEN}`;
  const scriptStatus = useScript({ src, id: SCRIPT_ID, async: false });
  const [status, setStatus] = useState<ZopimStatus>(
    !!(window as WindowWithZopim).$zopim ? 'ready' : 'init'
  );
  const [error, setError] = useState<Error | undefined>();
  const { $zopim } = window as WindowWithZopim;

  useEffect(() => {
    if (scriptStatus === ScriptStatus.READY && status === 'init') {
      setStatus('loading');
      waitForZopim(timeout)
        .then(() => {
          setStatus('ready');
        })
        .catch((err: Error) => {
          setStatus('error');
          setError(err);
          console.error('[Zendesk Chat]: ' + err.message);
        });
    }
  }, [scriptStatus]);

  return {
    zopim: status === 'ready' ? ($zopim as Zopim) : null,
    loading: status === 'loading',
    error,
  };
};

export default useZopim;
