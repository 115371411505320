import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { charts } from '../../../../../atoms/Icon/libraries/glyph';
import { useHasRole } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';

const HomepageStatsItem: FC = () => {
  const hasVXPageInHPMode = useHasRole(SecurityRole.VX_USER_VXPAGES_HP);

  return hasVXPageInHPMode ? (
    <LeftMenuItem title={_('navigation:homepage.stats')} icon={charts} uri="/stats" />
  ) : null;
};

export default HomepageStatsItem;
