import { APP_SCOPE } from './env';

export const VXMODELS = 'vxmodels';
export const VXPAGES = 'vxpages';

export enum Scopes {
  VXModels = 'vxmodels',
  VXPages = 'vxpages',
}

const scopeEnvironment = {
  vxmodels: {
    APP_NAME: 'VXModels',
    CONFIG_GOOGLE_TAG_MANAGER_ID_BETA: 'GTM-KHSMPQV',
    LANDING_PAGE_URL: 'https://www.vxmodels.com',
    PICTURES_CATEGORIES: ['new', 'create', 'shop', 'free', 'profile', 'campaigns'], // prettier-ignore
    VIDEOS_CATEGORIES: ['all', 'new', 'shop', 'free', 'profile', 'campaigns'],
  },

  vxpages: {
    APP_NAME: 'VX-PAGES',
    CONFIG_GOOGLE_TAG_MANAGER_ID_BETA: undefined,
    LANDING_PAGE_URL: 'https://www.vxpages.com',
    PICTURES_CATEGORIES: ['new', 'create', 'shop', 'free'],
    VIDEOS_CATEGORIES: ['all', 'new', 'shop', 'free'],
  },
};

export const getScopeEnvironment = (scope = APP_SCOPE) => scopeEnvironment[scope] || {};

/**
 * checks if one of given scopes matches APP_SCOPE
 *
 * @param {Scopes.VXPages} scopes
 * @returns {boolean}
 */
export const hasScope = (scopes: Scopes[]) => scopes.includes(APP_SCOPE);
