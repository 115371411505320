import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../atoms';
import { ButtonGroup, LinkBox, Markdown, T } from '../../../components';

import { RED, WHITE } from '../../../camtool-styles';
import { useAppState } from '../../../util/AppState';
import { APP_BASE_PATH } from '../../../util/env';
import useCountdown from '../../../hooks/useCountdown';

import { PropContestStatus, TYPE_PHOTO, TYPE_VIDEO } from './Props';
import CampaignHeaderTitle from './CampaignHeaderTitle';
import CampaignDescriptionPrices from './CampaignDescriptionPrices';
import CampaignTopList from './CampaignTopList';
import CampaignMediaPhoto from './CampaignMediaPhoto';
import CampaignMediaVideo from './CampaignMediaVideo';
import CampaignCountdown from './CampaignCountdown';
import { _ } from '../../../util/translate';

/** Determine until when the countdown should go */
function getCountdownString(props) {
  if (props.status === 'active') {
    if (props.hasParticipated) {
      if (Date.parse(props.contestStart) < Date.now()) {
        return props.contestEnd;
      }
      // Voting started
      else {
        return props.contestStart;
      } // Voting didn't start yet
    } else {
      return props.uploadEnd;
    }
  }
}

const Campaign = (props) => {
  const [days, hours, minutes] = useCountdown(getCountdownString(props)); // '2020-09-25T10:30:00.000Z'
  const [{ lang }] = useAppState();

  return (
    <div className="grid__box__row marketing-campaign__item">
      <div className="grid__box__column grid__box__sub min-width--0">
        <CampaignHeaderTitle
          {...{
            title: props.title,
            type: props.type,
            contestStart: props.contestStart,
            contestEnd: props.contestEnd,
            uploadStart: props.uploadStart,
            uploadEnd: props.uploadEnd,
          }}
        />

        <div className="grid__box__sub__item">
          <div className="grid__box__sub__item__content marketing-campaign__item__content min-width--0">
            <div
              className="grid__box__row marketing-campaign__item__header"
              css={{ flexDirection: 'column', alignItems: 'stretch' }}
            >
              <CampaignCountdown
                {...props}
                days={days}
                hours={hours}
                minutes={minutes}
                lang={lang}
              />
              <img className="marketing-campaign__item__img" src={props.titlePictureUrl} alt="" />
            </div>

            <div className="grid__box__row marketing-campaign__item__description">
              <div className="grid__box__column marketing-campaign__item__elements">
                <div className="marketing-campaign__item__title">
                  <T _={`marketing:campaigns.${props.type}.description.title`} />
                </div>

                <Markdown className="marketing-campaign__item__text" source={props.description} />

                <div className="marketing-campaign__item__title">
                  <T _={'marketing:campaigns.tasks'} />
                </div>

                <Markdown className="marketing-campaign__item__rating" source={props.tasks} />

                {props.prices && (
                  <div className="marketing-campaign__item__title">
                    {!props.isExpired
                      ? _('marketing:campaigns.prizes.title1')
                      : _('marketing:campaigns.prizes.title1After')}
                  </div>
                )}

                {props.prices && <CampaignDescriptionPrices {...props} />}

                {props.hasParticipated /*&& props.isActive*/ && props.linkSexiestSelfies && (
                  <div css={{ margin: '8px 0 24px 0', flexDirection: 'column' }}>
                    <div>
                      Du kannst den aktuellen Wettbewerb auf Dein Socials bewerben und damit neue
                      Kunden gewinnen und bis zu 72% Werbeprovision bekommen! <br />
                      (Provision gilt für Neukunden, die sich über den oben genannten Werbelink
                      angemeldet haben. Dein Werbelink darf nicht auf VISIT-X und deren
                      Partnerseiten wie z.B. Lustagenten beworben werden)
                    </div>
                    <div css={{ padding: '8px 0' }}>
                      <LinkBox
                        iconColor="#fff"
                        iconBgColor="#1f93e9"
                        icon="fas fa-camera-retro"
                        link={props.linkSexiestSelfies}
                        buttonLabelCopy={_('common:text.linkCopy')}
                        buttonLabelCopied={_('common:text.linkCopied')}
                      />
                    </div>
                  </div>
                )}
                {props.hasParticipated && props.type === TYPE_PHOTO && (
                  <CampaignMediaPhoto {...props} />
                )}
                {props.hasParticipated && props.type === TYPE_VIDEO && (
                  <CampaignMediaVideo {...props} />
                )}
              </div>

              {((props.isActive && props.toplist && props.toplist.length > 0) ||
                (props.hasWinners && props.hasWinners.length > 0)) && (
                <div className="grid__box__column marketing-campaign__item__winner">
                  <CampaignTopList {...props} />

                  {props.linkContest && (
                    <ButtonGroup
                      className="marketing-campaign__item__winner__button-group-top10"
                      position="center"
                    >
                      <div className="button button--blue button--max-width marketing-campaign__item__winner__button-top10">
                        <a
                          href={props.linkContest + 'top10'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <T _={'marketing:campaigns.gotoCompetitionPageTop10'} />
                        </a>
                      </div>
                    </ButtonGroup>
                  )}
                </div>
              )}
            </div>

            <div className="grid__box__row marketing-campaign__item__bottom-button">
              {/* Wenn nicht teilgenommen und upload aktiv anzeigen */}
              {props.isUploadActive && !props.hasParticipated && (
                <ButtonGroup position={props.hasParticipated ? 'center' : 'left'}>
                  <Button
                    theme="blue"
                    label={_('marketing:campaigns.startnow')}
                    onClick={() => props.openModalBox(props.type)}
                    type="button"
                  />
                </ButtonGroup>
              )}
              {/* anzeigen wenn teilgenommen und upload aktiv */}
              {props.isUploadActive && props.hasParticipated && (
                <ButtonGroup position="center">
                  <div className="button button--blue">
                    <a href={`${APP_BASE_PATH}/mediamanagement/picture/campaign`}>
                      <T _={'marketing:campaigns.startnow'} />
                    </a>
                  </div>
                </ButtonGroup>
              )}

              {/* anzeigen Wenn teilgenommen und upload expired und Type Photo (Link ist bereits in Type Video) */}
              {props.hasParticipated && props.type === TYPE_PHOTO && props.isUploadExpired && (
                <ButtonGroup position="center">
                  <div className="button button--blue">
                    <a href={props.linkContest} target="_blank" rel="noopener noreferrer">
                      <T _={'marketing:campaigns.gotoCompetitionPage'} />
                    </a>
                  </div>
                </ButtonGroup>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Campaign.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  openModalBox: PropTypes.func.isRequired,
  status: PropContestStatus,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  winners: PropTypes.string,
  prices: PropTypes.string,
  tasks: PropTypes.string,
  uploadStart: PropTypes.string.isRequired,
  uploadEnd: PropTypes.string.isRequired,
  contestStart: PropTypes.string.isRequired,
  contestEnd: PropTypes.string.isRequired,
  uploadLimit: PropTypes.number.isRequired,
  uploadedMedia: PropTypes.any,
  totalUploadedMedia: PropTypes.number.isRequired,
  titlePictureUrl: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
  isUploadActive: PropTypes.bool.isRequired,
  isUploadExpired: PropTypes.bool.isRequired,
  hasWinners: PropTypes.bool.isRequired,
  hasParticipated: PropTypes.bool.isRequired,
  linkContest: PropTypes.string,
  linkSexiestSelfies: PropTypes.string,
  toplist: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.number,
      userId: PropTypes.number,
      username: PropTypes.string,
      likes: PropTypes.number,
      previewPictureUrl: PropTypes.string,
    })
  ),
};

Campaign.defaultProps = {
  description: '',
  winners: '',
  prices: '',
  tasks: '',
  uploadedMedia: [],
  titlePictureUrl: '',
  linkToMedia: '',
  toplist: [],
  linkContest: '',
  linkSexiestSelfies: '',
};

export default Campaign;
