import React, { Fragment } from 'react';
import styled from '@emotion/styled';

import { Button } from '../../../atoms';
import { T } from '../../../components';

import { getFormattedDate } from '../../../util/Formatter';

import { BLACK_2, GREEN, RED, WHITE } from '../../../camtool-styles';
import { useLang } from '../../../util/AppState';
import { _ } from '../../../util/translate';

const CampaignLabel = styled.div`
  white-space: nowrap;
  letter-spacing: 1px;
  padding: 8px;
  text-transform: uppercase;
  border-radius: 8px;
  font-weight: bold;
`;

const LABEL_COLORS = {
  expired: { backgroundColor: RED, color: WHITE },
  planned: { backgroundColor: BLACK_2, color: WHITE },
  active: { backgroundColor: '#f2f2f2', color: BLACK_2 },
};

const CampaignCountdown = (props) => {
  const handleJoinNowClick = () => props.openModalBox(props.type);
  const [lang] = useLang();

  if (props.status === 'active') {
    if (!props.days && !props.hours && !props.minutes) {
      return null;
    }

    if (props.hasParticipated) {
      const languageKey =
        Date.parse(props.contestStart) < Date.now()
          ? 'userVotingEndsOn' // Voting started
          : 'userVotingStartsOn'; // Voting didn't start yet

      return (
        <CampaignLabel
          css={{
            ...LABEL_COLORS[props.status],
            textTransform: 'none',
            borderRadius: 0,
            boxShadow: '0 0 4px 0px #000',
            position: 'absolute',
            top: 24,
            left: 24,
          }}
        >
          <span className="icon-thumbs-up" css={{ marginRight: 8 }} />
          <T
            _={`marketing:competition.label.${languageKey}`}
            sprintf={[
              props.lang === 'de'
                ? `${props.days} T : ${props.hours} h : ${props.minutes} min`
                : `${props.days} D : ${props.hours} h : ${props.minutes} min`,
            ]}
          />
        </CampaignLabel>
      );
    } else {
      // Didn't join yet
      if (props.isUploadActive) {
        // Still can join
        return (
          <CampaignLabel
            css={{
              backgroundColor: GREEN,
              color: WHITE,
              borderRadius: 0,
              textTransform: 'none',
              justifyContent: 'space-between',
            }}
          >
            <div css={{ alignItems: 'center' }}>
              <span className="icon-clock" css={{ marginRight: 4 }} />
              <p>
                <Fragment>
                  {_('marketing:competition.label.participationPeriodStill')} {props.days} T :{' '}
                  {props.hours} h : {props.minutes} min
                </Fragment>
              </p>
            </div>

            <Button
              onClick={handleJoinNowClick}
              label={_('common:texts.joinNow')}
              theme="link-white"
              css={{ marginRight: 8 }}
            />
          </CampaignLabel>
        );
      } else {
        // Can't join anymore
        return null;
      }
    }
  } else if (props.status === 'expired') {
    return (
      <CampaignLabel
        css={{ ...LABEL_COLORS[props.status], position: 'absolute', top: 24, left: 24 }}
      >
        <T _={'marketing:competition.label.finished'} />
      </CampaignLabel>
    );
  } else if (props.status === 'planned') {
    return (
      <CampaignLabel
        css={{ ...LABEL_COLORS[props.status], position: 'absolute', top: 24, left: 24 }}
      >
        <T
          _={'marketing:competition.label.startOn'}
          sprintf={[getFormattedDate(props.uploadStart, lang)]}
        />
      </CampaignLabel>
    );
  } else {
    return null;
  }
};

export default CampaignCountdown;
