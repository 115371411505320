import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import React, { FC, useRef, useState } from 'react';
import { Button, Spinner } from '../../../../components';
import {
  QUERY_MODEL_DOCUMENTS_MISSING_TIN,
  QUERY_MODEL_DOCUMENTS_UPLOADURL,
} from '../../../../graphql/VXModels/queries';
import { Query } from '../../../../graphql/VXModels/types';
import AlertsStore from '../../../../stores/Alerts/AlertsStore';
import { log } from '../../../../util';
import { _ } from '../../../../util/translate';
import { uploadDocuments } from '../utils';
import {
  BREAKPOINT_DESKTOP_CONDITION,
  BREAKPOINT_SPHONE_CONDITION,
} from '../../../../camtool-styles';
import styled from '@emotion/styled';

const Row = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 100%;
  }
  @media ${BREAKPOINT_SPHONE_CONDITION} {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
  }
`;

const Span = styled.span`
  padding: 0px 8px;
`;

interface Props {
  onUploadSuccess: () => void;
  userId: number;
  options: Array<{ id: number; name: string }>;
}

const DocUploader: FC<Props> = ({ onUploadSuccess, userId, options }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState(null);
  const [docType, setDocType] = useState('');
  const [docName, setDocName] = useState('');
  const [fileType, setFileType] = useState('');
  const [error, setError] = useState('');

  const { data } = useQuery(QUERY_MODEL_DOCUMENTS_MISSING_TIN);

  const showMissingTINBanner = React.useMemo(() => {
    return data?.model?.documents?.showBannerMissingTIN ?? false;
  }, [data?.model?.documents?.showBannerMissingTIN]);

  const [getUploadUrl, { loading: uploadUrlLoading }] = useLazyQuery<Query>(
    QUERY_MODEL_DOCUMENTS_UPLOADURL,
    {
      onCompleted: (res) => {
        const url = res?.model?.documents?.uploadUrl || '';
        uploadFile(url);
      },
    }
  );

  const handleDocTypeChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setDocType(event.target.value);
  };

  const handleFileChange = (event: any) => {
    if (event.target.value) {
      setFileType(event.target.files.item(0).type.split('/')[1] || '');
      setDocName(event.target.files.item(0).name);
      setDoc(event.target.files.item(0));
    }
  };
  const resetDoc = () => {
    setError('');
    setDocName('');
    setDocType('');
  };

  const getNewUploadUrl = () => {
    getUploadUrl({ variables: { userId: userId, type: docType } });
  };

  const onUploadError = (res: { request: { responseText: string } }) => {
    resetDoc();
    setLoading(false);

    AlertsStore.add({
      type: 'error',
      message: _('common:error.generic'),
    });
    log('error', JSON.stringify(res?.request.responseText), { context: 'Doc Uploader failed' });
  };

  const uploadFile = (url: string) => {
    if (uploadUrlLoading === false) {
      uploadDocuments(doc, onUploadSuccess, onUploadError, url);
      onUploadSuccess();
    }
  };

  const renderHeading = () => {
    if (error) return <p css={{ color: '#ff4d3c' }}>{error}</p>;
    if (docType) {
      if (docName) return <p>{_('account:documents.uploader.uploadDocText')}</p>;
      else return <p>{_('account:documents.uploader.selectFileText')}</p>;
    } else {
      return <p>{_('account:documents.uploader.pickDocType')}</p>;
    }
  };
  const renderPictureSizeNote = () => {
    if (docType && !docName)
      return (
        <>
          <span className="fas fa-info-circle" />
          {' ' + _('account:documents.uploader.resolution')}
        </>
      );
  };

  return (
    <>
      <div className="grid__column grid__box">
        <div className="grid__box__header profiles-overview__header">
          <div className="grid__box__header__title profiles-overview__header__text">
            {_('account:documents.uploader.title')}
          </div>
        </div>

        <article
          className="grid__box__row spinner-container"
          css={{
            color: '#3c3d3e',
            padding: '32px 16px',
            flexDirection: 'column',
            backgroundColor: '#f5f5f5',
          }}
        >
          {loading && <Spinner />}
          <div css={{ fontSize: '1.2em', padding: '4px 2px' }}>{renderHeading()}</div>

          <div>
            <div
              css={{
                flexWrap: 'wrap',
                rowGap: '10px',
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                overflow: 'hidden',
              }}
            >
              <div
                css={{
                  position: 'relative',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                  '&::after': {
                    fontFamily: 'GLYPHICONS',
                    content: `'\\E602'`,
                    position: 'absolute',
                    pointerEvents: 'none',
                    color: '#3c3d3e',
                    right: 8,
                    fontSize: '1.3em',
                    padding: '4px 0',
                  },
                }}
              >
                <select
                  onChange={handleDocTypeChange}
                  css={{
                    appearance: 'none',
                    width: 332,
                    backgroundColor: '#fff',
                    padding: '0 8px',
                    height: '32px',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  value={docType}
                >
                  <option value="">{_('account:documents.uploader.pickDocTypeSelect')}</option>
                  {options.map((item) => (
                    <option key={item.id} value={item.name}>
                      {_(`account:documents.name.${item.name}`)}
                    </option>
                  ))}
                </select>
              </div>
              {docName ? (
                <>
                  <div
                    css={{
                      borderTopLeftRadius: 4,
                      borderBottomLeftRadius: 4,
                      marginLeft: '10px',
                      marginRight: '10px',
                      overflow: 'hidden',
                      backgroundColor: '#fff',
                      width: 256,
                      alignItems: 'center',
                      padding: '0 8px',
                      height: '32px',
                    }}
                  >
                    {docName.length >= 20
                      ? docName.substring(0, 20) + '[...].' + fileType
                      : docName}
                  </div>

                  <Button
                    onClick={getNewUploadUrl}
                    className="button button--blue"
                    label={_('account:documents.uploader.uploadButton')}
                    css={{ padding: 0 }}
                    disabled={!docType}
                  />

                  <Button
                    onClick={resetDoc}
                    className="button button--darkgray"
                    label={_('account:documents.uploader.uploadCancel')}
                    css={{ padding: '0 8px' }}
                  />
                </>
              ) : (
                <Button
                  className="button button--blue"
                  label={_('account:documents.uploader.selectFile')}
                  css={{ padding: 0 }}
                  disabled={!docType}
                  onClick={() => {
                    inputRef?.current?.click();
                  }}
                />
              )}
              <input
                ref={inputRef}
                type="file"
                name={`doc-${docType}`}
                disabled={!docType}
                onChange={handleFileChange}
                css={{ display: 'none' }}
              />
            </div>
          </div>

          <p css={{ alignItems: 'center', padding: '4px 2px', color: '#888' }}>
            {renderPictureSizeNote()}
          </p>

          {showMissingTINBanner && (
            <Row className="grid__row">
              <div className="grid__column grid__box grid__box--documents__infobox">
                <section className={` documents__infobox`}>
                  <Span className={`fas fa-info-circle fa-2x`} />
                  <p>{_('account:documents.banner.tinUpdate.text')}</p>
                </section>
              </div>
            </Row>
          )}
        </article>
      </div>
    </>
  );
};

export default DocUploader;
