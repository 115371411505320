import { FC, useEffect } from 'react';
import { ApiLang } from '../../graphql/VXModels/types';
import { _ } from '../../util/translate';
import useZopim from './hooks/useZopim';
import { Brands, Zopim } from './types';
import { sanitizeLang } from './util';

interface ZendeskChatProps {
  username?: string;
  userId?: number | string;
  email?: string;
  lang: ApiLang;
  deps: Brands[];
  hideAll?: boolean | (($zopim: Zopim) => boolean);
}

const ZendeskChat: FC<ZendeskChatProps> = (props) => {
  const { lang, username, email, userId, deps, hideAll } = props;
  const { zopim } = useZopim();

  useEffect(() => {
    if (zopim) {
      const { livechat } = zopim;
      livechat.button.setHideWhenOffline(true);
      livechat.setLanguage(sanitizeLang(lang));
      livechat.setGreetings({
        online: _('common:supportchat.greeting_online'),
        offline: _('common:supportchat.greeting_offline'),
      });
      livechat.window.setTitle(_('common:supportchat.title'));
      livechat.departments.setLabel(_('common:supportchat.departments_title'));
      livechat.departments.filter(...deps);
      livechat.setName(username);
      livechat.setEmail(email);
      livechat.addTags(userId?.toString());
      livechat.setOnConnected(() => {
        if (hideAll === true || (typeof hideAll === 'function' && hideAll(zopim))) {
          livechat.hideAll();
        }
      });
    }
  }, [zopim]);

  useEffect(() => {
    if (zopim) {
      const { livechat } = zopim;
      if (hideAll === true || (typeof hideAll === 'function' && hideAll(zopim))) {
        livechat.button.hide();
      } else {
        livechat.button.show();
      }
    }
  });

  return null; // we do not add anything to the DOM, everything is done by the Zopim script on window.$zopim
};

export default ZendeskChat;
