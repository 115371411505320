import React, { FC } from 'react';
import { RED } from '../../../../../camtool-styles';
import { Translation } from '../../../../../components';
import { StyledSection } from '../../styles';

const SendNotice: FC = () => {
  return (
    <StyledSection css={{ flexDirection: 'row', color: RED, maxWidth: 600 }}>
      <span className="icon-info-sign" css={{ marginRight: 8, alignItems: 'center' }} />
      <p>
        <Translation t={'mailings:email.form.sendNotice'} />
      </p>
    </StyledSection>
  );
};

export default SendNotice;
