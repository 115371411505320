import React, {Component} from 'react';
import { log } from '../../util';

class ErrorBoundary extends Component {
  constructor(props:any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  public static getDerivedStateFromError(error:any, errorInfo:any) {
    console.log('componentDidCatch', JSON.stringify(error), errorInfo);
    log(
      'error',
      `ErrorBoundry getDerivedStateFromError: Error: ${error &&
        JSON.stringify(error)} --- ErrorInfo: ${errorInfo && JSON.stringify(errorInfo)}`
    );
    return { error, errorInfo };
  }

  componentDidCatch(error:any, errorInfo:any) {
    console.log('componentDidCatch', JSON.stringify(error), errorInfo);
    log(
      'error',
      `ErrorBoundry componentDidCatch: Error: ${error &&
        JSON.stringify(error)} --- ErrorInfo: ${errorInfo && JSON.stringify(errorInfo)}`
    );
  }

  render() {
    const { error, errorInfo } = this.state;
    return error ? (
      <main>
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo && errorInfo.componentStack}
          </details>
        </div>
      </main>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
