import React, { FC } from 'react';
import { LeftMenuItem, LeftMenuGroup } from '../../components';
import { film, picture, user } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';
import { Scopes, hasScope } from '../../../../util/scope';

const ContentGroup: FC = () => {
  const hasScopeVXPages = hasScope([Scopes.VXPages]);

  return (
    <LeftMenuGroup title={_('navigation:main.content')} icon={film}>
      <LeftMenuItem
        title={_('navigation:content.videos')}
        icon={film}
        uri="/mediamanagement/video"
      />
      <LeftMenuItem
        title={_('navigation:content.pictures')}
        icon={picture}
        uri="/mediamanagement/picture"
      />
      {hasScopeVXPages && (
        // Temp workaround until VXPages integrates all relevant fields directly into the creator
        <LeftMenuItem
          title={_('navigation:main.profile.header')}
          icon={user}
          uri="/onlineprofile/overview"
        />
      )}
    </LeftMenuGroup>
  );
};

export default ContentGroup;
