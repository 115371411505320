import React, { FC } from 'react';
import { _ } from '../../../../../../util/translate';
import { Link } from 'react-router-dom';
import EmptyContent from '../../../../../../components/EmptyContent/EmptyContent';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { APP_BASE_PATH } from '../../../../../../util/env';
import styled from '@emotion/styled';

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
`;

const EmptyResults: FC = () => (
  <EmptyContent css={{ margin: 30 }} icon={'icon-search'} title={_('help:helpcenter.search.empty')}>
    <StyledLink to={`${APP_BASE_PATH}/help/helpcenter/`}>
      {_('help:helpcenter.search.goBack')} <TrendingFlatIcon />
    </StyledLink>
  </EmptyContent>
);

export default EmptyResults;
