import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button } from '../../../atoms';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';
import withSlideContainer, { GetBackgroundImageCallback } from './withSlideContainer';
import { bgPromolink, logoPromolink } from './assets';
import { useHistory } from 'react-router';

const Container = styled.div`
  flex-direction: column;
  align-items: center;
`;

const Title1 = styled.h3`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 4px;
`;

const Title2 = styled.h2`
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 10px;
`;

const Logo = styled.img`
  margin-bottom: 62px;
`;

const BUTTON_ROUTE = `${APP_BASE_PATH}/vxcash/`;

const getBackgroundImage: GetBackgroundImageCallback = () => bgPromolink;

const SlidePromolink: FC = () => {
  const history = useHistory();

  return (
    <Container>
      <Title1>{_('dashboard:newsticker.slides.promoLink.title1')}</Title1>
      <Title2>{_('dashboard:newsticker.slides.promoLink.title2')}</Title2>
      <Logo src={logoPromolink} />
      <Button theme="blue" size="lg" onClick={() => history.push(BUTTON_ROUTE)}>
        {_('dashboard:newsticker.slides.promoLink.button')}
      </Button>
    </Container>
  );
};

export default withSlideContainer(SlidePromolink, getBackgroundImage);
