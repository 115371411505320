import React, { FC } from 'react';
import { Icon } from '../../../../atoms';
import { iconMapping } from './icons';

interface Props {
  categoryId: string;
}

const CategoryIcon: FC<Props> = ({ categoryId }) => {
  if (iconMapping[categoryId]) {
    const svgStyles = { marginBottom: '10px', alignSelf: 'center' };
    const iconStyles = {
      marginBottom: '10px',
      alignItems: 'center',
      justifyContent: 'center',
      color: `${iconMapping[categoryId].color}`,
    };

    const styles = iconMapping[categoryId].svg ? svgStyles : iconStyles;
    return <Icon css={styles} icon={iconMapping[categoryId].icon} size="26px" />;
  } else return null;
};

export default CategoryIcon;
