import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { useHasRole, useUserData } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';
import { ticket } from '../../../../../atoms/Icon/libraries/svg';

const BonusCodesItem: FC = () => {
  const hasRoleVXModels = useHasRole(SecurityRole.VX_USER_VXMODELS);
  const hasVXPageInLPMode = useHasRole(SecurityRole.VX_USER_VXPAGES_LP);
  const hasVXPageInHPMode = useHasRole(SecurityRole.VX_USER_VXPAGES_HP);

  const userData = useUserData();
  const canCreateVisitXBonusCodes = !!userData?.model?.bonuscodes?.canCreateVisitXBonusCodes;
  const isTelegramOnlyPage = !!userData?.model?.vxpages?.isTelegramOnlyPage;

  const showBonusCodes =
    (hasVXPageInHPMode && !isTelegramOnlyPage) ||
    ((hasRoleVXModels || hasVXPageInLPMode) && canCreateVisitXBonusCodes);

  return showBonusCodes ? (
    <LeftMenuItem title={_('navigation:marketing.bonusCodes')} icon={ticket} uri="/vouchers" />
  ) : null;
};

export default BonusCodesItem;
