import update from 'immutability-helper';
import gql from 'graphql-tag';
import { FRAGMENT_NOTIFICATION_ITEMS } from '../graphql/VXModels/fragments';

export const NOTIFICATIONS_LIMIT = 15;

export const QUERY_UNREAD_NOTIFICATIONS = gql`
  query QUERY_UNREAD_NOTIFICATIONS(
    $notificationsOffset: Int = 0
    $notificationsLimit: Int
    $timezone: String!
  ) {
    # QUERY_UNREAD_NOTIFICATIONS
    model {
      unreadNotifications: notifications(
        showExpired: false
        unreadOnly: true
        category: [
          common
          directNotification
          document
          finance
          marketing
          medal
          mediacontent
          homepage
          profile
          ranking
          system
          vxchallenges
        ]
        timezone: $timezone
      ) @connection(key: "notifications", filter: ["showExpired", "unreadOnly", "category"]) {
        count
        items(sort: PRIO_DESC__DATE_DESC, offset: $notificationsOffset, limit: $notificationsLimit)
          @connection(key: "items", filter: ["sort"]) {
          ...NotificationItems
        }
      }
    }
  }
  ${FRAGMENT_NOTIFICATION_ITEMS}
`;

export const QUERY_NEWSCENTER_NOTIFICATIONS = gql`
  query QUERY_NEWSCENTER_NOTIFICATIONS(
    $notificationsOffset: Int = 0
    $notificationsLimit: Int
    $timezone: String!
  ) {
    # QUERY_ACTIVE_NOTIFICATIONS
    model {
      newscenterNotifications: notifications(
        showExpired: true
        unreadOnly: false
        category: [system, snagbar, newscenter, vxgames2019, vxgames2020, vxchallenges]
        timezone: $timezone
      ) @connection(key: "notifications", filter: ["showExpired", "unreadOnly", "category"]) {
        count
        items(sort: DATE_DESC, offset: $notificationsOffset, limit: $notificationsLimit)
          @connection(key: "items", filter: ["sort"]) {
          ...NotificationItems
        }
      }
    }
  }

  ${FRAGMENT_NOTIFICATION_ITEMS}
`;

export const MUTATION_NOTIFICATIONS_MARKREAD = gql`
  mutation MarkRead($notificationIds: [Int]!) {
    # MUTATION_NOTIFICATIONS_MARKREAD
    model {
      notifications {
        markNotificationRead(id: $notificationIds) {
          id
          read
        }
      }
    }
  }
`;

export const updateQuery =
  (notificationsField) =>
  (prev, { fetchMoreResult }) => {
    if (!fetchMoreResult) {
      return prev;
    }

    const prevItemIds = prev.model[notificationsField].items.reduce((carry, item) => {
      carry.push(item.id);
      return carry;
    }, []);

    const nextItems = fetchMoreResult.model[notificationsField].items.filter(
      (item) => prevItemIds.indexOf(item.id) === -1
    );

    const model = {};
    model[notificationsField] = { items: { $push: nextItems } };

    return update(prev, { model });
  };

export const updateQueryAfterMarkRead =
  (notificationsField, query, timezone) =>
  (
    cache,
    {
      data: {
        model: {
          notifications: { markNotificationRead },
        },
      },
    }
  ) => {
    const ids = markNotificationRead.map((item) => item.id);
    cache.writeQuery({
      query: query,
      data: update(cache.readQuery({ query, variables: { timezone } }), {
        model: {
          [notificationsField]: {
            count: (count) => count - ids.length,
            items: (items) =>
              items.map((item) => {
                return item;
              }),
          },
        },
      }),
    });
  };

export const optimisticMarkReadResponse = (notificationIds) => {
  const now = new Date();
  return {
    model: {
      notifications: {
        markNotificationRead: notificationIds.map((notificationId) => {
          return {
            id: notificationId,
            read: now.toJSON(),
            __typename: 'Notification',
          };
        }),
        __typename: 'NotificationsMutation',
      },
      __typename: 'ModelMutation',
    },
  };
};

/** Notifications count for <LeftMenu> badge */
export const NOTIFICATIONS_COUNT_MENU = gql`
  query NOTIFICATIONS_COUNT_MENU($timezone: String!) {
    model {
      newscenterNotifications: notifications(
        unreadOnly: true
        showExpired: true
        category: [newscenter, system, snagbar, vxgames2020, vxchallenges]
        timezone: $timezone
      ) {
        count
      }
    }
  }
`;
