import React, { FC } from 'react';
import { VXPagesUser } from '../../../../../../graphql/VXModels/types';
import { Field, FieldLabel, FieldValue } from './Base';
import { _ } from '../../../../../../util/translate';

interface IProps {
  user: VXPagesUser;
}

const AvsField: FC<IProps> = ({ user }) => {
  const { hasAvs } = user;

  return (
    <Field>
      <FieldLabel>{_('userManagement:field.avsAlt')}</FieldLabel>
      <FieldValue>{hasAvs ? _('common:text.yes') : _('common:text.no')}</FieldValue>
    </Field>
  );
};

export default AvsField;
