import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { WHITE } from '../../../camtool-styles';

import image from './VXPages-creator.png';
import { APP_BASE_PATH } from '../../../util/env';
import { T } from '../../../components';

const Container = styled.div`
  background-image: linear-gradient(#e23831, #e85630);
  box-shadow: 0 4px 4px -4px #000;
  background-color: #e23831;
  color: ${WHITE};
  padding: 16px;
  align-items: center;
  flex: 1 0 0;
  position: relative;
  max-height: 76px;
  cursor: pointer;
`;

interface IProps {
  loading: boolean;
  className?: string;
} // prettier-ignore

const VXPagesCreator = React.memo(({ loading, className }: IProps) => {
  return (
    <Container css={loading ? { backgroundColor: '#f5f5f5' } : null} className={className}>
      <Link
        to={APP_BASE_PATH + '/website/creator'}
        css={{ textDecoration: 'none', display: 'flex', color: 'white', whiteSpace: 'nowrap' }}
      >
        <img src={image} css={{ width: 64 }} alt="" />
        <div css={{ marginLeft: 16, fontSize: 18, alignItems: 'center' }}>
          <T _={'dashboard:app.creator.buttonText'} />
          <span className="icon-chevron-right" css={{ fontSize: 12, marginLeft: 8 }} />
        </div>
      </Link>
    </Container>
  );
});

VXPagesCreator.displayName = 'VXPagesCreator';

export default VXPagesCreator;
