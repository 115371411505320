import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import gql from 'graphql-tag';
import { withApollo } from '@apollo/react-hoc';

import AbstractComponent from '../../../AbstractComponent';
import Formsy from 'formsy-react';
import {
  CampaignActionCreators,
  CAMPAIGNS,
  CampaignStore,
} from '../../../../stores/Marketing/Campaign';

import AlertsStore from '../../../../stores/Alerts/AlertsStore';
import Spinner from '../../../Spinner/Spinner';
import OptionBoxRadio from '../../../Form/SingleOption/OptionBoxRadio/OptionBoxRadio';
import Dropdown from '../../../Form/SingleOption/Dropdown/Dropdown';
import Notice from '../../../Notice/Notice';
import MultiLangInputfield from '../../../Form/MultiLang/MultiLangInputfield/MultiLangInputfield';
import MultiLangTextareafield from '../../../Form/MultiLang/MultiLangTextareafield/MultiLangTextareafield';
import TagListInputAutocomplete from '../../../Form/MultiOptions/TagListInputAutocomplete/TagListInputAutocomplete';
import OptionBoxOnOff from '../../../Form/SingleOption/OptionBoxOnOff/OptionBoxOnOff';
import Range from '../../../Form/Range/Range';
import Button from '../../../Button/Button';
import Markdown from '../../../Markdown/Markdown';
import { getSpokenLangs, hasRole } from '../../../../util/UserData';
import { MediaStatus, SecurityRole } from '../../../../graphql/VXModels/types';
import { withAppState } from '../../../../util/AppState';
import { _ } from '../../../../util/translate';
import { MediaFormGroupRow } from '../../styles';
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_SPHONE,
  BREAKPOINT_TABLET,
  BREAKPOINT_TABLET_CONDITION,
} from '../../../../camtool-styles';
import styled from '@emotion/styled';
import { Box, Typography } from '@material-ui/core';
import { Fieldset } from '../../../../atoms';
import SimpleCheckbox from '../../../Form/Checkbox/SimpleCheckbox';
import InfoIcon from '@material-ui/icons/Info';

const MediaFormGroupRowStyled = styled(MediaFormGroupRow)`
  section:last-child {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
  }
  ${BREAKPOINT_TABLET} {
    flex-direction: row;
    flex-wrap: wrap;
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    .label {
      -ms-flex: 1 1 100%;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%;
    }
    section {
      -ms-flex: 1 1 100%;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%;
      flex-wrap: wrap;
      flex-direction: column;
      &:last-child {
        flex-wrap: wrap;
        flex-direction: column;
      }
    }
  }

  ${BREAKPOINT_SPHONE} {
    -ms-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
    .maxvaluelength-anchorframe .maxvaluelength {
      top: 42px;
      right: 12px;
    }
  }
`;

const OptionBoxRadioStyled = styled(OptionBoxRadio)`
  @media ${BREAKPOINT_TABLET_CONDITION} {
    -ms-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    div[class^='albumType'] {
      -ms-flex: 1 1 100%;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%;
    }
  }
`;

const TagListInputAutocompleteStyled = styled(TagListInputAutocomplete)`
  @media ${BREAKPOINT_TABLET_CONDITION} {
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .taglistinputautocomplete__input {
    flex-wrap: wrap;
  }
`;

const LabelStyled = styled(Box)`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: -11px;
  margin-right: 16px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(245, 245, 245);
  margin-left: -4px;
  padding-right: 4px;
  padding-left: 4px;
`;

const TextInBorderSpan = styled('span')`
  color: #3c3d3e;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  padding: 7px;
`;

const SectionLabelBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

function getCampaignById(id) {
  const data = CampaignStore.get(CAMPAIGNS);
  const campaigns = data.campaigns;
  for (let campaign of campaigns) {
    if (campaign.campaignId === id) {
      return campaign;
    }
  }
  return null;
}

class AlbumForm extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      albumType: this.props.album.albumType,
      pictureCampaigns: [],
      hasChanges: false,
      isFormValid: false,
      isLoading: this.props.isLoading,
      isAlbumLocked: false,
      checked: false,
      allIDCardsUploadedState: this.props.album.allIDCardsUploaded,
      allModelReleaseFormsUploadedState: this.props.album.allModelReleaseFormsUploaded,
    };

    this.campaignOptions = [];
    this.campaignDescription = '';
    this.currentCampaignId = 0;
    this.currentCampaignTitle = '';
    this.currentCampaignDescription = '';
    this.currentValues = this.getCurrentValuesFromStoreValues(props.album);
    this.autocompleteUrl = '/v1/public/tags/media?search={search}';
  }

  componentWillMount() {
    CampaignStore.addChangeListener(CAMPAIGNS, this.onStoreChange);
    CampaignStore.reset(CAMPAIGNS);
    CampaignActionCreators.readResource(CAMPAIGNS, {
      query: { photo: 1, activity: 'uploadActive' },
    });
    this.spokenLangs = getSpokenLangs();

    this.pictureTypeOptions = [
      { value: 'shop', label: _('mediamanagement:picfolder.nav.shop') },
      { value: 'free', label: _('mediamanagement:picfolder.nav.free') },
      { value: 'campaign', label: _('mediamanagement:picfolder.nav.campaign') },
    ];

    if (this.props.album.id) {
      this.setAlbumLockedStatus(this.props.album.id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.album.id !== this.props.album.id && this.props.album.id > 0) {
      this.setAlbumLockedStatus(this.props.album.id);
    }
  }

  componentWillUnmount() {
    CampaignStore.reset(CAMPAIGNS);
    CampaignStore.removeChangeListener(CAMPAIGNS, this.onStoreChange);
  }

  componentWillReceiveProps(nextProps) {
    const [{ lang: currentLanguage }] = this.props.appState;
    this.currentValues = this.getCurrentValuesFromStoreValues(nextProps.album);
    this.currentCampaignId =
      nextProps.album.albumType === 'campaign' ? nextProps.album.photoCampaign.id : 0;
    this.currentCampaignTitle =
      nextProps.album.albumType === 'campaign'
        ? nextProps.album.photoCampaign.titles[currentLanguage]
        : '';
    this.currentCampaignDescription =
      nextProps.album.albumType === 'campaign'
        ? nextProps.album.photoCampaign.description[currentLanguage]
        : '';
  }

  setAlbumLockedStatus = (albumId) => {
    this.props.client
      .query({
        query: gql`{
          model {
              photoAlbums(id: ${albumId}) {
                  albums {
                      id
                      dailyBonus {
                          modelContent {
                              contentId
                              contentType
                              selectedFor
                              preSelectedFor {
                                  from
                                  to
                              }
                          }
                      }
                  }
              }
          }
      }`,
      })
      .then((res) => {
        const { dailyBonus } = res.data.model.photoAlbums.albums[0];
        this.setState({
          isAlbumLocked: dailyBonus.modelContent.some(
            (item) => item.selectedFor || item.preSelectedFor.from
          ),
        });
      });
  };

  onStoreChange() {
    const data = CampaignStore.get(CAMPAIGNS);
    const campaigns = data.campaigns;
    const [{ lang: currentLanguage }] = this.props.appState;
    this.campaignOptions = campaigns.map((campaign) => {
      return { value: campaign.campaignId, label: campaign.texts.text_title[currentLanguage] };
    });

    // if edit of campaign album
    if (!this.props.showAlbumType && this.currentValues.albumType === 'campaign') {
      this.campaignOptions.push({
        value: this.currentCampaignId,
        label: this.currentCampaignTitle,
      });
    }

    this.setState({ isLoading: false });
  }

  onClickReset() {
    this.props.onReset(this.props.album);
  }

  onSubmit(currentValues) {
    // filter _lang property out (php doesn't want it)
    const { _langs: _langs_titles, ...titles } = currentValues.titles || {};
    const { _langs: _langs_descriptions, ...descriptions } = currentValues.descriptions || {};

    this.props.onSubmit({ ...currentValues, titles, descriptions });
  }

  onClickDelete() {
    if (confirm(_('mediamanagement:picture.album.form.confirmDelete'))) {
      this.props.onDelete(this.props.album);
    }
  }

  onChange(currentValues, hasChanges) {
    if (currentValues.campaign && currentValues.campaign !== this.currentValues.campaign) {
      const [{ lang: currentLanguage }] = this.props.appState;
      this.campaign = getCampaignById(parseInt(currentValues.campaign));
      if (this.campaign) {
        this.campaignDescription = this.campaign.texts.text_description[currentLanguage];
        this.forceUpdate();
      }
    }

    const nextState = {
      albumType: currentValues.albumType,
      hasChanges: hasChanges,
    };
    this.currentValues = Object.assign(this.currentValues, currentValues);
    this.setState(nextState);
  }

  onCheckboxChange = (event) => {
    if (event.target.name === 'allIDCardsUploaded') {
      this.setState({ allIDCardsUploadedState: event.target.checked });
    }
    if (event.target.name === 'allModelReleaseFormsUploaded') {
      this.setState({ allModelReleaseFormsUploadedState: event.target.checked });
    }
  };

  getCurrentValuesFromStoreValues(storeValues) {
    let currentValues = {};
    if (this.formsyRef) {
      const inputNames = this.getInputNames().concat(this.formsyRef.prevInputNames);
      for (let inputName of inputNames) {
        if (storeValues[inputName] !== undefined) {
          currentValues[inputName] = storeValues[inputName];
        }
      }
    } else {
      currentValues = cloneDeep(storeValues);
    }
    return currentValues;
  }

  getInputNames() {
    return ['albumType'];
  }

  onStatusChange(ev) {
    let ret = true;
    if (ev.target.value === 'offline') {
      ret = confirm(_('mediamanagement:picfolder.status.confirmOffline'));
    }

    return ret;
  }

  showErrorFields() {
    if (this.state.displayErrors === false) {
      this.setState({ displayErrors: true });
    }
    window.scrollTo(0, 0);
    AlertsStore.add({
      type: 'error',
      message: _('common:alerts.profile.formInvalid'),
    });
  }

  render() {
    const { album, isLoading, showAlbumType } = this.props;
    const campaignOptions = this.campaignOptions;
    const pictureTypeOptions = this.pictureTypeOptions;
    const albumStatus = album.status;
    const albumStatusRatingDelayed = MediaStatus.ratingDelayed === albumStatus;
    const albumStatusRating = MediaStatus.rating === albumStatus;
    const albumType = this.currentValues.albumType;
    const isCampaign = albumType === 'campaign';
    const isFree = albumType === 'free';
    const isShop = albumType === 'shop';
    const showTitle = isShop || isFree;
    const showCampaignNotice = isCampaign && showAlbumType && campaignOptions.length === 0;
    const showCampaignSelection = isCampaign && campaignOptions.length > 0;
    const showDescription = !!albumType && !showCampaignNotice;
    const showPrice = isShop || isCampaign;
    const showTags = isShop || isFree;
    const newAlbum = !album.id;
    const showStatus = newAlbum || isFree || isShop;
    const isStatusDisabled = false;
    const selectedCampaign = showAlbumType
      ? campaignOptions[0]
        ? campaignOptions[0].value
        : ''
      : this.currentCampaignId;
    const maxPrice = album.maxPrice ? album.maxPrice : 19.99;

    const modifyAlbum = !newAlbum;

    return (
      <div className="grid__box__sub__item">
        <Formsy.Form
          className="grid__box__sub__item__content mediamanagement__pic__formwidth mediamanagement__pic__album__content__item__edit spinner-container"
          onValidSubmit={this.onSubmit}
          onInvalidSubmit={this.showErrorFields}
          onChange={this.onChange}
          // eslint-disable-next-line react/no-string-refs
          ref="form"
        >
          {isLoading && <Spinner />}

          {showAlbumType && (
            <MediaFormGroupRowStyled
              label={`${_('mediamanagement:picfolder.albumType.label')}:`}
              tooltipText={_('mediamanagement:picfolder.albumType.tooltipText')}
            >
              <OptionBoxRadioStyled
                required
                requiredMessage={_('mediamanagement:picfolder.albumType.requiredMessage')}
                name="albumType"
                options={pictureTypeOptions.filter(option => option.value === 'campaign' ? hasRole(SecurityRole.VX_USER_VXMODELS) : true)} // prettier-ignore
                value={album.albumType}
              />
            </MediaFormGroupRowStyled>
          )}

          {showCampaignSelection && (
            <MediaFormGroupRowStyled
              label={`${_('mediamanagement:picfolder.campaign.label')}:`}
              tooltipText={_('mediamanagement:picfolder.campaign.tooltipText')}
            >
              <Dropdown
                required
                requiredMessage={_('mediamanagement:picfolder.campaign.requiredMessage')}
                name="campaign"
                options={campaignOptions}
                placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                value={selectedCampaign.toString()}
                onChange={this.onChangeCampaign}
                disabled={!showAlbumType}
              />
            </MediaFormGroupRowStyled>
          )}

          {this.showCampaignNotice && (
            <MediaFormGroupRowStyled label={`${_('mediamanagement:picfolder.campaign.label')}:`}>
              <Notice
                text={_('mediamanagement:mediamanagement:picfolder.notice.noValidCampaigns')}
                icon="icon-warning-sign"
              />
            </MediaFormGroupRowStyled>
          )}

          {/*<Fieldset*/}
          {/*  css={{ marginBottom: 24 }}*/}
          {/*  legend={*/}
          {/*    <LabelStyled>*/}
          {/*      <TextInBorderSpan>*/}
          {/*        {_('mediamanagement:mediamanagement:actorRequest.headline')}*/}
          {/*      </TextInBorderSpan>*/}
          {/*    </LabelStyled>*/}
          {/*  }*/}
          {/*>*/}
          {/*<Box display={'flex'} flexDirection={'column'} style={{ marginLeft: '9px' }}>*/}
          {/*  <SectionLabelBox>*/}
          {/*    <Box*/}
          {/*      style={{*/}
          {/*        fontSize: '16px',*/}
          {/*        fontFamily: 'Roboto, sans-serif',*/}
          {/*        fontWeight: 'bold',*/}
          {/*        color: '#3C3D3E',*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {_('mediamanagement:mediamanagement:actorRequest.intro')}*/}
          {/*    </Box>*/}
          {/*    <Box*/}
          {/*      style={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', color: '#888888' }}*/}
          {/*    >*/}
          {/*      {_('mediamanagement:mediamanagement:actorRequest.pleaseUpload')}*/}
          {/*    </Box>*/}
          {/*  </SectionLabelBox>*/}

          {/*<Box pt={2}>*/}
          {/*  <SimpleCheckbox*/}
          {/*    name="allIDCardsUploaded"*/}
          {/*    label={_('mediamanagement:mediamanagement:actorRequest.idsText')}*/}
          {/*    value={this.state.allIDCardsUploadedState}*/}
          {/*    onChange={this.onCheckboxChange}*/}
          {/*    required={true}*/}
          {/*    disabled={albumStatusRatingDelayed}*/}
          {/*    checked={this.state.allIDCardsUploadedState}*/}
          {/*  />*/}
          {/*</Box>*/}
          {/*<Box pt={2}>*/}
          {/*  <SimpleCheckbox*/}
          {/*    name="allModelReleaseFormsUploaded"*/}
          {/*    label={_('mediamanagement:mediamanagement:actorRequest.modelReleaseFormText')}*/}
          {/*    value={this.state.allModelReleaseFormsUploadedState}*/}
          {/*    onChange={this.onCheckboxChange}*/}
          {/*    required={true}*/}
          {/*    disabled={albumStatusRatingDelayed}*/}
          {/*    checked={this.state.allModelReleaseFormsUploadedState}*/}
          {/*  />*/}
          {/*</Box>*/}

          {/*    {albumStatusRating && (*/}
          {/*      <Box*/}
          {/*        style={{*/}
          {/*          fontSize: '12px',*/}
          {/*          color: '#FFB033',*/}
          {/*          marginLeft: '-11px',*/}
          {/*          marginRight: ' 16px',*/}
          {/*          verticalAlign: 'middle',*/}
          {/*        }}*/}
          {/*        display={'inline-flex'}*/}
          {/*        alignItems={'center'}*/}
          {/*        pt={2}*/}
          {/*      >*/}
          {/*        <Box padding={'9px'}>*/}
          {/*          <InfoIcon />*/}
          {/*        </Box>*/}
          {/*        <Box>{_('mediamanagement:mediamanagement:actorRequest.checkBySupport')}</Box>*/}
          {/*      </Box>*/}
          {/*    )}*/}
          {/*    {albumStatusRatingDelayed && (*/}
          {/*      <Box*/}
          {/*        style={{*/}
          {/*          fontSize: '12px',*/}
          {/*          color: '#FF4D3C',*/}
          {/*          marginLeft: '-11px',*/}
          {/*          marginRight: ' 16px',*/}
          {/*          verticalAlign: 'middle',*/}
          {/*        }}*/}
          {/*        display={'inline-flex'}*/}
          {/*        alignItems={'center'}*/}
          {/*        pt={2}*/}
          {/*      >*/}
          {/*        <Box padding={'9px'}>*/}
          {/*          <InfoIcon />*/}
          {/*        </Box>*/}
          {/*        <Box>*/}
          {/*          <strong>*/}
          {/*            {_('mediamanagement:mediamanagement:actorRequest.rejectedPrefix')}*/}
          {/*          </strong>*/}
          {/*          <br />*/}
          {/*          {_('mediamanagement:mediamanagement:actorRequest.rejectedSuffix')}*/}
          {/*        </Box>*/}
          {/*      </Box>*/}
          {/*    )}*/}
          {/*  </Box>*/}
          {/*</Fieldset>*/}

          {showTitle && (
            <MediaFormGroupRowStyled
              label={`${_('mediamanagement:picfolder.titles.label')}:`}
              tooltipText={_('mediamanagement:picfolder.titles.tooltipText')}
            >
              <MultiLangInputfield
                required
                requiredMessage={_('mediamanagement:picfolder.titles.requiredMessage')}
                validations="isValidMultiLangField"
                validationError={_('common:validationMessage.isValidMultiLangField')}
                name="titles"
                value={album.titles || {}}
                placeholder={_('mediamanagement:picfolder.titles.placeholder')}
                langs={this.spokenLangs}
                maxLength={60}
              />
            </MediaFormGroupRowStyled>
          )}

          {showDescription && (
            <MediaFormGroupRowStyled
              label={`${_('mediamanagement:picfolder.descriptions.label')}:`}
              tooltipText={_('mediamanagement:picfolder.descriptions.tooltipText')}
            >
              {!isCampaign && (
                <MultiLangTextareafield
                  required
                  requiredMessage={_('mediamanagement:picfolder.descriptions.requiredMessage')}
                  validations="isValidMultiLangField"
                  validationError={_('common:validationMessage.isValidMultiLangField')}
                  name="descriptions"
                  value={album.descriptions}
                  placeholder={_('mediamanagement:picfolder.descriptions.placeholder')}
                  langs={this.spokenLangs}
                  maxLength={2000}
                />
              )}

              {isCampaign && (
                <Markdown
                  css={{ padding: '0 8px' }}
                  source={
                    showAlbumType ? this.campaignDescription : this.currentCampaignDescription
                  }
                />
              )}
            </MediaFormGroupRowStyled>
          )}

          {showTags && (
            <MediaFormGroupRowStyled
              label={`${_('mediamanagement:picfolder.tags.label')}:`}
              tooltipText={_('mediamanagement:picfolder.tags.tooltipText')}
            >
              <TagListInputAutocompleteStyled
                name="tags"
                value={album.tags}
                autocompleteUrl={this.autocompleteUrl}
                maxLength={10}
                placeholder={_('common:dropdown.placeholder.pleaseSelect')}
              />
            </MediaFormGroupRowStyled>
          )}

          {showStatus && (
            <MediaFormGroupRowStyled
              label={`${_('mediamanagement:picfolder.status.label')}:`}
              tooltipText={_('mediamanagement:picfolder.status.tooltipText')}
            >
              <OptionBoxOnOff
                name="status"
                value={album.status}
                valueOn="online"
                valueOff="offline"
                labelOn={_('mediamanagement:picfolder.status.button.online')}
                labelOff={_('mediamanagement:picfolder.status.button.offline')}
                onChange={this.onStatusChange}
                disabled={isStatusDisabled || this.state.isAlbumLocked}
              />
            </MediaFormGroupRowStyled>
          )}

          {showPrice && (
            <MediaFormGroupRowStyled
              label={`${_('mediamanagement:picfolder.price.label')}:`}
              tooltipText={_('mediamanagement:picfolder.price.tooltipText')}
            >
              <Range
                name="price"
                start={2.49}
                step={0.5}
                range={{ min: 0.49, max: maxPrice }}
                unit="euro"
                value={Math.max(0.49, album.price)}
                onChange={this.onChangePrice}
                disabledInputOnly={true}
                disabled={this.state.isAlbumLocked}
              />
            </MediaFormGroupRowStyled>
          )}

          <MediaFormGroupRowStyled position="right">
            {modifyAlbum && album.isDeletable && (
              <Button
                label={_('common:button.delete')}
                type="button"
                className="button--red"
                onClick={this.onClickDelete}
              />
            )}
            {modifyAlbum && (
              <Button
                disabled={newAlbum}
                label={_('common:button.cancel')}
                type="button"
                onClick={this.onClickReset}
              />
            )}
            {newAlbum && albumType && !showCampaignNotice && (
              <Button label={_('common:button.next')} type="submit" className="button--blue" />
            )}
            {modifyAlbum && albumType && (
              <Button
                label={_('common:button.save')}
                // disabled={
                //   !(
                //     this.state.allIDCardsUploadedState &&
                //     this.state.allModelReleaseFormsUploadedState
                //   )
                // }
                type="submit"
                className="button--blue"
              />
            )}
          </MediaFormGroupRowStyled>
        </Formsy.Form>
      </div>
    );
  }
}

AlbumForm.propTypes = {
  album: PropTypes.shape({
    id: PropTypes.number,
    albumType: PropTypes.string,
    campaign: PropTypes.string,
    titles: PropTypes.object,
    description: PropTypes.object,
    tags: PropTypes.array,
    price: PropTypes.number,
    isDeletable: PropTypes.bool,
    status: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  showAlbumType: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

AlbumForm.defaultProps = {
  album: {
    id: 0,
    albumType: '',
    campaign: '',
    titles: {},
    descriptions: {},
    tags: [],
    price: 0.0,
    isDeletable: false,
    status: 'online',
  },
  isLoading: false,
  onSubmit: () => {},
  onDelete: () => {},
  showAlbumType: false,
};

export default withApollo(withAppState(AlbumForm));
