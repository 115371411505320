import React, { FC } from 'react';
import { useUserData } from '../../../../util/UserData';
import { getDropdownDates } from '../Timeframes/timeframes';
import { format } from '../../../../atoms/I18n/DateTimeFormatter';
import { Select } from '../../../../atoms';
import { _ } from '../../../../util/translate';

interface IProps {
  customMonthStart: string | undefined;
  setCustomMonthStart: (value: string | undefined) => any;
}

const CustomTimeframeDropdown: FC<IProps> = ({ customMonthStart, setCustomMonthStart }) => {
  const userData = useUserData();
  const vxPagesRegistrationDate = userData?.model?.vxpages?.info?.registered;
  const dropdownDates = getDropdownDates(new Date(vxPagesRegistrationDate));

  const selectOptions = dropdownDates.map(date => ({
    label: format(date.toJSON(), false, 'MMMM y'),
    value: date.toISOString(),
  }));

  return (
    <Select
      onChange={event => setCustomMonthStart(event.target.value)}
      placeholder={_('statistics:section.site.selectCompTimeframe')}
      options={selectOptions}
      value={customMonthStart || ''}
      compact={true}
      css={{ width: '100%' }}
    />
  );
};

export default CustomTimeframeDropdown;
