import React, { ReactElement } from 'react';
import { WHITE } from '../../../camtool-styles';

const Other = (): ReactElement => {
  return (
    <g transform="translate(-3730.8 664.2)">
      <g transform="translate(2931.724 -1751.328)">
        <path
          d="M810.7,1115.045a.372.372,0,0,1-.294-.149.481.481,0,0,1-.129-.325V1098.6a.519.519,0,0,1,.208-.4.354.354,0,0,1,.215-.073h15.989a.5.5,0,0,1,.342.152.437.437,0,0,1,.164.321v11.369h-4.711a.333.333,0,0,0-.266.11.412.412,0,0,0-.1.288v4.678Zm2.961-11.842h10.15a.416.416,0,0,0,.423-.423v-.846a.4.4,0,0,0-.059-.217.452.452,0,0,0-.152-.152.414.414,0,0,0-.211-.054H813.66a.419.419,0,0,0-.423.423v.846a.419.419,0,0,0,.423.423Zm0,3.383h10.15a.416.416,0,0,0,.423-.423v-.846a.443.443,0,0,0-.122-.3.484.484,0,0,0-.135-.09.47.47,0,0,0-.166-.032H813.66a.419.419,0,0,0-.423.423v.846a.419.419,0,0,0,.423.423Zm0,3.383h5.921a.416.416,0,0,0,.423-.423v-.846a.4.4,0,0,0-.059-.217.452.452,0,0,0-.152-.152.414.414,0,0,0-.211-.054H813.66a.419.419,0,0,0-.423.423v.846a.419.419,0,0,0,.423.423Zm9.3,5.016v-3.781a.408.408,0,0,1,.095-.284.334.334,0,0,1,.269-.1H827.1Z"
          fill={WHITE}
        />
      </g>
    </g>
  );
};

export default Other;
