import React, { ReactNode, useState } from 'react';
import FillRightTableMargin from './FillRightTableMargin';
import CustomTimeframeDropdown from './CustomTimeframeDropdown';

interface ICustomTimeframeDropdownResult {
  customMonthStart: Date | undefined;
  dropdownRenderFunction: () => ReactNode;
}

const useCustomTimeframeDropdown = (): ICustomTimeframeDropdownResult => {
  const [customMonthStart, setCustomMonthStart] = useState<string | undefined>(undefined);

  const dropdownRenderFunction = () => (
    <FillRightTableMargin>
      <CustomTimeframeDropdown
        customMonthStart={customMonthStart}
        setCustomMonthStart={setCustomMonthStart}
      />
    </FillRightTableMargin>
  );

  return {
    customMonthStart: customMonthStart ? new Date(customMonthStart) : undefined,
    dropdownRenderFunction,
  };
};

export default useCustomTimeframeDropdown;
