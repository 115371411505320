import React, { FC } from 'react';
import styled from '@emotion/styled';
import TermsAcceptForm from '../../Terms/TermsAcceptForm';
import { DARK_GRAY } from '../../../camtool-styles';
import { _ } from '../../../util/translate';

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 100px;
`;

const TermsTitle = styled.h2`
  color: ${DARK_GRAY};
  font-size: 20px;
  margin: 0 0 15px;
  text-align: center;
  letter-spacing: 0.2px;
`;

interface IProps {
  cmsArticleName: string;
  type: string;
  onCheckboxLinkClick: () => void;
  requirePassword: boolean;
}

const ModalTermsContentShort: FC<IProps> = ({
  cmsArticleName,
  type,
  onCheckboxLinkClick,
  requirePassword,
}) => (
  <Container>
    <TermsTitle>{_(`modalbox:${cmsArticleName}.title`)}</TermsTitle>
    <TermsAcceptForm
      type={type}
      cmsArticleName={cmsArticleName}
      onCheckboxLinkClick={onCheckboxLinkClick}
      requirePassword={requirePassword}
    />
  </Container>
);

export default ModalTermsContentShort;
