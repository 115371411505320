import React, { FC, useMemo } from 'react';
import { ErrorMessage } from 'formik';
import { SectionLabel, StyledSection } from '../../../styles';
import { Translation } from '../../../../../../components';
import { RED } from '../../../../../../camtool-styles';
import FieldSelect from '../../../../../../molecules/Formik/Fields/FieldSelect';
import { _ } from '../../../../../../util/translate';
import { MailingTemplateEnum } from '../../../../../../graphql/VXModels/types';
import { Option } from '../../../../../../atoms/Select/Select';

interface Props {
  disabled: boolean;
}

const templatesOrder: MailingTemplateEnum[] = [MailingTemplateEnum.LIGHT, MailingTemplateEnum.DARK];

const getSelectOptions = (): Option[] =>
  templatesOrder.map((template) => ({
    label: _(`mailings:email.form.templates.${template}`),
    value: template,
  }));

const FieldEmailTemplate: FC<Props> = ({ disabled }) => {
  const options = useMemo(getSelectOptions, []);

  return (
    <StyledSection>
      <div css={{ flexDirection: 'column', flex: '0 1 auto' }}>
        <div css={{ justifyContent: 'space-between' }}>
          <SectionLabel>
            <Translation t={'mailings:email.form.template'} />
          </SectionLabel>
        </div>

        <FieldSelect name="template" disabled={disabled} options={options} />
        <ErrorMessage name="subject" component="span" css={{ color: RED }} />
      </div>
    </StyledSection>
  );
};

export default FieldEmailTemplate;
