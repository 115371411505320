import React from 'react';
import PropTypes from 'prop-types';
import RankingList, { PropContestToplist } from '../../../components/RankingList/RankingList';
import { _ } from '../../../util/translate';

const CampaignTopList = props => [
  <div key={0} className="marketing-campaign__item__toplist-title">
    {!props.isExpired
      ? _('marketing:campaigns.currentWinners.title') // wenn aktuell
      : _('marketing:campaigns.winners.title') // wenn abgelaufen
    }
  </div>,
  <RankingList key={1} list={props.toplist || []} showMax={3} />,
];

CampaignTopList.propTypes = {
  isExpired: PropTypes.bool,
  toplist: PropContestToplist,
};

CampaignTopList.defaultProps = {
  isExpired: false,
};

export default CampaignTopList;
