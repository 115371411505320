import { QueryResult } from '@apollo/react-common';
import { Query } from '@apollo/react-components';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { Main } from '../../atoms';
import { Spinner } from '../../components';
import VXPagesRedirect from '../../components/VXPagesRedirect';
import { QUERY_MODEL_TERMS_ACCEPTANCE } from '../../graphql/VXModels/queries';
import { SecurityRole } from '../../graphql/VXModels/types';
import { stripSlash } from '../../util/urlHelper';
import { useHasRole } from '../../util/UserData';
import ComingSoon from '../Fallbacks/ComingSoon';
import { OtherServices } from './OtherServices';
import TermsOfUseGateway from './TermsOfUseGateway';

const URL_SETTINGS = '/settings';
const URL_CREATOR = '/creator';
const URL_BLOG = '/blog';
const URL_ABO = '/abos';
const URL_OTHER_SERVICES = '/services';

const VXPages: FC = (props) => {
  const { match } = props;
  const path = stripSlash(match.url);
  // const indexRoute = `${path}${URL_SETTINGS}`;
  const type = 'VXPAGES_TERMS_OF_USE_HOMEPAGE';

  const loggedInViaCT = useHasRole(SecurityRole.VX_USER_ADMIN);

  return (
    <Query query={QUERY_MODEL_TERMS_ACCEPTANCE} variables={{ type }}>
      {({ data, loading, error }: QueryResult) => {
        if (loading) {
          return <Spinner />;
        }

        if (error) {
          return (
            <Main>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </Main>
          );
        }

        if (data) {
          const {
            model: {
              terms: {
                acceptance: { hasAccepted },
              },
            },
          } = data;

          if (!hasAccepted && !loggedInViaCT) {
            return <TermsOfUseGateway type={type} />;
          }

          return (
            <Switch>
              {/*<Redirect exact from={`${path}/`} to={indexRoute} />*/}
              <Route
                path={path + URL_SETTINGS}
                component={() => <VXPagesRedirect to={'/admin/settings/domain'} />}
              />
              <Route
                path={path + URL_CREATOR}
                component={() => <VXPagesRedirect to={'/admin/creator/'} />}
              />
              <Route
                path={path + URL_BLOG}
                component={() => <VXPagesRedirect to={'/admin/blog/'} />}
              />
              <Route path={path + URL_OTHER_SERVICES} component={OtherServices} />
              <Route path={path + URL_ABO} component={ComingSoon} />
              {/*<Redirect to={indexRoute} />*/}
            </Switch>
          );
        }
      }}
    </Query>
  );
};

export default VXPages;
