import { Scopes } from '../../util/scope';
import { APP_BASE_PATH } from '../../util/env';
import { AppScope } from '../../graphql/VXModels/types';

export enum Slugs {
  HELPCENTER_VXSPLITTER = 'helpcenter-vxsplitter',
  HELPCENTER_LOVENSE = 'helpcenter-lovense',
}

type Links = Partial<
  Record<AppScope | Scopes | '_default', Partial<Record<Slugs | '_default', string>>>
>;

export const DEFAULT = '_default';

// @todo generate this from backend, perhaps?
export const links: Links = {
  [DEFAULT]: {
    [DEFAULT]: APP_BASE_PATH,
    [Slugs.HELPCENTER_VXSPLITTER]:
      APP_BASE_PATH + '/help/helpcenter/categories/360002286979/articles/360018440740',
    [Slugs.HELPCENTER_LOVENSE]:
      APP_BASE_PATH + '/help/helpcenter/categories/360002286979/articles/360011317880',
  },
  [Scopes.VXPages]: {
    [Slugs.HELPCENTER_VXSPLITTER]:
      APP_BASE_PATH + '/help/helpcenter/categories/360001679039/articles/360018765900',
    [Slugs.HELPCENTER_LOVENSE]: undefined, // does not exists, yet
  },
};
