import React, { FC } from 'react';
import { Spinner } from '../../../../components';
import EmptyContent from '../../../../components/EmptyContent/EmptyContent';
import Button from '../../../../atoms/Button/Button';
import { _ } from '../../../../util/translate';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { QUERY_MODEL_INSTANTMESSAGING_ACCOUNT_STATUS } from '../../../../graphql/VXModels/queries';
import { MUTATION_MODEL_INSTANTMESSAGING_REQUESTTELEGRAM } from '../../../../graphql/VXModels/mutations';
import { TelegramStatusEnum } from '../../../../graphql/VXModels/types';
import { SpinnerContainer } from './styles';
import Icon from '../../../../atoms/Icon/Icon';
import { lock2 } from '../../../../atoms/Icon/libraries/svg';

interface Props {
  isLocked: boolean;
}

const TelegramRequestButton: FC<Props> = ({ isLocked }) => {
  const { loading, error, data } = useQuery(QUERY_MODEL_INSTANTMESSAGING_ACCOUNT_STATUS);
  const requestStatus = data?.model?.instantMessaging?.account?.status;

  const [requestTelegram, { loading: mutationLoading, error: mutationError }] = useMutation(
    MUTATION_MODEL_INSTANTMESSAGING_REQUESTTELEGRAM,
    {
      refetchQueries: [{ query: QUERY_MODEL_INSTANTMESSAGING_ACCOUNT_STATUS }],
      awaitRefetchQueries: true,
    }
  );

  return (
    <div css={{ flex: '0.1' }}>
      {loading || mutationLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : error || mutationError ? (
        <EmptyContent icon="icon-warning-sign" title={_('common:error.generic')} />
      ) : requestStatus === TelegramStatusEnum.requested ? (
        <Button theme={'gray'} disabled={true}>
          {_('telegram:requestTelegram.requestButton.unlocked.requested')}
        </Button>
      ) : isLocked ? (
        <Button theme={'gray'} disabled={true}>
          <Icon icon={lock2} size={'10px'} css={{ marginRight: '4px' }} />
          {_('telegram:requestTelegram.requestButton.locked.request')}
        </Button>
      ) : requestStatus === TelegramStatusEnum.rejected ? (
        <Button theme={'red'} disabled={true}>
          {_('telegram:requestTelegram.requestButton.unlocked.rejected')}
        </Button>
      ) : requestStatus === TelegramStatusEnum.accepted ? (
        <Button theme={'green'} disabled={true}>
          {_('telegram:requestTelegram.requestButton.unlocked.accepted')}
        </Button>
      ) : (
        <Button onClick={() => requestTelegram()}>
          {_('telegram:requestTelegram.requestButton.unlocked.request')}
        </Button>
      )}
    </div>
  );
};

export default TelegramRequestButton;
