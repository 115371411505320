import React from 'react';
import { LeftMenuItem } from '../../components';
import { compass } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';
import useDeepLinkBeta from '../../../../hooks/useDeepLinkBeta';
import { VXModelsMobileTargetEnum } from '../../../../graphql/VXModels/types';

const FeedItem = (): JSX.Element => {
  const { data: feedData, refetch } = useDeepLinkBeta(VXModelsMobileTargetEnum.feed);
  const onclick = () => {
    refetch();
    const feedDeepLink = feedData?.auth?.betaDeeplink || '';
    window.open(feedDeepLink, '_blank');
  };

  return <LeftMenuItem title={'VXFeed'} icon={compass} onClick={onclick} />;
};

export default FeedItem;
