import React, { Fragment } from 'react';
import NavSub from '../../../components/NavSub/NavSub';
import NavSubItem from '../../../components/NavSubItem/NavSubItem';
import { APP_BASE_PATH } from '../../../util/env';
import { hasRole } from '../../../util/UserData';
import { SecurityRole } from '../../../graphql/VXModels/types';
import { _ } from '../../../util/translate';

class NavSubVX extends React.Component {
  render() {
    return (
      <NavSub>
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/visitx/common`}
          icon="icon-nameplate"
          label={_('profiles:visitx.navigation.profile')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/visitx/profilepic`}
          icon="icon-pictures"
          label={_('profiles:visitx.navigation.pictures')}
        />
        {!hasRole(SecurityRole.VX_USER_SEXOLE) && (
          <Fragment>
            <NavSubItem
              link={`${APP_BASE_PATH}/onlineprofile/visitx/fetish`}
              icon="icon-stethoscope"
              label={_('profiles:visitx.navigation.fetish')}
            />
            <NavSubItem
              link={`${APP_BASE_PATH}/onlineprofile/visitx/automails`}
              icon="icon-envelope"
              label={_('profiles:visitx.navigation.automails')}
            />
            {/*
            <NavSubItem link={`${APP_BASE_PATH}/onlineprofile/visitx/pagepreview`}
                        icon="icon-eye-open"
                        label={_('profiles:visitx.navigation.preview')}
            />
            */}
          </Fragment>
        )}
      </NavSub>
    );
  }
}

export default NavSubVX;
export { NavSubVX };
