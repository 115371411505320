import React, { FC, KeyboardEvent, useState } from 'react';
import { _ } from '../../../../../util/translate';
import { Box, TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { SEARCH_LIMIT_PER_PAGE } from '../SearchResults/utils';
import { HelpCentersearchArgs } from '../../../../../graphql/VXModels/types';

interface Props {
  onSearch: (variables: HelpCentersearchArgs) => void;
}

const SearchField: FC<Props> = ({ onSearch }) => {
  const [searchText, setSearchText] = useState(() => '');

  const search = (): void => onSearch({ query: searchText, page: 1, limit: SEARCH_LIMIT_PER_PAGE });
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    // 'enter' button
    if (e.keyCode == 13) {
      search();
    }
  };

  return (
    <Box display="flex" justifyContent="center" pl={'24px'} pr={'33px'}>
      <Box width="100%">
        <TextField
          fullWidth
          placeholder={_('common:input.placeholder.search')}
          value={searchText}
          onChange={(e): void => setSearchText(e.target.value)}
          onKeyDown={onKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={search}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default SearchField;
