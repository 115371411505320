/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import styled from '@emotion/styled';

import { T } from '../../../components';
import { getFormattedDate } from '../../../util/Formatter';
import { BLUE, GRAY_2, RED, YELLOW, DARK_GRAY } from '../../../camtool-styles';

import { getGroupIcon } from './utils';
import { withAppState } from '../../../util/AppState';
import { _ } from '../../../util/translate';

export const Row = styled.div`
  border-radius: 4px;
  flex-direction: column;
  background-color: white;
  margin: 4px 0;
  padding: 24px 16px;
  justify-content: space-between;
  ${(props) => props.classname};
  min-width: 1105px;
`;

export const RowContent = styled.div`
  ${(props) => props.className};
`;

export const Cell = styled.div`
  flex-direction: ${(props) => (props.contentAsColum ? 'column' : 'row')};
  flex: ${(props) => (props.flex ? props.flex : 'auto')};
  align-self: start;
  justify-content: flex-start;
  align-items: flex-start;
  ${(props) => props.className};
`;

export const BorderButton = styled.button`
  border: 1px solid #999;
  background-color: transparent;
  color: #999;
  padding: 6px 24px;
  border-radius: 4px;
  &:hover {
    background-color: #999;
    color: #fff;
  }
`;

export const ButtonLink = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
`;

export const RowHeader = () => (
  <Row css={{ backgroundColor: 'transparent', padding: '0 16px' }}>
    <RowContent>
      <Cell
        flex={4}
        css={{
          color: '#888',
          fontWeight: 'bold',
          letterSpacing: 1,
          justifyContent: 'flex-start',
        }}
      >
        <T _={'account:documents.tableHeader.document'} />
      </Cell>
      <Cell flex={1} />
      <Cell flex={2} css={{ color: '#888', fontWeight: 'bold', letterSpacing: 1 }}>
        <T _={'account:documents.tableHeader.uploadDate'} />
      </Cell>
      <Cell flex={3} css={{ color: '#888', fontWeight: 'bold', letterSpacing: 1 }}>
        <T _={'account:documents.tableHeader.forPayoutRequired'} />
      </Cell>
      <Cell flex={2} css={{ color: '#888', fontWeight: 'bold', letterSpacing: 1 }}>
        <T _={'account:documents.tableHeader.status'} />
      </Cell>
      <Cell flex={2} css={{ color: '#888', fontWeight: 'bold', letterSpacing: 1 }}>
        <T _={'account:documents.tableHeader.actions'} />
      </Cell>
      <Cell flex={3} css={{ color: '#888', fontWeight: 'bold', letterSpacing: 1 }}>
        <T _={'account:documents.tableHeader.templates'} />
      </Cell>
    </RowContent>
  </Row>
);

const EXPIRES_ON = 28;

export const RowHeaderNoIcon = () => (
  <Row css={{ backgroundColor: 'transparent', padding: '0 16px', flex: '0 0 auto' }}>
    <RowContent>
      <Cell
        flex={4}
        css={{
          color: '#888',
          fontWeight: 'bold',
          letterSpacing: 1,
          justifyContent: 'flex-start',
        }}
      >
        <T _={'account:documents.tableHeader.document'} />
      </Cell>
      <Cell flex={2} css={{ color: '#888', fontWeight: 'bold', letterSpacing: 1 }}>
        <T _={'account:documents.tableHeader.uploadDate'} />
      </Cell>

      <Cell flex={3} css={{ color: '#888', fontWeight: 'bold', letterSpacing: 1 }}>
        <T _={'account:documents.tableHeader.status'} />
      </Cell>

      <Cell flex={2} css={{ color: '#888', fontWeight: 'bold', letterSpacing: 1 }}>
        <T _={'account:documents.tableHeader.reason'} />
      </Cell>

      <Cell flex={2} css={{ color: '#888', fontWeight: 'bold', letterSpacing: 1 }}>
        <T _={'account:documents.tableHeader.actions'} />
      </Cell>
      <Cell flex={2} css={{ color: '#888', fontWeight: 'bold', letterSpacing: 1 }}>
        <T _={'account:documents.tableHeader.delete'} />
      </Cell>
    </RowContent>
  </Row>
);

class DocumentGroup extends React.Component {
  openFile = (path) => window.open(path).focus();

  renderMainButton = () => {
    const { isGroupOpen, documents, closeGroup, openGroup } = this.props;

    if (documents.length <= 0) return null;
    else {
      if (isGroupOpen)
        return (
          <BorderButton onClick={closeGroup}>
            <T _={'account:documents.button.hide'} />
          </BorderButton>
        );
      else
        return (
          <BorderButton onClick={openGroup}>
            <T _={'account:documents.button.display'} />
          </BorderButton>
        );
    }
  };

  renderExpiredBadge(type, documents) {
    if (type === 'businnesRegistration' || type === 'vat') {
      let expireDate = null;
      documents.map(
        (doc, index) => (expireDate = !doc.rejectionReason ? doc.expireDate : expireDate)
      );
      if (expireDate) {
        const daysLeft2Expire = Math.floor(
          (new Date(expireDate) - new Date()) / 1000 / 60 / 60 / 24
        );
        if (daysLeft2Expire <= 30) {
          return (
            <div
              css={{
                backgroundColor: '#ff4d3c',
                color: '#fff',
                padding: '4px 8px',
                borderRadius: 4,
                fontSize: 10,
                letterSpacing: 0.5,
                marginBottom: 4,
              }}
            >
              {daysLeft2Expire < 1 && _('account:documents.originator.expired')}
              {daysLeft2Expire === 1 && _('account:documents.originator.expiresInOneDay')}
              {daysLeft2Expire > 1 &&
                _('account:documents.originator.expiresInDays', {
                  postProcess: 'sprintf',
                  sprintf: [daysLeft2Expire],
                })}
            </div>
          );
        }
      }
    }
    return null;
  }

  renderState = (type, status) => {
    if (status === 'ok') {
      return <Cell flex={1}>{getGroupIcon(type, BLUE)}</Cell>;
    } else if (status === 'checking') {
      return <Cell flex={1}>{getGroupIcon(type, YELLOW)}</Cell>;
    } else if (status === 'missing') {
      return <Cell flex={1}>{getGroupIcon(type, GRAY_2)}</Cell>;
    } else {
      return <Cell flex={1}>{getGroupIcon(type, RED)}</Cell>;
    }
  };

  render() {
    const {
      isGroupOpen,
      documents,
      required,
      title,
      description,
      exampleDocumentUrl,
      templateDocumentUrl,
      type,
      removeLink,
      status,
      appState: [{ lang }],
    } = this.props;
    return (
      <Row>
        <RowContent>
          {type && this.renderState(type, status)}
          <Cell contentAsColum={true} flex={4} css={{ alignItems: 'flex-start' }}>
            {documents.length > 0 && this.renderExpiredBadge(type, documents)}
            <div
              css={{
                fontWeight: type ? 'bold' : '',
                fontSize: '1.3em',
                color: status === 'denied' ? RED : DARK_GRAY,
              }}
            >
              {type ? _('account:documents.name.' + title) : title}
            </div>
            <div css={{ fontSize: '.9em', color: status === 'denied' ? RED : DARK_GRAY }}>
              {description && _('account:documents.description.' + description)}
            </div>
          </Cell>
          <Cell flex={2}></Cell>
          <Cell flex={3}>
            <T _={`account:documents.required.${required ? 'yes' : 'no'}`} />
          </Cell>
          {status === 'ok' ? (
            <Cell flex={2} css={{ color: BLUE }}>
              <span className="icon-ok" /> <T _={`account:documents.status.${status}`} />
            </Cell>
          ) : status === 'checking' ? (
            <Cell flex={2} css={{ color: YELLOW }}>
              <span className="icon-roundabout" css={{ marginRight: '5px' }} />
              <T _={`account:documents.status.${status}`} />
            </Cell>
          ) : status === 'missing' ? (
            <Cell flex={2} css={{ color: GRAY_2 }}>
              <span className="icon-remove" /> <T _={`account:documents.status.${status}`} />
            </Cell>
          ) : (
            <Cell flex={2} css={{ color: RED }}>
              <span className="icon-remove" /> <T _={`account:documents.status.${status}`} />
            </Cell>
          )}
          <Cell flex={2}>{this.renderMainButton()}</Cell>
          {removeLink ? (
            <Cell flex={2} css={{ color: '#ff4d3c', a: { color: '#ff4d3c' } }}>
              <span className="icon-remove" />
              {` `}
              <a href="#">
                <T _={'account:documents.button.remove'} />
              </a>
            </Cell>
          ) : (
            <Cell flex={3} contentAsColum={true}>
              {exampleDocumentUrl && (
                <ButtonLink onClick={() => window.open(exampleDocumentUrl).focus()}>
                  <T _={'account:documents.button.showExample'} />
                </ButtonLink>
              )}
              {templateDocumentUrl && (
                <ButtonLink onClick={() => window.open(templateDocumentUrl).focus()}>
                  <T _={'account:documents.button.showTemplate'} />
                </ButtonLink>
              )}
            </Cell>
          )}
        </RowContent>
        {documents.length > 0 && isGroupOpen
          ? documents.map((doc, index) => {
              //T22786 Ticket
              if (
                status === 'rejected'
                  ? index <= 0
                  : status === 'ok' && type === 'identityProof'
                  ? index <= 1
                  : index <= 4
              ) {
                return (
                  <RowContent key={doc.fileName} css={{ marginTop: 24 }}>
                    <Cell flex={1} />
                    <Cell flex={4} css={{ justifyContent: 'flex-start' }}>
                      <div css={{ fontSize: '1.3em', color: '#999' }}>{doc.fileName}</div>
                    </Cell>
                    <Cell flex={2}>{getFormattedDate(doc.insertDate, lang)}</Cell>
                    <Cell flex={3} />
                    <Cell flex={2} css={{ textAlign: 'left', justifyContent: 'left' }}>
                      {doc.rejectionReason}
                    </Cell>
                    <Cell flex={2}>
                      <BorderButton onClick={() => this.openFile(doc.url)}>
                        <T _={'account:documents.button.open'} />
                      </BorderButton>
                    </Cell>
                    <Cell flex={3} />
                  </RowContent>
                );
              }
            })
          : null}
      </Row>
    );
  }
}

const DocumentGroupWithAppState = withAppState(DocumentGroup);

export { DocumentGroupWithAppState as DocumentGroup };
