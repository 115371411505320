import { Box, Card, CardContent, Container, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { CardHeaderInverted } from '../../../atoms';
import { GRAY_2 } from '../../../camtool-styles';
import { _ } from '../../../util/translate';
import { UploadManagerQueue } from '../components';
import FtpCredentials from '../components/FtpCredentials';
import UploadField from '../components/UploadField';
import { useFilteredQueue } from '../hooks';
import { MediaTypeEnum } from '../types';

interface Props {
  mediaType: MediaTypeEnum;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: () => ({
      margin: -4,
    }),
    root: () => ({
      paddingBottom: 8,
      maxWidth: 'none',
    }),
    flex: () => ({
      display: 'flex',
      flex: 1,
    }),
  })
);

export const UploadFieldAndUploadQueue: FC<Props> = ({ mediaType }) => {
  const { container, root, flex } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const textCurrentlyUploading = _('UploadManager:uploadQueue.currentlyUploading.text');
  const textType = _('UploadManager:mediaType.' + mediaType + '.label');
  const queue = useFilteredQueue({ mediaType });

  return (
    <Box>
      <Grid container spacing={2} classes={{ root }}>
        <Grid
          item
          container
          lg={6}
          md={12}
          xs={12}
          direction={'column'}
          style={{ minHeight: 200 }}
          justify={'flex-start'}
        >
          <Grid item classes={{ root: flex }}>
            <Card square variant={'elevation'} elevation={0} classes={{ root: flex }}>
              <CardContent classes={{ root: flex }}>
                <UploadField multiple mediaType={mediaType} />
              </CardContent>
            </Card>
          </Grid>
          {mediaType === MediaTypeEnum.video && (
            <Grid item style={{ paddingTop: 16 }}>
              <Card square variant={'elevation'} elevation={0}>
                <CardContent classes={{ root: flex }}>
                  <FtpCredentials />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <Card
            square
            variant={'elevation'}
            elevation={0}
            style={{ minHeight: 200, height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <CardHeaderInverted title={`${textCurrentlyUploading}: ${queue.length} ${textType}`} />
            <CardContent classes={{ root: flex }} style={{ minHeight: 200 }}>
              <UploadManagerQueue mediaType={mediaType} maxHeight={350}>
                <Box
                  display={'flex'}
                  flex={1}
                  justifyContent={'center'}
                  alignItems={'center'}
                  height="100%"
                >
                  <Box color={GRAY_2}>{_('UploadManager:uploadQueue.emptyQueue.text')}</Box>
                </Box>
              </UploadManagerQueue>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
