import React from 'react';
import PropTypes from 'prop-types';
import EmptyContent from '../EmptyContent/EmptyContent';
import HelpCenterSection from '../HelpCenterSection/HelpCenterSection';
import { _ } from '../../util/translate';

const HelpCenterSections = ({ category }) => {
  const { name, sections = [], ...rest } = category;

  return (
    <div className="grid__row helpcenter-articles__section grid__box" css={{ minHeight: 155 }}>
      {sections.length === 0 && (
        <EmptyContent
          icon="icon-folder-open"
          title={_('help:helpcenter.sections.noArticlesFound')}
        />
      )}
      {sections.map((section) => (
        <HelpCenterSection
          key={section.id}
          category={category}
          section={section}
          header={section.name}
        />
      ))}
    </div>
  );
};

HelpCenterSections.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

HelpCenterSections.defaultProps = {
  category: {
    name: '',
    sections: {},
  },
};

export default HelpCenterSections;
export { HelpCenterSections };
