import React, { FC } from 'react';
import NavSub from '../../components/NavSub/NavSub';
import NavSubItem from '../../components/NavSubItem/NavSubItem';
import { CREDITS, SITE } from './routes';
import { _ } from '../../util/translate';

interface IProps {
  basePath: string;
}

const Navigation: FC<IProps> = ({ basePath }) => (
  <NavSub>
    <NavSubItem
      link={`${basePath}/${SITE}`}
      icon="icon-pie-chart"
      label={_('statistics:navigation.site')}
    />
    <NavSubItem
      link={`${basePath}/${CREDITS}`}
      icon="icon-money"
      label={_('statistics:navigation.credits')}
    />
  </NavSub>
);

export default Navigation;
