import React from 'react';
import { Redirect, Switch } from 'react-router';
import { stripSlash } from '../../util/urlHelper';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import Overview from './Overview';
import Details from './Details';
import { useHasRole, useUserData } from '../../util/UserData';
import { SecurityRole } from '../../graphql/VXModels/types';

const UserManagement = ({ match: { url } }) => {
  const path = stripSlash(url);
  const overviewRoute = `${path}/`;
  const detailsRoute = `${path}/:id`;

  const isVXPagesAccount = useHasRole(SecurityRole.VX_USER_VXPAGES);
  const isVXPageInLandingPageMode = useUserData().model.vxpages.info.isInLandingPageMode;

  return (
    <Switch>
      {(!isVXPagesAccount || isVXPageInLandingPageMode !== false) && <Redirect to={''} />}
      <ProtectedRoute path={overviewRoute} exact component={Overview} />
      <ProtectedRoute path={detailsRoute} exact component={Details} />
      <Redirect to={overviewRoute} />
    </Switch>
  );
};

export default UserManagement;
