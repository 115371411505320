import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { conversation } from '../../../../../atoms/Icon/libraries/glyph';
import { useHasRole } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';

const MessengerItem: FC = () => {
  const hasVXPage = useHasRole(SecurityRole.VX_USER_VXPAGES);

  return hasVXPage ? (
    <LeftMenuItem
      title={_('navigation:marketing.messenger')}
      icon={conversation}
      uri="/mailings/messenger"
    />
  ) : null;
};

export default MessengerItem;
