import React, { ReactElement } from 'react';
import { WHITE } from '../../../camtool-styles';

const IdShot = (): ReactElement => {
  return (
    <path
      fill={WHITE}
      d="M7.4,28c-0.1,0-0.1,0-0.2,0C7.1,28,7,27.9,7,27.8c0-0.1,0-0.1,0-0.2v-2c0-0.3,0-0.7,0.2-1
	s0.4-0.5,0.7-0.6c2.9-1.6,4.8-2.7,5.8-3.1v-3.1c-0.6-0.4-0.9-1.1-0.8-1.9v-2.5c0-0.5,0.1-1,0.2-1.5c0.2-0.4,0.4-0.8,0.7-1.2
	s0.7-0.6,1.2-0.8c1.1-0.4,2.2-0.4,3.3,0c0.5,0.2,0.9,0.5,1.2,0.8s0.5,0.7,0.7,1.2s0.2,1,0.2,1.5v2.5c0.1,0.7-0.3,1.4-0.8,1.9v3.1
	c0.2,0.1,0.5,0.2,0.9,0.4c0.3,0.2,0.7,0.3,1,0.5s0.7,0.4,1,0.5c0.3,0.2,0.7,0.4,1,0.6l0.9,0.5c0.2,0.1,0.5,0.3,0.7,0.4
	s0.4,0.2,0.4,0.2c0.3,0.1,0.5,0.4,0.7,0.6c0.1,0.3,0.2,0.7,0.2,1v2c0,0.2-0.2,0.4-0.4,0.4l0,0L7.4,28z M23.5,20.3
	c-0.4,0-0.7-0.3-0.7-0.7v-5.4c0-0.4,0.3-0.7,0.7-0.7h7.7c0.4,0,0.7,0.3,0.7,0.7v5.4c0,0.4-0.3,0.7-0.7,0.7h-1.6v-0.4v-0.1h-0.1h-0.3
	c-0.1,0-0.1,0.1-0.1,0.1l0,0v0.4h-3.4v-0.4v-0.1h-0.1h-0.4h-0.1v0.1v0.4H23.5L23.5,20.3z M24.4,16.6c-0.1,0.1-0.2,0.2-0.3,0.3
	S24,17.2,24,17.4s0,0.3,0,0.5s0.1,0.3,0.2,0.5c0.1,0.1,0.2,0.2,0.4,0.2h2.3c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.5
	s0-0.4,0-0.5c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c0,0-0.1,0-0.1,0.1l-0.2,0.1
	c0,0-0.1,0-0.2,0.1c-0.2,0.1-0.4,0.1-0.6,0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1L25,16.6c-0.1,0-0.1-0.1-0.1-0.1
	C24.6,16.4,24.5,16.5,24.4,16.6L24.4,16.6z M28.1,17.4c-0.1,0-0.1,0.1-0.1,0.1l0,0v0.3v0.1h0.1h2.6c0.1,0,0.1-0.1,0.1-0.1l0,0v-0.3
	v-0.1h-0.1H28.1z M28.1,16.3L28.1,16.3L28,16.4v0.2c0,0.1,0.1,0.2,0.2,0.2l0,0h2.5c0.1,0,0.2-0.1,0.2-0.2l0,0v-0.3v-0.1h-0.1l0,0
	L28.1,16.3z M24.9,14.9c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0s0.4-1,0-1.4c-0.2-0.2-0.4-0.3-0.7-0.3l0,0C25.3,14.6,25.1,14.7,24.9,14.9
	L24.9,14.9z M28,15.1c-0.1,0-0.1,0.1-0.1,0.1l0,0v0.3v0.1H28h2.6c0.1,0,0.1-0.1,0.1-0.1l0,0v-0.3c0-0.1-0.1-0.1-0.1-0.1l0,0H28z"
    />
  );
};

export default IdShot;
