export { default as detail_bas } from './bassharingimage.png';
export { default as tile_bas } from './BA6-2.png';
export { default as banner_bas } from './banner_beianrufsex.png';

export { default as detail_recruitGuest } from './recruitguestsimage.png';
export { default as tile_recruitGuest } from './Kunden-werben-2.png';
export { default as banner_recruitGuest } from './banner_wirb-neue-kunden.png';

export { default as detail_livechat } from './livechatimage.png';
export { default as tile_livechat } from './Livechat-provision-2.png';
export { default as banner_livechat } from './banner_livechat-provision.png';

export { default as detail_existingCustomer } from './bestandskundenprovisionimage.png';
export { default as tile_existingCustomer } from './Bestandskunden-Provision-2.png';
export { default as banner_existingCustomer } from './banner_bestandskunden-provision.png';

export { default as detail_newGuest } from './neukundenprovisionimage.png';
export { default as tile_newGuest } from './Neukunden-Provision-2.png';
export { default as banner_newGuest } from './banner_neukunden-provision.png';

export { default as detail_videoSales } from './videosalesimage.png';
export { default as tile_videoSales } from './Video-verkaeufe-2.png';
export { default as banner_videoSales } from './banner_video-verkaeufe.png';

export { default as tile_ticketshow } from './image-ticket-shows-kachel.png';
export { default as detail_ticketShow } from './image-ticket-shows.png';
export { default as banner_ticketShow } from './image-ticket-shows-small.png';

export { default as banner_messenger } from './messenger-verkaeufe-banner.png';
export { default as tile_messenger } from './messenger-verkaeufe-card.png';
export { default as detail_messenger } from './messenger-verkaeufe-detailpage.png';

export { default as background } from './background.png';
export { default as competition_completed_icon } from './check-circle.png';
