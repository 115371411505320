import React from 'react';
import PropTypes from 'prop-types';

import { PropContestType } from './Props';

const CampaignHeaderTitle = ({ type, title }) => (
  <div className="grid__box__sub__header" style={{ justifyContent: 'space-between' }}>
    <div
      className={`${type === 'photo' ? 'icon-camera' : 'icon-camera-small'} grid__box__sub__header__item`}
      style={{ flex: 'none' }}
      css={{ fontWeight: 'bold' }}
    >
      {title}
    </div>
  </div>
);

CampaignHeaderTitle.propTypes = {
  type: PropContestType,
  title: PropTypes.string.isRequired,
};

export default CampaignHeaderTitle;
