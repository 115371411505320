import { AbstractRestApiStoreClass, AbstractRestApiActionCreatorsClass } from '../AbstractRestApi';
import { SessionStore } from '../Session/Session';
import ServerUtils from '../../util/ServerUtils';
import * as http from 'superagent';
import * as ActionTypes from '../../util/constants/ActionTypes';
import remove from 'lodash/remove';
import AppDispatcher from '../../util/dispatcher/AppDispatcher';

// define resources
const RESOURCE_ALBUM = 'ALBUM';
const RESOURCE_ALBUM_PICTURE_POOL = 'ALBUM_PICTURE_POOL'; // avoid overwrite of other album during selection from picture pool
const RESOURCE_ALBUMS = 'ALBUMS';
const RESOURCE_PICTURE = 'PICTURE';
const RESOURCE_PICTURES = 'PICTURES';
const RESOURCE_VIDEOS = 'VIDEOS';
const RESOURCE_VIDEO = 'VIDEO';
const RESOURCE_VIDEO_SALES = 'VIDEO_SALES';
const RESOURCE_PHOTOALBUM_SALES = 'PHOTOALBUM_SALES';
const RESOURCE_VIDEO_FEEDBACK = 'VIDEO_FEEDBACK';
const RESOURCE_PHOTOALBUM_FEEDBACK = 'PHOTOALBUM_FEEDBACK';

const DEPRECATED_PICTURE_NEW = 'PICTURE_NEW';
const DEPRECATED_PICTURE_PROFILE_LA = 'PICTURE_PROFILE_LA';

const SERVER_EVENT = 'SERVER_EVENT';

export const MAPPING_PICTURE_TYPE = {
  mainProfilePicture: 21,
  titleProfilePicture: 22,
  shop: 20,
  sedcard: 15,
  profile: 15, // deprecated, now sedcard
  communityProfile: 15,
  flirtlifeProfile: 15,
  free: 14,
  campaign: 14,
  contest: 14,
  videoPreview16: 17,
  videoPreview18: 17,
  vxhpmediapool: 18,
};

const albumDefaultData = {
  id: 0,
  albumType: '',
  mediatype: '',
  status: '',
  titles: {},
  descriptions: {},
  titlesVXPages: {},
  descriptionsVXPages: {},
  titlesPornMe: {},
  descriptionsPornMe: {},
  tags: [],
  uploaded: '',
  previewUrl: '',
  rating: 0,
  sales: 0,
  price: 0,
  numberOfReleasedPictures: 0,
  numberOfAllPictures: 0,
  numberOfRejectedPictures: 0,
  numberOfUncheckedPictures: 0,
  totalFeedbacks: 0,
  unansweredFeedbacks: 0,
  rejectionReason: {},
  pictures: [],
  totalGuestPrice: 0,
  totalHostPrice: 0,
  numberOfAlbumLikes: 0,
  numberOfAlbumDislikes: 0,
  salesTotalVX: 0,
  salesAmountVX: 0,
  salesTotalVXPage: 0,
  allIDCardsUploaded: false,
  allModelReleaseFormsUploaded: false,
};

const videoDefaultData = {
  albumId: 0,
  albumType: '',
  status: '',
  titles: {},
  descriptions: {},
  titlesVXPages: {},
  descriptionsVXPages: {},
  titlesPornMe: {},
  descriptionsPornMe: {},
  tags: [],
  uploaded: '',
  releaseDate: '',
  previewUrl16: '',
  previewUrl18: '',
  previewPicId16: 0,
  previewPicId18: 0,
  rating: 0,
  sales: 0,
  price: 0,
  totalFeedbacks: 0,
  unansweredFeedbacks: 0,
  rejectionReason: {},
  videoURL: '',
  longitude: 0,
  latitude: 0,
  originalFilename: '',
  videoIdShots: [],
  pictures: [],
  totalGuestPrice: 0,
  totalHostPrice: 0,
  numberOfAlbumLikes: 0,
  numberOfAlbumDislikes: 0,
  allIDCardsUploaded: false,
  allModelReleaseFormsUploaded: false,
};

const config = {
  name: 'Media',
  resources: {
    ALBUMS: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/album',
        POST: '/v1/camtool/user/{userId}/media/album',
      },
      defaultData: {
        albums: [],
        albumType: '',
        totalAlbums: 0,
      },
      appendFunc: (currentData, nextData) => {
        for (const i in nextData.albums) {
          currentData.albums.push(nextData.albums[i]);
        }
        return currentData;
      },
    },
    ALBUM: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/album/{albumId}',
        PUT: '/v1/camtool/user/{userId}/media/album/{albumId}',
        DELETE: '/v1/camtool/user/{userId}/media/album/{albumId}',
      },
      defaultData: {
        album: albumDefaultData,
      },
    },
    PHOTOALBUM_SALES: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/album/{albumId}/sales',
      },
      defaultData: {
        album: albumDefaultData,
      },
    },
    PHOTOALBUM_FEEDBACK: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/album/{albumId}/feedbacks',
      },
      defaultData: {
        album: albumDefaultData,
      },
    },
    ALBUM_PICTURE_POOL: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/album/_pool',
      },
      defaultData: {
        album: albumDefaultData,
      },
    },
    PICTURES: {
      uri: {
        POST: '/v1/camtool/user/{userId}/media/album/{albumId}/picture',
      },
      defaultData: {
        album: albumDefaultData,
      },
    },
    PICTURE: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/album/{albumId}/picture/{pictureId}',
        PUT: '/v1/camtool/user/{userId}/media/album/{albumId}/picture/{pictureId}',
        DELETE: '/v1/camtool/user/{userId}/media/album/{albumId}/picture/{pictureId}',
      },
      defaultData: {
        album: albumDefaultData,
      },
    },

    VIDEOS: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/videoalbum',
        POST: '/v1/camtool/user/{userId}/media/videoalbum',
      },
      defaultData: {
        entries: [],
        totalEntries: 0,
      },
      appendFunc: (currentData, nextData) => {
        for (const i in nextData.entries) {
          currentData.entries.push(nextData.entries[i]);
        }
        return currentData;
      },
    },

    VIDEO: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/videoalbum/{umaId}',
        PUT: '/v1/camtool/user/{userId}/media/videoalbum/{umaId}',
        DELETE: '/v1/camtool/user/{userId}/media/videoalbum/{umaId}',
      },
      defaultData: videoDefaultData,
    },
    VIDEO_SALES: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/videoalbum/{umaId}/sales',
      },
      defaultData: videoDefaultData,
    },
    VIDEO_FEEDBACK: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/videoalbum/{umaId}/feedbacks',
      },
      defaultData: videoDefaultData,
    },

    // prepare to deprecated
    PICTURE_NEW: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/album/_new',
      },
      defaultData: {
        album: albumDefaultData,
      },
    },
    PICTURE_PROFILE_LA: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/album/_profileLA',
      },
      defaultData: {
        album: albumDefaultData,
      },
    },
  },
};

function resolvePictureTypeByName(pictureTypeName) {
  return MAPPING_PICTURE_TYPE[pictureTypeName];
}

class MediaManagementStoreClass extends AbstractRestApiStoreClass {
  constructor(config) {
    super(config);
    AppDispatcher.register((payload) => {
      switch (payload.type) {
        case ActionTypes.MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA:
          switch (payload.data.event) {
            case 'media.video.transcoding.finished':
              if (payload.data.albumType === '53_6') {
                // pool video
                SessionStore.update({ media: { video: { hasUnusedVideos: true } } });
              }
              break;
          }
          this.emit(SERVER_EVENT + '::' + payload.type, payload.data);
          break;
      }
    });
  }

  addServerEventListener(type, callback) {
    this.on(SERVER_EVENT + '::' + type, callback);
  }

  removeServerEventListener(type, callback) {
    this.removeListener(SERVER_EVENT + '::' + type, callback);
  }
}

const MediaManagementStore = new MediaManagementStoreClass(config);

class MediaManagementActionCreatorsClass extends AbstractRestApiActionCreatorsClass {
  // @todo: refactor to PUT/POST /v1/camtool/user/{userId}/media/album/{umaId}/picture
  addPictureFromPool(picture, nextUmaId, onSuccess) {
    const pictureType = MAPPING_PICTURE_TYPE[picture.pictureType];
    const params = {
      targetUmaId: nextUmaId,
      pos: picture.position,
      umpType: pictureType,
    };
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/media/album/' +
        picture.albumId +
        '/picture/' +
        picture.pictureId +
        '/addToAlbum',
      { data: JSON.stringify(params) },
      (response) => {
        this.receiveData(RESOURCE_ALBUM, response);
        if (onSuccess) onSuccess(response.album);
      }
    );
  }

  setPictureAsPreview(albumId, pictureId, fsk, onSuccess) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/media/album/' +
        albumId +
        '/picture/' +
        pictureId +
        '/setPreviewPicture',
      { data: JSON.stringify({ fsk: fsk }) },
      (response) => {
        this.receiveData(RESOURCE_ALBUM, response);
        if (onSuccess) onSuccess(response.album);
      }
    );
  }

  movePictureToPosition(albumId, pictureId, newPosition, onSuccess) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/media/album/' +
        albumId +
        '/picture/' +
        pictureId +
        '/switchPicturePosition',
      { data: JSON.stringify({ newPos: newPosition }) },
      (response) => {
        this.receiveData(RESOURCE_ALBUM, response);
        if (onSuccess) onSuccess(response.album);
      }
    );
  }

  movePictureToPool(albumId, pictureId, onSuccess) {
    const params = {
      targetUmaId: SessionStore.get().picturePoolUmaId,
    };
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/media/album/' + albumId + '/picture/' + pictureId + '/addToAlbum',
      { data: JSON.stringify(params) },
      (response) => {
        this.receiveData(RESOURCE_ALBUM, response);
        if (onSuccess) onSuccess(response.album);
      }
    );
  }

  getPictureFromAlbum(albumId, pictureId, onSuccess) {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/media/album/' + albumId + '/picture/' + pictureId,
      null,
      function (response) {
        onSuccess(response.picture);
      }
    );
  }

  applyRatingFromOriginalPicture(
    sourceAlbumId,
    sourcePictureId,
    umaId,
    uploadedPictureId,
    onSuccess
  ) {
    const params = {
      sourceAlbumId: sourceAlbumId,
      sourcePictureId: sourcePictureId,
    };
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/media/album/' +
        umaId +
        '/picture/' +
        uploadedPictureId +
        '/copyRating',
      { data: JSON.stringify(params) },
      function (response) {
        onSuccess(response.result);
      }
    );
  }

  deletePictureFromAlbum(picture, onSuccess, onError) {
    ServerUtils.request(
      'DELETE',
      '/v1/camtool/user/{userId}/media/album/' + picture.album.id + '/picture/' + picture.pictureId,
      null,
      (response) => {
        if (response.result === true) {
          const storeData = MediaManagementStore.get(RESOURCE_ALBUM);
          remove(storeData.album.pictures, function (p) {
            return p.pictureId === picture.pictureId && p.albumId === picture.albumId;
          });
          this.receiveData(RESOURCE_ALBUM, storeData);
          if (onSuccess) onSuccess(storeData.album);
        }
      },
      (err, response) => {
        if (onError) onError(err, response);
      }
    );
  }

  uploadPicture(
    uploadUrl,
    sessionId,
    umaId,
    pictureType,
    pos,
    file,
    sourceAlbumId,
    sourcePictureId,
    onSuccess,
    onError
  ) {
    // This only creates pictureId and calls onSuccess()
    // e.g Moving from Pool to ViewAlbum should be done in onSuccess()
    try {
      const type = MAPPING_PICTURE_TYPE[pictureType];
      umaId = umaId !== 0 ? umaId : SessionStore.get().picturePoolUmaId;
      const query = {
        sid: sessionId,
        type: type,
        uma_id: umaId,
      };

      if (pos !== null) {
        query.pos = pos;
      }

      const uploadForm = new FormData();
      uploadForm.append('file', file);

      //Errors: "errorcode=-5"
      // 1 Session check failed
      // 2 UMA ID not found
      // 3 File extension wrong
      // 4 Max file size reached
      // 5 Too small
      // 7 Oracle Error
      // 8 Duplicate
      // 9 Monochrome
      http
        .post(uploadUrl)
        .send(uploadForm)
        .query(query)
        .end((error, response) => {
          if (!error) {
            if (response.text.indexOf('id=') !== -1) {
              const uploadedPictureId = response.text.split('id=')[1];
              this.applyRatingFromOriginalPicture(
                sourceAlbumId,
                sourcePictureId,
                umaId,
                uploadedPictureId,
                (result) => {
                  this.getPictureFromAlbum(umaId, uploadedPictureId, (picture) => {
                    onSuccess(picture);
                  });
                }
              );
            } else {
              onError(response.text);
            }
          } else {
            onError(response.text);
          }
        });
    } catch (e) {
      onError('uploadError');
    }
  }
}

const MediaManagementActionCreators = new MediaManagementActionCreatorsClass(config);

const PICTURE_TYPES = Object.keys(MAPPING_PICTURE_TYPE);

export {
  MediaManagementStore,
  MediaManagementActionCreators,
  RESOURCE_ALBUM,
  RESOURCE_ALBUMS,
  RESOURCE_PICTURE,
  RESOURCE_PICTURES,
  RESOURCE_VIDEOS,
  RESOURCE_VIDEO,
  RESOURCE_VIDEO_SALES,
  RESOURCE_PHOTOALBUM_SALES,
  RESOURCE_VIDEO_FEEDBACK,
  RESOURCE_PHOTOALBUM_FEEDBACK,
  PICTURE_TYPES,
  resolvePictureTypeByName,
  DEPRECATED_PICTURE_PROFILE_LA,
  RESOURCE_ALBUM_PICTURE_POOL,
  DEPRECATED_PICTURE_NEW,
};
