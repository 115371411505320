import React, { FC } from 'react';
import { VXPagesUser } from '../../../../../../graphql/VXModels/types';
import { Field, FieldLabel, FieldValue } from './Base';
import { _ } from '../../../../../../util/translate';

interface IProps {
  user: VXPagesUser;
  canSeeDirectUserData: boolean;
}

const EmailField: FC<IProps> = ({ user, canSeeDirectUserData }) => {
  const { email, isEmailVerified, isSubscribedToNewsletter } = user;

  return (
    <Field>
      <FieldLabel>
        {canSeeDirectUserData
          ? _('userManagement:field.email')
          : _('userManagement:field.emailVerified')}
      </FieldLabel>
      <FieldValue>
        {canSeeDirectUserData
          ? isEmailVerified
            ? isSubscribedToNewsletter
              ? email
              : _('userManagement:email.unsubscribedFromNewsletter')
            : _('userManagement:email.notVerified')
          : isEmailVerified
          ? _('common:text.yes')
          : _('common:text.no')}
      </FieldValue>
    </Field>
  );
};

export default EmailField;
