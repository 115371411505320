import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { notes2 } from '../../../../../atoms/Icon/libraries/glyph';
import { useHasRole } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';

const UsernotesItem: FC = () => {
  const hasRoleVXModels = useHasRole(SecurityRole.VX_USER_VXMODELS);
  const isSexoleModel = useHasRole(SecurityRole.VX_USER_SEXOLE);

  return hasRoleVXModels && !isSexoleModel ? (
    <LeftMenuItem
      title={_('navigation:videochat.usernotes')}
      icon={notes2}
      uri="/settings/usernotes"
    />
  ) : null;
};

export default UsernotesItem;
