import React from 'react';
import PropTypes from 'prop-types';

import { T } from '../../../../components';

import { RowHeader, DocumentGroup } from '../components';
import { _ } from '../../../../util/translate';

class Model extends React.Component {
  state = {
    isGroupOpen: {
      identityProof: false,
      idShot: false,
      modelReleaseForm: false,
    },
  };

  /** opens group named `groupName` and closes all the others */
  openGroup = (groupName) => {
    const currentIsGroupOpen = this.state.isGroupOpen;
    Object.keys(currentIsGroupOpen).forEach((key) => (currentIsGroupOpen[key] = key === groupName));
    this.setState({ isGroupOpen: { ...currentIsGroupOpen } });
  };

  /** closes group named `groupName` */
  closeGroup = (groupName) => {
    this.setState({ isGroupOpen: { ...this.state.isGroupOpen, [groupName]: false } });
  };

  renderRows = () => {
    return this.props.data.documentTypes.map((docsGroup) => {
      return (
        <DocumentGroup
          key={docsGroup.type}
          title={docsGroup.type}
          description={docsGroup.type}
          openGroup={() => this.openGroup(docsGroup.type)}
          closeGroup={() => this.closeGroup(docsGroup.type)}
          isGroupOpen={this.state.isGroupOpen[docsGroup.type]}
          documents={docsGroup.documents}
          required={docsGroup.isRequired}
          exampleDocumentUrl={docsGroup.exampleDocumentUrl}
          templateDocumentUrl={docsGroup.templateDocumentUrl}
          type={docsGroup.type}
          status={docsGroup.status}
          rejectionReason={docsGroup.rejectionReason || ''}
        />
      );
    });
  };

  render() {
    return (
      <div className="grid__column grid__box" css={{ overflow: 'auto' }}>
        <div className="grid__box__header profiles-overview__header">
          <div className="grid__box__header__title profiles-overview__header__text">
            <div>
              <T _={'account:documents.model.header'} />
            </div>
            <p css={{ color: '#999', padding: '0 8px', fontWeight: 'bold' }}>
              <span>{this.props.data.realName}</span> <span>(ID: {this.props.data.userId})</span>
            </p>
          </div>
        </div>

        <article
          className="grid__box__row"
          css={{
            flex: 'none',
            color: '#3c3d3e',
            padding: '32px 16px',
            backgroundColor: '#f5f5f5',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <RowHeader />
          {this.renderRows()}
        </article>
      </div>
    );
  }
}

Model.propTypes = {
  data: PropTypes.shape({
    realName: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    documentTypes: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
        documents: PropTypes.arrayOf(
          PropTypes.shape({
            fileName: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            insertDate: PropTypes.string.isRequired,
          })
        ),
      })
    ).isRequired,
  }).isRequired,
};

export default Model;
