import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router';

import ProtectedRoute from '../../../components/Routing/ProtectedRoute';
import { Main, Section } from '../../../atoms';
import { stripSlash } from '../../../util/urlHelper';
import { useHasRole, useUserData } from '../../../util/UserData';
import { Chats } from '../../../packages/Telegram';
import { BuildUrl, NewMessage } from '../../../packages/Mailings';
import { InstantMessagingPlatformEnum, SecurityRole } from '../../../graphql/VXModels/types';
import { _ } from '../../../util/translate';
import AutoMailEntry from '../../../packages/Mailings/components/AutoMailTelegram/AutoMailEntry';
import RequestTelegram from '../../../packages/Mailings/components/RequestTelegram/RequestTelegram';
import TelegramArchive from './TelegramArchive';
import {
  ABOS,
  ARCHIVE,
  AUTO_MAIL,
  CHATS,
  NEW_MAILING,
  NEWSLETTER_ARCHIVE_FULL,
  NEWSLETTER_ARCHIVE_OLD,
  PREMIUMABO_ARCHIVE_FULL,
  PREMIUMABO_ARCHIVE_OLD,
  REQUEST_TELEGRAM,
  type,
  defaultRecipientsGroup,
} from '../../Telegram/constants';
import AboWizard from '../../AboWizard/AboWizard';
import { AboContentType } from '../../AboWizard/types';

const Telegram: FC<RouteComponentProps> = ({ match: { url } }) => {
  const path = stripSlash(url);
  const isInternalUser = useHasRole(SecurityRole.VX_USER_INTERNAL_ACCOUNT);
  const entryRoute = NEW_MAILING; // todo (from @dk): hasActivatedTelegram ? NEW_MAILING : REQUEST_TELEGRAM;
  const indexRoute = `${path}${entryRoute}`;
  const userData = useUserData();
  const isTelegramActive = !!userData?.model?.instantMessaging?.account?.active;
  const buildUrl: BuildUrl = ({ id }) => `${path}/archive/${id}`;

  return (
    <Main>
      <Switch>
        <Redirect exact from={`${path}/`} to={indexRoute} />
        {/* @todo write redirects for old telegram routes */}
        <ProtectedRoute path={path + REQUEST_TELEGRAM} verify={() => isInternalUser}>
          <RequestTelegram />
        </ProtectedRoute>
        <ProtectedRoute path={path + NEW_MAILING}>
          <Section title={_('mailings:telegram.sectionTitle.new')} css={{ minHeight: 300 }}>
            <NewMessage group={defaultRecipientsGroup} type={type} buildUrl={buildUrl} />
          </Section>
        </ProtectedRoute>
        <ProtectedRoute path={path + ARCHIVE} component={TelegramArchive} />
        <Redirect exact from={path + NEWSLETTER_ARCHIVE_OLD} to={path + NEWSLETTER_ARCHIVE_FULL} />
        <Redirect exact from={path + PREMIUMABO_ARCHIVE_OLD} to={path + PREMIUMABO_ARCHIVE_FULL} />
        <ProtectedRoute path={path + CHATS}>
          <Chats
            title={_('mailings:telegram.sectionTitle.chats')}
            path={path + CHATS}
            platform={InstantMessagingPlatformEnum.TELEGRAM}
          />
        </ProtectedRoute>
        <ProtectedRoute path={path + AUTO_MAIL}>
          <Section title={_('mailings:telegram.sectionTitle.autoMail')}>
            <AutoMailEntry />
          </Section>
        </ProtectedRoute>
        <ProtectedRoute
          path={path + ABOS}
          component={AboWizard}
          componentProps={{
            typesFilter: { includedAboContentTypes: [AboContentType.Telegram] },
          }}
          verify={(): boolean => isTelegramActive}
        />
        <Redirect to={indexRoute} />
      </Switch>
    </Main>
  );
};

export default Telegram;
