import React, { Fragment, FunctionComponent } from 'react';
import { ChatHoldingTimes, Finance, OnlineTimeThisMonth, VXPagesCreator } from '../WidgetsSmall';
import { _ } from '../../../util/translate';
import { Container, Grid } from '@material-ui/core';
import TelegramTile from '../Telegram/TelegramTile';
import { ProfilePicture, ProfileStatus, WelcomeBack } from '../WidgetsMedium';
import SalesStatistics from '../SalesStatistics/SalesStatistics';
import OnlineTimeAndUsers from '../OnlineTimeAndUsers/OnlineTimeAndUsers';
import IncomeSources from '../IncomeSources/IncomeSources';
import VXCashStatistics from '../VXCashStatistics/VXCashStatistics';
import { NewsTicker, Spinner } from '../../../components';
import { NEWS_VXPAGES } from '../NewstickerConfig';
import { ProfilePictureType } from '../../../components/ProfilePicture/ProfilePictureType';
import {
  MediaAlbum,
  MediaPicture,
  VXGChallengeTypeEnum,
  VXGChallengeUserStatusEnum,
} from '../../../graphql/VXModels/types';
import GraphIcon from '../../../atoms/Icon/VXCash/GraphIcon';
import styled from '@emotion/styled';
import GridTileItem from '../../../atoms/DashboardTile/GridTileItem';
import { useCanUseTelegram } from '../../../packages/Telegram';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { VXGamesBanner } from '../WidgetsXL';

interface OwnProps {
  loading: boolean;
  dashboard: never;
  userPrivileged: boolean;
  wasOnlineWithSSW: boolean;
  picture: never | MediaPicture;
  album: never | MediaAlbum;
  commonProfileFillingPercentage: number;
  vxChallenge: {
    type: VXGChallengeTypeEnum;
    targetValue: number;
    userStatistic: { status: VXGChallengeUserStatusEnum; actualValue: number };
    texts: { title: string; unit: string };
  };
}

type Props = OwnProps;

const DashboardSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const VXPagesDashboard: FunctionComponent<Props> = ({
  loading,
  dashboard,
  userPrivileged,
  wasOnlineWithSSW,
  picture,
  album,
  commonProfileFillingPercentage,
  vxChallenge,
}: OwnProps) => {
  const canUseTelegram = useCanUseTelegram();
  const isParticipating =
    vxChallenge?.userStatistic?.status === VXGChallengeUserStatusEnum.incomplete;

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2} justify="space-between" alignItems="stretch">
        {isParticipating && (
          <GridTileItem
            xs={12}
            noHeader={true}
            noSmallCardLayout={true}
            content={
              loading ? (
                <DashboardSpinner>
                  <Spinner inline={true} size={'s'} />
                </DashboardSpinner>
              ) : (
                <VXGamesBanner vxChallenge={vxChallenge} />
              )
            }
          />
        )}
        <GridTileItem
          xs={12}
          md={6}
          lg={3}
          noSmallCardLayout={true}
          noHeader={true}
          content={
            loading ? (
              <DashboardSpinner>
                <Spinner inline={true} size={'s'} />
              </DashboardSpinner>
            ) : (
              <VXPagesCreator loading={loading} />
            )
          }
        />

        <GridTileItem
          xs={12}
          md={6}
          lg={3}
          content={
            loading ? (
              <DashboardSpinner>
                <Spinner inline={true} size={'s'} />
              </DashboardSpinner>
            ) : (
              <ChatHoldingTimes loading={loading} chatHoldingTime={dashboard.chatHoldingTime} />
            )
          }
          noHeader={true}
        />

        <GridTileItem
          xs={12}
          md={6}
          lg={3}
          content={
            loading ? (
              <DashboardSpinner>
                <Spinner inline={true} size={'s'} />
              </DashboardSpinner>
            ) : (
              <OnlineTimeThisMonth
                loading={loading}
                monthOnlineTime={dashboard.onlineTimeCurrentMonth}
              />
            )
          }
          noHeader={true}
        />

        <GridTileItem
          xs={12}
          md={6}
          lg={3}
          content={
            loading ? (
              <DashboardSpinner>
                <Spinner inline={true} size={'s'} />
              </DashboardSpinner>
            ) : (
              <Finance
                loading={loading}
                amount={userPrivileged && dashboard.payableAmount}
                error={userPrivileged ? null : _('common:forbidden.text')}
              />
            )
          }
          noHeader={true}
        />
        {canUseTelegram && (
          <GridTileItem
            xl={4}
            lg={6}
            md={6}
            xs={12}
            content={
              loading ? (
                <DashboardSpinner>
                  <Spinner inline={true} size={'m'} />
                </DashboardSpinner>
              ) : (
                <TelegramTile loading={loading} />
              )
            }
            title={loading ? '' : _('dashboard:app.telegram.title')}
          />
        )}

        {loading ? (
          <Fragment>
            <GridTileItem
              xs={12}
              md={6}
              xl={4}
              content={
                <DashboardSpinner>
                  <Spinner inline={true} size={'m'} />
                </DashboardSpinner>
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            {wasOnlineWithSSW === false ? (
              <GridTileItem
                xs={12}
                md={6}
                xl={4}
                icon={<AddPhotoAlternateIcon />}
                title={loading ? '' : _('dashboard:app.profilePicture.header')}
                content={
                  <ProfilePicture
                    type={ProfilePictureType.avatar16}
                    album={album}
                    picture={picture}
                    loading={loading}
                  />
                }
              />
            ) : (
              <GridTileItem
                md={6}
                xs={12}
                xl={4}
                title={loading ? '' : _('dashboard:app.welcomeBack.header')}
                content={<WelcomeBack loading={loading} />}
              />
            )}
          </Fragment>
        )}

        {commonProfileFillingPercentage === 100 && (
          <GridTileItem
            xl={4}
            md={6}
            xs={12}
            noCardLayout={true}
            title={loading ? '' : 'Newsticker'}
            content={
              loading ? (
                <DashboardSpinner>
                  <Spinner inline={true} size={'m'} />
                </DashboardSpinner>
              ) : (
                <NewsTicker
                  items={NEWS_VXPAGES}
                  containerStyle={{
                    justifyContent: 'stretch',
                    flexGrow: 1,
                    height: '100%',
                  }}
                  containerClass="dashboard-app-big"
                />
              )
            }
          />
        )}
        {commonProfileFillingPercentage < 100 && (
          <GridTileItem
            xl={4}
            md={6}
            xs={12}
            title={loading ? '' : _('dashboard:app.profileStatus.header')}
            content={
              loading ? (
                <DashboardSpinner>
                  <Spinner inline={true} size={'m'} />
                </DashboardSpinner>
              ) : (
                <ProfileStatus progress={commonProfileFillingPercentage} loading={loading} />
              )
            }
          />
        )}

        <GridTileItem
          md={6}
          xs={12}
          icon={<span className="icon-charts" />}
          centerItems={true}
          content={
            loading ? (
              <DashboardSpinner>
                <Spinner inline={true} size={'m'} />
              </DashboardSpinner>
            ) : (
              <SalesStatistics loading={loading} userAllowed={userPrivileged} />
            )
          }
          title={loading ? '' : _('dashboard:app.salesStatistics.header')}
        />
        <GridTileItem
          md={6}
          xs={12}
          icon={<span className="icon-clock" />}
          title={loading ? '' : _('dashboard:app.onlineTime.header')}
          content={
            loading ? (
              <DashboardSpinner>
                <Spinner inline={true} size={'m'} />
              </DashboardSpinner>
            ) : (
              <OnlineTimeAndUsers loading={loading} />
            )
          }
        />
        <GridTileItem
          md={6}
          xs={12}
          icon={<span className="icon-pie-chart" />}
          title={loading ? '' : _('dashboard:app.incomingSources.header')}
          centerItems={true}
          content={
            loading ? (
              <DashboardSpinner>
                <Spinner inline={true} size={'m'} />
              </DashboardSpinner>
            ) : (
              <IncomeSources loading={loading} userAllowed={userPrivileged} />
            )
          }
        />

        <GridTileItem
          md={6}
          xs={12}
          icon={<GraphIcon />}
          title={loading ? '' : _('dashboard:app.vxCashStat.header')}
          content={
            loading ? (
              <DashboardSpinner>
                <Spinner inline={true} size={'m'} />
              </DashboardSpinner>
            ) : (
              <VXCashStatistics loading={loading} userAllowed={userPrivileged} />
            )
          }
        />
      </Grid>
    </Container>
  );
};

export default VXPagesDashboard;
