import React, { FC, useState, useEffect } from 'react';
import { _ } from '../../../../../util/translate';
import backgroundTelegram from '../Assets/backgroundTelegram.jpg';
import { Link } from 'react-router-dom';
import { APP_BASE_PATH } from '../../../../../util/env';
import AutoMailGridController from './AutoMailGridController';
import { hasScope, Scopes } from '../../../../../util/scope';
import { WHITE_2, WHITE_3 } from '../../../../../camtool-styles';
import { BadgesBanner } from '../../../../../atoms';
import { Typography } from '@material-ui/core';
import { DetailsListItem } from '../../../../../atoms/Banner';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import TelegramIcon from '@material-ui/icons/Telegram';
import IconBase from '../../../../../atoms/Icon/IconBase';
import LockedPhone from '../../../../../atoms/Icon/LockedPhone';
import SharePhone from '../../../../../atoms/Icon/SharePhone';

interface AutoMailMobileEntryProps {
  isVip: boolean;
}

const AutoMailMobileEntry: FC<AutoMailMobileEntryProps> = ({ isVip = false }) => {
  const [displayVip, setDisplayVip] = useState(isVip);
  useEffect(() => setDisplayVip(isVip), [isVip]);

  const benefitList = [
    // first benefit
    {
      icon: <AccessAlarmIcon />,
      text: (
        <DetailsListItem>{`1. ${_(
          'mailings:telegram.autoMail.banner.leftBadgeText'
        )}`}</DetailsListItem>
      ),
    },
    // second benefit
    {
      icon: <TelegramIcon />,
      text: (
        <>
          <DetailsListItem>{`2. ${_(
            'mailings:telegram.autoMail.banner.centerBadgeText'
          )}`}</DetailsListItem>
        </>
      ),
    },
    // third benefit
    {
      icon: displayVip ? (
        <IconBase iconElement={<LockedPhone />} />
      ) : (
        <IconBase iconElement={<SharePhone />} />
      ),
      text: (
        <DetailsListItem>{`3. ${_(
          `mailings:telegram.autoMail.banner.rightBadgeText.${displayVip ? 'VIP' : 'free'}`
        )}`}</DetailsListItem>
      ),
    },
  ];

  return (
    <article
      css={{
        flexDirection: 'column',
        padding: '0px 8px',
      }}
    >
      <BadgesBanner
        backgroundImage={backgroundTelegram}
        backgroundImagePosition={'bottom center'}
        summaryText={
          <Typography color="inherit">
            {_(`mailings:telegram.autoMail.banner.summary.${displayVip ? 'VIP' : 'free'}`)}
          </Typography>
        }
        benefitList={benefitList}
        detailsText={
          <Typography color="inherit" align="center">
            {_(`mailings:telegram.autoMail.banner.subTitleText.${displayVip ? 'VIP' : 'free'}`)}
            <Link
              css={{ marginLeft: '2px', color: WHITE_2 }}
              to={`${APP_BASE_PATH}/help/helpcenter/categories/${
                hasScope([Scopes.VXModels])
                  ? '360002286959/articles/360017625959'
                  : '360001679099/articles/360017676720'
              }`}
            >
              {_('mailings:telegram.autoMail.subTitleLink')}
            </Link>
          </Typography>
        }
      />
      <div css={{ backgroundColor: WHITE_3 }}>
        <AutoMailGridController isVip={displayVip} />
      </div>
    </article>
  );
};

export default AutoMailMobileEntry;
