import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { AccountStore } from '../../../stores/Account/AccountStore';
import { AccountActionCreators } from '../../../stores/Account/AccountActionCreators';
import { AlertsStore } from '../../../stores/Alerts/AlertsStore';
import ServerUtils from '../../../util/ServerUtils';
import { hasRole, UserDataContext } from '../../../util/UserData';
import { InputSection, tableHeaderItemStyles } from './styles';
import NewButton from '../../../atoms/Button/NewButton';
import { Box } from '@material-ui/core';
import {
  AbstractComponent,
  ButtonGroup,
  Dropdown,
  EmptyContent,
  Form,
  GroupRow,
  Inputfield,
  Notice,
  Spinner,
  T,
} from '../../../components';
import { SecurityRole } from '../../../graphql/VXModels/types';
import { _ } from '../../../util/translate';
import Section from '../../../atoms/Grid/Section';
import { BLUE, GRAY_2, RED, YELLOW } from '../../../camtool-styles';
import Table from '../../../atoms/Table/Table';
import { TableCell, TableRow } from '../../../atoms/Table';

const getColumns = () => [
  { label: _('finances:feeInfo.table.head.method'), width: 0.3 },
  { label: _('finances:feeInfo.table.head.minAmount'), width: 0.3 },
  {
    // eslint-disable-next-line react/display-name
    label: () => (
      <div css={[{ flexDirection: 'column' }, tableHeaderItemStyles]}>
        <span>{_('finances:feeInfo.table.head.payout.main')}</span>
        <span>{_('finances:feeInfo.table.head.payout.sub')}</span>
      </div>
    ),
    width: 0.3,
  },
];

const STORE = 'payoutdata';

const getStateFromStores = () => ({
  storeData: AccountStore.get(STORE),
  hasChanges: false,
  enableCancelButton: false,
  isFormValid: false,
  displayErrors: false,
  errorInputNames: [],
  displayErrorInputs: [],
  bicFieldReadOnly: false,
});

class PayoutData extends AbstractComponent {
  static contextType = UserDataContext;

  constructor(props) {
    super(props);
    this.state = {
      ...getStateFromStores(),
      displayNewPayoutForm: false,
    };
    this.isLoading = true;
  }

  displayNewPayoutData() {
    this.setState({ displayNewPayoutForm: true });
  }

  componentDidMount() {
    AccountStore.addChangeListener(STORE, this.onStoreChange);
    this.formsyRef = this.refs.payoutDataForm;
    AccountActionCreators.getPayoutData();
  }

  componentWillUnmount() {
    AccountStore.removeChangeListener(STORE, this.onStoreChange);
  }

  onStoreChange() {
    // set state from store to initialize a form pristine values
    this.isLoading = false;
    const stateFromStore = cloneDeep(getStateFromStores());
    this.setState(stateFromStore);

    // ALERTS
    if (this.state.storeData.savingResult.savingStatus === 'ok') {
      AlertsStore.add({ type: 'info', message: _('common:alerts.saveSuccess') });
    } else if (this.state.storeData.savingResult.savingStatus === 'fail') {
      AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
      const { invalidFields } = this.state?.storeData?.savingResult?.invalidFields || [];
      const errors = {};
      if (invalidFields.length > 0) {
        for (let i = 0; i < invalidFields.length; i++) {
          errors[invalidFields[i]] = this.getTranslationForFormField(invalidFields[i]);
        }
        this.formsyRef.updateInputsWithError(errors);
        const errorInputNames = this.formsyRef.getErrorInputNames();
        // because after REST-Request hasChanges=false! but we need a cancel button!
        this.setState({
          enableCancelButton: true,
          errorInputNames,
          displayErrorInputs: this.formsyRef.getDisplayErrorInputs(errorInputNames),
        });
      }
    }
  }

  onValid() {
    this.setState({
      isFormValid: true,
      displayErrorInputs: this.formsyRef.getDisplayErrorInputs(this.state.errorInputNames),
    });
  }

  onInvalid() {
    this.setState({
      isFormValid: false,
      displayErrorInputs: this.formsyRef.getDisplayErrorInputs(this.state.errorInputNames),
    });
  }

  showErrorFields() {
    const errorInputNames = this.formsyRef.getErrorInputNames();
    const nextState = {
      errorInputNames,
      displayErrorInputs: this.formsyRef.getDisplayErrorInputs(errorInputNames),
    };

    if (this.state.displayErrors === false) {
      nextState.displayErrors = true;
    }

    this.setState(nextState);
    window.scrollTo(0, 0);
    AlertsStore.add({
      type: 'error',
      message: _('common:alerts.profile.formInvalid'),
    });
  }

  hasInputError(inputName) {
    return this.state.displayErrorInputs[inputName] !== undefined;
  }

  submitForm(model) {
    this.setState({ isLoading: true, displayErrors: false });
    AccountActionCreators.newPayoutDataRequest(model);
  }

  onInvalidSubmit(model) {
    AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
  }

  onFormChange(model, formChanged) {
    this.setState({ hasChanges: formChanged });
  }

  resetForm() {
    if (this.state.hasChanges) {
      this.refs.payoutDataForm.reset(this.refs.payoutDataForm.getPristineValues());
    }
    // because changing of payout method is not a form changing!
    AccountActionCreators.getPayoutData();
  }

  onChangePayoutMethod(event) {
    const newVal = cloneDeep(getStateFromStores());
    newVal.storeData.newPayoutData.method = event.currentTarget.value;
    // because changing of payout method is not a form changing!
    newVal.enableCancelButton = true;
    this.setState(newVal);
  }

  getTranslationForFormField(fieldname) {
    if (fieldname === 'lastname') {
      return _('common:formValidationMessage.lastName');
    } else if (fieldname === 'firstname') {
      return _('common:formValidationMessage.firstName');
    } else if (fieldname === 'street') {
      return _('common:formValidationMessage.street');
    } else if (fieldname === 'zip') {
      return _('common:formValidationMessage.zip');
    } else if (fieldname === 'city') {
      return _('common:formValidationMessage.city');
    } else if (fieldname === 'country') {
      return _('common:formValidationMessage.country');
    } else if (fieldname === 'iban') {
      return _('common:formValidationMessage.iban');
    } else if (fieldname === 'bic') {
      return _('common:formValidationMessage.bic');
    } else if (fieldname === 'accountowner') {
      return _('common:formValidationMessage.bankAccountHolder');
    } else if (fieldname === 'paxumEmail') {
      return _('common:formValidationMessage.paxumAccount');
    }

    return '';
  }

  isCancelButtonDisabled() {
    return !this.state.hasChanges && !this.state.enableCancelButton;
  }

  isSaveButtonDisabled() {
    return this.state.bicFieldReadOnly;
  }

  getFormsyFieldRef(name) {
    return this.formsyRef.inputs.filter((input) => input.props.name === name).pop();
  }

  setBIC(IBAN) {
    this.setState({ bicFieldReadOnly: true }, () => {
      const bicFormsyRef = this.getFormsyFieldRef('bic');

      if (bicFormsyRef) bicFormsyRef.setValue(_('account:payoutdata.bic.loading'));
      ServerUtils.request(
        'GET',
        `/v1/public/bic/${IBAN}`,
        null,
        (response) => {
          if (response && response.valid) {
            if (bicFormsyRef) bicFormsyRef.setValue(response.bankData.bic);
          } else if (bicFormsyRef) bicFormsyRef.setValue('');
          this.setState({ bicFieldReadOnly: false });
        },
        (err) => {
          if (bicFormsyRef) bicFormsyRef.setValue('');
        }
      );
    });
  }

  handleIBANFieldKeyDown(e) {
    if (e.key === 'Enter' && e.target.value.trim() !== '') {
      e.preventDefault();
      this.ibanInputRef.blur();
    }
  }

  handleIBANFieldBlur(e) {
    if (this.haveIbanAndBicChange() && this.ibanInputRef.value.trim() !== '') {
      this.setBIC(this.ibanInputRef.value.trim().toUpperCase().replace(/\s/g, ''));
      this.getFormsyFieldRef('iban').setValue(
        this.ibanInputRef.value.trim().toUpperCase().replace(/\s/g, '')
      );
    }
  }

  handleSubmitForm() {
    this.formsyRef.submit();
  }

  haveIbanAndBicChange() {
    return !(
      this.state.storeData.newPayoutData.bankAccount.iban === this.ibanInputRef.value.trim() &&
      this.state.storeData.newPayoutData.bankAccount.bic === this.bicInputRef.value.trim()
    );
  }

  render() {
    const isVXMaster = hasRole(SecurityRole.VX_USER_MASTER);
    const columns = getColumns();
    const currentPayoutData = this.state.storeData.currentPayoutData;
    const newPayoutData = this.state.storeData.newPayoutData;

    return (
      <div css={{ flexWrap: 'wrap', maxWidth: '100%' }}>
        {isVXMaster ? (
          <Fragment>
            {this.isLoading && <Spinner />}

            <Section
              css={{ flex: '1 0 48%' }}
              styles={{ padding: '20px 40px' }}
              title={_('account:payoutdata.pageTitle')}
            >
              <div css={{ color: BLUE, flexDirection: 'column' }}>
                <h4>{_('common:text.privacy.title')}</h4>
                <p>{_('common:text.privacy.description')}</p>
              </div>
              <div css={{ flexDirection: 'column', flex: '1 0 auto', marginTop: '20px' }}>
                <div
                  css={{
                    flexDirection: 'column',
                  }}
                >
                  {/* UNEDITABLE OLD / CURRENTLY ACCEPTED PAYOUTDATA FORMULAR */}
                  {this.state.storeData.hasCurrentPayoutData && (
                    <>
                      <strong css={{ marginBottom: '10px' }}>
                        {_('account:payoutdata.box.head.title')}
                      </strong>
                      <Form
                        css={{ flex: '1 1 auto', flexDirection: 'column' }}
                        // eslint-disable-next-line react/no-string-refs
                        ref="payoutCurrentDataForm"
                      >
                        {/* PAYOUT METHOD SELECTOR */}
                        <InputSection>
                          <span>{_('account:payoutdata.formfield.payoutMethod')}</span>
                          <Inputfield
                            css={{ padding: '8px 0px' }}
                            readOnly
                            name="method"
                            type="text"
                            value={_(
                              'account:payoutdata.method.' +
                                this.state.storeData.currentPayoutData.method
                            )}
                          />
                        </InputSection>

                        {/* PAYOUT METHOD -> BANK TRANSACTION */}
                        {currentPayoutData.method === 'bank' && (
                          <Fragment>
                            <InputSection>
                              <span>{_('common:text.accountOwner')}</span>
                              <Inputfield
                                readOnly
                                css={{ padding: '8px 0px' }}
                                name="accountowner"
                                type="text"
                                value={currentPayoutData.bankAccount.accountOwner}
                              />
                            </InputSection>

                            <InputSection>
                              <span>{_('common:text.iban')}</span>
                              <Inputfield
                                readOnly
                                css={{ padding: '8px 0px' }}
                                name="iban"
                                type="text"
                                value={currentPayoutData.bankAccount.iban}
                              />
                            </InputSection>

                            <InputSection>
                              <span>{_('common:text.bic')}</span>
                              <Inputfield
                                readOnly
                                css={{ padding: '8px 0px' }}
                                name="bic"
                                type="text"
                                value={currentPayoutData.bankAccount.bic}
                              />
                            </InputSection>
                          </Fragment>
                        )}
                        {/* PAYOUT METHOD -> CHEQUE (NOT AVAILABLE ANYMORE BUT EVENTUALLY STILL REQUIRED FOR MASTER OUTSIDE OF GERMANY DUE TO AGB POLICY) */}
                        {currentPayoutData.method === 'cheque' && (
                          <Fragment>
                            <InputSection>
                              <span>{_('common:text.firstname')}</span>
                              <Inputfield
                                readOnly
                                css={{ padding: '8px 0px' }}
                                name="firstname"
                                type="text"
                                value={currentPayoutData.chequeAddress.firstname}
                              />
                            </InputSection>

                            <InputSection>
                              <span>{_('common:text.lastname')}</span>

                              <Inputfield
                                readOnly
                                css={{ padding: '8px 0px' }}
                                name="lastname"
                                type="text"
                                value={currentPayoutData.chequeAddress.lastname}
                              />
                            </InputSection>

                            <InputSection>
                              <span>{`${_('common:text.street')}, ${_(
                                'common:text.streetnbr'
                              )}:`}</span>
                              <div>
                                <Inputfield
                                  readOnly
                                  css={{ padding: '8px 0px' }}
                                  name="street"
                                  type="text"
                                  value={currentPayoutData.chequeAddress.street}
                                />
                                <Inputfield
                                  readOnly
                                  css={{ flex: 0.5 }}
                                  name="streetnbr"
                                  type="text"
                                  value={currentPayoutData.chequeAddress.streetnbr}
                                />
                              </div>
                            </InputSection>

                            <InputSection>
                              <span>{`${_('common:text.zipcode')}, ${_(
                                'common:text.city'
                              )}:`}</span>
                              <div>
                                <Inputfield
                                  readOnly
                                  css={{ flex: 0.5, padding: '8px 0px' }}
                                  name="zip"
                                  type="text"
                                  value={currentPayoutData.chequeAddress.zip}
                                />
                                <Inputfield
                                  readOnly
                                  name="city"
                                  type="text"
                                  value={currentPayoutData.chequeAddress.city}
                                />
                              </div>
                            </InputSection>

                            <InputSection>
                              <span>{_('common:text.country')}</span>
                              <Inputfield
                                css={{ padding: '8px 0px' }}
                                readOnly
                                name="country"
                                type="text"
                                value={_(
                                  'enum:common.country.' + currentPayoutData.chequeAddress.country
                                )}
                              />
                            </InputSection>
                          </Fragment>
                        )}
                        {/* PAYOUT METHOD -> PAXUM (models living outside germany) */}
                        {currentPayoutData.method === 'paxum' && (
                          <InputSection>
                            <span>{_('common:text.email')}</span>
                            <Inputfield
                              readOnly
                              css={{ padding: '8px 0px' }}
                              name="paxumEmail"
                              type="text"
                              value={currentPayoutData.paxumAccount.paxumEmail}
                            />
                          </InputSection>
                        )}
                      </Form>
                    </>
                  )}
                  {this.state.storeData.hasCurrentPayoutData &&
                    !this.state.storeData.hasNewPayoutData &&
                    !this.state.displayNewPayoutForm && (
                      <ButtonGroup
                        className="account-payoutdata__toogle-btn-group"
                        position="right"
                      >
                        <NewButton
                          className="button button--blue"
                          type="button"
                          onClick={this.displayNewPayoutData}
                        >
                          {_(
                            'account:payoutdata.box.head.' +
                              (this.state.storeData.hasCurrentPayoutData ? 'titleNewData' : 'title')
                          )}
                        </NewButton>
                      </ButtonGroup>
                    )}
                  <div
                    css={{
                      flexDirection: 'column',
                      // show logic via display or else formsy throws errors when refs are generated during runtime...
                      display:
                        this.state.storeData.hasNewPayoutData ||
                        (!this.state.storeData.hasNewPayoutData &&
                          !this.state.storeData.hasCurrentPayoutData) ||
                        this.state.displayNewPayoutForm
                          ? 'flex'
                          : 'none !important',
                      marginTop: this.state.storeData.hasCurrentPayoutData ? '40px' : '',
                    }}
                  >
                    <strong css={{ marginBottom: '10px' }}>
                      {_(
                        'account:payoutdata.box.head.' +
                          (this.state.storeData.hasCurrentPayoutData ? 'titleNewData' : 'title')
                      )}
                    </strong>

                    {/* EDITABLE NEWPAYOUTDATA FORMULAR */}
                    <Form
                      css={{ flex: '1 1 auto', flexDirection: 'column' }}
                      // eslint-disable-next-line react/no-string-refs
                      ref="payoutDataForm"
                      onValidSubmit={this.submitForm}
                      onInvalidSubmit={this.showErrorFields}
                      onValid={this.onValid}
                      onInvalid={this.onInvalid}
                      onChange={this.onFormChange}
                    >
                      {/* PAYOUT METHOD SELECTOR */}
                      <InputSection>
                        <span>{_('account:payoutdata.formfield.payoutMethod')}</span>
                        <Dropdown
                          css={{ padding: '8px 0px' }}
                          name="payoutMethod"
                          value={newPayoutData.method}
                          forceTranslate={true}
                          options={this.state.storeData.allowedPayoutMethods}
                          onChange={this.onChangePayoutMethod}
                        />
                      </InputSection>

                      {/* PAYOUT METHOD -> BANK TRANSACTION */}
                      {newPayoutData.method === 'bank' && (
                        <Fragment>
                          <InputSection>
                            <span>{_('common:text.accountOwner')}</span>
                            <Inputfield
                              css={{ padding: '8px 0px' }}
                              required
                              requiredMessage={_('common:formRequiredMessage.bankAccountHolder')}
                              validations="minLength:5"
                              validationError={_('common:formValidationMessage.bankAccountHolder')}
                              name="accountowner"
                              type="text"
                              value={newPayoutData.bankAccount.accountOwner}
                              placeholder={_('common:text.accountOwner')}
                              labelClass="payoutdata__inputfield__label"
                              errorMessage={this.state.displayErrorInputs['accountowner']}
                            />
                          </InputSection>

                          <InputSection>
                            <span>{_('common:text.iban')}</span>
                            <Inputfield
                              css={{ padding: '8px 0px' }}
                              required
                              requiredMessage={_('common:formRequiredMessage.iban')}
                              validations="isIBAN"
                              validationError={_('common:formValidationMessage.iban')}
                              name="iban"
                              type="text"
                              value={newPayoutData.bankAccount.iban}
                              placeholder={_('common:text.iban')}
                              labelClass="payoutdata__inputfield__label"
                              inputRef={(r) => {
                                this.ibanInputRef = r;
                              }}
                              onKeyDown={this.handleIBANFieldKeyDown}
                              onBlur={this.handleIBANFieldBlur}
                              errorMessage={this.state.displayErrorInputs['iban']}
                            />
                          </InputSection>

                          <InputSection>
                            <span>{_('common:text.bic')}</span>
                            <Inputfield
                              css={{ padding: '8px 0px' }}
                              required
                              requiredMessage={_('common:formRequiredMessage.bic')}
                              validations="isBIC"
                              validationError={_('common:formValidationMessage.bic')}
                              name="bic"
                              type="text"
                              value={newPayoutData.bankAccount.bic}
                              placeholder={_('common:text.bic')}
                              labelClass="payoutdata__inputfield__label"
                              readOnly={this.state.bicFieldReadOnly}
                              inputRef={(r) => {
                                this.bicInputRef = r;
                              }}
                              errorMessage={this.state.displayErrorInputs['bic']}
                            />
                          </InputSection>
                        </Fragment>
                      )}

                      {/* PAYOUT METHOD -> CHEQUE (NOT AVAILABLE ANYMORE BUT EVENTUALLY STILL REQUIRED FOR MASTER OUTSIDE OF GERMANY DUE TO AGB POLICY) */}
                      {newPayoutData.method === 'cheque' && (
                        <Fragment>
                          <InputSection>
                            <span>{_('common:text.firstname')}</span>
                            <Inputfield
                              css={{ padding: '8px 0px' }}
                              required
                              requiredMessage={_('common:formRequiredMessage.firstName')}
                              validations="minLength:2"
                              validationError={_('common:formValidationMessage.firstName')}
                              name="firstname"
                              type="text"
                              value={newPayoutData.chequeAddress.firstname}
                              placeholder={_('common:text.firstname')}
                              labelClass="payoutdata__inputfield__label"
                            />
                          </InputSection>

                          <InputSection>
                            <span>{_('common:text.lastname')}</span>

                            <Inputfield
                              css={{ padding: '8px 0px' }}
                              required
                              requiredMessage={_('common:formRequiredMessage.lastName')}
                              validations="minLength:2"
                              validationError={_('common:formValidationMessage.lastName')}
                              name="lastname"
                              type="text"
                              value={newPayoutData.chequeAddress.lastname}
                              placeholder={_('common:text.lastname')}
                              labelClass="payoutdata__inputfield__label"
                            />
                          </InputSection>

                          <InputSection>
                            <span>{`${_('common:text.street')}, ${_(
                              'common:text.streetnbr'
                            )}:`}</span>
                            <div>
                              <Inputfield
                                css={{ padding: '8px 0px' }}
                                required
                                requiredMessage={_('common:formRequiredMessage.street')}
                                validations="minLength:3"
                                validationError={_('common:formValidationMessage.street')}
                                name="street"
                                type="text"
                                value={newPayoutData.chequeAddress.street}
                                placeholder={_('common:text.street')}
                                labelClass="payoutdata__inputfield__label"
                              />
                              <Inputfield
                                css={{ flex: 0.5 }}
                                class="streetnbr"
                                name="streetnbr"
                                type="text"
                                value={newPayoutData.chequeAddress.streetnbr}
                                placeholder={_('common:text.streetnbr')}
                                maxLength={14}
                                labelClass="payoutdata__inputfield__label"
                              />
                            </div>
                          </InputSection>

                          <InputSection>
                            <span>{`${_('common:text.zipcode')}, ${_('common:text.city')}:`}</span>
                            <div>
                              <Inputfield
                                css={{ flex: 0.5, padding: '8px 0px' }}
                                required
                                requiredMessage={_('common:formRequiredMessage.zipCode')}
                                validations="minLength:3"
                                validationError={_('common:formValidationMessage.zipCode')}
                                class="zip"
                                name="zip"
                                type="text"
                                value={newPayoutData.chequeAddress.zip}
                                placeholder={_('common:text.zipcode')}
                                labelClass="payoutdata__inputfield__label"
                              />
                              <Inputfield
                                required
                                requiredMessage={_('common:formRequiredMessage.city')}
                                validations="minLength:3"
                                validationError={_('common:formValidationMessage.city')}
                                name="city"
                                type="text"
                                value={newPayoutData.chequeAddress.city}
                                placeholder={_('common:text.city')}
                                labelClass="payoutdata__inputfield__label"
                              />
                            </div>
                          </InputSection>

                          <InputSection>
                            <span>{_('common:text.country')}</span>
                            <Dropdown
                              css={{ padding: '8px 0px' }}
                              required
                              requiredMessage={_('common:text.pleaseSelect')}
                              name="country"
                              value={newPayoutData.chequeAddress.country}
                              forceTranslate={true}
                              options={this.state.storeData.countries}
                              placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                            />
                          </InputSection>
                        </Fragment>
                      )}
                      {/* PAYOUT METHOD -> PAXUM (models living outside germany) */}
                      {newPayoutData.method === 'paxum' && (
                        <InputSection>
                          <span>{_('common:text.email')}</span>
                          <Inputfield
                            css={{ padding: '8px 0px' }}
                            required
                            requiredMessage={_('common:formRequiredMessage.paxumAccount')}
                            validations="isEmail"
                            validationError={_('common:formValidationMessage.paxumAccount')}
                            name="paxumEmail"
                            type="text"
                            value={newPayoutData.paxumAccount.paxumEmail}
                            placeholder={_('common:text.email')}
                            onChange={this.onChangePaxumEmail}
                            labelClass="payoutdata__inputfield__label"
                          />
                        </InputSection>
                      )}

                      <ButtonGroup css={{ margin: '20px 0px' }} position="center">
                        <Box m={1}>
                          <NewButton
                            color={'default'}
                            disabled={this.isCancelButtonDisabled()}
                            onClick={this.resetForm}
                          >
                            {_('common:button.cancel')}
                          </NewButton>
                        </Box>
                        <Box m={1}>
                          <NewButton
                            disabled={this.isSaveButtonDisabled()}
                            onClick={this.handleSubmitForm}
                          >
                            {_('common:button.save')}
                          </NewButton>
                        </Box>
                      </ButtonGroup>
                    </Form>
                    {this.state.storeData.status !== 'new' && this.state.storeData.status !== '' && (
                      <GroupRow tooltip="none">
                        <Notice
                          css={{
                            backgroundColor:
                              this.state.storeData.status === 'rejected' ? RED : YELLOW,
                          }}
                          icon="icon-warning-sign"
                          text={_('account:payoutdata.statusInfo.' + this.state.storeData.status)}
                        />
                      </GroupRow>
                    )}
                  </div>
                </div>
              </div>
            </Section>

            {/* Right side: payout method, minimum turnover and fees */}
            <Section css={{ flex: '1 0 48%' }} title={_('finances:feeInfo.box.head.title')}>
              <div className="grid__box__column grid__box__sub" style={{ minWidth: 520 }}>
                <div className="grid__box__sub__item">
                  <div className="grid__box__sub__item__content account-payoutdata__feetable">
                    <Table
                      headerItemStyles={tableHeaderItemStyles}
                      css={{ width: '100%', flex: 0 }}
                      columns={columns}
                    >
                      <TableRow hoverEffect={false}>
                        <TableCell columns={columns} index={0}>
                          <span css={{ fontWeight: 'bold' }}>
                            <T _={'finances:feeInfo.table.bankDE.method'} />
                          </span>
                        </TableCell>
                        <TableCell columns={columns} index={1}>
                          {this.state.storeData.minAmounts.bankDE}
                        </TableCell>
                        <TableCell columns={columns} index={2}>
                          <T _={'finances:feeInfo.table.bankDE.fee'} />
                        </TableCell>
                      </TableRow>

                      <TableRow hoverEffect={false}>
                        <TableCell columns={columns} index={0}>
                          <span css={{ fontWeight: 'bold' }}>
                            <T _={'finances:feeInfo.table.bankEU.method'} />
                          </span>
                        </TableCell>
                        <TableCell columns={columns} index={1}>
                          {this.state.storeData.minAmounts.bankEU}
                        </TableCell>
                        <TableCell columns={columns} index={2}>
                          {this.state.storeData.fees.bankEU}
                        </TableCell>
                      </TableRow>

                      <TableRow hoverEffect={false}>
                        <TableCell columns={columns} index={0}>
                          <div css={{ flexDirection: 'column' }}>
                            <span css={{ fontWeight: 'bold' }}>
                              <T _={'finances:feeInfo.table.bank3L.method'} />
                            </span>
                            <small>
                              <T _={'finances:feeInfo.table.bank3L.methodsub'} />
                            </small>
                          </div>
                        </TableCell>
                        <TableCell columns={columns} index={1}>
                          {this.state.storeData.minAmounts.bank3L}
                        </TableCell>
                        <TableCell columns={columns} index={2}>
                          <div css={{ flexDirection: 'column' }}>
                            <span>
                              {this.state.storeData.fees.bank3L} {' *'}
                            </span>
                            <small>
                              <T _={'finances:feeInfo.table.bank3L.fee'} />
                            </small>
                          </div>
                        </TableCell>
                      </TableRow>

                      <TableRow hoverEffect={false}>
                        <TableCell columns={columns} index={0}>
                          <div css={{ flexDirection: 'column' }}>
                            <span css={{ fontWeight: 'bold' }}>
                              <T _={'finances:feeInfo.table.paxum.method'} />
                            </span>
                            <small>
                              <T _={'finances:feeInfo.table.paxum.methodsub'} />
                            </small>
                          </div>
                        </TableCell>
                        <TableCell columns={columns} index={1}>
                          {this.state.storeData.minAmounts.paxum}
                        </TableCell>
                        <TableCell columns={columns} index={2}>
                          <div css={{ flexDirection: 'column' }}>
                            <span>
                              {this.state.storeData.fees.paxum} {' *'}
                            </span>
                            <small>
                              <T _={'finances:feeInfo.table.paxum.fee'} />
                            </small>
                          </div>
                        </TableCell>
                      </TableRow>
                    </Table>

                    <div className="account-payoutdata__feetable__warning">
                      <T _={'finances:feeInfo.table.otherFee.title'} />:
                    </div>
                    <div>
                      {_('finances:feeInfo.table.otherFee.description', {
                        postProcess: 'sprintf',
                        sprintf: [this.state.storeData.fees.underMinAmount],
                      })}
                    </div>
                    <small css={{ marginTop: '16px', color: GRAY_2 }}>
                      {_('finances:feeInfo.table.otherFee.asterisk')}
                    </small>
                  </div>
                </div>
              </div>
            </Section>
          </Fragment>
        ) : (
          <EmptyContent title={_('common:forbidden.text')} />
        )}
      </div>
    );
  }
}

export default PayoutData;
export { PayoutData };
