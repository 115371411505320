import React, { FC } from 'react';
import { HELPCENTER_ARTICLE_FETISH } from '../../../../../util/helpcenterArticles';
import { _ } from '../../../../../util/translate';
import HelpcenterLink from './HelpcenterLink';

interface IProps {
  className?: string;
}

const FetishHelpcenterLink: FC<IProps> = ({ className }) => (
  <HelpcenterLink
    label={_('mediamanagement:video.flagFetish.link')}
    href={HELPCENTER_ARTICLE_FETISH}
    className={className}
  />
);

export default FetishHelpcenterLink;
