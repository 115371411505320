import React, { FC } from 'react';
import { GREEN, RED } from '../../../../camtool-styles';
import { VXPagesUser } from '../../../../graphql/VXModels/types';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import BlockIcon from '@material-ui/icons/Block';
import { _ } from '../../../../util/translate';

interface IProps {
  user: Pick<VXPagesUser, 'isLocked' | 'isOnline'>;
  showAsIcon?: boolean;
}

export const StatusLabel: FC<IProps> = ({ user, showAsIcon = true }) => {
  const isOnline = !user.isLocked && user.isOnline;
  const blockedStatus = showAsIcon ? (
    <BlockIcon htmlColor={RED} />
  ) : (
    <span>{_('common:text.blocked')}</span>
  );
  const onlineStatus = showAsIcon ? (
    <FiberManualRecordIcon htmlColor={GREEN} />
  ) : (
    <span>{_('common:text.online')}</span>
  );
  const offlineStatus = showAsIcon ? (
    <FiberManualRecordIcon htmlColor={RED} />
  ) : (
    <span>{_('common:text.offline')}</span>
  );

  return <span>{user.isLocked ? blockedStatus : isOnline ? onlineStatus : offlineStatus}</span>;
};
