import React, { FC } from 'react';
import { backgroundCheckmark, backgroundLock } from '../../../../atoms/Icon/libraries/svg';
import { ListItem, StyledIcon, ListDiv } from './styles';
import { _ } from '../../../../util/translate';

interface Props {
  isVXPagePublished: boolean;
  hasEarnedEnough: boolean;
}

const AdvantagesListLocked: FC<Props> = ({ isVXPagePublished, hasEarnedEnough }) => {
  return (
    <>
      <ListItem noBullets={true}>
        <ListDiv>
          <StyledIcon
            icon={isVXPagePublished ? backgroundCheckmark : backgroundLock}
            size={'16px'}
          />
          {_('telegram:requestTelegram.request.advantages.locked.firstArgument')}
        </ListDiv>
      </ListItem>
      <ListItem noBullets={true}>
        <ListDiv>
          <StyledIcon icon={backgroundCheckmark} size={'16px'} />
          {_('telegram:requestTelegram.request.advantages.locked.secondArgument')}
        </ListDiv>
      </ListItem>
      <ListItem noBullets={true}>
        <ListDiv>
          <StyledIcon icon={hasEarnedEnough ? backgroundCheckmark : backgroundLock} size={'16px'} />
          {_('telegram:requestTelegram.request.advantages.locked.thirdArgument')}
        </ListDiv>
      </ListItem>
    </>
  );
};

export default AdvantagesListLocked;
