import React, { FC } from 'react';
import Row from './Row';
import { Table } from '../../../atoms/Table';
import { ITableColumn } from '../../../atoms/Table/types';
import { ICreditsUsageDisplayData } from './types';

interface IProps {
  creditsUsagesDisplayData: ICreditsUsageDisplayData[];
}

const columns: ITableColumn[] = [{ width: 1 }, { width: 1 }];

const CreditsUsagesTable: FC<IProps> = ({ creditsUsagesDisplayData }) => {
  return (
    <Table columns={columns} hideHeader={true}>
      {creditsUsagesDisplayData.map((creditsUsageDisplayData, index) => (
        <Row creditsUsageDisplayData={creditsUsageDisplayData} columns={columns} key={index} />
      ))}
    </Table>
  );
};

export default CreditsUsagesTable;
