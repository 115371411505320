import React, { Fragment } from 'react';

import AbstractComponent from '../../../components/AbstractComponent';
import {
  PROFILE_ASSISTANT,
  ProfileAssistantActionCreators,
  ProfileAssistantStore,
} from '../../../stores/Profile/ProfileAssistant';
import Footer from '../../../components/Footer/Footer';

import Profile from './Profile/Profile';
import Profiles from './Profiles/Profiles';
import { CSS_GRID } from '../../../util/supports';
import { hasScope, VXPAGES } from '../../../util/scope';
import { APP_API_HOST } from '../../../util/env';

class ProfilesOverview extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: {},
    };
    this.onStoreChange = this.onStoreChange.bind(this);
  }

  componentDidMount() {
    ProfileAssistantStore.addChangeListener(PROFILE_ASSISTANT, this.onStoreChange);
    this.setState({ isLoading: true }, () => {
      ProfileAssistantActionCreators.readResource(PROFILE_ASSISTANT);
    });
  }

  componentWillUnmount() {
    ProfileAssistantStore.removeChangeListener(PROFILE_ASSISTANT, this.onStoreChange);
  }

  onStoreChange() {
    const data = ProfileAssistantStore.get(PROFILE_ASSISTANT);
    this.setState({
      isLoading: false,
      data,
    });
  }

  filterTraits = data => {
    // filter interview out in VXPAGES scrope
    if (data && hasScope([VXPAGES])) {
      return {
        ...data,
        traits: data.traits.filter(
          trait =>
            trait.url !== `${APP_API_HOST}/onlineprofile/common/interview` &&
            trait.label !== 'profileAssistant:interview.label'
        ),
      };
    }

    return data;
  };

  render() {
    if (!CSS_GRID) {
      return (
        <main className="grid profiles-overview" style={{ minWidth: 636 }}>
          <Profiles profiles={this.state.data} />
        </main>
      );
    }

    return (
      <main className="grid profiles-overview" style={{ minWidth: 636 }}>
        {!hasScope([VXPAGES]) && <Profiles profiles={this.state.data} />}

        <Profile
          profile1={this.state.data ? this.filterTraits(this.state.data.common) : {}}
          profile2={!hasScope([VXPAGES]) && this.state.data ? this.state.data.partner : null}
        />

        {!hasScope([VXPAGES]) && (
          <Fragment>
            <Profile
              profile1={this.state.data ? this.state.data.visitx : {}}
              profile2={this.state.data ? this.state.data.fetish : {}}
            />
            <Profile
              profile1={
                this.state.data &&
                this.state.data.lustagenten &&
                this.state.data.lustagenten.isProfileActive
                  ? this.state.data.lustagenten
                  : null
              }
            />
          </Fragment>
        )}

        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default ProfilesOverview;
