import React, { FC } from 'react';
import { _ } from '../../../util/translate';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Mutation, Query } from '../../../graphql/VXModels/types';
import { Box } from '@material-ui/core';
import { Main } from '../../../atoms/';
import { QUERY_MODEL_SETTINGS_MARKETING } from '../../../graphql/VXModels/queries';
import { Spinner } from '../../../components';
import SettingsSection from './SettingsSection';
import MarketingStatus from './MarketingStatus';
import GenericStatus from './GenericStatus';
import Contact from '../../../atoms/Contact/Contact';
import { getCurrentScopeSupportMail } from '../../../util/emailAdresses';
import GenericEmptyContent from '../../../atoms/GenericEmptyContent/GenericEmptyContent';
import {
  MUTATION_MODEL_SETTINGS_SETCROSSMARKETING,
  MUTATION_MODEL_SETTINGS_SETUSERREACTIVATION,
} from '../../../graphql/VXModels/mutations';

const Settings: FC = () => {
  const { data, loading, error } = useQuery<Query>(QUERY_MODEL_SETTINGS_MARKETING);
  const [toggleCrossMarketing, { loading: crossMarketingLoading }] = useMutation<Mutation>(
    MUTATION_MODEL_SETTINGS_SETCROSSMARKETING,
    {
      refetchQueries: [{ query: QUERY_MODEL_SETTINGS_MARKETING }],
      awaitRefetchQueries: true,
    }
  );
  const [toggleReactivation, { loading: reactivationLoading }] = useMutation<Mutation>(
    MUTATION_MODEL_SETTINGS_SETUSERREACTIVATION,
    {
      refetchQueries: [{ query: QUERY_MODEL_SETTINGS_MARKETING }],
      awaitRefetchQueries: true,
    }
  );
  const marketingSettings = data?.model?.settings?.marketingSettings;
  const hasVXMarketing = marketingSettings?.hasVXMarketing;
  const isVisibleVXPagesMarketing = marketingSettings?.isVisibleVXPagesMarketing;
  const hasCrossMarketing = marketingSettings?.hasCrossMarketing;
  const hasUserReactivation = marketingSettings?.hasUserReactivation;

  return error ? (
    <GenericEmptyContent />
  ) : (
    <Main isMobileEnabled>
      <SettingsSection
        title={_('marketing:settings.vxmarketing.title')}
        headline={_('marketing:settings.vxmarketing.headline')}
        subHeadline={_('marketing:settings.vxmarketing.subHeadline')}
        bulletList={[
          _('marketing:settings.vxmarketing.list.firstArgument'),
          _('marketing:settings.vxmarketing.list.secondArgument'),
          _('marketing:settings.vxmarketing.list.thirdArgument'),
        ]}
      >
        {loading ? <Spinner /> : <MarketingStatus checked={hasVXMarketing} />}
      </SettingsSection>

      {!loading && isVisibleVXPagesMarketing && (
        <>
          <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
            <SettingsSection
              css={{ flex: '0 0 calc(50% - 16px)' }}
              title={_('marketing:settings.crossmarketing.title')}
              headline={_('marketing:settings.crossmarketing.headline')}
              subHeadline={_('marketing:settings.crossmarketing.subHeadline')}
              bulletList={[
                _('marketing:settings.crossmarketing.list.firstArgument'),
                _('marketing:settings.crossmarketing.list.secondArgument'),
                _('marketing:settings.crossmarketing.list.thirdArgument'),
                _('marketing:settings.crossmarketing.list.fourthArgument'),
              ]}
            >
              <Box mt={'auto'}>
                <GenericStatus
                  isActive={hasCrossMarketing}
                  toggleFn={toggleCrossMarketing}
                  loading={crossMarketingLoading}
                />
              </Box>
            </SettingsSection>

            <SettingsSection
              css={{ flex: '0 0 calc(50% - 16px)' }}
              title={_('marketing:settings.reactivationmarketing.title')}
              headline={_('marketing:settings.reactivationmarketing.headline')}
              subHeadline={_('marketing:settings.reactivationmarketing.subHeadline')}
              bulletList={[
                _('marketing:settings.reactivationmarketing.list.firstArgument'),
                _('marketing:settings.reactivationmarketing.list.secondArgument'),
              ]}
            >
              <Box mt={'auto'}>
                <GenericStatus
                  isActive={hasUserReactivation}
                  toggleFn={toggleReactivation}
                  loading={reactivationLoading}
                />
              </Box>
            </SettingsSection>
          </Box>
          <Contact
            msgTranslationKey={_('marketing:settings.contact.support')}
            email={getCurrentScopeSupportMail()}
          />
        </>
      )}
    </Main>
  );
};

export default Settings;
