import React, { FC } from 'react';
import { VXPagesUser } from '../../../../../../graphql/VXModels/types';
import { Field, FieldLabel, FieldValue } from './Base';
import { _ } from '../../../../../../util/translate';
import { StatusLabel } from '../../../../../../packages/CustomerManagement/components/ColumnRenders';

interface IProps {
  user: VXPagesUser;
}

const StatusField: FC<IProps> = ({ user }) => {
  return (
    <Field>
      <FieldLabel>{_('userManagement:field.status')}</FieldLabel>
      <FieldValue>
        <StatusLabel user={user} showAsIcon={false} />
      </FieldValue>
    </Field>
  );
};

export default StatusField;
