import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { SecurityRole } from '../../../../graphql/VXModels/types';
import { useHasRole } from '../../../../util/UserData';
import { _ } from '../../../../util/translate';
import { donateHeart } from '../../../../atoms/Icon/libraries/glyph';

const AbosItem: FC = () => {
  const hasVXPageInHPMode = useHasRole(SecurityRole.VX_USER_VXPAGES_HP);

  return hasVXPageInHPMode ? (
    <LeftMenuItem title={_('navigation:homepage.abos')} icon={donateHeart} uri="/abos" />
  ) : null;
};

export default AbosItem;
