import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { SecurityRole } from '../../../../graphql/VXModels/types';
import { useHasRole } from '../../../../util/UserData';
import { _ } from '../../../../util/translate';
import { group } from '../../../../atoms/Icon/libraries/glyph';

const CustomersItem: FC = () => {
  const hasVXPageInHPMode = useHasRole(SecurityRole.VX_USER_VXPAGES_HP);

  return hasVXPageInHPMode ? (
    <LeftMenuItem
      title={_('navigation:main.customers')}
      icon={group}
      iconSize="14px"
      uri="/customers"
    />
  ) : null;
};

export default CustomersItem;
