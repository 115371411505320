import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import ProfilePictureContent from '../../../../components/ProfilePicture/ProfilePictureContent';
import WhiteBox from '../WhiteBox';
import { MediaAlbum, MediaPicture } from '../../../../graphql/VXModels/types';
import { Spinner, T } from '../../../../components';
import { ProfilePictureType } from '../../../../components/ProfilePicture/ProfilePictureType';
import { Alert } from '../../../../atoms';
import { APP_BASE_PATH } from '../../../../util/env';
import { BLACK_1 } from '../../../../camtool-styles';
import { getPictureAndVideoTopics } from '../../../../util/helpcenterLinks';

interface Props {
  type: ProfilePictureType;
  album?: MediaAlbum;
  picture?: MediaPicture;
  rejectionReason?: string;
  pictureChecking?: boolean;
  loading: boolean;
}

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 30p 0 0;
`;

const SpinnerContainer = styled.div`
  min-height: 150px;
  width: 100%;
  position: relative;
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
`;

const ProfilePictureTile: FC<Props> = ({
  type,
  album,
  picture,
  loading,
  rejectionReason,
  pictureChecking,
}) => {
  return (
    <WhiteBox>
      <Container>
        {!pictureChecking && rejectionReason && (
          <LinkStyled to={getPictureAndVideoTopics}>
            <Alert>
              <T t={'welcome:assistent.pictureRejected'} /> {rejectionReason}
            </Alert>
          </LinkStyled>
        )}
        {loading ? (
          <SpinnerContainer>
            <Spinner noBackground={true} />
          </SpinnerContainer>
        ) : (
          <ProfilePictureContent type={type} album={album} picture={picture} forQuickStart={true} />
        )}
      </Container>
    </WhiteBox>
  );
};

export default ProfilePictureTile;
