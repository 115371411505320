import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';
import styled from '@emotion/styled';
import { BREAKPOINT_PHONE_CONDITION } from '../../camtool-styles';

const Label = styled.label`
  @media ${BREAKPOINT_PHONE_CONDITION} {
    flex: 1 1 auto !important;
  }
`;

class BigIconButton extends AbstractComponent {
  render() {
    return (
      <Label htmlFor={this.props.labelFor} className="calltoactionbtn">
        <div role="form" onClick={this.props.onClick}>
          <div className="iconcollection">
            <div className="iconbggroup">
              <div className={`left ${this.props.iconLeft}`} />
              <div className="middle" />
              <div className={`right ${this.props.iconRight}`} />
            </div>
            <div className={`main ${this.props.iconMain}`} />
          </div>
          <div className="label">
            <span>{this.props.label}</span>
          </div>
          <div className="info">
            <span>{this.props.info}</span>
          </div>
        </div>
      </Label>
    );
  }
}

BigIconButton.propTypes = {
  info: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelFor: PropTypes.string,
  onClick: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconMain: PropTypes.string,
};

BigIconButton.defaultProps = {
  onClick: () => {
    // console.log('BigIconButton.defaultProps', 'onClick');
  },
};

export default BigIconButton;
export { BigIconButton };
