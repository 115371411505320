import React, { FC } from 'react';
import styled from '@emotion/styled';
import { EmptyContent } from '../../../../../components';
import { _ } from '../../../../../util/translate';
import { VXPagesUser } from '../../../../../graphql/VXModels/types';

interface IProps {
  user: VXPagesUser;
}

const Container = styled.div`
  min-height: 360px;
  flex: 1 1 0;
`;

const UserLockedPlaceholder: FC<IProps> = ({ user }) => {
  const { username } = user;

  return (
    <Container>
      <EmptyContent
        icon="icon-gift"
        title={
          <span>
            {_('userManagement:details.userUnavailable', { sprintf: [username] })}
            <br />
            {_('userManagement:details.section.giftContent.userLockedLine2')}
          </span>
        }
      />
    </Container>
  );
};

export default UserLockedPlaceholder;
