import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import { Modal } from '../../atoms';
import { Dot } from './styles';
import { graphql } from '@apollo/react-hoc';

import {
  MUTATION_NOTIFICATIONS_MARKREAD,
  optimisticMarkReadResponse,
  updateQueryAfterMarkRead,
  NOTIFICATIONS_COUNT_MENU,
  QUERY_UNREAD_NOTIFICATIONS,
} from '../../util/NotificationsHelper';
import { QUERY_DASHBOARD_INITIAL } from '../../routes/Dashboard/graphql';
import Translation from '../Translation/Translation';

import { WHITE, BLACK_2, GRAY_2 } from '../../camtool-styles';
import NotificationContent from './NotificationContent';
import { getTimezone } from '../../util/timezone';

const StyledDiv = styled.div`
  max-width: 1000px;
  padding: 10px 14px;
  align-self: flex-start;
  flex: 1;
  background-color: ${WHITE};
  color: ${BLACK_2};
  flex-direction: column;
  border-radius: 0 0 3px 3px;
  position: relative;
  overflow: hidden;
`;

const MessageCountDiv = styled.div`
  justify-content: flex-end;
  font-size: 0.9em;
  color: ${GRAY_2};
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  right: 14px;
`;

const DotDiv = styled.div`
  margin-bottom: 8px;
  justify-content: center;
  font-size: 2em;
`;

const DEFAULT_RIBBON_COLOR = GRAY_2;

const getBackgroundImageByColor = (color) =>
  `linear-gradient(to right, ${color} 0, ${color} 8px, ${WHITE} 8px, ${WHITE} 100%)`;

const getBackgroundImage = (message) => {
  const meta = message.type.meta || {};
  return !meta.ribbon || !meta.ribbon.hide
    ? getBackgroundImageByColor((meta.ribbon && meta.ribbon.color) || DEFAULT_RIBBON_COLOR)
    : 'none';
};

class NotificationBox extends PureComponent {
  state = {
    /* eslint-disable-next-line react/prop-types */
    messages: this.props.messages,
    currentMessage: 0,
    messagesRead: [],
  };

  markRead = (id) => {
    this.mutateRead(id);

    this.setState(({ currentMessage, messagesRead, messages }) => {
      if (!id || messagesRead.includes(id)) {
        return null;
      }

      const noneRemaining = currentMessage >= messages.length - 1;

      const nextMessages = noneRemaining ? [] : messages;
      const nextCurrentMessage = Math.min(currentMessage + 1, nextMessages.length - 1);
      const nextMessagesRead = noneRemaining ? [] : messagesRead.concat([id]);

      return {
        messages: nextMessages,
        currentMessage: nextCurrentMessage,
        messagesRead: nextMessagesRead,
      };
    });
  };

  mutateRead(id) {
    const timezone = getTimezone();
    /* eslint-disable-next-line react/prop-types */
    this.props.mutate({
      variables: { notificationIds: [id] },
      optimisticResponse: optimisticMarkReadResponse([id]),
      update: updateQueryAfterMarkRead('snagbarNotifications', QUERY_DASHBOARD_INITIAL, timezone),
      refetchQueries: [
        { query: NOTIFICATIONS_COUNT_MENU, variables: { timezone } },
        { query: QUERY_UNREAD_NOTIFICATIONS, variables: { timezone } },
      ],
    });
  }

  render() {
    const { messages, currentMessage } = this.state;

    if (messages.length === 0) {
      return null;
    }

    const currentMessageObject = messages[currentMessage];

    return (
      <Modal css={{ overflowY: 'auto' }}>
        <StyledDiv css={{ backgroundImage: getBackgroundImage(currentMessageObject) }}>
          {messages.length > 1 && (
            <MessageCountDiv>
              <Translation
                _={'notifications:text.cursorText'}
                sprintf={{ num: currentMessage + 1, total: messages.length }}
              />
            </MessageCountDiv>
          )}
          <NotificationContent notification={currentMessageObject} markRead={this.markRead} />
          {messages.length > 1 && (
            <DotDiv>
              {messages.map((item, i) => (
                <Dot key={item.id} active={i === currentMessage} />
              ))}
            </DotDiv>
          )}
        </StyledDiv>
      </Modal>
    );
  }
}

NotificationBox.propTypes = {
  // TODO
};

export default graphql(MUTATION_NOTIFICATIONS_MARKREAD)(NotificationBox);
