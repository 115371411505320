import React, { FC, ReactNode, useMemo } from 'react';
import { GRAY_ULTRATHIN } from '../../camtool-styles';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';

interface IProps {
  children: ReactNode;
  disabled?: boolean;
  to?: string;
  href?: string;
  onClick?: () => void;
  className?: string;
  borderColor?: string;
  hoverEffect?: boolean;
  lastRowHasBorder?: boolean;
}

export const HORIZONTAL_PADDING = 8;

const getTableRowCss = (
  borderColor: string,
  hoverEffect: boolean,
  lastRowHasBorder: boolean
) => css`
  display: flex;
  text-decoration: none;
  color: inherit;
  flex: 0 0 64px;
  padding: 0 ${HORIZONTAL_PADDING}px;
  position: relative;
  border-top: 1px solid ${borderColor};

  ${lastRowHasBorder
    ? `
      &:last-of-type {
        border-bottom: 1px solid ${borderColor};
      }`
    : ''}

  ${hoverEffect
    ? `
      &:hover {
        background-color: ${GRAY_ULTRATHIN};
      }`
    : ''}
`;

const TableRow: FC<IProps> = ({
  children,
  disabled = false,
  to,
  href,
  onClick,
  borderColor = GRAY_ULTRATHIN,
  hoverEffect = true,
  lastRowHasBorder = true,
  className,
}) => {
  const linkProps: { to?: string; href?: string; onClick?: () => void } = {};
  let RowComponent = 'div';

  if (!disabled) {
    if (typeof to !== 'undefined') {
      RowComponent = Link;
      linkProps.to = to;
    } else if (typeof href !== 'undefined') {
      RowComponent = 'a';
      linkProps.href = href;
    } else if (typeof onClick === 'function') {
      linkProps.onClick = onClick;
    }
  }

  const styles = useMemo(() => getTableRowCss(borderColor, hoverEffect, lastRowHasBorder), [
    borderColor,
    hoverEffect,
    lastRowHasBorder,
  ]);

  return (
    <RowComponent css={styles} {...linkProps} className={className}>
      {children}
    </RowComponent>
  );
};

export default TableRow;
