import { ReactNode } from 'react';
import { ITableColumn, TableColumnAlignmentEnum } from '../../../atoms/Table/types';
import { TimeframeKey } from './types';
import { _ } from '../../../util/translate';

export const getColumns = (dropdownRenderFunction: () => ReactNode): ITableColumn[] => [
  {
    width: 1.8,
  },
  ...Object.values(TimeframeKey)
    .filter((key) => key !== TimeframeKey.custom)
    .map((key) => ({
      label: _(`statistics:section.site.timeframes.${key}`),
      align: TableColumnAlignmentEnum.start,
    })),
  { width: 0.5 },
  { width: 0.2 },
  {
    label: dropdownRenderFunction,
    align: TableColumnAlignmentEnum.center,
    width: 1.6,
    horizontalPadding: 0,
  },
];
