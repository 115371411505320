import React, { FC } from 'react';
import { NewMessage } from '../../packages/Mailings';
import { _ } from '../../util/translate';
import { MailingRecipientsGroupsEnum, MailingTypeEnum } from '../../graphql/VXModels/types';
import { Main, Section } from '../../atoms';
import { useRouteMatch } from 'react-router';

const BroadcastNew: FC = () => {
  const { path } = useRouteMatch();
  const buildUrlPathRedirect = path.substring(0, path.lastIndexOf('/'));
  return (
    <Main>
      <Section title={_('telegram:BroadcastNew.sectionTitle.new')} css={{ minHeight: 300 }}>
        <NewMessage
          buildUrl={({ id }) => `${buildUrlPathRedirect}/drafts/${id}`}
          group={MailingRecipientsGroupsEnum.TELEGRAM}
          type={MailingTypeEnum.VXPAGES_TELEGRAM}
        />
      </Section>
    </Main>
  );
};

export default BroadcastNew;
