import styled from '@emotion/styled';
import { hasScope, VXPAGES } from '../../../util/scope';
import { VXModelsLogo, VXPagesLogo } from '../../../atoms/Logo';
import React from 'react';
import { GREEN, RED } from '../../../camtool-styles';

export const Main = styled.main`
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  color: #262728;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow-y: scroll;
  overflow-x: auto;
  min-width: initial; // Unset global default on <main>
`;

export const Window = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 101;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  background-color: #fff;
`;

export const HeaderLogo = styled.div`
  background-color: #000;
  width: 100%;
  text-align: center;
  position: relative;
  justify-content: center;
`;

export const HeaderText = styled.div``;

export const HeaderTextTitle = styled.h2`
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  margin: 24px 0 4px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  padding: 4px 0;
  color: #b2b2b2;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  border-radius: 4px;
  border: 1px solid #888;
  padding: 8px;
  font-size: 16px;
  letter-spacing: 1px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 500px;
  padding: 8px 64px 64px;
`;

export const ErrorBox = styled.div`
  border: 1px solid ${RED};
  border-radius: 4px;
  color: ${RED};
  padding: 8px;
  margin-bottom: 8px;
`;

export const SuccessBox = styled.div`
  border: 1px solid ${GREEN};
  border-radius: 4px;
  color: ${GREEN};
  padding: 8px;
  margin-bottom: 8px;
`;

// eslint-disable-next-line react/prop-types
export const WindowCompilation = ({ name, title, children }) => (
  <Window id={name}>
    <Header>
      <HeaderLogo>{hasScope([VXPAGES]) ? <VXPagesLogo /> : <VXModelsLogo />}</HeaderLogo>
      {title && (
        <HeaderText>
          <HeaderTextTitle>{title}</HeaderTextTitle>
        </HeaderText>
      )}
    </Header>
    <Content className="spinner-container">{children}</Content>
  </Window>
);
