import React, { FC } from 'react';
import { Spinner } from '../../../../../components';
import { Button, Modal } from '../../../../../atoms';
import styled from '@emotion/styled';
import { BLACK_2, RED, WHITE } from '../../../../../camtool-styles';
import { VoucherTypeEnum, VXPagesUser } from '../../../../../graphql/VXModels/types';
import { _ } from '../../../../../util/translate';

interface IProps {
  user: VXPagesUser;
  voucherType: VoucherTypeEnum;
  amount: number;
  isGift: boolean;
  loading: boolean;
  error?: any;
  onConfirm: () => any;
  onClose: () => any;
}

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ModalContents = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: ${WHITE};
  flex-direction: column;
  padding: 54px 62px 25px;
  align-items: center;
  cursor: initial;
  text-align: center;
  color: ${BLACK_2};
`;

const ModalHeader = styled.div`
  font-size: 1.5rem;
  margin-bottom: 33px;
`;

const StatusContainer = styled.div`
  display: inline-block !important;
  width: 100%;
  margin-bottom: 30px;
`;

const ErrorLabel = styled.div`
  display: block !important;
  color: ${RED};
`;

const ModalVoucherAmount: FC<IProps> = ({
  user,
  voucherType,
  amount,
  isGift,
  error,
  loading,
  onClose,
  onConfirm,
}) => {
  const { username } = user;

  const onCloseModalClick = () => {
    if (!loading) onClose();
  };

  const onConfirmClick = () => {
    if (!loading) onConfirm();
  };

  return (
    <Modal onBackgroundClick={onCloseModalClick}>
      <ModalContainer onClick={onCloseModalClick}>
        <ModalContents onClick={(e) => e.stopPropagation()}>
          <ModalHeader>
            {_(
              amount === 1
                ? isGift
                  ? `userManagement:details.voucherAmount.confirm.add.singular.${voucherType.substr(
                      0,
                      10
                    )}`
                  : `userManagement:details.voucherAmount.confirm.sub.singular.${voucherType.substr(
                      0,
                      10
                    )}`
                : isGift
                ? `userManagement:details.voucherAmount.confirm.add.plural.${voucherType}`
                : `userManagement:details.voucherAmount.confirm.sub.plural.${voucherType}`,
              {
                username,
                amount,
              }
            )}
          </ModalHeader>

          {(loading || error) && (
            <StatusContainer>
              {loading ? (
                <Spinner inline={true} noBackground={true} size={'m'} />
              ) : (
                <ErrorLabel>{_('userManagement:details.voucherAmount.error')}</ErrorLabel>
              )}
            </StatusContainer>
          )}

          <Button
            type={'button'}
            size={'lg'}
            label={_(
              isGift
                ? 'userManagement:details.voucherAmount.btnGift'
                : 'userManagement:details.voucherAmount.btnSubtract'
            )}
            onClick={onConfirmClick}
            disabled={loading}
          />

          <Button
            type={'button'}
            label={_('common:button.cancel')}
            theme={'link-gray'}
            onClick={onCloseModalClick}
            disabled={loading}
            css={{ marginTop: 20 }}
          />
        </ModalContents>
      </ModalContainer>
    </Modal>
  );
};

export default ModalVoucherAmount;
