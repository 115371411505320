import React, { FC } from 'react';
import { EmptyContent } from '../../../../../../components';
import { _ } from '../../../../../../util/translate';
import { PlaceholderContainer } from './styles';

interface IProps {
  username: string;
}

const NoTransactionsPlaceholder: FC<IProps> = ({ username }) => (
  <PlaceholderContainer>
    <EmptyContent
      icon="icon-money"
      title={_('userManagement:details.section.transactions.empty', {
        sprintf: [username],
      })}
    />
  </PlaceholderContainer>
);

export default NoTransactionsPlaceholder;
