import { VXPagesUserTransactionCurrency } from '../../../../../../graphql/VXModels/types';
import { _ } from '../../../../../../util/translate';

const formatAmount = (amount: number, currency: VXPagesUserTransactionCurrency): string => {
  switch (currency) {
    case VXPagesUserTransactionCurrency.Credits:
      return _(
        amount === 1
          ? 'userManagement:details.section.transactions.credit'
          : 'userManagement:details.section.transactions.credits',
        { sprintf: [amount.toLocaleString()] }
      );
    case VXPagesUserTransactionCurrency.EUR:
      return amount.toLocaleString(undefined, {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
      });
    default:
      throw new Error('Unrecognized currency: ' + currency);
  }
};

export default formatAmount;
