import React, { FC } from 'react';
import { css } from '@emotion/core';
import { VXPagesUser } from '../../../../../graphql/VXModels/types';
import { Section } from '../../../../../atoms';
import { BREAKPOINT_TABLET, WHITE_3 } from '../../../../../camtool-styles';
import { sectionMargin } from '../../../../../atoms/Grid/Section';
import { _ } from '../../../../../util/translate';
import CurrentCredits from './CurrentCredits';
import EditCredits from './EditCredits';
import UserLockedPlaceholder from './UserLockedPlaceholder';

interface IProps {
  user: VXPagesUser;
}

const sectionStyles = css`
  padding: 37px 30px;
  background-color: ${WHITE_3};
  flex-direction: column;
  align-items: center;
  ${BREAKPOINT_TABLET} {
    -webkit-box-flex: 1 1 100%;
    -moz-box-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 0 0;
  }
`;

const SectionCredits: FC<IProps> = ({ user }: IProps) => {
  const { isLocked } = user;

  return (
    <Section
      title={_('userManagement:details.section.credits.title')}
      styles={sectionStyles}
      css={{ width: `calc(50% - 2 * ${sectionMargin}px)` }}
    >
      <CurrentCredits user={user} />

      {isLocked ? <UserLockedPlaceholder user={user} /> : <EditCredits user={user} />}
    </Section>
  );
};

export default SectionCredits;
