import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_VXPAGES_STATS_KPI_TURNOVER } from '../../../graphql/VXModels/queries';
import {
  VXPagesStatsKPI,
  VXPagesStatsKPIItem,
  VXPagesStatsTurnover,
  VXPagesStatsTurnoverItem,
} from '../../../graphql/VXModels/types';
import { DataByTimeframe, ITimeframeData, Timeframes } from './types';
import {
  getCustomTimeframeForGraphQLQuery,
  getDataByTimeframe,
  getDefaultTimeframesForGraphQLQuery,
} from './Timeframes/timeframes';

const getStatsFromData = (data: any) => {
  const defaultStats = data?.model?.vxpages?.stats;
  const {
    kpi,
    turnover,
  }: {
    kpi: VXPagesStatsKPIItem[] | undefined | null;
    turnover: VXPagesStatsTurnoverItem[] | undefined | null;
  } = defaultStats || {};

  return { kpi, turnover };
};

const getJoinedDataByTimeframe = <DataType extends any>(
  defaultItems: Array<ITimeframeData<DataType>> | undefined | null,
  customItems: Array<ITimeframeData<DataType>> | undefined | null,
  timeframes: Timeframes
): DataByTimeframe<DataType | undefined> | undefined =>
  defaultItems
    ? getDataByTimeframe([...defaultItems, ...(customItems || [])], timeframes)
    : undefined;

const useStatsByTimeframe = (timeframes: Timeframes) => {
  const defaultTimeframes = getDefaultTimeframesForGraphQLQuery(timeframes);
  const customTimeframe = getCustomTimeframeForGraphQLQuery(timeframes, defaultTimeframes);

  const { data: defaultData, loading: defaultLoading, error: defaultError } = useQuery(
    QUERY_MODEL_VXPAGES_STATS_KPI_TURNOVER,
    {
      fetchPolicy: 'network-only',
      variables: { timeframes: defaultTimeframes },
    }
  );
  const { data: customData, loading: customLoading, error: customError } = useQuery(
    QUERY_MODEL_VXPAGES_STATS_KPI_TURNOVER,
    {
      variables: { timeframes: [customTimeframe] },
      skip: !customTimeframe,
    }
  );

  const { kpi: defaultKpiStats, turnover: defaultTurnoverStats } = getStatsFromData(defaultData);
  const { kpi: customKpiStats, turnover: customTurnoverStats } = getStatsFromData(customData);

  const kpiStatsByTimeframe = getJoinedDataByTimeframe<VXPagesStatsKPI | undefined>(
    defaultKpiStats,
    customKpiStats,
    timeframes
  );
  const turnoverStatsByTimeframe = getJoinedDataByTimeframe<VXPagesStatsTurnover | undefined>(
    defaultTurnoverStats,
    customTurnoverStats,
    timeframes
  );

  return {
    status: {
      default: { loading: defaultLoading, error: defaultError },
      custom: { loading: customLoading, error: customError },
    },
    statsByTimeframe: {
      kpi: kpiStatsByTimeframe,
      turnover: turnoverStatsByTimeframe,
    },
  };
};

export default useStatsByTimeframe;
