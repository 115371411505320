import styled from '@emotion/styled';
import { BREAKPOINT_PHONE, DARK_GRAY } from '../../../../../../../camtool-styles';

const PADDING_BOTTOM = 30;

export const FieldsContainer = styled.div(`
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-right: -35px;
  margin-bottom: -${PADDING_BOTTOM}px;
  padding-left: 40px;
`);

export const Field = styled.div(`
  flex: 0 0 auto;
  flex-direction: column;
  color: ${DARK_GRAY};
  font-size: 1.333rem;
  letter-spacing: 0.01333rem;
  padding-bottom: ${PADDING_BOTTOM}px;
  padding-right: 35px;
  width: 50%;

  @media (min-width: 1400px) {
    width: 33.3333%;
  }
  ${BREAKPOINT_PHONE} {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
`);

export const FieldLabel = styled.div`
  padding: 0 3px;
  margin-bottom: 2px;
`;
