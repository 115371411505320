import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { APP_BASE_PATH } from '../../../../../util/env';
import { BLACK, DARK_GRAY, GRAY_4, WHITE } from '../../../../../camtool-styles';
import CategoryIcon from '../CategoryIcon';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_HELPCENTER_CATEGORIES } from '../../../../../graphql/VXModels/queries';
import { Spinner } from '../../../../../components';
import { Query } from '../../../../../graphql/VXModels/types';

const Container = styled.div`
  flex: 1 0 auto;
  flex-flow: row wrap;
  padding: 0px 24px 24px 24px;
  justify-content: center;
`;

const CategoryTitle = styled.div`
  text-align: center;
  padding: 0 16px;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 auto;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex: 1 0 180px;
  justify-content: center;
  align-items: center;
  color: ${BLACK};
  text-decoration: none;
  background: ${WHITE};
`;

const CategoryGridItem = styled.div<{ index: number }>`
  flex-direction: column;
  border: 1px solid ${GRAY_4};
  margin: 10px 10px 10px 0;

  // show first tile as full row
  flex: ${(props): string => (props.index === 0 ? '1 0 99%' : '1 0 220px')};
  &:hover ${StyledLink} {
    background: ${DARK_GRAY};
    color: ${WHITE};
  }
`;

const CategoryGrid: FC = () => {
  const { data, loading } = useQuery<Query>(QUERY_HELPCENTER_CATEGORIES);
  const helpcenter = data?.helpcenter;
  const categories = helpcenter?.categories;

  return loading ? (
    <Spinner />
  ) : categories?.length > 0 ? (
    <Container>
      {categories.map((category, index) => (
        <CategoryGridItem index={index} key={category.id}>
          <StyledLink to={`${APP_BASE_PATH}/help/helpcenter/categories/${category.id}/articles`}>
            <div className="grid__box__column">
              <CategoryIcon categoryId={category.id} />
              <CategoryTitle>{category.name}</CategoryTitle>
            </div>
          </StyledLink>
        </CategoryGridItem>
      ))}
    </Container>
  ) : null;
};

export default CategoryGrid;
