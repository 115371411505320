import {
  VXPagesStatsKPI,
  VXPagesStatsTurnover,
  VXPagesStatsTurnoverData,
} from '../../../../graphql/VXModels/types';
import { IRowDefinition, TimeframeKey } from '../types';

const getHostAmount = (turnoverStats?: VXPagesStatsTurnoverData) =>
  (turnoverStats?.hostAmount || 0).toLocaleString(undefined, {
    style: 'currency',
    currency: 'EUR',
  });

const formatPercentage = (value: number) =>
  value.toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

export const kpiRows: Array<IRowDefinition<VXPagesStatsKPI>> = [
  {
    label: 'statistics:section.site.itemLabel.visits',
    callback: (vxPagesStatsKpi) => vxPagesStatsKpi.visits,
  },
  {
    label: 'statistics:section.site.itemLabel.signups',
    callback: (vxPagesStatsKpi) => vxPagesStatsKpi.signups,
  },
  {
    label: 'statistics:section.site.itemLabel.signupRate',
    callback: ({ signups, visits }, key) =>
      key !== TimeframeKey.today ? formatPercentage(signups && visits ? signups / visits : 0) : '-',
  },
  {
    label: 'statistics:section.site.itemLabel.logins',
    callback: (vxPagesStatsKpi) => vxPagesStatsKpi.logins,
  },
  {
    label: 'statistics:section.site.itemLabel.transactions',
    callback: (vxPagesStatsKpi) => vxPagesStatsKpi.transactions,
  },
  {
    label: 'statistics:section.site.itemLabel.transactionRate',
    callback: ({ transactions, visits }, key) =>
      key !== TimeframeKey.today
        ? formatPercentage(transactions && visits ? transactions / visits : 0)
        : '-',
  },
  /*
  {
    label: 'statistics:section.site.itemLabel.firstPayments',
    callback: (vxPagesStatsKpi) => vxPagesStatsKpi.firstPayments,
  },
  {
    label: 'statistics:section.site.itemLabel.firstPaymentRate',
    callback: ({ firstPayments, signups }, key) =>
      key !== TimeframeKey.today
        ? formatPercentage(signups && firstPayments ? firstPayments / signups : 0)
        : '-',
  },
  */
  {
    label: 'statistics:section.site.itemLabel.referredModels',
    callback: (vxPagesStatsKpi) => vxPagesStatsKpi.referredModels,
  },
];

export const sharingRows: Array<IRowDefinition<VXPagesStatsTurnover>> = [
  {
    label: 'statistics:section.site.itemLabel.creditsCharges',
    callback: (vxPagesStatsTurnover) => getHostAmount(vxPagesStatsTurnover.creditsCharges),
  },
  {
    label: 'statistics:section.site.itemLabel.subscriptions',
    callback: (vxPagesStatsTurnover) => getHostAmount(vxPagesStatsTurnover.subscriptions),
  },
  {
    label: 'statistics:section.site.itemLabel.chargebacks',
    callback: (vxPagesStatsTurnover) => getHostAmount(vxPagesStatsTurnover.chargebacks),
  },
  {
    label: 'statistics:section.site.itemLabel.collections',
    callback: (vxPagesStatsTurnover) => getHostAmount(vxPagesStatsTurnover.collections),
  },
  {
    label: 'statistics:section.site.itemLabel.resellerComission',
    callback: (vxPagesStatsTurnover) => getHostAmount(vxPagesStatsTurnover.resellerComission),
  },
  {
    label: 'statistics:section.site.itemLabel.otherProfit',
    callback: (vxPagesStatsTurnover) => getHostAmount(vxPagesStatsTurnover.otherProfit),
  },
];

export const feeRows: Array<IRowDefinition<VXPagesStatsTurnover>> = [
  {
    label: 'statistics:section.site.itemLabel.chargebacksFee',
    callback: (vxPagesStatsTurnover) => getHostAmount(vxPagesStatsTurnover.chargebacksFee),
  },
  {
    label: 'statistics:section.site.itemLabel.chargebackFeeRefund',
    callback: (vxPagesStatsTurnover) => getHostAmount(vxPagesStatsTurnover.chargebackFeeRefund),
  },
  {
    label: 'statistics:section.site.itemLabel.otherFee',
    callback: (vxPagesStatsTurnover) => getHostAmount(vxPagesStatsTurnover.otherFee),
  },
];
