import React, { FC } from 'react';
import { Paper } from '@material-ui/core';
import { WHITE } from '../../../../../camtool-styles';
import {
  CompetitionPrizeFooter,
  CompetitionPrizeDisplay,
  CompetitionPrizeHeader,
  CompetitionPrizePreviewProps,
} from '.';
import { VXGAMES_CURRENT_DATE } from '../../../utils';
import { konfetti } from '.';

const CompetitionPrizePreview: FC<CompetitionPrizePreviewProps> = ({
  winningList,
  reward,
  endDate,
  participationStatus,
  numberOfWinners,
}) => {
  const competitionFinished = VXGAMES_CURRENT_DATE > new Date(endDate);
  return (
    <Paper
      variant="elevation"
      style={{
        backgroundColor: WHITE,
        padding: '40px',
        height: 'calc(100% - 80px)',
        ...(competitionFinished && { backgroundImage: `url(${konfetti})` }),
      }}
    >
      <CompetitionPrizeHeader
        participationStatus={participationStatus}
        competitionFinished={competitionFinished}
        competitionDeadline={endDate}
      />
      <CompetitionPrizeDisplay
        participationStatus={participationStatus}
        competitionFinished={competitionFinished}
        numberOfWinners={numberOfWinners}
        reward={reward}
      />
      <CompetitionPrizeFooter winningList={winningList} />
    </Paper>
  );
};

export { CompetitionPrizePreview };
