import { ZENDESK_CHAT_DENY_ROUTES } from '../../config';
import { ApiLang } from '../../graphql/VXModels/types';
import { BrandsName, Department, Zopim } from './types';

const LANG_MAPPING = {
  [ApiLang.de]: 'de_DE',
  [ApiLang.en]: 'en_US',
};

export const hideAllOnRouteDeniedOrNotAllDepsOnline = () => ($zopim: Zopim) =>
  isRouteDenied() || !verifyAllDepartmentsOnline($zopim);

export const verifyAllDepartmentsOnline = ($zopim: Zopim): boolean => {
  const deps = $zopim.livechat.departments
    .getAllDepartments()
    .filter((dep) => dep.name === BrandsName.MODELSERVICE);
  const allDepsOnline = deps.reduce<boolean>((p, dep) => p && dep.status === 'online', true);

  if (!allDepsOnline) {
    console.log('[Zendesk Chat] Hide chat, while not all departments are online');
    deps.map((dep: Department) =>
      console.log(`[Zendesk Chat] Department "${dep.name}" is ${dep.status}`)
    );
  }

  return allDepsOnline;
};

export const isRouteDenied = (): boolean => {
  const denied = ZENDESK_CHAT_DENY_ROUTES.some(
    (routeUrl) => window.location.pathname.indexOf(routeUrl) > -1
  );
  if (denied) {
    console.log('[Zendesk Chat] Hide chat, while route is on deny list');
  }
  return denied;
};

export const sanitizeLang = (lang: ApiLang): string => LANG_MAPPING[lang] ?? lang;
