import React, { FC, ReactNode } from 'react';
import { MailingsList } from '../../packages/Mailings';
import { SELECT_ID, type } from './constants';
import { MailingRecipientsGroupsEnum, MailingStatusEnum } from '../../graphql/VXModels/types';
import { CardHeaderInverted } from '../../atoms';
import { ColumnName } from '../../packages/Mailings/components/MailingsList/MailingsList';
import { NavSub, NavSubItem } from '../../components';
import { _ } from '../../util/translate';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { EditMessage, BuildUrl } from '../../packages/Mailings';
import { Card, CardContent, Container, Grid } from '@material-ui/core';
import { useStyleFix } from '../../app/Theme/stylefix';

const SPACING = 3;

const BroadcastDrafts: FC = () => {
  const { path } = useRouteMatch();
  const buildUrl: BuildUrl = ({ id }) => `${path}/${id}`;
  const { gridBoundries, containerBoundries } = useStyleFix({ spacing: SPACING });

  return (
    <>
      <NavSub>
        <NavSubItem link={path} icon="icon-edit" label={_('telegram:BroadcastDrafts.nav.list')} />
        <Route path={path + SELECT_ID}>
          {({ match }): ReactNode =>
            match ? (
              <NavSubItem
                link={path + '/' + match.params.id}
                icon="icon-inbox"
                label={_('telegram:BroadcastDrafts.nav.message', {
                  sprintf: { id: match.params.id },
                })}
              />
            ) : null
          }
        </Route>
      </NavSub>
      <Container disableGutters classes={{ root: containerBoundries }}>
        <Switch>
          <Route exact path={path}>
            <Card square variant={'elevation'} elevation={0}>
              <CardHeaderInverted title={_('telegram:BroadcastDrafts.sectionTitle.list')} />
              <CardContent classes={{ root: gridBoundries }} style={{ minHeight: 300 }}>
                <Grid container>
                  <Grid item xs>
                    <MailingsList
                      buildUrl={buildUrl}
                      type={type}
                      recipientsGroups={[
                        MailingRecipientsGroupsEnum.TELEGRAM,
                        MailingRecipientsGroupsEnum.VXPAGES_TELEGRAM_NEWSLETTER,
                        MailingRecipientsGroupsEnum.VXPAGES_TELEGRAM_PREMIUMABO,
                      ]}
                      status={[MailingStatusEnum.DRAFT, MailingStatusEnum.SHIPMENT_PENDING]}
                      columns={[ColumnName.status, ColumnName.name, ColumnName.modified]}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Route>

          <Route path={path + SELECT_ID}>
            {({
              match: {
                params: { id },
              },
            }) => (
              <Card square variant={'elevation'} elevation={0}>
                <CardHeaderInverted
                  title={_('telegram:BroadcastDrafts.sectionTitle.message', {
                    sprintf: { id },
                  })}
                />
                <CardContent classes={{ root: gridBoundries }} style={{ minHeight: 300 }}>
                  <Grid container>
                    <Grid item xs>
                      <EditMessage id={id} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Route>

          <Redirect to={path} />
        </Switch>
      </Container>
    </>
  );
};

export default BroadcastDrafts;
