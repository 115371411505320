import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { _ } from '../../../util/translate';
import { WHITE_3 } from '../../../camtool-styles';
import { useMutation } from '@apollo/react-hooks';
import { Mutation } from '../../../graphql/VXModels/types';
import { MUTATION_MODEL_VXCASH_CREATE_WEBMASTER_ID } from '../../../graphql/VXModels/mutations';
import { APP_BASE_PATH } from '../../../util/env';
import { Typography } from '@material-ui/core';
import Section from '../../../atoms/Grid/Section';
import VXCashAdvantages from './VXCashAdvantages';
import Teaser from './Assets/teaser.jpg';
import Header from './Assets/header.jpg';
import { Main } from '../../../atoms/Grid';
import Markdown from '../../../components/Markdown/Markdown';
import NewButton from '../../../atoms/Button/NewButton';
import TeaserBanner from '../../../components/TeaserBanner/TeaserBanner';
import AlertsStore from '../../../stores/Alerts/AlertsStore';
import { MUICheckbox, MUICheckboxFormControlLabel } from '../../../components/MUI/Checkbox';
import { BadgesBanner } from '../../../atoms';
import { DetailsListItem } from '../../../atoms/Banner';
import UserGroup from '../../../atoms/Icon/UserGroup';
import SignUpCheckmark from '../../../atoms/Icon/SignUpCheckmark';
import Euro from '../../../atoms/Icon/Euro';
import { makeStyles, createStyles } from '@material-ui/core';
import { css } from '@emotion/core';

const useStyles = makeStyles(() =>
  createStyles({
    // align checkbox in MUICheckboxFormControlLabel next to label (todo: refactor MUICheckboxFormControlLabel to do this more easy)
    root: {
      padding: '1px 0px 0px 5px',
      alignSelf: 'flex-start',
    },
  })
);

const TOSContent = styled.div`
  background-color: ${WHITE_3};
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const CheckboxContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
`;

const Register: FC = () => {
  const classes = useStyles();

  const [createWebmasterId, { loading, data }] = useMutation<Mutation>(
    MUTATION_MODEL_VXCASH_CREATE_WEBMASTER_ID
  );

  const mutationData = data?.model?.vxcash?.createWebmaster;
  const [state, setState] = useState({
    termsConditionsOk: false,
    disabled: false,
  });

  useEffect(() => {
    if (mutationData?.status === 'INTERNAL_ERROR') {
      AlertsStore.add({
        type: 'error',
        message: _('common:error.generic'),
      });
    }

    if (mutationData?.data.wmId) {
      window.location.href = `${APP_BASE_PATH}/vxcash/invite-users`;
    }
  }, [mutationData]);

  return (
    <Main isMobileEnabled>
      <Section>
        <BadgesBanner
          defaultExpanded
          backgroundImage={Header}
          backgroundImagePosition={'bottom center'}
          summaryText={
            <Typography color="inherit">
              {_('vxcash:register.acceptTerms.header.primaryTitle')}
            </Typography>
          }
          benefitList={[
            {
              icon: <UserGroup />,
              text: (
                <DetailsListItem>{`1. ${_(
                  'vxcash:register.acceptTerms.header.leftBadgePrimaryText'
                )}`}</DetailsListItem>
              ),
            },
            {
              icon: <SignUpCheckmark />,
              text: (
                <>
                  <DetailsListItem>{`2. ${_(
                    'vxcash:register.acceptTerms.header.centerBadgePrimaryText'
                  )}`}</DetailsListItem>
                  <DetailsListItem>
                    {_('vxcash:register.acceptTerms.header.centerBadgeSecondaryText')}
                  </DetailsListItem>
                </>
              ),
            },
            {
              icon: <Euro />,
              text: (
                <DetailsListItem>{`3. ${_(
                  'vxcash:register.acceptTerms.header.rightBadgePrimaryText'
                )}`}</DetailsListItem>
              ),
            },
          ]}
          detailsText={
            <Typography color="inherit" align="center">
              {_('vxcash:register.acceptTerms.header.secondaryTitle')}
            </Typography>
          }
        />
        <VXCashAdvantages />
        <TeaserBanner
          background={Teaser}
          primaryText={_(`vxcash:register.acceptTerms.teaser.primaryTitle`)}
          secondaryText={_(`vxcash:register.acceptTerms.teaser.secondaryTitle`)}
        />
        <TOSContent>
          <h2 css={{ fontSize: '18px' }}>{_(`vxcash:register.acceptTerms.actionCall.title`)}</h2>
          <CheckboxContainer>
            <MUICheckboxFormControlLabel
              checked={state.termsConditionsOk}
              control={
                <MUICheckbox
                  className={classes.root}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    setState({ ...state, termsConditionsOk: e.target.checked })
                  }
                />
              }
              label={
                <Markdown
                  css={css`
                    p {
                      padding: 0px;
                    }
                  `}
                  source={_('vxcash:register.aceptText')}
                  renderers={{
                    // eslint-disable-next-line react/display-name
                    link: (props: Record<string, never>): JSX.Element => (
                      <a href={props.href} target="_blank" rel="noopener noreferrer">
                        {props.children}
                      </a>
                    ),
                  }}
                />
              }
            />
          </CheckboxContainer>
          <NewButton
            size={'medium'}
            disabled={!state.termsConditionsOk || state.disabled || loading}
            loading={loading}
            onClick={(): void => {
              if (state.termsConditionsOk) createWebmasterId();
            }}
          >
            {_(`vxcash:register.acceptTerms.actionCall.submit`)}
          </NewButton>
        </TOSContent>
      </Section>
    </Main>
  );
};

export default Register;
