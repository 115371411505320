import React, { FC } from 'react';
import { Spinner } from '../../../../../../components';
import { TableRow } from '../../../../../../atoms/Table';

const Loader: FC = () => (
  <TableRow>
    <Spinner size="s" />
  </TableRow>
);

export default Loader;
