import React, { FC } from 'react';
import styled from '@emotion/styled';
import { _ } from '../../../util/translate';
import { APP_BASE_PATH } from '../../../util/env';

const DynamicAdvantageContainer = styled.div`
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 75%;
  align-self: center;
`;

const AdvantagesSection = styled.div`
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: center;
  height: 150px;
  width: 460px;
  flex: 0 0 auto;
  align-items: flex-start;
`;

const AdvantageContent = styled.div`
  flex-direction: column;
  height: 70%;
`;

const UnorderedList = styled.ul`
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  list-style-position: inside;
`;

const StyledListItem = styled.li`
  list-style-type: none;
  padding-bottom: 12px;
`;

const ToyControlRequirements: FC = () => {
  return (
    <DynamicAdvantageContainer>
      <AdvantagesSection>
        <AdvantageContent>
          <h2 css={{ marginBottom: '20px' }}>{_('settings:toycontrol.requirements.heading')}</h2>
          <UnorderedList>
            <StyledListItem>{_('settings:toycontrol.requirements.text')}</StyledListItem>
            <StyledListItem>
              {_('settings:toycontrol.requirements.helpcenter')}
              &nbsp;
              <a
                href={`${APP_BASE_PATH}/help/helpcenter/categories/4546758366610/articles`}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </StyledListItem>
          </UnorderedList>
        </AdvantageContent>
      </AdvantagesSection>
    </DynamicAdvantageContainer>
  );
};

export default ToyControlRequirements;
