import { ITableColumn } from '../../../../../../atoms/Table/types';
import { _ } from '../../../../../../util/translate';

const getColumns = (): ITableColumn[] => {
  return [
    { label: _('userManagement:details.section.transactions.column.date') },
    { label: _('userManagement:details.section.transactions.column.type'), width: 1.4 },
    { label: _('userManagement:details.section.transactions.column.amount'), width: 1.1 },
  ];
};

export default getColumns;
