import { QueueFilter, QueueItem, MediaTypeEnum } from '../types';
import { useLayoutEffect, useState } from 'react';
import { UploadManagerStore } from '../store/UploadManagerStore';

export const useFilteredQueue = (filter: QueueFilter): QueueItem[] => {
  const [queue, setQueue] = useState<QueueItem[]>([]);

  useLayoutEffect(() => {
    const subscription = UploadManagerStore.queueSubscribe((queue: QueueItem[] = []) => {
      setQueue(
        queue.filter(
          (item: QueueItem) =>
            item.mediaType === filter.mediaType ||
            filter.mediaType === MediaTypeEnum.all ||
            filter.mediaType === undefined
        )
      );
    });
    UploadManagerStore.queueInit();

    return () => subscription.unsubscribe();
  }, []);

  return queue;
};
