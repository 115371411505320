import React, { FC } from 'react';
import styled from '@emotion/styled';
import LinkBox from '../../../components/LinkBox/LinkBox';
import { WHITE_3 } from '../../../camtool-styles';
import { _ } from '../../../util/translate';
import { useUserData } from '../../../util/UserData';
import { SerializedStyles } from '@emotion/core';

const Container = styled.div`
  position: relative;
  background-color: ${WHITE_3};
  padding: 24px;
`;

interface IProps {
  className?: string;
  description: string;
  descriptionStyle: SerializedStyles;
  title: string;
  titleStyle: SerializedStyles;
  showReferalLinkBox: boolean;
}

const ProvisionInfo: FC<IProps> = ({
  className,
  description,
  descriptionStyle,
  title,
  titleStyle,
  showReferalLinkBox: showLinkBox = false,
}) => {
  const username = useUserData()?.auth?.username;
  return (
    <Container className={className}>
      <div css={{ flexDirection: 'column' }}>
        <h3 css={titleStyle}>{title}</h3>
        <p css={descriptionStyle}>{description}</p>
        {showLinkBox && (
          <div css={{ flex: 1, marginTop: '10px' }}>
            <LinkBox
              link={`https://www.vxpages.com/?sub=${encodeURIComponent(username)}`}
              label={_('components:linkbox.referal.shareLink')}
              buttonLabelCopy={_('common:text.linkCopy')}
              buttonLabelCopied={_('common:text.linkCopied')}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default ProvisionInfo;
