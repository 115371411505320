import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { Select } from '../../../atoms';
import { Option } from '../../../atoms/Select/Select';

interface Props {
  name: string;
  disabled?: boolean;
  options: Option[];
  className?: string;
}

const FieldSelect: FC<Props> = ({ name, disabled, options, className }: Props) => (
  <Field name={name}>
    {({ field }: FieldProps): React.ReactNode => (
      <Select {...field} options={options} disabled={disabled} className={className} />
    )}
  </Field>
);

export default FieldSelect;
