import React, { FC } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import * as overrides from './overrides';
import * as props from './props';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
// import CssBaseline from '@material-ui/core/CssBaseline';
import './animate.css';

const theme = createMuiTheme({ props, overrides, palette, typography, breakpoints });
const MUIThemeProvider: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    {/* temporally disabled due font size problems */}
    {/*<CssBaseline />*/}
    {children}
  </ThemeProvider>
);

export default MUIThemeProvider;
