import React, { useState } from 'react';
import { BLACK, WHITE } from '../../../camtool-styles';
import { PromptBackground } from './styles';

interface ILinkPromptProps {
  onAddLink: (link: string) => void;
  onClose: () => void;
}

const LinkPrompt = (props: ILinkPromptProps) => {
  const [link, setLink] = useState('');

  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
  };

  const handleApplyLink = () => {
    const linkURL = link.includes('https://') || link.includes('http://') ? link : `http://${link}`;
    props.onAddLink(linkURL);
    props.onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleApplyLink();
    } else if (event.key === 'Escape') {
      props.onClose();
    }
  };

  return (
    <PromptBackground
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
    >
      <div
        css={{
          border: `1px solid ${BLACK}`,
          borderRadius: 4,
          padding: '16px 24px',
          position: 'relative',
          backgroundColor: WHITE,
          boxShadow: '0px 0px 5px rgba(0,0,0,0.4)',
        }}
      >
        <div css={{ flexDirection: 'column' }}>
          <label css={{ flexDirection: 'column' }}>
            <span>Link</span>
            <input
              type="text"
              placeholder="https://"
              css={{ border: '1px solid', padding: '4px 8px', borderRadius: 2 }}
              value={link}
              onChange={handleLinkChange}
              onKeyDown={handleKeyDown}
              autoFocus={true}
            />
          </label>
        </div>
        <button
          onClick={handleApplyLink}
          css={{
            alignSelf: 'flex-end',
            border: '1px solid',
            borderRadius: 2,
            padding: '4px 8px',
            background: 'none',
            marginLeft: 8,
            whiteSpace: 'nowrap',
          }}
        >
          Anwenden
        </button>
        <a href="#" css={{ position: 'absolute', right: 8, top: 8 }} onClick={props.onClose}>
          <span className="icon-remove" css={{ color: BLACK }} />
        </a>
      </div>
    </PromptBackground>
  );
};

export default LinkPrompt;
