import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import Button from '../../../../../atoms/Button/Button';
import { _ } from '../../../../../util/translate';
import ModalDailyBonusOptIn from '../../../../Modalboxes/ModalDailyBonusOptIn/ModalDailyBonusOptIn';
import { DailyBonusHelpcenterLink } from '../HelpcenterLinks';

const Container = styled.div`
  flex-direction: column;
  margin-top: 9px;
  margin-left: 8px;
`;

const MainText = styled.div`
  display: block !important;
`;

const StyledDailyBonusHelpcenterLink = styled(DailyBonusHelpcenterLink)`
  margin-top: 8px;
`;

const DailyBonusOptInMessage: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = (): void => setIsModalOpen(true);
  const closeModal = (): void => setIsModalOpen(false);

  const mainTextPart1 = _('mediamanagement:video.dailyBonusOptIn.part1');
  const conditionsName = _('mediamanagement:video.dailyBonusOptIn.part2');
  const mainTextPart3 = _('mediamanagement:video.dailyBonusOptIn.part3');

  return (
    <Container>
      <MainText>
        {mainTextPart1 + ' '}
        <Button
          theme="link-blue"
          onClick={openModal}
          css={{ display: 'inline-flex !important', fontSize: 12 }}
        >
          {conditionsName}
        </Button>
        {(mainTextPart3.startsWith(',') ? '' : ' ') + mainTextPart3}
      </MainText>
      <StyledDailyBonusHelpcenterLink />
      {isModalOpen && <ModalDailyBonusOptIn closable={true} onClose={closeModal} />}
    </Container>
  );
};

export default DailyBonusOptInMessage;
