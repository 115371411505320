import React, { FC } from 'react';
import styled from '@emotion/styled';
import PaginationItem, { IPaginationItemSelectionStateColors } from './PaginationItem';
import { Page, SpecialPagesEnum } from './types';

interface IProps {
  count: number;
  currentPage: number;
  onSelect: (page: number) => void;
  colors?: IPaginationItemSelectionStateColors;
  itemLeftRightMargin?: number;
}

const PaginationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const Pagination: FC<IProps> = ({
  count,
  currentPage,
  onSelect,
  colors,
  itemLeftRightMargin,
}: IProps) => {
  const maxPage = count - 1;
  const itemFillCount = 2;
  const ellipsisPosStart = 1;
  const ellipsisPosEnd = maxPage - ellipsisPosStart;

  const displayedPages: Page[] = new Array(2 * itemFillCount + 1)
    .fill(currentPage)
    .map((num, i) => num - (itemFillCount - i))
    .filter((page) => page >= 0 && page < count);

  if (currentPage > itemFillCount + ellipsisPosStart) {
    displayedPages.unshift(
      currentPage === ellipsisPosStart + itemFillCount + 1
        ? ellipsisPosStart
        : SpecialPagesEnum.ellipsis
    );
  }
  if (currentPage > itemFillCount) {
    displayedPages.unshift(0);
  }
  if (currentPage > 0) {
    displayedPages.unshift(SpecialPagesEnum.back);
  }
  if (currentPage < ellipsisPosEnd - itemFillCount) {
    displayedPages.push(
      currentPage === ellipsisPosEnd - (itemFillCount + 1)
        ? ellipsisPosEnd
        : SpecialPagesEnum.ellipsis
    );
  }
  if (currentPage < maxPage - itemFillCount) {
    displayedPages.push(maxPage);
  }
  if (currentPage < maxPage) {
    displayedPages.push(SpecialPagesEnum.forward);
  }

  const onClick = (page: Page) => {
    if (typeof page === 'number') {
      onSelect(page);
    } else if (page === SpecialPagesEnum.forward) {
      onSelect(currentPage + 1);
    } else if (page === SpecialPagesEnum.back) {
      onSelect(currentPage - 1);
    }
  };

  return (
    <PaginationDiv>
      {displayedPages.map((page, index) => (
        <PaginationItem
          key={index}
          page={page}
          isSelected={page === currentPage}
          onClick={onClick}
          colors={colors}
          leftRightMargin={itemLeftRightMargin}
        />
      ))}
    </PaginationDiv>
  );
};

export default Pagination;
