import qs from 'querystring';
import { hasScope, Scopes } from './scope';
import { ApiLang } from '../graphql/VXModels/types';

export const getStartpageLoginUrl = (lang: ApiLang, redir?: string): string => {
  const queryStringArgs = qs.stringify(redir ? { redir } : {});
  const queryString = queryStringArgs ? `?${queryStringArgs}` : '';

  return hasScope([Scopes.VXPages])
    ? `https://www.vxpages.com/login${queryString}`
    : `https://www.vxmodels.com/${lang}/login${queryString}`;
};

export const getStartpageSignupUrl = (lang: ApiLang): string =>
  hasScope([Scopes.VXPages])
    ? 'https://www.vxpages.com/signup'
    : `https://www.vxmodels.com/${lang}/signup`;

export const getStartpagePasswordResetUrl = (lang: ApiLang): string =>
  hasScope([Scopes.VXPages])
    ? 'https://www.vxpages.com/reset-password'
    : `https://www.vxmodels.com/${lang}/passwordreset`;
