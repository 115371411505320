import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { hasScope, Scopes } from '../../../../../util/scope';
import { vxOutline } from '../../../../../atoms/Icon/libraries/svg';

const VisitXProfileItem: FC = () => {
  const hasScopeVXPages = hasScope([Scopes.VXPages]);

  return (
    <LeftMenuItem
      title={_(
        hasScopeVXPages ? 'navigation:visitx.profileVisitX' : 'navigation:main.profile.visitx'
      )}
      icon={vxOutline}
      uri="/onlineprofile/visitx"
    />
  );
};

export default VisitXProfileItem;
