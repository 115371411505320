import React, { FC, useReducer } from 'react';
import { VXPagesUser } from '../../../../graphql/VXModels/types';
import { Button, InputField, Section, TextareaField } from '../../../../atoms';
import { css } from '@emotion/core';
import { BREAKPOINT_TABLET, RED, WHITE_3 } from '../../../../camtool-styles';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_VXPAGES_USER_UPDATE_USER_NOTE } from '../../../../graphql/VXModels/mutations';
import omit from 'lodash/omit';
import { Spinner } from '../../../../components';
import { sectionMargin } from '../../../../atoms/Grid/Section';
import { _ } from '../../../../util/translate';
import { reducer, useOnNoteChange } from '../../components/utils';
import { log } from '../../../../util';

interface IProps {
  user: VXPagesUser;
}

const sectionStyles = css`
  padding: 37px 40px 40px;
  background-color: ${WHITE_3};
  flex-direction: column;
  ${BREAKPOINT_TABLET} {
    -webkit-box-flex: 1 1 100%;
    -moz-box-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 24px;
  }
`;

const ButtonRow = styled.div`
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 31px;
`;

const Label = styled.div`
  margin: 0 3px 3px;
  font-size: 1.3333rem;
`;

const CheckboxLabel = styled.span`
  font-weight: 300;
  font-size: 1.333rem;
`;

const FormField = styled.div`
  margin-bottom: 20px;
  flex-direction: column;
`;

const SpinnerContainer = styled.div`
  margin-top: 21px;
  flex-direction: column;
`;

const Error = styled.div`
  display: block !important;
  margin-top: 21px;
  color: ${RED};
  text-align: center;
  font-size: 1.33333rem;
`;

const SectionNotes: FC<IProps> = ({ user }: IProps) => {
  const { id, note: initialNote } = user;
  const [note, dispatch] = useReducer(reducer, initialNote);

  useOnNoteChange(initialNote, (previousNote, newNote) => {
    if (previousNote) {
      dispatch({ type: 'init', value: newNote });
    }
  });

  const getOnChangeFn = (fieldName: string, checkbox = false) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({ type: fieldName, value: checkbox ? e.target.checked : e.target.value });
  };

  const [mutateUserNote, { loading, error }] = useMutation(
    MUTATION_MODEL_VXPAGES_USER_UPDATE_USER_NOTE
  );

  const save = async (): Promise<void> => {
    if (error) {
      log('notice', error.message, { context: 'Usernotes', error });
    }

    await mutateUserNote({
      variables: {
        vxPagesUserId: id,
        note: omit(note, '__typename'),
      },
    });
  };

  return (
    <Section
      title={_('userManagement:details.section.notes.title')}
      styles={sectionStyles}
      css={{ width: `calc(50% - 2 * ${sectionMargin}px)` }}
    >
      {[
        { field: 'firstName' },
        { field: 'lastName' },
        { field: 'age' },
        { field: 'city' },
        { field: 'freetext', multiline: true, maxLength: 2000 },
      ].map(({ field, multiline = false, maxLength }) => {
        const Field = multiline ? TextareaField : InputField;
        const fieldName = _(`userManagement:field.note.${field}`);

        return (
          <FormField key={field}>
            <Label>{fieldName}</Label>
            <Field
              value={note[field] || ''}
              onChange={getOnChangeFn(field)}
              maxLength={maxLength}
              disabled={loading}
              showCharCount={multiline}
            />
          </FormField>
        );
      })}
      {loading ? (
        <SpinnerContainer>
          <Spinner inline={true} size={'m'} />
        </SpinnerContainer>
      ) : error ? (
        <Error>{_('userManagement:details.section.notes.mutationError')}</Error>
      ) : null}
      <ButtonRow>
        <Button
          type={'button'}
          theme={'blue'}
          label={_('common:button.save')}
          onClick={save}
          disabled={loading}
        />
      </ButtonRow>
    </Section>
  );
};

export default SectionNotes;
