import React, { FC } from 'react';
import { LeftMenuRoot } from '../../components';
import {
  WelcomeItem,
  DashboardItem,
  AbosItem,
  CustomersItem,
  FinancesItem,
  NewscenterItem,
  HelpItem,
} from '../items';
import {
  ContentGroup,
  HomepageGroup,
  MarketingGroup,
  TelegramGroup,
  VideochatGroup,
  VisitXGroup,
} from '../groups';

const LeftMenuVXPages: FC = () => {
  return (
    <LeftMenuRoot>
      <WelcomeItem />
      <DashboardItem />
      <ContentGroup />
      <HomepageGroup />
      <TelegramGroup />
      <VideochatGroup />
      <AbosItem />
      <MarketingGroup />
      <CustomersItem />
      <FinancesItem />
      <VisitXGroup />
      <NewscenterItem />
      <HelpItem />
    </LeftMenuRoot>
  );
};

export default LeftMenuVXPages;
