import React, { Fragment } from 'react';
import Spinner from '../../../../components/Spinner/Spinner';
import HelpCenterBreadcrumbs from '../../../../components/HelpCenterBreadcrumbs/HelpCenterBreadcrumbs';
import HelpCenterArticle from '../../../../components/HelpCenterArticle/HelpCenterArticle';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_HELPCENTER_ARTICLE } from '../../../../graphql/VXModels/queries';
import { Redirect } from 'react-router';

const Article = ({
  match: {
    params: { categoryId, articleId },
    path,
  },
}) => {
  const { loading, data, error } = useQuery(QUERY_HELPCENTER_ARTICLE, {
    variables: { categoryId, articleId },
  });

  const article = data?.helpcenter?.article;

  return (
    <div className="grid__box__row">
      <div className="grid__column spinner-container" css={{ minHeight: 203 }}>
        {loading ? (
          <Spinner />
        ) : !!article ? (
          <Fragment>
            <HelpCenterBreadcrumbs
              category={article.category}
              section={article.section}
              article={article}
            />
            <header className="helpcenter__title">{article.title}</header>
            <HelpCenterArticle
              category={article.category}
              section={article.section}
              article={article}
              onVoteUp={article.wasHelpfulYes}
              onVoteDown={article.wasHelpfulNo}
            />
          </Fragment>
        ) : (
          <Redirect to={path + '/../../..'} />
        )}
      </div>
    </div>
  );
};

export default Article;
export { Article };
