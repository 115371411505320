import React, { Component, FC } from 'react';
import { ActionEnum, BuildUrl } from '../../types';
import {
  ApiLang,
  Mailing,
  MailingRecipientsGroupsEnum,
  MailingRecipientsGroupsFiltersEnum,
  MailingStatusEnum,
  MailingTemplateEnum,
  MailingTypeEnum,
} from '../../../../graphql/VXModels/types';
import { Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { updateMailing } from '../../graphql';
import { AlertsStore } from '../../../../stores/Alerts/AlertsStore';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import {
  MUTATION_MODEL_MAILINGS_PUBLISH,
  MUTATION_MODEL_MAILINGS_SAVE,
  MUTATION_MODEL_MAILINGS_TEST,
} from '../../../../graphql/VXModels/mutations';
import { MutationFunction } from '@apollo/react-common';
import { _ } from '../../../../util/translate';
import { PAID_CONTENT_PRICE_MIN } from './FormInstantMessenger';

export interface HandleProps {
  buildUrl: BuildUrl;
  callSave: MutationFunction;
  callPublish: MutationFunction;
  callTest: MutationFunction;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  history: any;
}

export interface FormValues {
  id?: number;
  type: MailingTypeEnum;
  recipientsGroup: MailingRecipientsGroupsEnum;
  recipientsGroupFilters: MailingRecipientsGroupsFiltersEnum[];
  action: ActionEnum;
  subject?: string;
  lang: ApiLang;
  recipient: string;
  textHtml?: string;
  text: string;
  template?: MailingTemplateEnum;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  attachment: any;
  published: string;
  publishScheduled: boolean;
  tipping: boolean;
  sticky: boolean;
  isPaidContent: boolean;
  price?: number;
}

const DEFAULT_LANG = ApiLang.de;

const sanitizeVariables = ({
  publishScheduled,
  published,
  attachment,
  isPaidContent,
  price,
  ...variables
}: FormValues) => ({
  ...variables,
  published: publishScheduled ? published : null,
  price: isPaidContent ? price : null,
  attachment: attachment
    ? {
        type: attachment.type,
        payload: attachment.payload,
        meta: attachment.meta
          ? {
              filename: attachment.meta.filename,
              type: attachment.meta.type,
              size: attachment.meta.size,
              hash: attachment.meta.hash,
              dimensions: attachment.meta.dimensions,
              previewUrl: attachment.meta.previewUrl,
            }
          : undefined,
      }
    : undefined,
});

export const handleSubmit =
  ({ callSave, callPublish, callTest, history, buildUrl }: HandleProps) =>
  (
    { action, ...variables }: FormValues,
    { setStatus, setSubmitting }: FormikActions<FormValues>
  ) => {
    const finish = (action: ActionEnum, mailing: Mailing) => {
      const { status } = mailing;
      if (status) {
        setStatus(status);
      }
      setSubmitting(false);
      window.scrollTo(0, 0);
    };
    const sanitizedVariables = sanitizeVariables(variables);
    switch (action) {
      case ActionEnum.SAVE:
        callSave({ variables: sanitizedVariables, update: updateMailing })
          .then(
            ({
              data: {
                model: {
                  mailings: { save: mailing },
                },
              },
            }) => {
              finish(action, mailing);
              if (buildUrl) history.push(buildUrl(mailing));
              AlertsStore.add({ type: 'info', message: _('mailings:alert.saved') });
            }
          )
          .catch((error) => {
            finish(action, {});
            AlertsStore.add({ type: 'error', message: _('mailings:alert.failed') });
          });
        break;

      case ActionEnum.PUBLISH:
        callPublish({ variables: sanitizedVariables, update: updateMailing })
          .then(
            ({
              data: {
                model: {
                  mailings: { publish: mailing },
                },
              },
            }) => {
              finish(action, mailing);
              if (buildUrl) history.push(buildUrl(mailing));
              AlertsStore.add({
                type: 'info',
                message: _('mailings:alert.messagePublishedSuccessful'),
              });
            }
          )
          .catch(() => {
            finish(action, {});
            AlertsStore.add({
              type: 'error',
              message: _('mailings:alert.messagePublishedFailed'),
            });
          });
        break;

      case ActionEnum.TEST:
        callTest({ variables })
          .then(
            ({
              data: {
                model: {
                  mailings: { test: testResult },
                },
              },
            }) => {
              setSubmitting(false);
              window.scrollTo(0, 0);
              if (testResult.success) {
                AlertsStore.add({ type: 'info', message: testResult.message });
              } else {
                AlertsStore.add({ type: 'error', duration: 6000, message: testResult.message });
              }
            }
          )
          .catch(() => {
            setSubmitting(false);
            AlertsStore.add({ type: 'error', message: _('mailings:alert.testSendingFailed') });
          });
        break;
    }
  };

interface FormWrapperProps {
  buildUrl: BuildUrl;
  mailing: Mailing;
  component: Component;
  validate?: (
    values: FormValues
  ) => void | Record<string, unknown> | Promise<FormikErrors<FormValues>>;
  validationSchema?: any | (() => any);
}

const FormWrapper: FC<FormWrapperProps> = ({
  buildUrl,
  component: C,
  mailing,
  validate,
  validationSchema,
}) => {
  const {
    id,
    type,
    content = {},
    recipientsFilters,
    recipientsGroups,
    status = MailingStatusEnum.DRAFT,
    published,
    price,
    isTipping,
    isSticky,
  } = mailing;

  const initialValues: Partial<FormValues> = {
    id,
    type,
    recipientsGroup: recipientsGroups.selected || undefined,
    subject: content?.subject || '',
    lang: content?.lang || DEFAULT_LANG,
    textHtml: content?.textHtml || '',
    text: content?.text || '',
    template:
      type === MailingTypeEnum.VXPAGES_EMAIL
        ? content?.template || MailingTemplateEnum.LIGHT
        : undefined,
    attachment: content?.attachment,
    recipientsGroupFilters: recipientsFilters.selected || [],
    published: published || new Date().toISOString(),
    publishScheduled: !!published,
    isPaidContent: (price || 0) > 0,
    price: price || PAID_CONTENT_PRICE_MIN,
    tipping: isTipping,
    sticky: isSticky,
  };

  const history = useHistory();
  const [callSave] = useMutation(MUTATION_MODEL_MAILINGS_SAVE, {});
  const [callPublish] = useMutation(MUTATION_MODEL_MAILINGS_PUBLISH, {});
  const [callTest] = useMutation(MUTATION_MODEL_MAILINGS_TEST, {});

  return (
    <Formik
      history={history}
      initialStatus={status}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={validate}
      onSubmit={handleSubmit({ history, callSave, callPublish, callTest, buildUrl })}
      className="spinner-container"
    >
      {(props: FormikProps<Record<string, unknown>>) => <C mailing={mailing} {...props} />}
    </Formik>
  );
};

export default FormWrapper;
