import React, { FC } from 'react';
import Button from '../../../../../atoms/Button/Button';

interface IProps {
  className?: string;
  label: string;
  href: string;
}

const HelpcenterLink: FC<IProps> = ({ className, href, label }) => (
  <Button theme="link-blue" href={href} className={className}>
    {label}
  </Button>
);

export default HelpcenterLink;
