import styled from '@emotion/styled';
import { GRAY_3 } from '../../../../../../../camtool-styles';
import React, { FC } from 'react';

interface IProps {
  multiline?: boolean;
}

const FieldValueDiv = styled.div(
  ({ multiline }: { multiline: boolean }) => `
  display: block !important;
  border: 1px solid ${GRAY_3};
  border-radius: 4px;
  padding: 8px 10px;
  font-weight: 300;
  white-space: ${multiline ? 'pre-wrap' : 'nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;
`
);

const FieldValue: FC<IProps> = ({ multiline = false, children }) => (
  <FieldValueDiv multiline={multiline}>{children || '\u200b'}</FieldValueDiv>
);

export default FieldValue;
