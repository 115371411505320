import React, { FC, useState, useEffect } from 'react';
import { _ } from '../../../../util/translate';
import backgroundTelegram from './Assets/backgroundTelegram.jpg';
import clock from './Assets/clock.svg';
import telegram from './Assets/telegram.svg';
import freeUser from './Assets/phone.svg';
import vip from './Assets/lockedPhone.svg';
import { Link } from 'react-router-dom';
import { APP_BASE_PATH } from '../../../../util/env';
import AutoMailFormik from './AutoMailFormik';
import { Spinner } from '../../../../components';
import GenericEmptyContent from '../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { useTelegramAccount } from '../../../Telegram/hooks/useTelegramAccount';
import { hasScope, Scopes } from '../../../../util/scope';
import ThreeBadgesBanner from '../../../../atoms/Banner/ThreeBadgesBanner';
import { WHITE_2, WHITE_3 } from '../../../../camtool-styles';

interface AutoMailEntryProps {
  isVip: boolean;
}

const AutoMailEntry: FC<AutoMailEntryProps> = ({ isVip = false }) => {
  const [displayVip, setDisplayVip] = useState(isVip);
  useEffect(() => setDisplayVip(isVip), [isVip]);
  const { account, loading, error } = useTelegramAccount();
  const token = account?.token;

  return loading ? (
    <Spinner />
  ) : error ? (
    <GenericEmptyContent />
  ) : (
    <article
      css={{
        flexDirection: 'column',
        overflowX: 'auto',
        padding: '0px 8px',
        marginTop: '-20px',
      }}
    >
      <ThreeBadgesBanner
        background={backgroundTelegram}
        primaryHeadline={_('mailings:telegram.autoMail.banner.title')}
        secondaryHeadline={
          <>
            {_(`mailings:telegram.autoMail.banner.subTitleText.${displayVip ? 'VIP' : 'free'}`)}
            <Link
              css={{ marginLeft: '2px', color: WHITE_2 }}
              to={`${APP_BASE_PATH}/help/helpcenter/categories/${
                hasScope([Scopes.VXModels])
                  ? '360002286959/articles/360017625959'
                  : '360001679099/articles/360017676720'
              }`}
            >
              {_('mailings:telegram.autoMail.subTitleLink')}
            </Link>
          </>
        }
        primaryThemeColor={WHITE_2}
        secondaryThemeColor={WHITE_2}
        iconColor={WHITE_2}
        leftBadge={clock}
        leftBadgeSecondaryText={_('mailings:telegram.autoMail.banner.leftBadgeText')}
        centerBadge={telegram}
        centerBadgeSecondaryText={_('mailings:telegram.autoMail.banner.centerBadgeText')}
        rightBadge={displayVip ? vip : freeUser}
        rightBadgeSecondaryText={_(
          `mailings:telegram.autoMail.banner.rightBadgeText.${displayVip ? 'VIP' : 'free'}`
        )}
      />
      <div css={{ backgroundColor: WHITE_3 }}>
        {!!token && <AutoMailFormik token={token} isVip={displayVip} />}
      </div>
    </article>
  );
};

export default AutoMailEntry;
