import React, { ReactElement } from 'react';
import { WHITE } from '../../../camtool-styles';

const VAT = (): ReactElement => {
  return (
    <g transform="translate(-3730.8 664.2)">
      <g transform="translate(3736.867 -659.383)">
        <g>
          <path
            d="M15.975,9.891h6.158L15.5,3.183V9.412a.465.465,0,0,0,.137.34A.454.454,0,0,0,15.975,9.891Z"
            fill={WHITE}
          />
          <path d="M13.715,16.539l-.8,2.34h1.61l-.785-2.34Z" fill={WHITE} />
          <path
            d="M14.691,10.711a.465.465,0,0,1-.138-.34V3.183H4.607a.455.455,0,0,0-.336.139.465.465,0,0,0-.137.34V25.7a.465.465,0,0,0,.137.34.455.455,0,0,0,.336.139H21.659A.454.454,0,0,0,22,26.044a.465.465,0,0,0,.138-.34V10.85H15.028A.455.455,0,0,1,14.691,10.711ZM8.886,20.9l-2-5.45h.792l1.391,4.02.122.453h.022l.122-.453,1.392-4.02h.788l-2,5.45Zm6.317,0-.474-1.4H12.7l-.481,1.4h-.744l1.943-5.45h.625l1.9,5.45Zm.566-5.45h4.259v.58H18.252V20.9h-.729V16.03H15.769Z"
            fill={WHITE}
          />
        </g>
      </g>
    </g>
  );
};

export default VAT;
