import React, { FC } from 'react';
import { globe } from '../../../../atoms/Icon/libraries/glyph';
import { SecurityRole } from '../../../../graphql/VXModels/types';
import { hasScope, Scopes } from '../../../../util/scope';
import { _ } from '../../../../util/translate';
import { useHasRole } from '../../../../util/UserData';
import { LeftMenuGroup } from '../../components';
import {
  AbosItem,
  BlogItem,
  CreatorItem,
  HomepageSettingsItem,
  HomepageStatsItem,
  OtherServicesItem,
} from '../items';

const HomepageGroup: FC = () => {
  const hasVXPage = useHasRole(SecurityRole.VX_USER_VXPAGES);
  const hasScopeVXModels = hasScope([Scopes.VXModels]);

  return hasVXPage ? (
    <LeftMenuGroup title={_('navigation:main.homepage')} icon={globe}>
      <HomepageSettingsItem />
      <CreatorItem />
      <BlogItem />
      {hasScopeVXModels && <AbosItem />}
      <HomepageStatsItem />
      <OtherServicesItem />
    </LeftMenuGroup>
  ) : null;
};

export default HomepageGroup;
