import React, { FC, Fragment } from 'react';
import styled from '@emotion/styled';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { stripSlash } from '../../../util/urlHelper';
import { Archive } from '../../../packages/Mailings';
import { MailingRecipientsGroupsEnum } from '../../../graphql/VXModels/types';
import { _ } from '../../../util/translate';
import { SELECT_ID, NEWSLETTER_ARCHIVE, PREMIUMABO_ARCHIVE, type } from '../../Telegram/constants';
import { NavSub, NavSubItem } from '../../../components';
import { EditMessage } from '../../../packages/Mailings';
import { Section } from '../../../atoms';

const Container = styled.div`
  flex-direction: column;
`;

const TelegramArchive: FC<RouteComponentProps> = ({ match: { url } }) => {
  const path = stripSlash(url);

  return (
    <Container>
      <NavSub>
        <NavSubItem
          link={path + NEWSLETTER_ARCHIVE}
          icon="icon-inbox"
          label={_('mailings:telegram.nav.archivNewsletter')}
        />
        <NavSubItem
          link={path + PREMIUMABO_ARCHIVE}
          icon="icon-inbox"
          label={_('mailings:telegram.nav.archiveAbo')}
        />
      </NavSub>

      <Switch>
        <Route path={path + NEWSLETTER_ARCHIVE}>
          <Section title={_('mailings:telegram.sectionTitle.newsletter')} css={{ minHeight: 300 }}>
            <Archive group={MailingRecipientsGroupsEnum.VXPAGES_TELEGRAM_NEWSLETTER} type={type} />
          </Section>
        </Route>

        <Route path={path + PREMIUMABO_ARCHIVE}>
          <Section title={_('mailings:telegram.sectionTitle.abo')} css={{ minHeight: 300 }}>
            <Archive group={MailingRecipientsGroupsEnum.VXPAGES_TELEGRAM_PREMIUMABO} type={type} />
          </Section>
        </Route>

        <Route path={path + SELECT_ID}>
          {({
            match: {
              params: { id },
            },
          }) => (
            <Section
              title={_('mailings:telegram.sectionTitle.edit', { sprintf: { id } })}
              css={{ minHeight: 300 }}
            >
              <EditMessage id={id} />
            </Section>
          )}
        </Route>

        <Redirect to={path + NEWSLETTER_ARCHIVE} />
      </Switch>
    </Container>
  );
};

export default TelegramArchive;
