import React, { FC, useEffect, useState, Fragment } from 'react';
import { Button } from '../../index';
import ImagePickerItem from './ImagePickerItem';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/react-hooks';
import { Spinner } from '../../../components';
import { useUserData } from '../../../util/UserData';
import { PictureAlbumTypeEnum, usePictureUploader } from '../../../util/Uploader';
import gql from 'graphql-tag';
import { Picture } from '../../../graphql/VXModels/types';
import { useLang } from '../../../util/AppState';
import ImagePickerAdd from './ImagePickerAdd';
import { DARK_GRAY, WHITE } from '../../../camtool-styles';
import { _ } from '../../../util/translate';

const QUERY_PHOTO_ALBUMS_BY_ID_IMAGEPICKER = gql`
  query QUERY_PHOTO_ALBUMS_BY_ID_IMAGEPICKER($umaId: Int) {
    model {
      photoAlbums(id: $umaId, sortField: id, sortDirection: desc) {
        albums {
          pictures {
            id
            status
            urlPreview: url(size: w160)
            url(size: w640)
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  flex-direction: column;
  background-color: white;
  align-self: center;
  //border-radius: 8px;
  min-width: 500px;
  min-height: 400px;
`;

const ContainerHeader = styled.div`
  background-color: ${DARK_GRAY};
  color: ${WHITE};
  flex: 1;
  max-width: 794px;
  max-height: 32px;
  min-height: 32px;
  align-content: flex-start;
  padding: 8px;
`;

const ContainerBody = styled.div`
  background-color: white;
  flex: 1;
  max-width: 794px;
  max-height: 578px;
  min-height: 512px;
  align-self: center;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
  padding: 8px;
`;

interface ImagePickerProps {
  onCancelClick?: () => void;
  onPictureSelect?: (picture: PictureResult) => void;
  maxFileSize?: number;
}

type PictureResult = Picture & { urlPreview: string };

const sortByIdDesc = (a: PictureResult, b: PictureResult): number =>
  a.id === b.id ? 0 : a.id > b.id ? -1 : 1;

const ImagePicker: FC<ImagePickerProps> = ({ onCancelClick, onPictureSelect, maxFileSize = 0 }) => {
  const [lang] = useLang();
  const [pictures, setPictures] = useState([]);
  const [selected, setSelected] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    model: {
      extra: { mailingsUmaId },
    },
  } = useUserData();

  const { loading: queryLoading, data, refetch } = useQuery(QUERY_PHOTO_ALBUMS_BY_ID_IMAGEPICKER, {
    variables: { umaId: mailingsUmaId },
    fetchPolicy: 'cache-and-network',
  });

  const [{ loading: uploaderLoading, error: uploaderError }, upload] = usePictureUploader({
    type: PictureAlbumTypeEnum.MAILINGS,
    umaId: mailingsUmaId,
    maxFileSize,
    onCompleted: ({ umpId }) => {
      setLoading(true);
      refetch().then(
        ({
          data: {
            model: {
              photoAlbums: { albums },
            },
          },
        }) => {
          const picture = albums[0].pictures.reduce((p: PictureResult, c: PictureResult) =>
            p ? p : c.id === umpId ? c : null
          );
          setLoading(queryLoading || uploaderLoading);
          setSelected(picture || {});
        }
      );
    },
    onError: (error) => setError(error),
  });

  useEffect(() => setLoading(queryLoading || uploaderLoading), [queryLoading, uploaderLoading]);

  useEffect(() => {
    if (data && data.model && data.model.photoAlbums) {
      setPictures(data.model.photoAlbums.albums[0].pictures);
    }
  }, [data]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      upload(file);
    }
  };

  const handleCancelClick = (): void => {
    // console.log('@handleCancel');
    setSelected({});
    if (onCancelClick) onCancelClick();
  };

  const handleOkClick = (): void => {
    if (onPictureSelect && selected.id) {
      onPictureSelect(selected);
    }
  };

  const handlePictureClick = (picture: PictureResult): void => {
    // console.log('@handlePictureClick');
    setSelected(picture);
    setError(null);
  };

  return (
    <Fragment>
      <Container className="spinner-container">
        <ContainerHeader>{_('atom:TextEditor.button.newPicture.label')}</ContainerHeader>
        {((pictures.length === 0 && loading) || uploaderLoading) && <Spinner />}

        <ContainerBody>
          {(pictures.length > 0 || !loading) && <ImagePickerAdd onChange={handleImageChange} />}
          {pictures.sort(sortByIdDesc).map((pic: PictureResult) => (
            <ImagePickerItem
              key={pic.id}
              url={pic.urlPreview}
              selected={pic.id === selected.id}
              onClick={(): void => handlePictureClick(pic)}
            />
          ))}
        </ContainerBody>

        {error && (
          <div
            css={{
              justifyContent: 'center',
              backgroundColor: 'whitesmoke',
              marginTop: 8,
              borderRadius: 4,
            }}
          >
            <div css={{ color: 'red', border: '1px solid red', padding: 4 }}>
              {error.message[lang]}
            </div>
          </div>
        )}

        {!loading && (
          <div
            css={{
              justifyContent: 'center',
              backgroundColor: 'whitesmoke',
              padding: '16px 0',
              marginTop: 8,
              borderRadius: 4,
            }}
          >
            <Button theme="gray" onClick={handleCancelClick} css={{ marginRight: 8 }}>
              {_('common:button.cancel')}
            </Button>

            <Button
              theme="blue"
              disabled={!selected.id}
              onClick={handleOkClick}
              css={{ marginRight: 8 }}
            >
              {_('atom:TextEditor.button.usePicture.label')}
            </Button>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default ImagePicker;
