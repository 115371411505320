import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BLACK_2 } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';
import { VXPagesUser } from '../../../../graphql/VXModels/types';

const Label = styled.div`
  font-size: 1.33333rem;
  font-weight: bold;
  margin: 5px;
  font-family: 'Roboto Condensed', sans-serif;
  color: ${BLACK_2};
  width: 100%;
  text-align: center;
  display: block !important;
`;

interface IProps {
  user: VXPagesUser;
}

const UserBalanceLabel: FC<IProps> = ({ user: { username } }) => (
  <Label>{_('userManagement:details.section.credits.userHas', { sprintf: [username] })}</Label>
);

export default UserBalanceLabel;
