import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { BLACK_2 } from '../../../camtool-styles';

export const InputSection = styled.div`
  flex-direction: column;
  margin-bottom: 10px;
`;

export const tableHeaderItemStyles = css`
  color: ${BLACK_2};
  opacity: 1;
  font-style: italic;
`;
