import { useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';
import { Query, SecurityRole, VXModelsMobileTargetEnum } from '../../../../graphql/VXModels/types';
import useDeepLinkBeta from '../../../../hooks/useDeepLinkBeta';
import { QUERY_MODEL_VXCHALLENGE_ALLOWED } from '../../../../packages/VXGames/graphql';
import { useHasRole } from '../../../../util/UserData';
import { LeftMenuRoot } from '../../components';
import {
  AffiliateGroup,
  ContentGroup,
  HomepageGroup,
  MarketingGroup,
  ProfileGroup,
  TelegramGroup,
  VideochatGroup,
} from '../groups';
import {
  CustomersItem,
  DashboardItem,
  FeedItem,
  FinancesItem,
  HelpItem,
  NewscenterItem,
  RankingItem,
  Service0900Item,
  VXGamesItem,
  WelcomeItem,
} from '../items';

const LeftMenuVXModels: FC = () => {
  const { data } = useQuery<Query>(QUERY_MODEL_VXCHALLENGE_ALLOWED);
  const vxgamesAllowed = data?.model?.vxChallenges?.isAllowed;
  const {
    data: feedData,
    loading: feedLoading,
    error: feedError,
  } = useDeepLinkBeta(VXModelsMobileTargetEnum.feed);
  const isFeedLinkReady = feedData && !feedLoading && !feedError;

  return (
    <LeftMenuRoot>
      <WelcomeItem />
      <DashboardItem />
      <ProfileGroup />
      <ContentGroup />
      <VideochatGroup />
      <TelegramGroup />
      <MarketingGroup />
      <CustomersItem />
      <FinancesItem />
      <AffiliateGroup />
      <Service0900Item />
      <RankingItem />
      {vxgamesAllowed && <VXGamesItem />}
      <HomepageGroup />
      {isFeedLinkReady && <FeedItem />}
      <NewscenterItem />
      <HelpItem />
    </LeftMenuRoot>
  );
};

export default LeftMenuVXModels;
