import React, { FC } from 'react';
import { LeftMenuGroup } from '../../components';
import { bullhorn } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';
import { hasScope, Scopes } from '../../../../util/scope';
import {
  AdmailsItem,
  EmailItem,
  MessengerItem,
  BonusCodesItem,
  CompetitionsItem,
  MarketingActionsItem,
} from '../items';

const MarketingGroup: FC = () => {
  const hasScopeVXPages = hasScope([Scopes.VXPages]);

  return (
    <LeftMenuGroup title={_('navigation:main.marketing')} icon={bullhorn}>
      <BonusCodesItem />
      {!hasScopeVXPages && <AdmailsItem />}
      <EmailItem />
      <MessengerItem />
      {hasScopeVXPages && <AdmailsItem />}
      {!hasScopeVXPages && (
        <>
          <CompetitionsItem />
          <MarketingActionsItem />
        </>
      )}
    </LeftMenuGroup>
  );
};

export default MarketingGroup;
