import React from 'react';
import PropTypes from 'prop-types';

const ProfileInfobox = ({ text, iconSize, className, children }) => (
  <section className={`profile-infobox ${className}`}>
    <span className={`fas fa-info-circle fa-${iconSize}x`} />
    {text ? <p className="profile-infobox__text">{text}</p> : children}
  </section>
);

ProfileInfobox.propTypes = {
  text: PropTypes.string,
  iconSize: PropTypes.number,
  className: PropTypes.string,
  children: React.ReactNode,
};

ProfileInfobox.defaultProps = {
  iconSize: 2,
  className: '',
  text: '',
};

export default ProfileInfobox;
export { ProfileInfobox };
