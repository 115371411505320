import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { useHasRole } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';
import { lovenseSquare } from '../../../../../atoms/Icon/libraries/svg';

const LovenseItem: FC = () => {
  const isSexoleModel = useHasRole(SecurityRole.VX_USER_SEXOLE);

  return isSexoleModel ? null : (
    <LeftMenuItem
      title={_('navigation:videochat.lovense')}
      icon={lovenseSquare}
      uri="/settings/toycontrol"
    />
  );
};

export default LovenseItem;
