export * from './modelInstantMessaging';
export * from './modelTvshowsReplacementOffer';

import gql from 'graphql-tag';
import {
    FRAGMENT_ALBUM_PREVIEW_PICTURES,
    FRAGMENT_ALBUM_SALES_DATA,
    FRAGMENT_AUTH_USER,
    FRAGMENT_AVATAR,
    FRAGMENT_BA6_DATA,
    FRAGMENT_CMS_ARTICLE,
    FRAGMENT_GAMES_BASE_DATA,
    FRAGMENT_GAMES_PART,
    FRAGMENT_GAMES_PART_DETAILED,
    FRAGMENT_GAMES_PART_WITH_USER_RANK,
    FRAGMENT_LOCALIZED_TEXT,
    FRAGMENT_MEDIA_ALBUM,
    FRAGMENT_NOTIFICATION_ITEMS,
    FRAGMENT_PROFILE_PREVIEW_PICTURE,
    FRAGMENT_TERM_ACCEPTANCE,
    FRAGMENT_VIDEO_PREVIEW,
    FRAGMENT_VXPAGES_REGISTER_INFORMATION,
    FRAGMENT_VXPAGES_STATS_TURNOVER_DATA,
    FRAGMENT_VXPAGES_USER,
    FRAGMENT_VXPAGES_USER_TRANSACTIONS_RESULT
} from '../fragments';
import { TermsTypeEnum } from '../types';

export const QUERY_APP_INITIAL = gql`
    query APP_INITIAL($notificationsOffset: Int = 0, $notificationsLimit: Int, $timezone: String!) {
        auth {
            ...AuthUser
        }
        model {
            isMobileRegistration
            instantMessaging {
                account(platform: TELEGRAM) {
                    active
                }
            }
            abo {
                token
            }
            contests {
                contests {
                    status
                    uploadEnd
                }
            }
            avs {
                status
            }
            documents {
                allActorDocumentsComplete
                allActorDocumentsUploaded
                allOriginatorDocumentsUploaded
                hasAllRequiredDocuments
            }
            hasActors
            hasOriginator
            avatar {
                ...Avatar
            }
            account {
                email
                isVerified
            }
            newscenterNotifications: notifications(
                unreadOnly: true
                showExpired: true
                category: [newscenter, system, snagbar, vxgames2020, vxchallenges]
                timezone: $timezone
            ) {
                count
            }
            extra {
                picturePoolUmaId
                mailingsUmaId
                showMenuItemActors
                isNewRegistrationWithoutVerifiedEmail
            }
            vxcash {
                wmid
                autologinLink
            }
            profile {
                languages{value}
                laSettingsProfileActive
                gender {
                    value
                }
            }
            vxpages {
                info {
                    ...VXPagesRegisterInformation
                }
                isTelegramOnlyPage
            }
            terms {
                acceptanceVXPagesTermsOfUse: acceptance(type: ${TermsTypeEnum.VXPAGES_TERMS_OF_USE_HOMEPAGE}) {
                    ...TermAcceptance
                }
                acceptanceVXModelsTermsAndConditions: acceptance(type: ${TermsTypeEnum.VXMODELS_TERMS_AND_CONDITIONS}) {
                    ...TermAcceptance
                }
                acceptancePrivacyPolicy: acceptance(type: ${TermsTypeEnum.VXMODELS_PRIVACY_POLICY}) {
                    ...TermAcceptance
                }
                acceptanceTelegramTermsAndConditions: acceptance(type: ${TermsTypeEnum.TELEGRAM_TERMS}) {
                    ...TermAcceptance
                }
            }
            unreadNotifications: notifications(
                showExpired: false
                unreadOnly: true
                category: [
                    common
                    directNotification
                    document
                    finance
                    marketing
                    medal
                    mediacontent
                    homepage
                    profile
                    ranking
                    system
                ]
                timezone: $timezone
            ) @connection(key: "notifications", filter: ["showExpired", "unreadOnly", "category"]) {
                count
                items(sort: PRIO_DESC__DATE_DESC, offset: $notificationsOffset, limit: $notificationsLimit)
                @connection(key: "items", filter: ["sort"]) {
                    ...NotificationItems
                }
            }
            bonuscodes {
                canCreateVisitXBonusCodes
            }
            media {
                hasSubmittedAlbumsForDailyBonus
                dailyBonusOptInStatus
                previewPicture16 {
                    picture {
                        isChecked
                    }
                }
            }
            permissions {
                isFinancesVisibleWithoutMasterPassword
            }
        }
    }
    ${FRAGMENT_AUTH_USER}
    ${FRAGMENT_NOTIFICATION_ITEMS}
    ${FRAGMENT_AVATAR}
    ${FRAGMENT_VXPAGES_REGISTER_INFORMATION}
    ${FRAGMENT_TERM_ACCEPTANCE}
`;

export const QUERY_VERIFY_PASSWORD_HASH = gql`
    query QUERY_VERIFY_PASSWORD_HASH($hash: String!) {
        verifyPasswordResetHash(hash: $hash)
    }
`;

export const QUERY_MODEL_VIDEOS_ALBUMS_BY_TYPE = gql`
    query QUERY_MODEL_VIDEOS_ALBUMS_BY_TYPE(
        $type: MediaType
        $offset: Int
        $limit: Int
        $sortField: MediaAlbumSortFields
        $sortDirection: MediaAlbumSortDirection
        $imported: Boolean
    ) {
        model {
            videos(
                type: $type
                offset: $offset
                limit: $limit
                sortField: $sortField
                sortDirection: $sortDirection
                imported: $imported
            ) {
                albumsTotal
                albums {
                    ...VideoPreview
                }
            }
        }
    }
    ${FRAGMENT_VIDEO_PREVIEW}
`;

export const QUERY_CMS_ARTICLE = gql`
    query QUERY_CMS_ARTICLE($id: ID!, $rev: Int = 0, $lang: ApiLang) {
        cms(lang: $lang) {
            article(id: $id, rev: $rev) {
                ...CmsArticle
            }
        }
    }

    ${FRAGMENT_CMS_ARTICLE}
`;

export const QUERY_PHOTO_ALBUMS_BY_ID = gql`
    query GET_ALBUM_BY_ID($umaId: Int) {
        model {
            photoAlbums(id: $umaId) {
                albums {
                    ...MediaAlbumItem
                }
            }
        }
    }
    ${FRAGMENT_MEDIA_ALBUM}
`;

export const QUERY_PHOTO_ALBUMS_BY_TYPE = gql`
    query GET_ALBUMS_BY_TYPE(
        $type: MediaType
        $offset: Int
        $limit: Int
        $sortField: MediaAlbumSortFields
        $sortDirection: MediaAlbumSortDirection
    ) {
        model {
            photoAlbums(
                type: $type
                offset: $offset
                limit: $limit
                sortField: $sortField
                sortDirection: $sortDirection
            ) {
                albumsTotal
                albums {
                    ...MediaAlbumItem
                }
            }
        }
    }
    ${FRAGMENT_MEDIA_ALBUM}
`;

export const QUERY_MODEL_MAILINGS_MAILINGS = gql`
    query QUERY_MODEL_MAILINGS_MAILINGS(
        $type: MailingTypeEnum!
        $recipientsGroups: [MailingRecipientsGroupsEnum!]
        $status: [MailingStatusEnum!]
        $limit: Int
    ) {
        model {
            mailings {
                mailings(
                    type: $type,
                    group: $recipientsGroups,
                    status: $status
                    limit: $limit,
                ) {
                    id
                    name
                    type
                    status
                    editable
                    shipmentRecipientsCount
                    created
                    modified
                    published
                }
            }
        }
    }
`;

export const QUERY_MODEL_VXPAGES_USERS = gql`
    query QUERY_MODEL_VXPAGES_USERS($limit: Int!, $offset: Int!, $filter: VXPagesUsersFilter! $order: VXPagesUsersOrder!) {
        model {
            vxpages {
                users(limit: $limit, offset: $offset, filter: $filter, order: $order) {
                    total
                    items {
                        ...VXPagesUser
                    }
                }
            }
        }
    }
    ${FRAGMENT_VXPAGES_USER}
`;

export const QUERY_MODEL_VXPAGES_USER = gql`
    query QUERY_MODEL_VXPAGES_USER($id: Int!, $transactionsLimit: Int!, $transactionsOffset: Int!) {
        model {
            vxpages {
                user(id: $id) {
                    ...VXPagesUser
                    transactions(limit: $transactionsLimit, offset: $transactionsOffset) {
                        ...VXPagesUserTransactionsResult
                    }
                }
            }
        }
    }
    ${FRAGMENT_VXPAGES_USER}
    ${FRAGMENT_VXPAGES_USER_TRANSACTIONS_RESULT}
`;

export const QUERY_MODEL_VXPAGES_STATS_KPI_TURNOVER = gql`
    query QUERY_MODEL_VXPAGES_STATS_KPI_TURNOVER($timeframes: [VXPagesStatsTimeframeInput!]!) {
        model {
            vxpages {
                stats {
                    kpi(filter: { timeframes: $timeframes }) {
                        timeframe {
                            start
                            end
                        }
                        data {
                            visits
                            signups
                            transactions
                            firstPayments
                            referredModels
                            logins
                        }
                    }
                    turnover(filter: { timeframes: $timeframes }) {
                        timeframe {
                            start
                            end
                        }
                        data {
                            creditsCharges {
                                ...VXPagesStatsTurnoverData
                            }
                            subscriptions {
                                ...VXPagesStatsTurnoverData
                            }
                            chargebacks {
                                ...VXPagesStatsTurnoverData
                            }
                            collections {
                                ...VXPagesStatsTurnoverData
                            }
                            otherProfit {
                                ...VXPagesStatsTurnoverData
                            }
                            chargebacksFee {
                                ...VXPagesStatsTurnoverData
                            }
                            guestAmateurFee {
                                ...VXPagesStatsTurnoverData
                            }
                            otherFee {
                                ...VXPagesStatsTurnoverData
                            }
                            chargebackFeeRefund {
                                ...VXPagesStatsTurnoverData
                            }
                            resellerComission {
                                ...VXPagesStatsTurnoverData
                            }
                        }
                    }
                }
            }
        }
    }
    ${FRAGMENT_VXPAGES_STATS_TURNOVER_DATA}
`;

export const QUERY_MODEL_TERMS_ACCEPTANCE = gql`
    query QUERY_MODEL_TERMS_ACCEPTANCE($type: TermsTypeEnum!) {
        model {
            terms {
                acceptance(type: $type) {
                    ...TermAcceptance
                }
            }
        }
    }
    ${FRAGMENT_TERM_ACCEPTANCE}
`;

export const QUERY_MODEL_SETTINGS_LOVENSE_TOY = gql`
    query QUERY_MODEL_SETTINGS_LOVENSE_TOY {
        model {
            settings {
                lovenseToySettings {
                    status
                    newRequestDate
                }
            }
        }
    }
`;

export const QUERY_MODEL_SETTINGS_MARKETING = gql`
    query QUERY_MODEL_SETTINGS_MARKETING {
        model {
            settings {
                marketingSettings {
                    hasVXMarketing
                    isVisibleVXPagesMarketing
                    hasCrossMarketing
                    hasUserReactivation
                }
            }
        }
    }
`;


export const QUERY_AUTH_MODEL_GENDER_AVATAR = gql`
    query QUERY_AUTH_MODEL_GENDER_AVATAR {
        auth {
            username
            screenname
            id
            hotlineCode
            roles
        }
        model {
            account{
                keyAccounter {
                    contactEmail
                    contactName
                    contactPhoneNumber
                }
            }
            profile {
                gender {
                    value
                }
            }
            avatar {
                ...Avatar
            }
        }
    }
    ${FRAGMENT_AVATAR}
`;

export const QUERY_HELPCENTER_CATEGORIES = gql`
    query QUERY_HELPCENTER_CATEGORIES {
        helpcenter {
            categories {
                id
                name
                meta {
                    icon
                    color
                }
            }
        }
    }
`;

export const QUERY_HELPCENTER_CATEGORY = gql`
    query QUERY_HELPCENTER_CATEGORY($categoryId: ID!) {
        helpcenter {
            category(id: $categoryId) {
                id
                name
                description
                locale
                sections {
                    id
                    name
                    articles {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const QUERY_HELPCENTER_ARTICLE = gql`
    query QUERY_HELPCENTER_ARTICLE($articleId: ID!) {
        helpcenter {
            article(id: $articleId) {
                id
                name
                category {
                    id
                    name
                }
                section {
                    id
                    name
                    articles {
                        id
                        name
                    }
                }
                body
            }
        }
    }
`;

export const QUERY_HELPCENTER_SEARCH = gql`
    query QUERY_HELPCENTER_SEARCH($query: String, $page: Int, $limit: Int) {
        helpcenter {
            search(
                query: $query
                page: $page
                limit: $limit
            ) {
                query
                articleCount
                currentPage
                pageCount
                limit
                articles {
                    # same category as the one inside section
                    category {
                        id
                        name
                    }
                    section {
                        id
                        name
                    }
                    id
                    name
                    body
                }
            }
        }
    }
`;

export const QUERY_MODEL_ABO_TOKEN = gql`
    {
        model {
            abo {
                token
            }
        }
    }
`;

export const QUERY_MODEL_VXPAGES_TOKEN = gql`
    {
        model {
            vxpages {
                token
            }
        }
    }
`;

export const QUERY_MODEL_CONTENT_VXPAGES = gql`
    query QUERY_MODEL_CONTENT_VXPAGES($offset: Int!, $limit: Int!) {
        model {
            videos(
                type: shop
                product: vxpages
                offset: $offset
                limit: $limit
                sortField: uploaded
                sortDirection: desc
            ) {
                albumsTotal
                albums {
                    id
                    status
                    video {
                        status
                    }
                    titles {
                        language
                        text
                    }
                }
            }
            photoAlbums(
                type: shop
                product: vxpages
                offset: $offset
                limit: $limit
                sortField: uploaded
                sortDirection: desc
            ) {
                albumsTotal
                albums {
                    id
                    status
                    titles {
                        language
                        text
                    }
                }
            }
        }
    }
`;

export const QUERY_MODEL_PROFILE_FETISH = gql`
    query QUERY_MODEL_PROFILE_FETISH {
        model {
            profile {
                isFetishUnlockedBySupport

                fetishGaysSpecials {
                    value
                    formData {
                        value
                        label
                    }
                }
                fetishExperiences {
                    value
                    formData {
                        value
                        label
                    }
                }
                fetishPractices {
                    value
                    formData {
                        value
                        label
                    }
                }
                fetishTaboos {
                    hasTexts
                    texts {
                        language
                        text
                    }
                    ratingStatus
                    rejectionReason
                    newsletterStatus
                }
                fetishState {
                    value
                    formData {
                        value
                        label
                    }
                }

                fetishToys {
                    value
                    formData {
                        value
                        label
                    }
                }

                fetishRole {
                    value
                    formData {
                        value
                        label
                    }
                }

                fetishText {
                    hasTexts
                    texts {
                        language
                        text
                    }
                    ratingStatus
                    rejectionReason
                    newsletterStatus
                }
            }
        }
    }
`;

export const QUERY_MODEL_PROFILE_LANGUAGES = gql`
    query QUERY_MODE_PROFILE_LANGUAGES {
        model {
            profile {
                languages {
                    value
                }
            }
        }
    }
`;

export const QUERY_MODEL_VXGAMES = gql`
    query QUERY_MODEL_VXGAMES($game: VXGamesEnum!) {
        model {
            vxgames(game: $game) {
                ...GamesBaseData
            }
        }
    }

    ${FRAGMENT_GAMES_BASE_DATA}
`;

export const QUERY_MODEL_GAMETIMELINE = gql`
    query QUERY_MODEL_GAMETIMELINE($game: VXGamesEnum, $type: GamesPartFilterTypesEnum) {
        model {
            vxgames(game: $game) {
                id # for caching
                parts (type: $type) {
                    ...GamesPart
                }
            }
        }
    }

    ${FRAGMENT_GAMES_PART}
`;

export const QUERY_MODEL_VXGAMES_USER_RANK = gql`
    query QUERY_MODEL_VXGAMES_USER_RANK($game: VXGamesEnum!) {
        model {
            vxgames(game: $game) {
                id # for caching
                part {
                    ...GamesPartWithUserRank
                    previousPart {
                        ...GamesPartWithUserRank
                    }
                }
            }
        }
    }

    ${FRAGMENT_GAMES_PART_WITH_USER_RANK}
`;

export const QUERY_MODEL_VXGAMES_RANKLIST = gql`
    query QUERY_MODEL_VXGAMES_RANKLIST($partId: Int, $game: VXGamesEnum!, $offset: Int!, $limit: Int!) {
        model {
            vxgames(game: $game) {
                id # for caching
                lastUpdate
                rankList(partId: $partId, offset: $offset, limit: $limit) {
                    total
                    items {
                        user {
                            userId
                            userName
                            avatarUrl
                        }
                        points
                        pointsTotal
                        rank
                    }
                }
            }
        }
    }
`;

export const QUERY_MODEL_VXGAMES_WINNERS = gql`
    query QUERY_MODEL_VXGAMES_WINNERS($game: VXGamesEnum!) {
        model {
            vxgames(game: $game) {
                id # for caching
                winners {
                    part {
                        id
                    }
                    rank
                    user {
                        userName
                        avatarUrl
                    }
                }
                parts {
                    ...GamesPart
                }
            }
        }
    }

    ${FRAGMENT_GAMES_PART}
`;

export const QUERY_MODEL_VXGAMES_COMPETITION_DATA = gql`
    query QUERY_MODEL_VXGAMES_COMPETITION_DATA($game: VXGamesEnum!) {
        model {
            vxgames(game: $game) {
                id # for caching
                annualPart {
                    ...GamesPartDetailed
                    nextPart {
                        ...GamesPartDetailed
                    }
                    previousPart {
                        ...GamesPartDetailed
                        previousPart {
                            ...GamesPartDetailed
                        }
                    }
                }
                part {
                    ...GamesPartDetailed
                    nextPart {
                        ...GamesPartDetailed
                    }
                    previousPart {
                        ...GamesPartDetailed
                        previousPart {
                            ...GamesPartDetailed
                        }
                    }
                    actualSubpart {
                        id
                        start
                        end
                        factor
                        # type does not exist for subParts therefore no FRAGMENT_GAMES_PART Fragment
                        titles {
                            ...LocalizedText
                        }
                        descriptions {
                            ...LocalizedText
                        }
                        rewards {
                            ...LocalizedText
                        }
                    }
                }
            }
        }
    }

    ${FRAGMENT_GAMES_PART_DETAILED}
    ${FRAGMENT_LOCALIZED_TEXT}
`;

export const QUERY_MODEL_VXGAMES_IS_USER_REGISTERED_FOR_GAMES = gql`
    query QUERY_MODEL_VXGAMES_IS_USER_REGISTERED_FOR_GAMES($game: VXGamesEnum!) {
        model {
            vxgames(game: $game) {
                id # for caching
                isUserRegisteredForGames
            }
        }
    }
`;

export const QUERY_MODEL_VXPAGES_STATS_CREDITS_USAGE = gql`
    query QUERY_MODEL_VXPAGES_STATS_CREDITS_USAGE($timeframe: VXPagesStatsTimeframeInput!) {
        model {
            vxpages {
                stats {
                    creditsUsage(filter: { timeframe: $timeframe }) {
                        type
                        amount
                    }
                }
            }
        }
    }
`;

export const QUERY_MODEL_VXCASH_WMID = gql`
    query QUERY_MODEL_VXCASH_WMID {
        model {
            vxcash {
                wmid
            }
        }
    }
`;

export const QUERY_MODEL_VXCASH_DASHBOARDSTATS = gql`
    query QUERY_MODEL_VXCASH_DASHBOARDSTATS {
        model {
            vxcash {
                wmid
                autologinLink
                dashboardStat {
                    id
                    date
                    turnover
                    percentTurnover
                }
            }
        }
    }
`;

export const QUERY_MODEL_DASHBOARD_MESSENGER_STAT = gql`
    query QUERY_MODEL_DASHBOARD_MESSENGER_STAT {
        model {
            dashboard {
                messengerStatistic {
                    messages
                    contentSales
                }
            }
        }
    }
`;

export const QUERY_VALIDATION_VALIDATE_SIGNUP_FIELD = gql`
    query QUERY_VALIDATION_VALIDATE_SIGNUP_FIELD($field: SignupField!, $value: Any!) {
        validation {
            validateSignupField(field: $field, value: $value) {
                isValid
                messages
            }
        }
    }
`;

export const QUERY_MODEL_AVS_URL = gql`
    query QUERY_MODEL_AVS_URL {
        model {
            avs {
                url
            }
        }
    }
`;

export const QUERY_AVS_REDIRECT = gql`
    query QUERY_AVS_REDIRECT {
        model {
            avs {
                status
                url
            }
        }
    }
`;

export const QUERY_MODEL_AVATAR_PICTURE = gql`
    query QUERY_MODEL_AVATAR_PICTURE {
        model {
            avatar {
                ...Avatar
            }
        }
    }
    ${FRAGMENT_AVATAR}
`;

export const QUERY_MODEL_MEDIA_DAILY_BONUS_OPT_IN_STATUS = gql`
    query QUERY_MODEL_MEDIA_DAILY_BONUS_OPT_IN_STATUS {
        model {
            media {
                dailyBonusOptInStatus
            }
        }
    }
`;

export const QUERY_MODEL_INSTANTMESSAGING_ACCOUNT_STATUS = gql`
    query QUERY_MODEL_INSTANTMESSAGING_ACCOUNT_STATUS {
        model {
            instantMessaging {
                account(platform: TELEGRAM) {
                    status
                }
            }
        }
    }
`;

export const QUERY_MODEL_ACCOUNT_KEYACCOUNTMANAGERINFO = gql`
    query QUERY_MODEL_ACCOUNT_KEYACCOUNTMANAGERINFO($id: Int!) {
        model(id: $id){
            account{
                keyAccounter{
                    contactEmail
                    contactName
                    contactPhoneNumber
                }
            }
        }
    }
`;

export const QUERY_MODEL_PHOTOALBUMS_SCHEDULING = gql`
    query QUERY_PHOTOALBUMS_SCHEDULING($id: Int!) {
        model {
            photoAlbums(id: $id) {
                albums {
                    id
                    dailyBonus {
                        modelContent {
                            contentId
                            contentType
                            selectedFor
                            preSelectedFor {
                                from
                                to
                            }
                        }
                    }
                    vip30Scheduling {
                        month
                        year
                    }
                }
            }
        }
    }
`;

export const QUERY_MODEL_VIDEOS_ALBUM = gql`
    query QUERY_MODEL_VIDEOS_ALBUM($id: Int!) {
        model {
            videos(id: $id) {
                albums {
                    video {
                        originalFilename
                        title
                        duration
                    }
                    ...AlbumSalesData
                    ...AlbumPreviewPictures
                }
            }
        }
    }
    ${FRAGMENT_ALBUM_SALES_DATA}
    ${FRAGMENT_ALBUM_PREVIEW_PICTURES}
`;

export const QUERY_MODEL_SERVICE0900 = gql`
    query QUERY_MODEL_SERVICE0900 {
        model {
            service0900 {
                status
                allowedCountries {
                    countryCodeAlpha2
                    countryCallingCode
                }

                visitx0900Numbers {
                    baseNumber
                    ddi
                    fullNumber
                    countryCodeAlpha2
                }

                targetNumber {
                    ...Service0900TargetNumber
                }

                settings {
                    voice
                    sexuality
                    description {
                        languages
                        texts {
                            ...LocalizedText
                        }
                    }
                }

                phoneCarousel {
                    targetNumbers {
                        id
                        countryCallingCode
                        phoneNumber
                        fullPhoneNumber
                    }
                }
            }
        }
    }
    ${FRAGMENT_BA6_DATA}
    ${FRAGMENT_LOCALIZED_TEXT}
`;

export const QUERY_MODEL_TELEGRAM_REQUEST_PAGE = gql`
    query QUERY_MODEL_TELEGRAM_REQUEST_PAGE($filter: VXPagesStatsTurnoverFilter!) {
        model {
            vxpages {
                info {
                    status
                }
                stats {
                    turnover(filter: $filter) {
                        data {
                            creditsCharges {
                                guestAmount
                                hostAmount
                            }
                            subscriptions {
                                guestAmount
                                hostAmount
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const QUERY_MODEL_MEDIA_FSK_PREVIEW_PICTURES = gql`
    query QUERY_MODEL_MEDIA_FSK_PREVIEW_PICTURES {
        model {
            media {
                previewPicture12 {
                    album {
                        id
                    }
                    picture {
                        url
                        id
                        isChecked
                    }
                }
                previewPicture16 {
                    album {
                        id
                    }
                    picture {
                        url
                        id
                        isChecked
                    }
                }
            }
        }
    }
`;

export const QUERY_WELCOME_CONTENT = gql`
    query QUERY_WELCOME_CONTENT {
        model {
            avatar {
                ...Avatar
            }
            media {
                previewPicture16 {
                    ...PreviewPicture
                }
            }
            tvshows {
                enabled
                planned {
                    start
                }
                links {
                    chat
                    calendar
                }
            }
            documents {
                allActorDocumentsComplete
                allActorDocumentsUploaded
                allOriginatorDocumentsUploaded
                allActorDocumentsComplete
            }
        }
    }
    ${FRAGMENT_AVATAR}
    ${FRAGMENT_PROFILE_PREVIEW_PICTURE}
`;

export const QUERY_MISSING_DOCUMENTS = gql`
    query QUERY_MISSING_DOCUMENTS {
        model {
            documents {
                documentsOfActors {
                    incompleteRequiredTypes {
                        type
                        status
                        documents {
                            rejectionReason
                        }
                    }
                }
                documentsOfOriginator {
                    incompleteRequiredTypes {
                        type
                        status
                        documents {
                            rejectionReason
                        }
                    }
                }
            }
        }
    }
`;


export const QUERY_DOCS = gql`
    query QUERY_DOCS {
        model {
            documents {
                allActorDocumentsComplete
                allActorDocumentsUploaded
                allActorDocumentsComplete
            }
        }
    }
`;

export const QUERY_ATLEASTONEACTORDOC = gql`
    query QUERY_ATLEASTONEACTORDOC {
        model {
            documents {
                atLeastOneActorDocumentUploaded
            }
        }
    }
`;


export const AVAILABLE_MIN_AMOUNTS = gql`
    query AVAILABLE_MIN_AMOUNTS {
        model {
            bonuscodes {availableContentMinAmounts {value label}}
        }
    }

`;

export const QUERY_MODEL_AVATAR = gql`
    query QUERY_MODEL_AVATAR {
        model {
            avatar {
                ...Avatar
            }
        }
    }
    ${FRAGMENT_AVATAR}
`;

export const QUERY_MODEL_PREVIEW_PICTURE16 = gql`
    query QUERY_MODEL_PREVIEW_PICTURE16 {
        model {
            media {
                previewPicture16 {
                    ...PreviewPicture
                }
            }
        }
    }
    ${FRAGMENT_PROFILE_PREVIEW_PICTURE}
`;


export const QUERY_MODEL_DASHBOARD_PRICES_SUBSET = gql`
    query QUERY_MODEL_DASHBOARD_PRICES_SUBSET {
        model {
            dashboard {
                prices {
                    videoChatUserPrice
                    singleChatUserPrice
                }
            }
        }
    }
`;

export const QUERY_MODEL_TVSHOWS_ENABLED = gql`
    query QUERY_MODEL_TVSHOWS_ENABLED {
        model {
            tvshows {
                enabled
            }
        }
    }
`;

export const MODEL_VIDEOS_ALBUMS_FEEDBACK = gql`
    query MODEL_VIDEOS_FEEDBACK($umaId: Int) {
        model {
            avatar {
                ...Avatar
            }
            videos(id: $umaId) {
                albums {
                    video {
                        title
                        originalFilename
                    }
                    dislikes
                    likes
                    groupedLikes(limit: 3, avatarSize: w60) {
                        userId
                        userName
                        avatarUrl
                    }
                    feedbacksItems {
                        id
                        guestLogin
                        guestAvatar
                        answer
                        text
                        date
                        isAnswered
                    }
                }
            }
        }
    }
    ${FRAGMENT_AVATAR}
`;

export const MODEL_PHOTOS_ALBUMS_FEEDBACK = gql`
    query MODEL_PHOTOS_FEEDBACK($umaId: Int) {
        model {
            avatar {
                ...Avatar
            }
            photoAlbums(id: $umaId) {
                albums {
                    titles {
                        language
                        text
                    }
                    dislikes
                    likes
                    groupedLikes(limit: 3, avatarSize: w60) {
                        userId
                        userName
                        avatarUrl
                    }
                    feedbacksItems {
                        id
                        guestLogin
                        guestAvatar
                        answer
                        text
                        date
                        isAnswered
                    }
                }
            }
        }
    }
    ${FRAGMENT_AVATAR}
`;

export const QUERY_MODEL_TOOLTIPSETTINGS_HASSEEN = gql`
    query QUERY_MODEL_TOOLTIPSETTINGS_HASSEEN($type: TooltipType) {
        model {
            tooltipSettings {
                hasSeen(type: $type)
            }
        }
    }
`;

export const QUERY_MODEL_DOCUMENTS_UPLOADURL = gql`
    query QUERY_MODEL_DOCUMENTS_UPLOADURL($userId: Int!, $type: DocumentTypeEnum, $side: DocumentSideEnum) {
        model {
            documents {
                uploadUrl(userId: $userId, type: $type, side: $side)
            }
        }
    }
`;

export const QUERY_MODEL_DOCUMENTS_MISSING_TIN = gql`
    query QUERY_MODEL_DOCUMENTS_MISSING_TIN {
        model {
            documents {
                showBannerMissingTIN
            }
        }
    }
`

export const QUERY_MODEL_DOCUMENTS_UPLOADASSISTANT_FLOW = gql`
    query QUERY_MODEL_DOCUMENTS_UPLOADASSISTANT_FLOW($flowType: DocumentUploadAssistantTypeEnum!, $flowStep: DocumentUploadAssistantStepEnum, $userId: Int) {
        model {
            documents {
                uploadAssistant {
                    flow(flowType: $flowType, flowStep: $flowStep, userId: $userId) {
                        userId
                        firstname
                        lastname
                        allStepsComplete
                        steps {
                            name
                            status
                            documentType
                            documentTypeId
                            uploadUrl
                            templateUrl
                            exampleUrl
                        }
                        currentStep {
                            name
                            status
                            documentType
                            documentTypeId
                            uploadUrl
                            templateUrl
                            exampleUrl
                        }
                    }
                }
            }
        }
    }
`;
export const QUERY_MODEL_DOCUMENTS = gql`
    query QUERY_MODEL_DOCUMENTS {
        model {
            documents {
                #deprecated
                uploadableDocumentTypes {
                    id
                    name
                }
                #deprecated
                history {
                    type
                    id
                    status
                    fileName
                    insertDate
                    rejectionReason
                    url
                }
                hasAllRequiredDocuments
                documentsOfOriginator {
                    type
                    realName
                    userId
                    documentTypes {
                        type
                        status
                        isRequired
                        isCompleted
                        exampleDocumentUrl
                        templateDocumentUrl
                        status
                        documents {
                            fileName
                            fileType
                            url
                            insertDate
                            comment
                            expireDate
                            rejectionReason
                        }
                    }
                    incompleteRequiredTypes {
                        type
                        status
                        isRequired
                        isCompleted
                        exampleDocumentUrl
                        templateDocumentUrl
                    }
                }
                documentsOfActors {
                    type
                    realName
                    userId
                    documentTypes {
                        type
                        status
                        isRequired
                        isCompleted
                        exampleDocumentUrl
                        templateDocumentUrl
                        status
                        documents {
                            fileName
                            fileType
                            url
                            insertDate
                            comment
                            rejectionReason
                        }
                    }
                    incompleteRequiredTypes {
                        type
                        status
                        isRequired
                        isCompleted
                        exampleDocumentUrl
                        templateDocumentUrl
                    }
                }
            }
        }
    }
`;
export const QUERY_MODEL_CHAT_SETTINGS = gql`
    query QUERY_MODEL_CHAT_SETTINGS {
        model {
            settings {
                chatSettings {
                    heatmap {
                        status
                        texts {
                            language
                            text
                        }
                    }
                    livePreview {
                        status
                        isEditable
                    }
                    voyeurInSingleChat {
                        status
                        isEditable
                    }
                    soundForVoyeurInSingleChat {
                        status
                        isEditable
                    }
                    softChat {
                        status
                        isEditable
                    }
                    conversionChat {
                        status
                        isEditable
                    }
                    freeChat {
                        status
                        isEditable
                    }
                    partyChat {
                        status
                        isEditable
                    }
                }
            }
            isOnlineWithSSW
            dashboard {
                prices {
                    videoChatMinPrice
                    videoChatMaxPrice
                    videoChatDefaultPrice
                    videoChatUserPrice
                    singleChatMinPrice
                    singleChatMaxPrice
                    singleChatDefaultPrice
                    singleChatUserPrice
                    messengerMinPrice
                    messengerMaxPrice
                    messengerDefaultPrice
                    messengerUserPrice
                    messengerPriceRange {
                        rangeKey
                        rangeValue
                    }
                }
            }
        }
    }
`;
export const QUERY_MODEL_DASHBOARD_TOP_AMATEUR = gql`
    query QUERY_MODEL_DASHBOARD_TOP_AMATEUR($fromDate: DateTime!, $limit: Int!) {
        model {
            topAmateur {
                hasSlots,
                getSlots (fromDate: $fromDate, limit: $limit)
                {start, end, status}
            }
        }
    }
`;

export const QUERY_AUTH_DEEPLINK_BETA_FEED = gql`
    query QUERY_AUTH_DEEPLINK {
        auth {
            id
            betaDeeplink(target:feed)
        }
    }
`

export const QUERY_HOOK_AUTH_DEEPLINK_BETA_FEED = gql`
    query QUERY_HOOK_AUTH_DEEPLINK($target: VXModelsMobileTargetEnum!) {
        auth {
            id
            betaDeeplink(target:$target)
        }
    }
`


