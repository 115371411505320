import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { REACT_APP_BASE_PATH as basePath } from '../../../util/env';
import { SecurityRole } from '../../../graphql/VXModels/types';
import ModalTerms from './ModalTerms';
import { useHasRole, useUserData } from '../../../util/UserData';
import { useCanUseTelegram } from '../../../packages/Telegram';

const useIsForbiddenModalTermsRoute = (): boolean => {
  const matches = [
    useRouteMatch(`${basePath}/legal/terms`),
    useRouteMatch(`${basePath}/legal/gdpr`),
    useRouteMatch(`${basePath}/legal/houserule`),
    useRouteMatch(`${basePath}/legal/imprint`),
    useRouteMatch(`${basePath}/legal/terms-of-use`),
    useRouteMatch(`${basePath}/legal/telegram-terms`),
    useRouteMatch(`${basePath}/legal/visit-x-select`),
    useRouteMatch(`${basePath}/website/creator`),
  ];

  return matches.reduce<boolean>((accumulator, match) => accumulator || !!match, false);
};

const TermsUpdatePrompts: FC = () => {
  const userData = useUserData();

  const isForbiddenModalTermsRoute = useIsForbiddenModalTermsRoute();
  const isLoggedInViaCT = useHasRole(SecurityRole.VX_USER_ADMIN);
  const isVXPagesUser = useHasRole(SecurityRole.VX_USER_VXPAGES);
  const isVXModelsUser = useHasRole(SecurityRole.VX_USER_VXMODELS);
  const canUseTelegram = useCanUseTelegram();

  if (isForbiddenModalTermsRoute || isLoggedInViaCT) return null;

  const {
    acceptanceVXPagesTermsOfUse,
    acceptanceVXModelsTermsAndConditions,
    acceptancePrivacyPolicy,
    acceptanceTelegramTermsAndConditions,
  } = userData.model.terms;

  const modals = [
    {
      acceptance: acceptanceVXPagesTermsOfUse,
      condition: isVXPagesUser,
    },
    {
      acceptance: acceptanceVXModelsTermsAndConditions,
      condition: isVXModelsUser,
    },
    {
      acceptance: acceptancePrivacyPolicy,
    },
    {
      acceptance: acceptanceTelegramTermsAndConditions,
      condition: canUseTelegram,
    },
    // Acceptance of updated video abo terms is checked when trying to submit a new video
  ];

  const modalToDisplay = modals.find(
    ({ acceptance, condition = true }) => !acceptance.hasAccepted && condition
  );

  if (!modalToDisplay) return null;

  const termsAcceptance = modalToDisplay.acceptance;

  return <ModalTerms key={termsAcceptance.type} termsAcceptance={termsAcceptance} />;
};

export default TermsUpdatePrompts;
