import React, { FC } from 'react';
import { LeftMenuGroup } from '../../components';
import { _ } from '../../../../util/translate';
import {
  ArchiveItem,
  AutoMailsItem,
  NewTelegramItem,
  ChatsItem,
  TelegramAbosItem,
  ChannelDescriptionItem,
  DraftsItem,
  BotItem,
} from '../items';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { useCanUseTelegram } from '../../../../packages/Telegram';

const TelegramGroup: FC = () => {
  const canUseTelegram = useCanUseTelegram();

  // const canRequestTelegram = useHasRole(SecurityRole.VX_USER_VXPAGES_HP);
  // todo (from @dk): place canUseTelegram || canRequestTelegram as condition once request-telegram page goes live
  return canUseTelegram ? (
    <LeftMenuGroup title={_('navigation:marketing.telegram')} icon={faTelegramPlane}>
      <NewTelegramItem />
      <DraftsItem />
      <ArchiveItem />
      <ChatsItem />
      <AutoMailsItem />
      <TelegramAbosItem />
      <ChannelDescriptionItem />
      <BotItem />
    </LeftMenuGroup>
  ) : null;
};

export default TelegramGroup;
