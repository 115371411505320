import React, { FC } from 'react';
// Outside Wizard
import { GRAY_2, RED } from '../../../../../camtool-styles';
// Inside Wizard
import { IPreviewItemProps } from '../../../types';

const PreviewMedia: FC<IPreviewItemProps> = ({ content, onClick, className, style }) => {
  const album = content.contentData;

  const handleClick = () => {
    if (onClick) {
      onClick(content);
    } else {
      throw new Error("Where is my `onClick` callback pal? I can't do shit without it...");
    }
  };

  return (
    <div className={className} style={style} css={{ alignItems: 'center' }}>
      <div
        css={{
          width: 92,
          height: 60,
          border: `1px solid ${GRAY_2}`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${
            (album.previewPicture16 && album.previewPicture16.url) ||
            (album.previewPicture18 && album.previewPicture18.url)
          })`,
        }}
      />

      <div css={{ flex: 1, alignItems: 'center' }}>
        <div css={{ flex: 1, flexDirection: 'column', padding: '0 16px' }}>
          <div css={{ flex: 1, alignItems: 'center', fontSize: 14 }}>
            <span
              css={{ marginRight: 4, fontSize: '0.8em' }}
              className={`icon-${album.isVideoAlbum ? 'play-button' : 'picture'}`}
            />
            <span
              title={album.titles[0] ? album.titles[0].text : ''}
              css={{
                width: 180,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'inline-block',
              }}
            >
              {album.titles[0] ? album.titles[0].text : ''}
            </span>
          </div>
          <div css={{ color: GRAY_2, marginTop: 4, fontSize: 12 }}>Price: {album.price}</div>
        </div>

        <button
          onClick={handleClick}
          css={{
            border: 'none',
            backgroundColor: 'transparent',
            color: RED,
            fontSize: 16,
            cursor: 'pointer',
            '&:hover': { opacity: 0.8 },
          }}
        >
          <span className="icon-remove-sign" />
        </button>
      </div>
    </div>
  );
};

export default PreviewMedia;
