import React, { FC } from 'react';
import { SectionLabel, StyledSection } from '../../../styles';
import { Translation } from '../../../../../../components';
import FieldHTMLEditor from '../../../../../../molecules/Formik/Fields/FieldHTMLEditor';
import { ErrorMessage } from 'formik';
import { RED } from '../../../../../../camtool-styles';

interface Props {
  disabled: boolean;
}

const FieldEmailEditor: FC<Props> = ({ disabled }) => {
  return (
    <StyledSection>
      <div css={{ flexDirection: 'column', flex: 1, cursor: 'text' }}>
        <div css={{ justifyContent: 'space-between' }}>
          <SectionLabel>
            <Translation t={'mailings:email.form.content'} />
          </SectionLabel>
        </div>
        <FieldHTMLEditor name={'textHtml'} disabled={disabled} />
        <ErrorMessage name="textHtml" component="span" css={{ color: RED }} />
      </div>
    </StyledSection>
  );
};

export default FieldEmailEditor;
