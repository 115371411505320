import { SecurityRole } from '../graphql/VXModels/types';

const securityRoleColors = {
  [SecurityRole.VX_USER]: '#333333',
  [SecurityRole.VX_USER_ADMIN]: '#ff00ff',
  [SecurityRole.VX_USER_INTERNAL_ACCOUNT]: '#ffff00',
  [SecurityRole.VX_USER_MASTER]: '#6e000d',
  [SecurityRole.VX_USER_ORIGINATOR]: '#456789',
  [SecurityRole.VX_USER_STANDARD]: '#cccccc',
  [SecurityRole.VX_USER_VXMODELS]: '#00ffff',
  [SecurityRole.VX_USER_VXPAGES]: '#ffa500',
  [SecurityRole.VX_USER_VXSTORIES]: '#000489',
};

export default securityRoleColors;
