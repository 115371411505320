import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { envelope } from '../../../../../atoms/Icon/libraries/glyph';
import { useHasRole } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';

const EmailItem: FC = () => {
  const hasVXPageInHPMode = useHasRole(SecurityRole.VX_USER_VXPAGES_HP);

  return hasVXPageInHPMode ? (
    <LeftMenuItem title={_('navigation:marketing.email')} icon={envelope} uri="/mailings/email" />
  ) : null;
};

export default EmailItem;
