import React, { FC } from 'react';
import { css } from '@emotion/core';
import { VXPagesUser } from '../../../../../graphql/VXModels/types';
import { Section } from '../../../../../atoms';
import { BREAKPOINT_TABLET, WHITE_3 } from '../../../../../camtool-styles';
import { sectionMargin } from '../../../../../atoms/Grid/Section';
import { _ } from '../../../../../util/translate';
import Transactions from './Transactions';

interface IProps {
  user: VXPagesUser;
  loadMore: (page: number) => Promise<void> | void;
}

const sectionStyles = css`
  padding: 30px;
  background-color: ${WHITE_3};
  flex-direction: row;
  ${BREAKPOINT_TABLET} {
    flex: 1 1 100%;
    padding: 0 24px;
  }
`;

const SectionTransactions: FC<IProps> = ({ user, loadMore }: IProps) => {
  return (
    <Section
      title={_('userManagement:details.section.transactions.title')}
      styles={sectionStyles}
      css={{ width: `calc(50% - 2 * ${sectionMargin}px)` }}
    >
      <Transactions user={user} loadMore={loadMore} />
    </Section>
  );
};

export default SectionTransactions;
