import React, { Fragment } from 'react';
import { Redirect, Switch } from 'react-router';

import { stripSlash } from '../../../util/urlHelper';
import { hasScope, VXPAGES } from '../../../util/scope';
import { Footer, NavSub, NavSubItem, ProtectedRoute } from '../../../components';
import {hasRole, useHasRole} from "../../../util/UserData";
import {SecurityRole} from "../../../graphql/VXModels/types";

import AboutYou from './AboutYou/AboutYou';
import Appearance from './Appearance/Appearance';
import Interview from './Interview/Interview';
import Partners from './Partners/Partners';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';

const DEFAULT_ROUTE = `/aboutyou`;

const Common = ({ match: { url } }) => {
  const path = stripSlash(url);
  return (
    <main id="appearance" className="appearance grid">
      <NavSub>
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/common/aboutyou`}
          icon="icon-nameplate"
          label={_('profiles:common.navigation.common')}
        />
        {!hasRole(SecurityRole.VX_USER_SEXOLE) && (
          <NavSubItem
            link={`${APP_BASE_PATH}/onlineprofile/common/appearance`}
            icon="icon-eye-open"
            label={_('profiles:common.navigation.appearance')}
          />
        )}

        {!hasScope([VXPAGES]) && !hasRole(SecurityRole.VX_USER_SEXOLE) && (
          <Fragment>
            <NavSubItem
              link={`${APP_BASE_PATH}/onlineprofile/common/interview`}
              icon="icon-quote"
              label={_('profiles:common.navigation.interview')}
            />
            <NavSubItem
              link={`${APP_BASE_PATH}/onlineprofile/common/partners`}
              icon="icon-parents"
              label={_('profiles:common.navigation.partner')}
            />
          </Fragment>
        )}
      </NavSub>

      <Switch>
        <ProtectedRoute exact path={`${path}/aboutyou`} component={AboutYou} />
        <ProtectedRoute exact path={`${path}/appearance`} component={Appearance} />
        <ProtectedRoute exact path={`${path}/interview`} component={Interview} />
        <ProtectedRoute exact path={`${path}/partners`} component={Partners} />
        <Redirect exact to={`${path}${DEFAULT_ROUTE}`} />
      </Switch>

      <div className="grid__row grid__box">
        <Footer className="grid__box__item" />
      </div>
    </main>
  );
};

export default Common;
