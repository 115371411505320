import React, { FC } from 'react';
import { SectionLabel, StyledDiv, StyledSection } from '../../../styles';
import { Translation } from '../../../../../../components';
import { ErrorMessage, Field } from 'formik';
import { BLACK_1, RED } from '../../../../../../camtool-styles';

interface Props {
  disabled: boolean;
}

const FieldEmailSubject: FC<Props> = ({ disabled }) => {
  return (
    <StyledSection>
      <div css={{ flexDirection: 'column', flex: '0 1 auto' }}>
        <div css={{ justifyContent: 'space-between' }}>
          <SectionLabel>
            <Translation t={'mailings:email.form.subject'} />
          </SectionLabel>
          {/*{editable &&  <Button theme="link-blue" label="Vorlage auswählen" />} */}
        </div>

        <StyledDiv
          css={{
            borderRadius: 4,
            border: '1px solid #c0c0c0',
            padding: 8,
          }}
        >
          <Field name="subject" type="text" css={{ flex: 1, color: BLACK_1 }} disabled={disabled} />
        </StyledDiv>
        <ErrorMessage name="subject" component="span" css={{ color: RED }} />
      </div>
    </StyledSection>
  );
};

export default FieldEmailSubject;
