import React, { FC } from 'react';
import { useHistory } from 'react-router';
import WhiteBox from '../WhiteBox';
import { pencil } from '../../../../atoms/Icon/libraries/glyph';
import { Container, StyledIcon, Title, Description } from './styles';
import { Button } from '../../../../atoms';
import { T } from '../../../../components';
import { APP_BASE_PATH } from '../../../../util/env';

const CreatorTile: FC = () => {
  const history = useHistory();
  const handleClick = (): void => history.push(`${APP_BASE_PATH}/website/creator`);

  return (
    <WhiteBox>
      <Container>
        <StyledIcon icon={pencil} size="35px" />
        <Title>
          <T _="components:quickstart.creatorTile.title" />
        </Title>
        <Description>
          <T _="components:quickstart.creatorTile.description" />
        </Description>
        <Button size="lg" theme="blue" onClick={handleClick}>
          <T _="components:quickstart.creatorTile.buttonText" />
        </Button>
      </Container>
    </WhiteBox>
  );
};

export default CreatorTile;
