import * as React from 'react';
import { MailingStatusEnum } from '../../../graphql/VXModels/types';
import { Translation } from '../../../components';
import { _ as translate } from '../../../util/translate';

interface IProps {
  status: MailingStatusEnum;
  noInfo?: boolean;
}

const getKey = (status: MailingStatusEnum) => `mailings:status.${status}`;

const StatusTranslated = ({ status, ...rest }: IProps) => {
  // @ts-ignore
  return <Translation t={getKey(status)} {...rest} />;
};

export const translateStatus = (status: MailingStatusEnum) => translate(getKey(status));

export default StatusTranslated;
