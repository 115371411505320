import React, { Fragment, memo } from 'react';
import { Query } from '@apollo/react-components';

import { Footer, NotificationBox, Spinner } from '../../components';
import { useHasRole, useUserData } from '../../util/UserData';
import styled from '@emotion/styled';
import { ChatOnline } from './WidgetsLarge';
import { Main } from '../../atoms';

import { QUERY_DASHBOARD_INITIAL } from './graphql';

import { SecurityRole } from '../../graphql/VXModels/types';
import { _ } from '../../util/translate';
import { getTimezone } from '../../util/timezone';
import BlockedCountries from './WidgetsSmall/BlockedCountries';
import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import VXPagesDashboard from './Scopes/VXPagesDashboard';
import VXModelsDashboard from './Scopes/VXModelsDashboard';
import GridTileItem from '../../atoms/DashboardTile/GridTileItem';
import { hasScope, VXPAGES } from '../../util/scope';

const ChatOnlineWrap = styled.div`
  flex-wrap: wrap;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 0 5px 0 1px;
`;

const DashboardSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const DashboardMain = memo(() => {
  const userData = useUserData();
  const userPrivileged =
    useHasRole(SecurityRole.VX_USER_MASTER) ||
    userData.model.permissions.isFinancesVisibleWithoutMasterPassword;
  const hasRoleSexole = useHasRole(SecurityRole.VX_USER_SEXOLE);
  const hasRoleAdmin = useHasRole(SecurityRole.VX_USER_ADMIN);
  const isScopeVXPages = hasScope([VXPAGES]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });

  return (
    <Query
      query={QUERY_DASHBOARD_INITIAL}
      variables={{ timezone: getTimezone() }}
      fetchPolicy={'network-only'}
    >
      {({ loading, error, data }) => {
        let {
          tvshows,
          snagbarNotifications,
          commonProfileFillingPercentage,
          wasOnlineWithSSW,
          dashboard,
          isOnlineWithSSW,
          settings,
          topAmateur,
          avatar,
          media,
          vxChallenges,
        } =
          !loading && data
            ? data.model
            : {
              dashboard: {},
              wasOnlineWithSSW: false,
              commonProfileFillingPercentage: 0,
              tvshows: { planned: [] },
              isOnlineWithSSW: true,
              topAmateur: {},
              avatar: {},
              media: {},
              vxChallenges: {}
            }; // prettier-ignore
        const album = isScopeVXPages ? avatar?.album : media.previewPicture16?.album;
        const picture = isScopeVXPages ? avatar?.picture : media.previewPicture16?.picture;
        const getSlots = topAmateur?.getSlots || {};
        const hasTopAmateurSlots = getSlots.length || false;
        const currentChallenge = vxChallenges?.challenges?.[0];

        return (
          <Fragment>
            <Main isMobileEnabled>
              {isScopeVXPages && (
                // VXPAGES DASHBOARD !!!
                <VXPagesDashboard
                  loading={loading}
                  dashboard={dashboard}
                  userPrivileged={userPrivileged}
                  wasOnlineWithSSW={wasOnlineWithSSW}
                  album={album}
                  picture={picture}
                  commonProfileFillingPercentage={commonProfileFillingPercentage}
                  vxChallenge={currentChallenge}
                />
              )}
              {!isScopeVXPages && hasRoleSexole && (
                // SEXOLE DASHBOARD!!!
                <Container maxWidth={false}>
                  {settings?.blockedCountries?.length > 0 && (
                    <BlockedCountries blockedCountries={settings.blockedCountries} />
                  )}
                  <GridTileItem
                    content={
                      loading ? (
                        <DashboardSpinner>
                          <Spinner inline={true} size={'s'} />
                        </DashboardSpinner>
                      ) : (
                        <ChatOnline loading={loading} />
                      )
                    }
                    title={loading ? '' : _('dashboard:app.chatOnline.header')}
                  />
                </Container>
              )}
              {!isScopeVXPages && !hasRoleSexole && (
                <VXModelsDashboard
                  loading={loading}
                  dashboard={dashboard}
                  userPrivileged={userPrivileged}
                  wasOnlineWithSSW={wasOnlineWithSSW}
                  isOnlineWithSSW={isOnlineWithSSW}
                  album={album}
                  picture={picture}
                  commonProfileFillingPercentage={commonProfileFillingPercentage}
                  userData={userData}
                  hasTopAmateurSlots={hasTopAmateurSlots}
                  tvshows={tvshows}
                  topAmateur={topAmateur}
                  vxChallenge={currentChallenge}
                />
              )}
              {snagbarNotifications && !hasRoleAdmin && (
                <NotificationBox
                  messages={snagbarNotifications.items.filter((item) => item.read === null)}
                />
              )}
              <Grid container item className="grid__row grid__box">
                <Footer className="grid__box__item" />
              </Grid>
            </Main>
          </Fragment>
        );
      }}
    </Query>
  );
});

export default DashboardMain;
