import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { _ } from '../../../../util/translate';
import { Section } from '../../../../atoms';
import { keyVisual } from './assets/images';
import { Box, Typography } from '@material-ui/core';
import { useTelegramBotStyles } from './styles';
import { useTelegramBotPin } from '../../hooks';
import CodeBox from './CodeBox';
import { APP_BASE_PATH } from '../../../../util/env';

const TelegramBot: FC = () => {
  const history = useHistory();
  const classes = useTelegramBotStyles();
  const [getTelegramBotPin, { data }] = useTelegramBotPin();
  const articlePath = '360002286959/articles/4414676857490';

  const botPin = data ? data.master?.botPin : 0;

  return (
    <Section title={'VXModels Bot'} css={{ height: '100%' }}>
      <Box className={classes.root}>
        <Box className={classes.container}>
          <img src={keyVisual} width={'101px'} height={'101px'} />
          <Typography className={classes.heading}>
            {_('mailings:telegram.vxmbot.heading')}
          </Typography>
          <CodeBox onClick={getTelegramBotPin} code={botPin} />
          <Typography>{_('mailings:telegram.vxmbot.codeInstructions')}</Typography>
          <Box className={classes.helpcenterBox}>
            <Typography>{_('mailings:telegram.vxmbot.needHelp')}</Typography>
            <Typography
              className={classes.helpcenterLink}
              onClick={() =>
                history.push(`${APP_BASE_PATH}/help/helpcenter/categories/${articlePath}`)
              }
            >
              {_('mailings:telegram.vxmbot.helpcenter')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

export default TelegramBot;
