import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_CONTENT_VXPAGES } from '../../../../graphql/VXModels/queries';
import { useEffect } from 'react';
import { MediaAlbums, Model } from '../../../../graphql/VXModels/types';

interface IContentQueryData {
  model: Model & Required<Pick<Model, 'videos' | 'photoAlbums'>>;
}

type AlbumsProperty = 'videos' | 'photoAlbums';

const CONTENT_BATCH_SIZE = 100;

// eslint-disable-next-line @typescript-eslint/ban-types
const getAlbumsDataByType = (data: IContentQueryData, key: AlbumsProperty): MediaAlbums | {} => {
  const modelData = (data && data.model) || {};
  return modelData[key] || {};
};

const getTotalAlbumsCountByType = (data: IContentQueryData, key: AlbumsProperty): number => {
  const albumsData = getAlbumsDataByType(data, key);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return albumsData.albumsTotal || 0;
};

const getCurrentAlbumsCountByType = (data: IContentQueryData, key: AlbumsProperty): number => {
  const albumsData = getAlbumsDataByType(data, key);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (albumsData.albums || []).length;
};

const getMissingAlbumsCountByType = (data: IContentQueryData, key: AlbumsProperty): number => {
  const totalAlbumsCount = getTotalAlbumsCountByType(data, key);
  const currentAlbumsCount = getCurrentAlbumsCountByType(data, key);

  return Math.max(totalAlbumsCount - currentAlbumsCount, 0);
};

const getMissingAlbumsCount = (data: IContentQueryData): number =>
  getMissingAlbumsCountByType(data, 'videos') + getMissingAlbumsCountByType(data, 'photoAlbums');

const useVXPagesAlbumData = () => {
  const {
    data: contentData,
    loading: contentLoading,
    error: contentError,
    fetchMore,
  } = useQuery<IContentQueryData>(QUERY_MODEL_CONTENT_VXPAGES, {
    variables: {
      offset: 0, // offset starts at 1
      limit: CONTENT_BATCH_SIZE,
    },
  });

  useEffect(() => {
    if (!contentData) return;

    if (getMissingAlbumsCount(contentData) > 0) {
      fetchMore({
        variables: {
          offset:
            1 +
            Math.max(
              getCurrentAlbumsCountByType(contentData, 'videos'),
              getCurrentAlbumsCountByType(contentData, 'photoAlbums')
            ),
          limit: CONTENT_BATCH_SIZE,
        },
        updateQuery: (previousData, { fetchMoreResult, variables }): IContentQueryData => {
          if (!fetchMoreResult) {
            return previousData;
          }

          return {
            ...fetchMoreResult,
            model: {
              ...fetchMoreResult.model,
              videos: {
                ...fetchMoreResult.model.videos,
                albums: [
                  ...previousData.model.videos.albums,
                  ...fetchMoreResult.model.videos.albums,
                ],
              },
              photoAlbums: {
                ...fetchMoreResult.model.photoAlbums,
                albums: [
                  ...previousData.model.photoAlbums.albums,
                  ...fetchMoreResult.model.photoAlbums.albums,
                ],
              },
            },
          };
        },
      });
    }
  }, [contentData]);

  return { contentData, contentLoading, contentError };
};

export default useVXPagesAlbumData;
