import React, { useEffect, useState } from 'react';
import { APP_SCOPE, REACT_API_VXPAGES_HOST } from '../../util/env';
import Spinner from '../Spinner/Spinner';
import { useLang } from '../../util/AppState';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_VXPAGES_TOKEN } from '../../graphql/VXModels/queries';
import { _ } from '../../util/translate';
import { EmptyContent } from '../index';

interface Props {
  to: string;
}

const VXPagesRedirect = ({ to }: Props) => {
  const { data, loading, error } = useQuery(QUERY_MODEL_VXPAGES_TOKEN, {
    fetchPolicy: 'network-only',
  });
  const token = data?.model?.vxpages?.token;

  const [lang] = useLang();
  const [redirectError, setRedirectError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    if (token) {
      fetch(`${REACT_API_VXPAGES_HOST}/api/auth/login`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        body: JSON.stringify({
          token,
          scope: APP_SCOPE,
          lang,
          origin: window.location.origin,
          destination: to,
        }),
      })
        .then(async (response) => {
          const { redirect_url: redirectUrl = null } = await response.json();

          if (redirectUrl) {
            window.location.replace(redirectUrl);
          } else {
            throw new Error(`redirect_url is falsy: ${redirectUrl}`);
          }
        })
        .catch((err) => {
          console.error('VXPagesRedirect failed', err);
          console.error(err);
          setRedirectError(err);
        });
    }
  }, [token]);

  return error || redirectError || (!loading && !token) ? (
    <EmptyContent icon="icon-warning-sign" title={_('common:error.generic')} />
  ) : (
    <Spinner size={'xl'} />
  );
};

export default VXPagesRedirect;
