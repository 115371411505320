/* eslint-disable react/display-name */
import React, { FC } from 'react';
import { CardHeaderInverted } from '../../../atoms';
import { useUserData } from '../../../util/UserData';
import { RouteComponentProps } from 'react-router';
import { _ } from '../../../util/translate';
import { Card, CardContent, Container, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useStyleFix } from '../../../app/Theme/stylefix';
import { Theme } from '@material-ui/core/styles';
import { CustomerList, CustomerStats } from '../../../packages/CustomerManagement';

const SPACING = 5;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fixTopPadding: {
      marginTop: theme.spacing(3) * -1,
    },
  })
);

const Overview: FC<RouteComponentProps> = () => {
  const { fixTopPadding } = useStyles();

  const { gridBoundries, containerBoundries } = useStyleFix({ spacing: SPACING });
  const canSeeDirectUserData = useUserData().model?.vxpages?.info?.canSeeDirectUserData || false;

  return (
    <Container disableGutters classes={{ root: containerBoundries }}>
      <Card square variant={'elevation'} elevation={0}>
        <CardHeaderInverted title={_('userManagement:overview.title')} />
        <CardContent classes={{ root: gridBoundries }}>
          <Grid container spacing={SPACING}>
            <CustomerStats />
            <Grid item xs={12} classes={{ root: fixTopPadding }}>
              <CustomerList canSeeDirectUserData={canSeeDirectUserData} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Overview;
