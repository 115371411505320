import React, { memo } from 'react';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import { Mutation } from '@apollo/react-components';
import styled from '@emotion/styled';

import { T } from '../../components';
import { Button, ButtonLink } from '../../atoms';

import { BLACK_1, DARK_GRAY, GREEN, ORANGE, WHITE } from '../../camtool-styles';

import ItemContainer from './ItemContainer';
import { APP_BASE_PATH } from '../../util/env';
import { _ } from '../../util/translate';
import { getCurrentScopeSupportMail } from '../../util/emailAdresses';
import { Grid, Box } from '@material-ui/core';
import NewButton from '../../atoms/Button/NewButton';
import { useUserData } from '../../util/UserData';
import { useLang } from '../../util/AppState';

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
  color: #888;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
`;

const Label = styled.p`
  font-size: 16px;
  margin-right: 8px;
  color: ${BLACK_1};
  text-align: left;
`;

const Item = styled.div`
  display: flex;
  align-self: flex-start;
  margin-top: 32px;
`;

const Badge = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
`;

const REQUEST_ACCOUNTING = gql`
  mutation {
    model {
      accounting {
        requestAccounting {
          status
          isRequestAllowed
        }
      }
    }
  }
`;

type Status = 'yes' | 'test' | 'no' | 'checking';

interface IProps {
  accountingStatus: Status;
  premiumCoopStatus: Status;
  accountingAvailable: boolean;
  loading: boolean;
  history: any;
  contactEmail: string;
  contactName: string;
  contactPhoneNumber: string;
}

const ItemAccounting = memo((props: IProps) => {
  const getBadgeStyle = (status: Status) => {
    switch (status) {
      case 'yes':
        return { color: WHITE, backgroundColor: GREEN };
      case 'test':
        return { color: WHITE, backgroundColor: ORANGE };
      case 'checking':
      case 'no':
      default:
        return { color: DARK_GRAY, backgroundColor: WHITE, border: '1px solid' };
    }
  };

  const getBadgeText = (status: Status) => {
    switch (status) {
      case 'yes':
        return _('common:text.yes');
      case 'test':
        return _('ranking:accountingStatus.test');
      case 'checking':
        return _('common:text.checking');
      case 'no':
      default:
        return _('common:text.no');
    }
  };

  const [lang] = useLang();

  const userData = useUserData();
  const {
    auth: { id, username },
  } = userData;

  const supportMail = getCurrentScopeSupportMail();

  const { contactEmail, contactName, contactPhoneNumber } = props;

  const mailToString =
    lang === 'de'
      ? `mailto:${contactEmail}?subject=Bitte%20Zurückrufen%20Modelid:%20${id}%20Modelname:%20${username}`
      : `mailto:${contactEmail}?subject=Please%20call%20back%20Modelid:%20${id}%20Modelname:%20${username}`;

  return (
    <ItemContainer title={_('ranking:newRanking.item.accounting.title')} loading={props.loading}>
      <Container>
        <p css={{ width: '100%' }}>
          <T
            _={'ranking:newRanking.item.accounting.description'}
            t={undefined}
            noInfo={undefined}
          />
        </p>

        <Item>
          <Label>
            <T
              _={'ranking:newRanking.item.accounting.accounting'}
              t={undefined}
              noInfo={undefined}
            />
            :
          </Label>
          <Badge css={getBadgeStyle(props.accountingStatus)}>
            {getBadgeText(props.accountingStatus)}
          </Badge>
        </Item>

        {contactEmail && contactName && contactPhoneNumber && (
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: 'center',
              textAlign: 'left',
              minWidth: '100%',
              marginTop: '15px',
              color: '#262728',
            }}
          >
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Box style={{ whiteSpace: 'nowrap' }}>
                  <T _="common:keyaccounter.name" />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box style={{ whiteSpace: 'nowrap' }}>{contactName}</Box>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Box style={{ whiteSpace: 'nowrap' }}>
                  <T _="common:keyaccounter.email" />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box style={{ whiteSpace: 'nowrap' }}>
                  <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Box style={{ whiteSpace: 'nowrap' }}>
                  <T _="common:keyaccounter.phone" />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box style={{ whiteSpace: 'nowrap' }}>{contactPhoneNumber}</Box>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <NewButton color="primary" size="medium" fullWidth href={mailToString}>
                <T _="common:keyaccounter.requestCallBackButton" />
              </NewButton>
            </Grid>
          </Grid>
        )}

        <Item css={{ marginBottom: '20px' }}>
          <Label>
            <T _={'ranking:newRanking.item.accounting.premiumCooperation'} />:
          </Label>
          <Badge css={getBadgeStyle(props.premiumCoopStatus)}>
            {getBadgeText(props.premiumCoopStatus)}
          </Badge>
        </Item>

        <div css={{ marginTop: 'auto', flexDirection: 'column', alignItems: 'center' }}>
          {props.accountingAvailable && (
            <Mutation
              mutation={REQUEST_ACCOUNTING}
              update={(cache, { data }) => {
                cache.writeData({
                  data: {
                    model: {
                      __typename: 'Model',
                      ranking: {
                        __typename: 'Ranking',
                        keyAccounting: data.model.accounting.requestAccounting,
                      },
                    },
                  },
                });
              }}
            >
              {(requestAccounting, { loading }) => {
                return (
                  <Button
                    css={{ textTransform: 'none', marginBottom: 4 }}
                    theme="blue"
                    onClick={requestAccounting}
                    disabled={loading}
                  >
                    <T _={'ranking:newRanking.item.accounting.requestKeyAccounting'} />
                  </Button>
                );
              }}
            </Mutation>
          )}

          <ButtonLink
            theme="link-blue"
            to={`${APP_BASE_PATH}/help/helpcenter/categories/360002305039/articles/360011075260`}
            css={{ textDecoration: 'none' }}
          >
            <span css={{ marginRight: 4 }}>
              <T _={'ranking:newRanking.item.accounting.helpLink'} />
            </span>
            <span className="icon-arrow-right" />
          </ButtonLink>

          <Button
            theme="link-blue"
            href={`mailto:${supportMail}`}
            label={supportMail}
            css={{ marginTop: 4, textDecoration: 'none' }}
          />
        </div>
      </Container>
    </ItemContainer>
  );
});

ItemAccounting.displayName = 'Accounting';

export default withRouter(ItemAccounting);
