import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';
import { hasRole } from '../../util/UserData';
import { SecurityRole } from '../../graphql/VXModels/types';
import MediaSalesTable from './MediaSalesTable';
import MediaSalesRow from './MediaSalesRow';
import styled from '@emotion/styled';
import { BREAKPOINT_DESKTOP_CONDITION } from '../../camtool-styles';

const Row = styled.section`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const filterProduct = (productList, product) => {
  return productList?.filter((el) => el.product === product)?.[0];
};

class MediaSaleOverview extends AbstractComponent {
  render() {
    const {
      coverImageURL,
      title,
      subtitle,
      totalSalesLabel,
      netLabel,
      currency,
      salesStatItems,
    } = this.props;
    const isVXPageUser = hasRole(SecurityRole.VX_USER_VXPAGES);
    const isVisitxUser = hasRole(SecurityRole.VX_USER_VXMODELS);
    const visitXStats = filterProduct(salesStatItems, 'VISIT-X');
    const vxPageStats = filterProduct(salesStatItems, 'VXPages');
    const amountTotal = (visitXStats?.amountTotal || 0) + (vxPageStats?.amountTotal || 0);

    return (
      <article className="media-sale-overview">
        <Row>
          <Row className="media-sale-overview__cover-image__container">
            <img className="media-sale-overview__cover-image" src={coverImageURL} />
          </Row>
          <Row className="media-sale-overview__info">
            <header className="media-sale-overview__info__header">
              <div>
                <h2>{title}</h2>
              </div>
              <div>{subtitle}</div>
            </header>

            <Row className="media-sale-overview__info__content">
              <MediaSalesTable
                netLabel={netLabel}
                totalSalesLabel={totalSalesLabel}
                amountTotal={amountTotal}
              >
                {(isVisitxUser || visitXStats) && (
                  <MediaSalesRow
                    currency={currency}
                    productName={'VISIT-X'}
                    productStats={visitXStats}
                  />
                )}

                {(isVXPageUser || vxPageStats) && (
                  <MediaSalesRow
                    currency={currency}
                    productName={'VXPages'}
                    productStats={vxPageStats}
                  />
                )}
              </MediaSalesTable>
            </Row>
          </Row>
        </Row>
      </article>
    );
  }
}
//
MediaSaleOverview.propTypes = {
  coverImageURL: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  totalSalesLabel: PropTypes.string.isRequired,
  netLabel: PropTypes.string.isRequired,
  currency: PropTypes.string,
};

MediaSaleOverview.defaultPropTypes = {
  title: '',
  currency: 'EUR',
  subtitle: '',
};

export default MediaSaleOverview;
