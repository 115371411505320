/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';

import { EmptyContent, T } from '../../../components';
import { Button } from '../../../atoms';
import { _ } from '../../../util/translate';
import { MUICheckbox } from '../../../components/MUI';
import { FormControlLabel } from '@material-ui/core';
import {
  useModelTvshowsReplacementOfferQuery,
  useModelTvshowsSetReplacementOfferMutation,
} from '../../../graphql/VXModels';
import { useHasRole } from '../../../util/UserData';
import { SecurityRole } from '../../../graphql/VXModels/types';

const Calendar = ({
  enabled,
  loading,
  links: { calendar: calendarUrl, chat: chatUrl },
  planned,
}) => {
  const { replacementOffer = false, loading: replacementOfferLoading } =
    useModelTvshowsReplacementOfferQuery();

  const [setReplacementOffer] = useModelTvshowsSetReplacementOfferMutation();

  const openChat = () => {
    console.log('Open TV Chat: ', chatUrl);
    const supportChatPopupWindow = window.open(
      chatUrl,
      '_blank',
      'height=690, width=330, resizable=0, scrollbars=auto',
      true
    );
    supportChatPopupWindow.focus();
  };

  const openTvCalender = () => {
    const vxCalendarPopupWindow = window.open(calendarUrl, '_blank', '', true);
    vxCalendarPopupWindow.focus();
  };

  const isInternal = useHasRole(SecurityRole.VX_USER_INTERNAL_ACCOUNT);

  return (
    <div
      css={{
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      {enabled ? (
        /* Display "next TV appointment" entry */
        <Fragment>
          <div css={{ flexDirection: 'column', overflowY: 'auto' }}>
            {planned.map((show) => (
              <TVDate key={show.start} date={show.start} />
            ))}
          </div>

          {planned.length === 0 && (
            <div className="dash__app__calendar__entry__tventry" style={{ marginTop: '0' }}>
              <div className="icon-money dash__app__calendar__entry__icon" />
              <div className="dash__app__calendar__entry__textbox">
                <div className="title">VISIT-X TV</div>
                <div className="date">
                  <span>
                    <T _={'dashboard:app.calendar.vxtvcalender.bookShow'} />
                  </span>
                </div>
              </div>
            </div>
          )}

          <div css={{ padding: '16px 8px' }}>
            <Button
              label={_('dashboard:app.calendar.vxtvcalender.button')}
              onClick={openTvCalender}
              css={{ margin: '0 8px', flex: 1 }}
            />
          </div>
          <div css={{ padding: '8px 16px' }}>
            <FormControlLabel
              disabled={replacementOfferLoading}
              onChange={(value) => setReplacementOffer(value.target.checked)}
              checked={replacementOffer}
              control={<MUICheckbox />}
              label={_('dashboard:app.calendar.vxtvcalender.replacementOffer')}
            />
          </div>
          <div css={{ padding: '8px 16px', marginTop: 'auto' }}>
            {_('dashboard:app.calendar.vxtvcalender.newControlChat')}
          </div>
        </Fragment>
      ) : (
        /* Display if nothing is to show at all */
        <EmptyContent icon="icon-bell" title={_('dashboard:app.calendar.emptyContent.title')} />
      )}
    </div>
  );
};

const TVDate = ({ date }) => {
  /*
  const isWithin24Hours = date => {
    let output = false;
    if (date) {
      let itemDate = Moment(date);
      let thisDate = Moment();
      let duration = Moment.duration(itemDate.diff(thisDate));
      output = Math.abs(duration.asHours()) < 24;
    }
    return output;
  };

  const getDateString = date => {
    let locale = Translator.getCurrentLanguage(true);
    Moment.locale(locale);
    let momdate = Moment(date);
    return momdate.format('LLL');
  };
  */

  return (
    <div css={{ padding: '16px 0', border: '1px solid #e5e5e5' }}>
      <div css={{ padding: '0 16px', alignItems: 'center' }}>
        <span className="icon-tv" />
      </div>
      <div css={{ flexDirection: 'column' }}>
        <div css={{ fontSize: 12 }}>VISIT-X TV</div>
        <div
          css={{ color: '#ffb033', textTransform: 'uppercase', fontSize: 10 }}
          // className={`date ${isWithin24Hours(date) ? 'today' : ''}`}
        >
          <span>{new Date(date).toLocaleString({}, { timeZone: 'Europe/Berlin' })}</span>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
