import { useQuery } from '@apollo/react-hooks';
import React, { ReactElement } from 'react';
import GenericEmptyContent from '../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { Spinner } from '../../../components';
import { QUERY_MODEL_DOCUMENTS } from '../../../graphql/VXModels/queries';
import { Query, SecurityRole } from '../../../graphql/VXModels/types';
import { useHasRole, useUserData } from '../../../util/UserData';
import History from './History/History';
import MasterPasswordRequired from './MasterPasswordRequired';
import Model from './Model/Model';
import Originator from './Originator/Originator';
import DocUploader from './Uploader/DocUploader';

const Documents = (): ReactElement => {
  const isVXMaster = useHasRole(SecurityRole.VX_USER_MASTER);
  const userData = useUserData();
  const userId = userData?.auth?.id;
  const { loading, error, data, refetch } = useQuery<Query>(QUERY_MODEL_DOCUMENTS, {
    fetchPolicy: 'cache-and-network', // because cache is wrong after finishing upload assistant in welcome page
  });

  if (!isVXMaster) {
    return <MasterPasswordRequired />;
  }

  const documents = data?.model?.documents;
  const actors = documents?.documentsOfActors;
  const originator = documents?.documentsOfOriginator;
  const history = documents?.history;
  const uploadableDocumentTypes = documents?.uploadableDocumentTypes;

  return loading ? (
    <Spinner size="l" />
  ) : error ? (
    <GenericEmptyContent />
  ) : (
    <>
      <div className="grid__row">
        <DocUploader options={uploadableDocumentTypes} userId={userId} onUploadSuccess={refetch} />
      </div>

      <div className="grid__row">
        <History data={history} />
      </div>

      <div className="grid__row">
        <Originator data={originator} />
      </div>

      {actors?.map((actor) => (
        <div key={actor?.userId} className="grid__row">
          <Model data={actor} />
        </div>
      ))}
    </>
  );
};

export default Documents;
