import React, { Fragment } from 'react';
import { Redirect, Switch } from 'react-router';

import { stripSlash } from '../../util/urlHelper';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import { EmptyContent, NavSub, NavSubItem, Translation } from '../../components';
import { Main, Section } from '../../atoms';
import { EditMessage, MailingsList, NewMessage, StatusTranslated } from '../../packages/Mailings';
import {
  Mailing,
  MailingRecipientsGroupsEnum,
  MailingTypeEnum,
} from '../../graphql/VXModels/types';
import { _ } from '../../util/translate';
import { BuildUrl } from '../../packages/Mailings/types';

const maintenanceMode = false; // @todo: fetch this from feature flags system

const NEW_MAILING = '/new';
const ARCHIVE = '/archive';
const EDIT = '/:id(\\d+)';

const type = MailingTypeEnum.VXPAGES_EMAIL;
const recipientsGroup = MailingRecipientsGroupsEnum.VXPAGES_EMAIL;

const titleFunc = ({ id, status, content: { subject = '' } = {} }: Mailing) => (
  <Fragment>
    {status && subject ? (
      <Fragment>
        {'['}
        <StatusTranslated status={status} />
        {`] - ${subject}`}
      </Fragment>
    ) : (
      <Translation t={'mailings:email.nav.title'} sprintf={{ id }} />
    )}
  </Fragment>
);

const Email = ({ match: { url } }) => {
  const path = stripSlash(url);
  const indexRoute = path + NEW_MAILING;
  const buildUrl: BuildUrl = ({ id }) => `${path}/${id}`;
  return (
    <Main>
      <NavSub>
        <NavSubItem
          link={path + NEW_MAILING}
          icon="icon-envelope"
          label={_('mailings:email.nav.new')}
        />
        <NavSubItem
          link={path + ARCHIVE}
          icon="icon-message-empty"
          label={_('mailings:email.nav.archive')}
        />
      </NavSub>

      <Switch>
        <Redirect exact from={`${path}/`} to={indexRoute} />
        <ProtectedRoute path={path + NEW_MAILING}>
          <Section title={_('mailings:email.sectionTitle.newEmail')} css={{ minHeight: 300 }}>
            {maintenanceMode ? (
              <EmptyContent
                icon={'icon-ban-circle'}
                title={_('mailings:email.maintainanceMode.primary')}
                subtitle={_('mailings:email.maintainanceMode.secondary')}
              />
            ) : (
              <NewMessage group={recipientsGroup} type={type} buildUrl={buildUrl} />
            )}
          </Section>
        </ProtectedRoute>
        <ProtectedRoute path={path + ARCHIVE}>
          <Section title={_('mailings:email.sectionTitle.archive')} css={{ minHeight: 300 }}>
            <MailingsList type={type} buildUrl={buildUrl} />
            {/*<Archive group={recipientsGroup} type={type} />*/}
          </Section>
        </ProtectedRoute>
        <ProtectedRoute path={path + EDIT}>
          {({
            match: {
              params: { id },
            },
          }) => (
            <Section
              title={_('mailings:messenger.sectionTitle.edit', { sprintf: { id } })}
              css={{ minHeight: 300 }}
            >
              <EditMessage id={id} />
            </Section>
          )}
        </ProtectedRoute>
        <Redirect to={indexRoute} />
      </Switch>
    </Main>
  );
};

export default Email;
