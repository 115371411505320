import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { MediaTypeEnum, QueueItem, QueueItemIdFn, StateEnum } from '../types';

import ImageIcon from '@material-ui/icons/Image';
import VideoIcon from '@material-ui/icons/OndemandVideo';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';

import { LinearProgressWithLabel } from '../../../molecules/LinearProgressWithLabel';
import { GRAY_3, MAGENTA } from '../../../camtool-styles';
import { Palette } from '@material-ui/core/styles/createPalette';
import { _ } from '../../../util/translate';
import { log } from '../../../util/errorHandling';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: () => ({
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    }),
    actionRoot: () => ({
      fontSize: 16,
    }),
  })
);

const stateColorMapping: Record<StateEnum, string> = {
  [StateEnum.finished]: 'secondary',
  [StateEnum.uploading]: 'primary',
  [StateEnum.waiting]: GRAY_3,
  [StateEnum.verifying]: GRAY_3,
  [StateEnum.transcoding]: MAGENTA,
  [StateEnum.error]: 'error',
  [StateEnum.canceled]: 'error',
};

const useStateColor = (state: StateEnum, resolve = false) => {
  const theme = useTheme();
  const stateColor = stateColorMapping[state];
  const paletteKey = stateColor as keyof Palette;
  const resolvedStateColor = theme.palette[paletteKey]?.main || stateColor;

  return resolve ? resolvedStateColor : stateColor;
};

type QueueItemFunctions = { onDelete: QueueItemIdFn; onCancel: QueueItemIdFn };

const getProgressType = (state: StateEnum): 'indeterminate' | 'determinate' =>
  [StateEnum.transcoding, StateEnum.verifying].includes(state) ? 'indeterminate' : 'determinate';

const getIcon = (mediaType: MediaTypeEnum) => {
  switch (mediaType) {
    case MediaTypeEnum.picture:
      return <ImageIcon />;
    case MediaTypeEnum.video:
      return <VideoIcon />;
    default:
      return <FileIcon />;
  }
};

const UploadQueueItem: FC<QueueItem & QueueItemFunctions> = ({
  id,
  displayName,
  state,
  preview,
  errorMessage,
  progress,
  mediaType,
  onDelete,
  onCancel,
}) => {
  const { itemRoot, actionRoot } = useStyles();
  const deleteQueueItem = () => onDelete(id);
  const cancelQueueItem = () => onCancel(id);
  const icon = getIcon(mediaType);

  const progressType = getProgressType(state);
  const stateColor = useStateColor(state);

  let errorOutput = `UploadManager:errorMessage.${errorMessage}.text`;
  if (typeof errorMessage === 'object' && errorMessage !== null) {
    log('error', 'Error Message: ' + JSON.stringify(errorMessage), { context: 'Upload' });
    errorOutput = `UploadManager:errorMessage.unknownError.text`;
  }

  return (
    <ListItem dense classes={{ root: itemRoot }}>
      <ListItemAvatar>
        <Avatar src={preview}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText disableTypography>
        <Typography variant={'body1'}>{displayName}</Typography>
        <Grid container>
          <Grid item xs>
            <Typography variant={'body1'} style={{ color: useStateColor(state, true) }}>
              {_(`UploadManager:state.${state}.text`)}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {errorMessage ? (
              <Typography variant={'body1'} color={'error'}>
                {_(errorOutput)}
              </Typography>
            ) : (
              <LinearProgressWithLabel
                showText={true}
                variant={progressType}
                color={stateColor}
                value={progress}
              />
            )}
          </Grid>
        </Grid>
      </ListItemText>
      <ListItemSecondaryAction classes={{ root: actionRoot }}>
        {state === StateEnum.uploading && (
          <IconButton edge="end" aria-label="delete" onClick={cancelQueueItem}>
            <CancelIcon />
          </IconButton>
        )}
        {![
          StateEnum.verifying,
          StateEnum.transcoding,
          StateEnum.uploading,
          StateEnum.canceled,
        ].includes(state) && (
          <IconButton edge="end" aria-label="delete" onClick={deleteQueueItem}>
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default UploadQueueItem;
