import React, { FC, ReactNode } from 'react';
import { Receivers, SectionLabel } from '../styles';
import { FilterCheckboxGroup } from './FilterCheckboxGroup';
import { _ } from '../../../../util/translate';
import { FilterRecipientsCount } from './FilterRecipientsCount';
import {
  MailingRecipientsGroupsEnum,
  MailingRecipientsGroupsFiltersEnum,
  MailingTypeEnum,
} from '../../../../graphql/VXModels/types';
import { Grid, Paper } from '@material-ui/core';

interface MailingRecipientsFilterProps {
  type: MailingTypeEnum;
  recipientsGroup: MailingRecipientsGroupsEnum;
  selected: MailingRecipientsGroupsFiltersEnum[];
  count?: number;
  title?: 'default' | false | ReactNode;
  description?: 'default' | false | ReactNode;
  disabled?: boolean;
  recipientsCount?: number;
  onUpdateCount?: (recipientsCount: number) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MailingRecipientsFilter: FC<MailingRecipientsFilterProps> = ({
  type,
  recipientsGroup,
  selected,
  count,
  title = 'default',
  description = 'default',
  disabled,
  recipientsCount,
  onUpdateCount,
  onChange,
}) => (
  <Grid container item spacing={3}>
    <Grid item xs>
      {title && (
        <SectionLabel>
          {title === 'default'
            ? _('components:Routes.Mailings.FieldMailingsRecipientsFilter.label')
            : title}
        </SectionLabel>
      )}
      <Paper style={{ backgroundColor: 'transparent', padding: 8 }}>
        <Grid container spacing={3}>
          <Grid item xs>
            <FilterCheckboxGroup
              recipientsGroup={recipientsGroup}
              description={
                description === 'default'
                  ? _('components:Routes.Mailings.FieldMailingsRecipientsFilter.subLabel')
                  : description
              }
              selected={selected}
              disabled={disabled}
              onChange={onChange}
            />
            <Receivers>
              {disabled ? (
                `${_(
                  'components:Routes.Mailings.FieldMailingsRecipientsFilter.recipientsCount'
                )}: ${recipientsCount}`
              ) : (
                <FilterRecipientsCount
                  type={type}
                  recipientsGroup={recipientsGroup}
                  count={count}
                  filter={selected}
                  onUpdate={onUpdateCount}
                />
              )}
            </Receivers>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);
