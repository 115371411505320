import React, { FC } from 'react';
import { _ } from '../../../util/translate';
import { makeStyles, createStyles, Box } from '@material-ui/core';
import { Theme, Typography } from '@material-ui/core';
import { MUICheckbox, MUICheckboxFormControlLabel } from '../../../components/MUI';
import { ExecutionResult } from 'graphql';
import { MutationFunctionOptions, OperationVariables } from '@apollo/react-common';
import { Mutation } from '../../../graphql/VXModels/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: (props: Pick<GenericStatusProps, 'isActive'>): string =>
        props.isActive ? theme.palette.success.main : theme.palette.error.main,
    },
  })
);

interface GenericStatusProps {
  isActive: boolean | undefined;
  toggleFn: (
    options?: MutationFunctionOptions<Mutation, OperationVariables> | undefined
  ) => Promise<ExecutionResult<Mutation>>;
  loading?: boolean;
}

const GenericStatus: FC<GenericStatusProps> = ({ isActive, toggleFn, loading }) => {
  const classes = useStyles({ isActive });

  return (
    <Box display="flex" alignItems="center">
      <MUICheckboxFormControlLabel
        disabled={loading}
        checked={isActive}
        color={isActive ? 'secondary' : 'error'}
        control={
          <MUICheckbox
            onChange={(e): Promise<ExecutionResult<Mutation>> =>
              toggleFn({ variables: { active: e.target.checked } })
            }
          />
        }
        label={
          <Typography classes={classes}>
            {isActive ? _('common:status.active') : _('common:onOff.disabled')}
          </Typography>
        }
      />
    </Box>
  );
};

export default GenericStatus;
