import React, { FC } from 'react';
import { VXPagesUser } from '../../../../../../graphql/VXModels/types';
import { Field, FieldLabel, FieldValue } from './Base';
import { _ } from '../../../../../../util/translate';

interface IProps {
  user: VXPagesUser;
}

const UsernameField: FC<IProps> = ({ user }) => {
  const { username } = user;

  return (
    <Field>
      <FieldLabel>{_('userManagement:field.username')}</FieldLabel>
      <FieldValue>{username}</FieldValue>
    </Field>
  );
};

export default UsernameField;
