import React from 'react';
import { Translation } from '../../../components';
import Info from '../Info/Info';
import { Redirect, Switch, withRouter } from 'react-router';
import { stripSlash } from '../../../util/urlHelper';
import ProtectedRoute from '../../../components/Routing/ProtectedRoute';
import Categories from './Categories/Categories';
import Sections from './Sections/Sections';
import Article from "./Article/Article";

const HelpCenter = withRouter(({ match, children, path: subpath }) => {
  const path = stripSlash(match.url);
  return (
    <div className="grid__row" style={{ flexDirection: 'column' }}>
      <div className="grid__column grid__box" style={{ flex: '1 0 auto ' }}>
        <div className="grid__box__header">
          <div className="grid__box__header__title">
            <Translation t={'help:navigation.helpcenter'} />
          </div>
        </div>
        <Switch>
          <Redirect exact from={`${path}${subpath}/`} to={`${path}${subpath}/categories`} />
          <ProtectedRoute exact path={`${path}${subpath}/categories`} component={Categories} />
          <ProtectedRoute exact path={`${path}${subpath}/categories/:categoryId/articles`} component={Sections} />
          <ProtectedRoute
            exact
            path={`${path}${subpath}/categories/:categoryId/articles/:articleId`}
            component={Article}
          />
          <Redirect to={`${path}${subpath}/categories`} />
        </Switch>
      </div>

      <Info />
    </div>
  );
});

export default HelpCenter;
export { HelpCenter };
