import React, { FC } from 'react';
import Section from '../../../../atoms/Grid/Section';
import ThreeBadgesBanner from '../../../../atoms/Banner/ThreeBadgesBanner';
import AdvantagesList from '../../../../atoms/AdvantagesList/AdvantagesList';
import TelegramRequestSection from './TelegramRequestSection';
import { moneyBadge, channelBadge, telegramBadge } from '../../../../atoms/Icon/libraries/svg';
import { WHITE_2, WHITE } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';

const RequestTelegram: FC = () => {
  return (
    <Section styles={{ paddingBottom: '40px' }}>
      <ThreeBadgesBanner
        leftBadge={telegramBadge}
        centerBadge={channelBadge}
        rightBadge={moneyBadge}
        primaryHeadline={_('telegram:requestTelegram.banner.title')}
        secondaryHeadline={_('telegram:requestTelegram.banner.subTitle')}
        primaryThemeColor={WHITE}
        secondaryThemeColor={WHITE_2}
        iconColor={WHITE}
        leftBadgePrimaryText={_('telegram:requestTelegram.banner.leftBadgeText')}
        centerBadgePrimaryText={_('telegram:requestTelegram.banner.middleBadgeText')}
        rightBadgePrimaryText={_('telegram:requestTelegram.banner.rightBadgeText')}
      />
      <AdvantagesList
        listItemArray={[
          _('telegram:requestTelegram.advantages.firstArgument'),
          _('telegram:requestTelegram.advantages.secondArgument'),
          _('telegram:requestTelegram.advantages.thirdArgument'),
          _('telegram:requestTelegram.advantages.fourthArgument'),
          _('telegram:requestTelegram.advantages.fifthArgument'),
          _('telegram:requestTelegram.advantages.sixthArgument'),
        ]}
        headline={_('telegram:requestTelegram.advantages.headline')}
      />
      <TelegramRequestSection />
    </Section>
  );
};

export default RequestTelegram;
