import React, { FC } from 'react';

import place1 from './__assets__/Platz1.svg';
import place2 from './__assets__/Platz2.svg';
import place3 from './__assets__/Platz3.svg';
import place4 from './__assets__/Platz4.svg';
import place5And6 from './__assets__/Platz5-6.svg';
import place7And8 from './__assets__/Platz7-8.svg';
import place9And10 from './__assets__/Platz9-10.svg';

// prettier-ignore
const PLACE_IMAGES = [place1, place2, place3, place4, place5And6, place7And8, place9And10];

const CampaignDescriptionPrices: FC<{ prices: string }> = ({ prices }) => (
  <div className="marketing-campaign__item__prices">
    {prices.split('\n').map((price, i) => (
      <div key={`${price}-${i}`} className="marketing-campaign__item__prices__item">
        <img src={PLACE_IMAGES[i]} alt="" css={{ maxHeight: 43.2 }} />
        <div className="marketing-campaign__item__prices__item-content">{price}</div>
      </div>
    ))}
  </div>
);

export default CampaignDescriptionPrices;
