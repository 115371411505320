import React, { Fragment, useState } from 'react';
import {
  BigIconButton,
  Translation,
  useZopim,
  verifyAllDepartmentsOnline,
} from '../../../components';
import { AlertsStore } from '../../../stores/Alerts/AlertsStore';
import { getCurrentScopeSupportMail } from '../../../util/emailAdresses';
import { hasScope, VXMODELS } from '../../../util/scope';
import { _ } from '../../../util/translate';
import ModalContactForm from '../ModalContactForm/ModalContactForm';

const Help = () => {
  const [modalOpen, setModalOpen] = useState();
  const { zopim } = useZopim();

  const openChat = () => {
    if (verifyAllDepartmentsOnline(zopim)) {
      zopim.livechat.window.show();
    } else {
      AlertsStore.add({ type: 'error', message: _('help:supportChat.notActive') });
    }
  };

  return (
    <Fragment>
      <div key="2" className="grid__row grid__row--wrap">
        {hasScope(VXMODELS) && (
          <div className="grid__column help__contact">
            <BigIconButton
              label={_('help:support.phone.title')}
              info={_('help:support.phone.text')}
              iconMain="icon-earphone"
              iconLeft="icon-user"
              iconRight="icon-girl"
            />
            <footer>
              <span className="adress">
                <Translation t={'help:support.phone.number'} />
              </span>
              <span className="info">
                <Translation t={'help:support.phone.timesAvailable'} />
              </span>
            </footer>
          </div>
        )}

        <div className="grid__column help__contact">
          <BigIconButton
            label={_('help:support.mail.title')}
            info={_('help:support.mail.text')}
            iconMain="icon-envelope"
            iconLeft="icon-user"
            iconRight="icon-girl"
            onClick={() => {
              setModalOpen(true);
            }}
          />
          <footer>
            <span className="adress">{getCurrentScopeSupportMail()}</span>
            <span className="info">
              <Translation t={'help:support.mail.timesAvailable'} />
            </span>
          </footer>
        </div>

        {hasScope(VXMODELS) && (
          <div className="grid__column help__contact">
            <BigIconButton
              onClick={openChat}
              label={_('help:support.chat.title')}
              info={_('help:support.chat.text')}
              iconMain="icon-chat"
              iconLeft="icon-user"
              iconRight="icon-girl"
            />
            <footer>
              <span className="adress">
                <Translation t={'help:support.chat.link.label'} />
              </span>
              <span className="info">
                <Translation t={'help:support.chat.timesAvailable'} />
              </span>
            </footer>
          </div>
        )}
      </div>

      {modalOpen && <ModalContactForm key="3" onBackgroundClick={() => setModalOpen(false)} />}
    </Fragment>
  );
};

export default Help;
