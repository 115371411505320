import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Section, Table } from '../../../atoms';
import { DARK_GRAY, GRAY_3, WHITE_3 } from '../../../camtool-styles';
import { DataByTimeframe, IRowDefinition, RowDataItem, TimeframeKey } from './types';
import { EmptyContent, Spinner } from '../../../components';
import { deriveRowDataFromIncompleteDataByTimeframe } from './Timeframes/timeframes';
import { feeRows, kpiRows, sharingRows } from './Rows/rows';
import StatisticsRows from './Rows/StatisticsRows';
import useCustomTimeframeDropdown from './Dropdown/useCustomTimeframeDropdown';
import useTimeframes from './Timeframes/useTimeframes';
import { getColumns } from './columns';
import useStatsByTimeframe from './useStatsByTimeframe';
import { _ } from '../../../util/translate';

const sectionStyles = css`
  padding: 50px;
  background-color: ${WHITE_3};
  flex-direction: column;
  align-items: center;
  min-height: 300px;
`;

const SubSectionHeader = styled.h2`
  font-size: 18px;
  color: ${DARK_GRAY};
  width: 100%;

  &:not(:first-of-type) {
    margin-top: 32px;
  }
`;

const TablesScrollContainer = styled.div`
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
`;

const TablesContainer = styled.div`
  width: 100%;
  min-width: 800px;
  flex-direction: column;
`;

const VisitsHint = styled.div`
  font-size: 12px;
  color: ${GRAY_3};
  margin: 8px 2px 0;
`;

const SiteStatistics: FC = () => {
  const { customMonthStart, dropdownRenderFunction } = useCustomTimeframeDropdown();
  const columns = getColumns(dropdownRenderFunction);
  const timeframes = useTimeframes(customMonthStart);

  const {
    status: {
      default: { loading: defaultLoading, error: defaultError },
      custom: { loading: customLoading, error: customError },
    },
    statsByTimeframe: { kpi: kpiStatsByTimeframe, turnover: turnoverStatsByTimeframe },
  } = useStatsByTimeframe(timeframes);

  const getRows = <OriginalDataType extends any>(
    definitions: Array<IRowDefinition<OriginalDataType>>,
    dataByTimeframe: DataByTimeframe<OriginalDataType | undefined>
  ): ReactNode => {
    const getDataCallback = (
      callback: (data: OriginalDataType, timeframeKey: TimeframeKey) => RowDataItem
    ) => deriveRowDataFromIncompleteDataByTimeframe<OriginalDataType>(dataByTimeframe, callback);

    return (
      <StatisticsRows
        columns={columns}
        definitions={definitions}
        getDataCallback={getDataCallback}
        loadingCustom={customLoading}
      />
    );
  };

  return (
    <Section title={_('statistics:section.site.title')} styles={sectionStyles}>
      {defaultLoading ? (
        <Spinner noBackground={true} />
      ) : defaultError || customError || !kpiStatsByTimeframe || !turnoverStatsByTimeframe ? (
        <EmptyContent icon="icon-warning-sign" title={_('statistics:error.title')} />
      ) : (
        <TablesScrollContainer>
          <TablesContainer>
            <SubSectionHeader>{_('statistics:section.site.header.visits')}</SubSectionHeader>
            <Table columns={columns}>{getRows(kpiRows, kpiStatsByTimeframe)}</Table>
            <VisitsHint>{_('statistics:section.site.visitsHint')}</VisitsHint>

            <SubSectionHeader>{_('statistics:section.site.header.sharing')}</SubSectionHeader>
            <Table columns={columns}>{getRows(sharingRows, turnoverStatsByTimeframe)}</Table>

            <SubSectionHeader>{_('statistics:section.site.header.fees')}</SubSectionHeader>
            <Table columns={columns}>{getRows(feeRows, turnoverStatsByTimeframe)}</Table>
          </TablesContainer>
        </TablesScrollContainer>
      )}
    </Section>
  );
};

export default SiteStatistics;
