import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { pencil } from '../../../../../atoms/Icon/libraries/glyph';

const CreatorItem: FC = () => (
  <LeftMenuItem title={_('navigation:homepage.creator')} icon={pencil} uri="/website/creator" />
);

export default CreatorItem;
