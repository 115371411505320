import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { cogwheels } from '../../../../../atoms/Icon/libraries/glyph';

const HomepageSettingsItem: FC = () => (
  <LeftMenuItem
    title={_('navigation:homepage.settings')}
    icon={cogwheels}
    uri="/website/settings"
  />
);

export default HomepageSettingsItem;
