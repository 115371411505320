import React, { FC } from 'react';
import { ButtonLink } from '../../../../atoms';
import { BLUE, RED } from '../../../../camtool-styles';
import { T } from '../../../../components';
import { _ } from '../../../../util/translate';

import {
  BREAKPOINT,
  TelSexItem,
  Content,
  ArrowContainer,
  CodeIcon,
  Badge,
  TelSexItemContainer,
  Label,
} from '../styles';
import { exclamationSign } from '../../../../atoms/Icon/libraries/glyph';
import Icon from '../../../../atoms/Icon/Icon';

interface IProps {
  scopedUrl: string;
}

const PhoneSexDescription: FC<IProps> = ({ scopedUrl }) => {
  return (
    <div css={{ alignItems: 'center', flexDirection: 'column', padding: '0px 32px' }}>
      <header
        css={{ flexDirection: 'column', textAlign: 'center', marginBottom: 24, maxWidth: 800 }}
      >
        <h2 css={{ fontSize: '25px' }}>
          {_('service0900:PhoneSex.inactive.description.headline')}
        </h2>
      </header>

      <Content>
        <TelSexItemContainer>
          <TelSexItem theme="BLUE">
            <span className="icon-ok" css={{ fontSize: 16, marginBottom: 8 }} />
            <p>
              <T _={'service0900:phoneSex.step1'} />
            </p>
          </TelSexItem>
        </TelSexItemContainer>

        <ArrowContainer css={{ justifyContent: 'center', height: 40 }}>
          <span className="icon-share" css={{ [BREAKPOINT]: { transform: 'rotate(90deg)' } }} />
        </ArrowContainer>

        <TelSexItemContainer>
          <TelSexItem theme="BLUE">
            <span className="icon-webcam" css={{ fontSize: 16, marginBottom: 8 }} />
            <p>
              <T _={'service0900:phoneSex.step2'} />
            </p>
          </TelSexItem>
        </TelSexItemContainer>

        <ArrowContainer css={{ height: 138, [BREAKPOINT]: { width: 248 } }}>
          <span
            className="icon-share"
            css={{ [BREAKPOINT]: { transform: 'scaleX(-1) rotate(90deg)' } }}
          />
          <span
            className="icon-share"
            css={{ transform: 'scaleY(-1)', [BREAKPOINT]: { transform: 'rotate(90deg)' } }}
          />
        </ArrowContainer>

        <TelSexItemContainer>
          <Label css={{ left: -102, top: 58, [BREAKPOINT]: { left: -5 } }}>
            <T _={'service0900:phoneSex.step31label'} />
          </Label>
          <TelSexItem>
            <div css={{ position: 'relative', marginBottom: 8, color: '#888' }}>
              <span className="icon-user" css={{ fontSize: 16 }} />
              <span
                className="icon-earphone"
                css={{ position: 'absolute', right: -8, top: -5, fontSize: 10 }}
              />
            </div>
            <h3 css={{ fontWeight: 'normal', fontSize: '13px !important' }}>
              <T _={'service0900:phoneSex.step311'} />
            </h3>
            <small css={{ color: '#888', fontSize: 11 }}>
              <T _={'service0900:phoneSex.step312'} />
            </small>
          </TelSexItem>

          <Label css={{ left: -132, top: 236, [BREAKPOINT]: { left: 340 } }}>
            <T _={'service0900:phoneSex.step32label'} />
          </Label>
          <TelSexItem>
            <div css={{ position: 'relative', marginBottom: 8, color: '#888' }}>
              <span className="icon-user" css={{ fontSize: 16 }} />
              <span className="icon-webcam" css={{ position: 'absolute', right: -10, top: -5 }} />
            </div>
            <h3 css={{ fontWeight: 'normal', fontSize: '13px !important' }}>
              {_('service0900:phoneSex.step321')}
            </h3>
            <small css={{ color: '#888', fontSize: 11 }}>{_('service0900:phoneSex.step322')}</small>
          </TelSexItem>
        </TelSexItemContainer>

        <ArrowContainer css={{ height: 220, [BREAKPOINT]: { width: 310 } }}>
          <span
            className="icon-share"
            css={{ [BREAKPOINT]: { transform: 'scaleX(-1) rotate(90deg)' } }}
          />
          <span
            className="icon-share"
            css={{ transform: 'scaleY(-1)', [BREAKPOINT]: { transform: 'rotate(90deg)' } }}
          />
        </ArrowContainer>

        <TelSexItemContainer>
          <TelSexItem>
            <CodeIcon>1234</CodeIcon>
            <h3 css={{ fontWeight: 'normal', fontSize: '13px !important' }}>
              {_('service0900:phoneSex.step411')}
            </h3>
            <small css={{ color: '#888', fontSize: 11 }}>{_('service0900:phoneSex.step412')}</small>
          </TelSexItem>

          <TelSexItem css={{ position: 'relative' }}>
            <CodeIcon>0900</CodeIcon>
            <h3 css={{ fontWeight: 'normal', fontSize: '13px !important' }}>
              {_('service0900:phoneSex.step421')}
            </h3>
            <small css={{ color: '#888', fontSize: 11 }}>
              <T _={'service0900:phoneSex.step422.text'} />
            </small>
            <div css={{ maxWidth: '150px', position: 'absolute', top: '115px', textAlign: 'left' }}>
              <Icon size={'10px'} icon={exclamationSign} css={{ color: RED, marginRight: '2px' }} />
              <span css={{ fontSize: '12px', color: RED }}>
                {_('service0900:phoneSex.step422.warning')}
              </span>
            </div>
          </TelSexItem>
        </TelSexItemContainer>

        <ArrowContainer css={{ height: 138, [BREAKPOINT]: { width: 284, marginTop: 78 } }}>
          <span
            className="icon-share"
            css={{
              transform: 'rotate(35deg)',
              [BREAKPOINT]: { transform: 'scaleX(-1) rotate(150deg)' },
            }}
          />
          <span
            className="icon-share"
            css={{
              transform: 'rotate(-35deg) scaleY(-1)',
              [BREAKPOINT]: { transform: 'rotate(150deg)' },
            }}
          />
        </ArrowContainer>

        <TelSexItemContainer css={{ marginTop: 24 }}>
          <TelSexItem theme="BLUE">
            <div>
              <span className="icon-call-video" css={{ fontSize: 16, marginBottom: 8 }} />
            </div>
            <p>{_('service0900:phoneSex.step5')}</p>
          </TelSexItem>

          <div
            css={{
              position: 'absolute',
              bottom: 65,
              left: 0,
              right: 0,
              justifyContent: 'center',
              [BREAKPOINT]: { bottom: -20 },
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div>
              <ButtonLink to={scopedUrl} theme="link-blue" css={{ textDecoration: 'none' }}>
                <span>
                  <span css={{ fontSize: '10px' }}>{_('common:texts.moreInfos')}</span>
                </span>
              </ButtonLink>
              <span className="icon-arrow-right" css={{ marginLeft: 8, color: BLUE }} />
            </div>
          </div>

          <Badge>
            <T _={'service0900:phoneSex.step5label'} />
          </Badge>
        </TelSexItemContainer>
      </Content>
    </div>
  );
};

export default PhoneSexDescription;
