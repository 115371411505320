import React, { FC } from 'react';
import { Mailing, MailingTypeEnum } from '../../../../graphql/VXModels/types';
import FormEmail, { validate as validateFormEmail } from './FormEmail';
import FormInstantMessenger, {
  validationSchema as validationSchemaInstantMessenger,
} from './FormInstantMessenger';

import FormWrapper from './FormWrapper';
import { BuildUrl } from '../../types';

type FormRouterProps = Mailing & { buildUrl: BuildUrl };

const FormRouter: FC<FormRouterProps> = ({ buildUrl, ...props }) => {
  switch (props.type) {
    case MailingTypeEnum.VXPAGES_EMAIL:
      return (
        <FormWrapper
          buildUrl={buildUrl}
          component={FormEmail}
          mailing={props}
          validate={validateFormEmail}
        />
      );
    case MailingTypeEnum.VXPAGES_VXMESSENGER:
    case MailingTypeEnum.VXPAGES_TELEGRAM:
      return (
        <FormWrapper
          buildUrl={buildUrl}
          component={FormInstantMessenger}
          mailing={props}
          validationSchema={validationSchemaInstantMessenger}
        />
      );
    case MailingTypeEnum.VXMODELS_POWERMAILING:
    default:
      throw new Error(`No Form configured for type "${props.type}"`);
  }
};

export default FormRouter;
