import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { BLACK, BLUE, GRAY_2 } from '../../../../../camtool-styles';

export const SearchResultContainer = styled.div`
  flex-direction: column;
  margin: 40px 50px 0px 50px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${BLACK};
  color: ${BLACK};
  margin-bottom: 40px;
`;

export const CategoriesWrapper = styled.div`
  flex-direction: column;
  color: ${BLACK};
`;

export const CategoryWrapper = styled.div`
  flex-direction: column;
  margin-bottom: 50px;
`;

export const CategoryBulletTitle = styled.div`
  &::before {
    content: '• ';
    width: 5px;
    line-height: 1.5;
  }

  & > strong {
    font-size: 1rem; // same as typography-body1
    margin-right: 3px;
    white-space: nowrap;
    line-height: 1.5; // same as typography-body1
  }
`;

export const CategoryContent = styled.div`
  flex-direction: column;
  margin-top: 15px;
  margin-left: 30px;
`;

export const ZendeskExcerpt = styled.div`
  max-height: 200px;
  overflow: hidden;
  box-shadow: 0 4px 2px -2px ${GRAY_2};
`;

export const StyledLink = styled(Link)`
  width: fit-content;
  margin-top: 15px;
  text-decoration: none;
  color: ${BLUE};
  display: inline-flex;
  align-items: center;
`;
