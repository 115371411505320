import React, { ReactNode, useEffect } from 'react';
import { Spinner } from '../../../../components';
import SearchField from './SearchField/SearchField';
import { Box } from '@material-ui/core';
import { useSearchHelpcenter } from './hooks';
import SearchResults from './SearchResults/SearchResults';
import CategoryGrid from './CategoryGrid/CategoryGrid';
import EmptyResults from './SearchResults/EmptyResults/EmptyResults';
import CategoryHeader from './CategoryHeader/CategoryHeader';

const Categories = (): ReactNode => {
  const { onSearch, data, called, loading, error } = useSearchHelpcenter();
  const searchResults = data?.helpcenter?.search;
  const query = searchResults?.query;

  useEffect(() => {
    document.getElementById('helpcenter')?.scrollIntoView(true);
  }, [searchResults?.currentPage]);

  return (
    <div className="grid__box__row">
      <div className="grid__column spinner-container" css={{ minHeight: 512 }}>
        <CategoryHeader />
        <Box display="flex" flexDirection="column">
          <SearchField onSearch={onSearch} />
          {!called ? (
            <CategoryGrid />
          ) : loading ? (
            <Spinner />
          ) : searchResults?.articles?.length > 0 && !error ? (
            <SearchResults searchTerm={query} results={searchResults} onSelect={onSearch} />
          ) : (
            <EmptyResults />
          )}
        </Box>
      </div>
    </div>
  );
};

export default Categories;
