import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { Main, Section } from '../../atoms';
import { NavSub, NavSubItem, ProtectedRoute } from '../../components';
import {
  MailingRecipientsGroupsEnum,
  MailingTypeEnum,
  SecurityRole,
} from '../../graphql/VXModels/types';
import { stripSlash } from '../../util/urlHelper';

import { EditMessage, MailingsList, NewMessage } from '../../packages/Mailings';
import { _ } from '../../util/translate';
import { useHasRole } from '../../util/UserData';
import { BuildUrl } from '../../packages/Mailings/types';

const NEW_MAILING = '/new';
const ARCHIVE = '/archive';
const EDIT = '/:id(\\d+)';

const type = MailingTypeEnum.VXPAGES_VXMESSENGER;

// const titleFunc = ({ id, status, content: { subject = '' } = {} }: Mailing) => (
//   <Fragment>
//     {status && subject ? (
//       <Fragment>
//         {'['}
//         <StatusTranslated status={status} />
//         {`] - ${subject}`}
//       </Fragment>
//     ) : (
//       <Translation t={'mailings:messenger.sectionTitle.edit'} sprintf={{ id }} />
//     )}
//   </Fragment>
// );

const Messenger = ({ match: { url } }) => {
  const path = stripSlash(url);
  const indexRoute = path + NEW_MAILING;
  const hasLP = useHasRole(SecurityRole.VX_USER_VXPAGES_LP);
  const recipientsGroup = hasLP
    ? MailingRecipientsGroupsEnum.LANDING_PAGE_USERS
    : MailingRecipientsGroupsEnum.VXPAGES_VXMESSENGER;
  const buildUrl: BuildUrl = ({ id }) => `${path}/${id}`;

  return (
    <Main>
      <NavSub>
        <NavSubItem
          link={path + NEW_MAILING}
          icon="icon-conversation"
          label={_('mailings:messenger.nav.new')}
        />
        <NavSubItem
          link={path + ARCHIVE}
          icon="icon-chat"
          label={_('mailings:messenger.nav.archive')}
        />
      </NavSub>

      <Switch>
        <Redirect exact from={`${path}/`} to={indexRoute} />

        <ProtectedRoute path={path + NEW_MAILING}>
          <Section title={_('mailings:messenger.sectionTitle.newMsg')} css={{ minHeight: 300 }}>
            <NewMessage group={recipientsGroup} type={type} buildUrl={buildUrl} />
          </Section>
        </ProtectedRoute>

        <ProtectedRoute path={path + ARCHIVE}>
          <Section title={_('mailings:messenger.sectionTitle.archive')} css={{ minHeight: 300 }}>
            {/*<Archive group={recipientsGroup} type={type} />*/}
            <MailingsList type={type} buildUrl={buildUrl} />
          </Section>
        </ProtectedRoute>

        <ProtectedRoute path={path + EDIT}>
          {({
            match: {
              params: { id },
            },
          }) => (
            <Section
              title={_('mailings:messenger.sectionTitle.edit', { sprintf: { id } })}
              css={{ minHeight: 300 }}
            >
              <EditMessage id={id} />
            </Section>
          )}
        </ProtectedRoute>
        <Redirect to={indexRoute} />
      </Switch>
    </Main>
  );
};

export default Messenger;
