import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { MEDIA_PICTURE_UPLOAD_MIN_HEIGHT } from '../../../config';
import { Box, Typography } from '@material-ui/core';
import { _ } from '../../../util/translate';
import { NewCropper } from '../../Cropper';
import getCroppedImg from '../../Cropper/util/cropImage';
import Spinner from '../../Spinner/Spinner';
import NewButton from '../../../atoms/Button/NewButton';

const CROPBOX_SIZES = {
  circle: {
    aspectRatio: 1,
    aspectRatioLabel: '1:1',
    // unused width and height?
    width: 250,
    height: 250,
  },
  landscape_16_9: {
    aspectRatio: 16 / 9,
    aspectRatioLabel: '16:9',
    // unused width and height?
    width: 280,
    height: 158,
  },
  landscape_4_3: {
    aspectRatio: 4 / 3,
    aspectRatioLabel: '4:3',
    // unused width and height?
    width: 250,
    height: 188,
  },
  landscape_25_10: {
    aspectRatioLabel: '25:10',
    aspectRatio: 25 / 10,
    // unused width and height?
    width: 250,
    height: 100,
  },
  vxhomepages: {
    aspectRatioLabel: '1:1',
    aspectRatio: 1,
    // unused width and height?
    width: 50,
    height: 50,
  },
};

class Editor extends React.PureComponent {
  static getUrl = (data) => {
    switch (data.selectionType) {
      case 'urlData':
        return data.urlData;
      case 'picture':
        // We only have one picture (the first and only element of the 'pictures') when this method is used.
        return !!data.pictures && !!data.pictures[0]
          ? data.pictures[0].urlOriginal || data.pictures[0].url
          : '';
    }
  };
  state = { canSubmit: true, croppedAreaPixels: null };

  aspectRatio = CROPBOX_SIZES[this.props.type].aspectRatio;
  aspectRatioLabel = CROPBOX_SIZES[this.props.type].aspectRatioLabel;

  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({ canSubmit: false });
    let urlData;
    try {
      urlData = await getCroppedImg(this.url, this.state.croppedAreaPixels, false, {
        width: 520,
        height: 520,
      });
    } catch (e) {
      console.error(e);
    }
    this.props.onSubmit({
      source: 'editor',
      selectionType: 'urlData',
      sourcePicture:
        !!this.props.data.pictures && !!this.props.data.pictures[0]
          ? this.props.data.pictures[0]
          : this.props.data.picture,
      urlData: urlData,
    });
  };

  setCroppedAreaPixels = (croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  render() {
    this.url = Editor.getUrl(this.props.data);
    return (
      <Formsy.Form
        css={{ maxHeight: '100%' }}
        // eslint-disable-next-line react/no-string-refs
        ref="profileForm"
        onValidSubmit={this.submitForm}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" width="100%" maxWidth="100%" position="relative" top="50%">
            {this.props.isLoading && <Spinner />}
          </Box>
          <div
            css={{
              backgroundColor: '#f5f5f5',
              padding: '32px 32px 10px 32px',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            <Typography variant="h6">
              {_('mediamanagement:cropper.chooseSection', {
                sprintf: [CROPBOX_SIZES[this.props.type].aspectRatioLabel],
              })}
            </Typography>
          </div>

          <Box display="flex" flexDirection="column" padding="10px" minHeight="400px">
            <NewCropper
              url={this.url}
              aspect={CROPBOX_SIZES[this.props.type].aspectRatio}
              setCroppedAreaPixels={this.setCroppedAreaPixels}
              cropShape={this.props.type}
              disable={!this.state.canSubmit}
            />
          </Box>

          <Box display="flex" justifyContent="center" width="200" margin="20px 0 20px 0">
            <NewButton
              disabled={!this.state.canSubmit}
              loading={!this.state.canSubmit}
              onClick={this.onSubmit}
            >
              {_('common:button.save')}
            </NewButton>
          </Box>
        </Box>
      </Formsy.Form>
    );
  }
}

Editor.propTypes = {
  type: PropTypes.oneOf(Object.keys(CROPBOX_SIZES)).isRequired,
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  minSize: PropTypes.number,
  isLoading: PropTypes.bool,
};

Editor.defaultProps = {
  isLoading: false,
  minSize: MEDIA_PICTURE_UPLOAD_MIN_HEIGHT,
};

export default Editor;
