import React, { FC, useState } from 'react';
import Icon from '../../../../atoms/Icon/Icon';
import { telegram } from '../../../../atoms/Icon/libraries/svg';
import { BLUE, BLACK_2 } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';
import TelegramRequestButton from './TelegramRequestButton';
import { Container, RequestWrapper, LeftSection, RightSection } from './styles';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_TELEGRAM_REQUEST_PAGE } from '../../../../graphql/VXModels/queries';
import { EmptyContent, Spinner } from '../../../../components';
import subMonths from 'date-fns/subMonths';
import formatISO from 'date-fns/formatISO';
import AdvantagesListLocked from './AdvantagesListLocked';
import AdvantagesListUnlocked from './AdvantagesListUnlocked';
import { VXPagesSiteStatusEnum } from '../../../../graphql/VXModels/types';

const calculateStartEndDuration = (): { start: string; end: string } => {
  const dateNow = new Date();
  const date3MonthsAgoISO8601 = formatISO(subMonths(dateNow, 3));
  const dateNowISO8601 = formatISO(dateNow);
  return {
    start: date3MonthsAgoISO8601,
    end: dateNowISO8601,
  };
};

const TelegramRequestSection: FC = () => {
  const [duration] = useState(() => calculateStartEndDuration());

  const { loading, error, data } = useQuery(QUERY_MODEL_TELEGRAM_REQUEST_PAGE, {
    variables: {
      filter: {
        timeframes: [duration],
      },
    },
  });

  const vxPagesData = data?.model?.vxpages;
  const isVXPagePublished = vxPagesData?.info?.status === VXPagesSiteStatusEnum.active;
  const turnoverData = vxPagesData?.stats?.turnover?.[0]?.data;
  const hasEarnedEnough =
    (turnoverData?.creditsCharges?.hostAmount || 0) +
      (turnoverData?.subscriptions?.hostAmount || 0) >
      500 && isVXPagePublished;

  return (
    <Container>
      <RequestWrapper>
        {loading ? (
          <Spinner />
        ) : !!error ? (
          <EmptyContent icon="icon-warning-sign" title={_('common:error.generic')} />
        ) : (
          <>
            <LeftSection>
              <Icon icon={telegram} size="130px" css={{ marginBottom: '20px' }} />
              <span css={{ color: BLUE, fontSize: '21px', fontWeight: 'bold' }}>
                {hasEarnedEnough
                  ? _('telegram:requestTelegram.request.unlocked.price')
                  : _('telegram:requestTelegram.request.locked.price')}
              </span>
            </LeftSection>
            <RightSection>
              <header css={{ marginBottom: '20px' }}>
                <h3 css={{ color: BLACK_2, fontSize: '21px' }}>
                  {hasEarnedEnough
                    ? _('telegram:requestTelegram.request.unlocked.title')
                    : _('telegram:requestTelegram.request.locked.title')}
                </h3>
              </header>
              <article css={{ flex: '0.4', marginLeft: '13px', width: '100%' }}>
                <ul css={{ width: '100%' }}>
                  {hasEarnedEnough ? (
                    <AdvantagesListUnlocked />
                  ) : (
                    <AdvantagesListLocked
                      isVXPagePublished={isVXPagePublished}
                      hasEarnedEnough={hasEarnedEnough}
                    />
                  )}
                </ul>
              </article>
              <TelegramRequestButton isLocked={!hasEarnedEnough} />
            </RightSection>
          </>
        )}
      </RequestWrapper>
    </Container>
  );
};

export default TelegramRequestSection;
