import React, { FC } from 'react';
import { _ } from '../../../util/translate';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';
import { T } from '../../../components';

const MasterPasswordRequired: FC = () => {
  return (
    <div className="grid__column grid__box" css={{ flex: 'none' }}>
      <div className="grid__box__header profiles-overview__header">
        <div className="grid__box__header__title profiles-overview__header__text">
          <T _={'account:documents.pageTitle'} />
        </div>
      </div>
      <div className="grid__row">
        <EmptyContent
          title={_('common:forbidden.text')}
          icon={undefined}
          css={{ padding: '128px 0' }}
        />
      </div>
    </div>
  );
};

export default MasterPasswordRequired;
