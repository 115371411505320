import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { edit } from '../../../../../atoms/Icon/libraries/glyph';

const BlogItem: FC = () => (
  <LeftMenuItem title={_('navigation:homepage.blog')} icon={edit} uri="/website/blog" />
);

export default BlogItem;
