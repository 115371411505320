import React, { FC } from 'react';
import styled from '@emotion/styled';
import CmsArticle from '../../../components/CmsArticle';
import TermsAcceptForm from '../../Terms/TermsAcceptForm';
import { BLACK } from '../../../camtool-styles';
import { _ } from '../../../util/translate';
import { logoVXPages } from './assets';

const Container = styled.div`
  flex: 1 0 auto;
  max-height: 100%;
  flex-direction: column;
  padding: 60px 60px 0;
  width: 100%;
`;

const StyledArticle = styled.article`
  flex: 1 1 auto;
  max-height: 100%;
  flex-direction: column;
  color: ${BLACK};
  font-size: 14px;
`;

const StyledCmsArticle = styled(CmsArticle)`
  margin-top: 23px;
  min-height: 300px;
  flex: 1 1 auto;
  overflow-y: auto;
  padding-right: 12px;
  margin-right: -18px; // 18px - 12px = 6px which is the width of the scrollbar

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1em;
  }
`;

const StyledArticleTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  align-self: center;
`;

const Logo = styled.img`
  margin-top: 9px;
  height: 20px;
`;

const StyledTermAcceptForm = styled(TermsAcceptForm)`
  margin-top: 50px;
  margin-bottom: 50px;
`;

interface IProps {
  cmsArticleName: string;
  type: string;
  requirePassword: boolean;
}

interface ILogoMapping {
  [key: string]: string;
}

const logos: ILogoMapping = {
  'terms-homepage': logoVXPages,
};

const ModalTermsContentLong: FC<IProps> = ({ cmsArticleName, type, requirePassword }) => {
  const logo = logos[cmsArticleName];

  return (
    <Container>
      <StyledArticle>
        <StyledArticleTitle>{_(`modalbox:${cmsArticleName}.title2`)}</StyledArticleTitle>
        {logo && <Logo src={logo} />}
        <StyledCmsArticle id={cmsArticleName}>
          <StyledTermAcceptForm
            type={type}
            cmsArticleName={cmsArticleName}
            requirePassword={requirePassword}
            hideCheckbox={true}
            smallMargins={true}
          />
        </StyledCmsArticle>
      </StyledArticle>
    </Container>
  );
};

export default ModalTermsContentLong;
