import React, { FC } from 'react';
import Translation from '../../components/Translation/Translation';
import { Main } from '../../atoms/Grid';
import Section from '../../atoms/Grid/Section';
import CmsArticle from '../../components/CmsArticle';
import CmsArticleTitle from '../../components/CmsArticleTitle';
import TermsAcceptForm from '../../components/Terms/TermsAcceptForm';
import { TermsTypeEnum } from '../../graphql/VXModels/types';
import styled from '@emotion/styled';

export const TermsArticle = styled.article`
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  padding: 40px;
`;

const cmsArticleName = 'terms-homepage';

const TermsOfUseGateway: FC = () => {
  return (
    <Main>
      <Section title={<CmsArticleTitle id={cmsArticleName} />}>
        <TermsArticle>
          <CmsArticle id={cmsArticleName}>
            <div style={{ marginTop: 24, justifyContent: 'center' }}>
              <TermsAcceptForm
                type={TermsTypeEnum.VXPAGES_TERMS_OF_USE_HOMEPAGE}
                cmsArticleName={cmsArticleName}
                hideCheckbox={true}
              >
                <Translation _={'common:text.accept'} />
              </TermsAcceptForm>
            </div>
          </CmsArticle>
        </TermsArticle>
      </Section>
    </Main>
  );
};

export default TermsOfUseGateway;
