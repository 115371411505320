import React from 'react';

import isEqual from 'lodash/isEqual';
import { SessionStore } from '../../../stores/Session/Session';
import CampaignFilter from './CampaignFilter';
import ModalDescription from './ModalDescription';
import FilteredCampaigns from './FilteredCampaigns';
import { Footer, T } from '../../../components';

import { STATUS_ACTIVE, STATUS_EXPIRED, TYPE_PHOTO, TYPE_VIDEO } from './Props';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';

export default class Campaigns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        type: [TYPE_VIDEO, TYPE_PHOTO],
        status: STATUS_ACTIVE,
      },
      firstLoad: true,
      countActiveContests: -1,
      activeModal: null,
    };

    // this.onStoreChange = this.onStoreChange.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onChangeResults = this.onChangeResults.bind(this);
    this.toExpired = this.toExpired.bind(this);
    this.openModalBox = this.openModalBox.bind(this);
    this.closeModalBox = this.closeModalBox.bind(this);
  }

  openModalBox(type) {
    this.setState({ activeModal: type });
  }

  closeModalBox() {
    this.setState({ activeModal: null });
  }

  showModalVideo() {
    return this.state.activeModal === TYPE_VIDEO;
  }

  showModalPhoto() {
    return this.state.activeModal === TYPE_PHOTO;
  }

  goTo(type) {
    let target = `${APP_BASE_PATH}/mediamanagement/`;
    if (type === TYPE_PHOTO) {
      target = `${target}picture/create`;
    } else if (type === TYPE_VIDEO) {
      target = `${target}video`;
    }

    window.location.href = target;
  }

  onChangeFilter(filter) {
    if (!isEqual(this.state.filter, filter)) {
      this.setState({ filter });
    }
  }

  onChangeResults(contests) {
    const nextState = {};

    if (
      contests.searchStatus === STATUS_ACTIVE &&
      contests.contests.length !== this.state.countActiveContests
    ) {
      nextState.countActiveContests = contests.contests.length;
    }

    if (
      this.state.firstLoad &&
      this.state.filter.status === STATUS_ACTIVE &&
      nextState.countActiveContests === 0
    ) {
      nextState.filter = { type: this.state.filter.type, status: STATUS_EXPIRED };
      nextState.firstLoad = false;
    }

    if (Object.keys(nextState).length > 0) {
      this.setState(nextState);
    }
  }

  toExpired() {
    this.setState({ filter: { type: this.state.filter.type, status: STATUS_EXPIRED } });
  }

  getActorId() {
    return SessionStore.get().userId;
  }

  onClickNavDescription(campaignId) {
    // console.log(this.__proto__.constructor.name, 'onClickNavDescription', this, campaignId);
    const nextSelectedTab = this.state.selectedTab;
    nextSelectedTab[campaignId] = 'description';
    this.setState({ selectedTab: nextSelectedTab });
  }

  onClickNavRanking(campaignId) {
    // console.log(this.__proto__.constructor.name, 'onClickNavRanking', this, campaignId);

    const nextSelectedTab = this.state.selectedTab;
    nextSelectedTab[campaignId] = 'ranking';
    this.setState({ selectedTab: nextSelectedTab });
  }

  render() {
    const { state } = this;
    return (
      <main
        id="marketing-campaigns"
        className="grid marketing marketing-campaigns"
        style={{ minWidth: '600px' }}
      >
        <div className="grid__row">
          <div className="grid__column grid__box h--pos-rel min-width--0">
            <div className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'marketing:campaigns.title'} />
              </div>
              <div className="grid__box__header__spacer" />
              <CampaignFilter onChange={this.onChangeFilter} filter={state.filter} />
            </div>
            <FilteredCampaigns
              onClickToExpired={this.toExpired}
              onChange={this.onChangeResults}
              openModalBox={this.openModalBox}
              filter={state.filter}
            />
          </div>
        </div>
        <ModalDescription
          name="modalDescriptionVideo"
          type={TYPE_VIDEO}
          state={state.activeModal === TYPE_VIDEO}
          onClose={this.closeModalBox}
          title={_('marketing:campaigns.modal.video.title')}
          description={_('marketing:campaigns.modal.video.text')}
          onClickStartNow={() => this.goTo(TYPE_VIDEO)}
        />
        <ModalDescription
          name="modalDescriptionPhoto"
          type={TYPE_PHOTO}
          state={state.activeModal === TYPE_PHOTO}
          onClose={this.closeModalBox}
          title={_('marketing:campaigns.modal.photo.title')}
          description={_('marketing:campaigns.modal.photo.text')}
          onClickStartNow={() => this.goTo(TYPE_PHOTO)}
        />
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}
