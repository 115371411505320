export interface ITimeframe {
  start: Date;
  end: Date;
}

export interface IStringTimeframe {
  start: string;
  end: string;
}

export type Timeframe = ITimeframe | null;
export type StringTimeframe = IStringTimeframe | null;
export type TimeframeValue = Timeframe | StringTimeframe;

export enum TimeframeKey {
  today = 'today',
  yesterday = 'yesterday',
  twoDaysAgo = 'twoDaysAgo',
  sevenDays = 'sevenDays',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  custom = 'custom',
}

export type Timeframes = {
  [key in TimeframeKey]: Timeframe;
};

export interface ITimeframeData<T extends any> {
  timeframe: IStringTimeframe;
  data: T;
}

export type DataByTimeframe<T extends any> = {
  [key in TimeframeKey]: T;
};

export type RowDataItem = string | number | undefined | null;

export interface IRowDefinition<DataSource extends any> {
  label: string;
  callback: (data: DataSource, timeframeKey: TimeframeKey) => RowDataItem;
}
