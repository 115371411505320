import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { Translation } from '../../../../../components';

const CategoryHeader: FC = () => {
  return (
    <Box m={2} flexDirection="column">
      <header className="helpcenter__title">
        <Translation t={'help:helpcenter.categories.howCanWeHelp'} />
      </header>
      <div className="helpcenter__description">
        <Translation t={'help:helpcenter.categories.chooseCategory'} />
      </div>
    </Box>
  );
};

export default CategoryHeader;
