import React, { FC } from 'react';
import { VXPagesUser } from '../../../../../../graphql/VXModels/types';
import { Field, FieldLabel, FieldValue } from './Base';
import { _ } from '../../../../../../util/translate';

interface IProps {
  user: VXPagesUser;
}

const NewsletterField: FC<IProps> = ({ user }) => {
  const { isSubscribedToNewsletter } = user;

  return (
    <Field>
      <FieldLabel>{_('userManagement:field.newsletterShort')}</FieldLabel>
      <FieldValue>
        {isSubscribedToNewsletter
          ? _('userManagement:newsletter.active')
          : _('userManagement:newsletter.inactive')}
      </FieldValue>
    </Field>
  );
};

export default NewsletterField;
