import React, { FC, Fragment } from 'react';
import { MailingStatusEnum } from '../../../../../graphql/VXModels/types';
import FieldActionButton from '../../../../../molecules/Formik/Fields/FieldActionButton';
import { ActionEnum } from '../../../types';
import { Translation } from '../../../../../components';
import { _ } from '../../../../../util/translate';
import { StyledSection } from '../../styles';

interface Props {
  isSubmitting: boolean;
  publishScheduled: boolean;
  recipientsCount: number;
  status: MailingStatusEnum;
}

const SendSection: FC<Props> = ({ isSubmitting, publishScheduled, recipientsCount, status }) => (
  <StyledSection css={{ flexDirection: 'row' }}>
    {status === MailingStatusEnum.DRAFT && (
      <Fragment>
        <FieldActionButton
          name={'action'}
          action={ActionEnum.SAVE}
          theme={'border-gray'}
          label={<Translation t={'mailings:text.saveDraft'} />}
          disabled={isSubmitting}
        />
        <FieldActionButton
          name={'action'}
          action={ActionEnum.PUBLISH}
          theme={'blue'}
          label={
            publishScheduled
              ? _('mailings:text.approveSendScheduled')
              : _('mailings:text.approveSend')
          }
          disabled={recipientsCount === 0 || isSubmitting}
        />
        {/* @todo deactivated because delete is not implemented yet */}
        {/*<FieldActionButton*/}
        {/*  name={'action'}*/}
        {/*  action={ActionEnum.DELETE}*/}
        {/*  theme={'red'}*/}
        {/*  label={'Entwurf löschen'}*/}
        {/*/>*/}
      </Fragment>
    )}
    {status === MailingStatusEnum.SHIPMENT_PENDING && (
      <FieldActionButton
        name={'action'}
        action={ActionEnum.SAVE}
        theme={'border-gray'}
        label={<Translation t={'mailings:text.approveEdit'} />}
      />
    )}
  </StyledSection>
);

export default SendSection;
