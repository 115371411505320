import React, { FC } from 'react';

const BackArrow: FC<{}> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.804" height="8.296" viewBox="0 0 15.804 8.296">
    <path
      d="M231.706,401.377l-3.818-3.819a.324.324,0,0,0-.464,0l-.854.855a.322.322,0,0,0,0,.463l1.806,1.806H216v1.853h12.376l-1.806,1.806a.322.322,0,0,0,0,.463l.854.854a.323.323,0,0,0,.464,0l3.818-3.819a.323.323,0,0,0,0-.463Z"
      transform="translate(231.804 405.756) rotate(180)"
    />
  </svg>
);

export default BackArrow;
