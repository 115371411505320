import React from 'react';
import PropTypes from 'prop-types';
import { MediaWrapper, T, VideoPlayer } from '../../../components';
import { PropContestToplist } from '../../../components/RankingList/RankingList';

const CampaignMediaVideo = ({ id, title, uploadedVideos, toplist }) => {
  toplist = toplist || [];
  const topUmpIds = toplist.slice(0, 3).map((entry) => {
    return entry.umxId;
  });

  const formattedToplist = {};
  for (let entry of toplist) {
    formattedToplist[entry.umxId] = entry;
  }

  return [
    <div key={0} className="marketing-campaign__item__title">
      <T _={'marketing:campaigns.type.video.title'} />
    </div>,
    <div key={1} className="grid__box__row">
      <div className="marketing-campaign__item__photo-grid">
        {uploadedVideos &&
          uploadedVideos.map((video) => (
            <div key={video.id} className="grid__box__column marketing-campaign__item__video-info">
              <div className="marketing-campaign__item__video-info__title">{video.title}</div>
              <div className="grid__box__row">
                <MediaWrapper
                  className="grid__box__column"
                  contentType="video"
                  source={video.previewPicture ? video.previewPicture.url : ''}
                  likes={video.likes}
                  ageRating={video.ageRating}
                  isTopPhoto={topUmpIds.indexOf(parseInt(video.id)) !== -1}
                  rank={formattedToplist[video.id] ? formattedToplist[video.id].rank : 0}
                >
                  <VideoPlayer url={video.url} />
                </MediaWrapper>
                <div className="grid__box__column marketing-campaign__item__video-info__description">
                  {video.description}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>,
  ];
};

CampaignMediaVideo.propTypes = {
  uploadedPictures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      ageRating: PropTypes.number.isRequired,
      likes: PropTypes.number.isRequired,
    })
  ),
  toplist: PropContestToplist,
};

CampaignMediaVideo.defaultProps = {};

export default CampaignMediaVideo;
