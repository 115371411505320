import React, { FC } from 'react';
import { LeftMenuRoot } from '../../components';
import { WelcomeItem, DashboardItem, FinancesItem, NewscenterItem, HelpItem } from '../items';
import { ContentGroup, ProfileGroup, VideochatGroup } from '../groups';

const LeftMenuWhitelabel: FC = () => {
  return (
    <LeftMenuRoot>
      <WelcomeItem />
      <DashboardItem />
      <ProfileGroup />
      <ContentGroup />
      <VideochatGroup />
      {/*<FinancesItem />*/}
      <NewscenterItem />
      <HelpItem />
    </LeftMenuRoot>
  );
};

export default LeftMenuWhitelabel;
