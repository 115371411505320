import React, { useState, FC } from 'react';
import HelpCenterSection from '../HelpCenterSection/HelpCenterSection';
import ZendeskRenderContainer from '../ZendeskRenderContainer/ZendeskRenderContainer';
import ModalContactForm from '../../routes/Help/ModalContactForm/ModalContactForm';
import { ButtonLink } from '../../atoms';
import { APP_BASE_PATH } from '../../util/env';
import { _ } from '../../util/translate';
import { HelpCenterArticle as HelpCenterArticleType } from '../../graphql/VXModels/types';

interface IProps {
  onVoteUp: () => void;
  onVoteDown: () => void;
  article: HelpCenterArticleType;
}

const HelpCenterArticle: FC<IProps> = ({ /*onVoteUp, onVoteDown,*/ article }) => {
  const { section, category } = article;

  const [modalOpen, setModalOpen] = useState(false);

  // const [hasVoted, setVoted] = useState(false);
  //
  // const upVote = (): void => {
  //   setVoted(true);
  //   onVoteUp();
  // };
  //
  // const downVote = (): void => {
  //   setVoted(true);
  //   onVoteDown();
  // };

  return (
    <div className="grid__row helpcenter-article grid__box">
      <aside className="grid__column helpcenter-article__aside">
        <div className="grid__row">
          <div className="grid__column helpcenter-article__aside-container">
            <HelpCenterSection
              section={section}
              category={category}
              header={_('help:helpcenter.article.moreFromThisSection')}
            />
            <ButtonLink
              to={`${APP_BASE_PATH}/help/helpcenter/categories/${category.id}/articles`}
              theme="gray"
              label="zurück"
              css={{ margin: 16 }}
            />
          </div>
        </div>
      </aside>
      <article className="grid__column helpcenter-article__body">
        <ZendeskRenderContainer htmlContent={article.body} />

        {/*<div className="helpcenter-article__votes">*/}
        {/*  <div className="helpcenter-article__votes-question">*/}
        {/*    {hasVoted*/}
        {/*      ? _('help:helpcenter.article.thanksForFeedback')*/}
        {/*      : _('help:helpcenter.article.wasHelpful')}*/}
        {/*  </div>*/}
        {/*  {!hasVoted && (*/}
        {/*    <div className="helpcenter-article__votes-controls" role="radiogroup">*/}
        {/*      <ButtonGroup position="center">*/}
        {/*        <Button*/}
        {/*          theme="blue"*/}
        {/*          label={_('help:helpcenter.article.wasHelpfulYes')}*/}
        {/*          onClick={upVote}*/}
        {/*          css={{ margin: 8 }}*/}
        {/*        />*/}
        {/*        <Button*/}
        {/*          theme="blue"*/}
        {/*          label={_('help:helpcenter.article.wasHelpfulNo')}*/}
        {/*          onClick={downVote}*/}
        {/*          css={{ margin: 8 }}*/}
        {/*        />*/}
        {/*      </ButtonGroup>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}

        <div className="helpcenter-article__more-questions">
          {_('help:helpcenter.article.moreQuestions') + ' '}
          <a href="#" onClick={() => setModalOpen(true)}>
            {_('help:helpcenter.article.contactUs')}
          </a>
        </div>
      </article>

      {modalOpen && <ModalContactForm onBackgroundClick={() => setModalOpen(false)} />}
    </div>
  );
};

export default HelpCenterArticle;
