import React from 'react';
import PropTypes from 'prop-types';

import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import Campaign from './Campaign';
import { PropFilter, STATUS_ACTIVE, STATUS_EXPIRED, STATUS_PLANNED } from './Props';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';

import Spinner from '../../../components/Spinner/Spinner';
import Button from '../../../components/Button/Button';
import { _ } from '../../../util/translate';

const SORT_CONTEST_START_ASC = 'contest_start_asc';
const SORT_CONTEST_START_DESC = 'contest_start_desc';

const StatusMessage = (props) => {
  return (
    <div className="grid__box__row marketing-campaign__item">
      {props.spinner && <Spinner />}
      <div className="grid__box__column grid__box__sub">
        <EmptyContent icon={props.icon} title={props.message}>
          {props.children}
        </EmptyContent>
      </div>
    </div>
  );
};

StatusMessage.propTypes = {
  spinner: PropTypes.bool,
  message: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
};

StatusMessage.defaultProps = {
  spinner: false,
  message: '',
  icon: 'icon-history',
};

const getNotFoundMessage = (status) => {
  switch (status) {
    case STATUS_ACTIVE:
      return _('marketing:campaigns.activeNotFound');
    case STATUS_PLANNED:
      return _('marketing:campaigns.plannedNotFound');
    default:
      return _('marketing:campaigns.notFound');
  }
};

const FilteredCampaigns = (props) => {
  return (
    <Query
      query={gql`
        query ($status: ContestStatus!, $type: [ContestType]!, $sort: ContestSort) {
          model {
            username
            contests(status: $status, type: $type, sort: $sort) {
              searchType
              searchStatus
              contests {
                id
                type
                status
                title
                description
                winners
                prices
                tasks
                uploadStart
                uploadEnd
                contestStart
                contestEnd
                uploadLimit
                uploadedPictures {
                  id
                  ageRating
                  url(size: w320)
                  likes
                }
                uploadedVideos {
                  id
                  ageRating
                  title
                  description
                  url(profile: _480p)
                  previewPicture {
                    url(size: w320)
                  }
                  likes
                }
                totalUploadedMedia
                titlePictureUrl
                isActive
                isExpired
                isUploadActive
                isUploadExpired
                hasWinners
                hasParticipated
                linkContest
                linkSexiestSelfies
                toplist {
                  rank
                  userId
                  username
                  likes
                  umxId
                  previewPictureUrl
                }
              }
            }
          }
        }
      `}
      variables={{
        ...props.filter,
        sort:
          props.filter.status === STATUS_EXPIRED || props.filter.status === STATUS_ACTIVE
            ? SORT_CONTEST_START_DESC
            : SORT_CONTEST_START_ASC,
      }}
      onCompleted={(data) => {
        props.onChange(data.model.contests);
      }}
    >
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div
              className="grid__box__row marketing-campaign__item spinner-container"
              style={{ height: 200 }}
            >
              <Spinner />
            </div>
          );
        if (error)
          return <StatusMessage message={_('common:text.error')} icon="icon-warning-sign" />;
        if (data) {
          const { model } = data;
          const { contests } = model;
          if (contests.contests.length > 0) {
            return contests.contests.map((contest) => {
              return <Campaign key={contest.id} {...contest} openModalBox={props.openModalBox} />;
            });
          }
          return (
            <StatusMessage message={getNotFoundMessage(contests.searchStatus)} icon="icon-podium">
              <Button
                className="button--blue"
                onClick={props.onClickToExpired}
                label={_('marketing:campaigns.toExpiredContests')}
              />
            </StatusMessage>
          );
        }
      }}
    </Query>
  );
};

FilteredCampaigns.propTypes = {
  filter: PropFilter.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickToExpired: PropTypes.func.isRequired,
  openModalBox: PropTypes.func.isRequired,
};

FilteredCampaigns.defaultProps = {};

export default FilteredCampaigns;
