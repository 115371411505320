import React, { FC, useState } from 'react';
import { VoucherType, IStepProps } from '../../types';
import BenefitsType from './BenefitsType/BenefitsType';
import { useHasRole, useUserData } from '../../../../util/UserData';
import { SecurityRole } from '../../../../graphql/VXModels/types';
import { VOUCHER_TYPES } from '../../utils';
import RadioField from '../../../../atoms/FormFIeldsCtrl/Fields/RadioField';
import { _ } from '../../../../util/translate';

const VXPAGES_ONLY_VOUCHER_TYPES = ['VXPAGES_CREDITS', 'VIDEO', 'PHOTOS'];

const StepBenefitsType: FC<IStepProps> = ({ dispatch }) => {
  const userData = useUserData();
  const hasVXPageInHomepageMode = useHasRole(SecurityRole.VX_USER_VXPAGES_HP);
  const hasVXPageInLandingPageMode = useHasRole(SecurityRole.VX_USER_VXPAGES_LP);
  const hasVXModelsRole = useHasRole(SecurityRole.VX_USER_VXMODELS);

  const canCreateVisitXCodes = !!(
    (hasVXModelsRole || hasVXPageInLandingPageMode) &&
    userData?.model?.bonuscodes?.canCreateVisitXBonusCodes
  );
  const canCreateVXPagesCodes = hasVXPageInHomepageMode;

  // Prefer VXPages if both are available, as the potential for financial damage is lower
  const [platform, setPlatform] = useState<string>(canCreateVXPagesCodes ? 'VXPAGES' : 'VISIT-X');

  function handleVoucherTypeSelect(voucherType: VoucherType) {
    dispatch({ type: 'SET_CURRENT_VOUCHER_TYPE', payload: { currentVoucherType: voucherType, platforms: [platform] } }); // prettier-ignore
    dispatch({ type: 'GO_TO_STEP_VOUCHER_CONTENT', payload: {} });
  }

  function handlePlatformChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPlatform(e.target.value);
  }

  const ticketshow = 'TICKET_SHOW';

  return (
    <article css={{ flexDirection: 'column', flex: '1 0 auto' }}>
      <header css={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <h2>{_('voucher:step1.title')}</h2>
      </header>

      {canCreateVisitXCodes && canCreateVXPagesCodes && (
        <article css={{ marginLeft: 32, marginTop: 32, flexDirection: 'column' }}>
          <header css={{ marginBottom: 8 }}>
            <h3 css={{ fontWeight: 'normal' }}>{_('benefits:voucher.typePage.headerPlatform')}</h3>
          </header>

          <div>
            <RadioField
              name="platform-selection"
              value="VXPAGES"
              onChange={handlePlatformChange}
              text="VXPAGES"
              checked={platform === 'VXPAGES'}
              css={{ width: 128 }}
            />

            <RadioField
              name="platform-selection"
              value="VISIT-X"
              onChange={handlePlatformChange}
              text="VISIT-X"
              checked={platform === 'VISIT-X'}
              css={{ width: 128, marginLeft: 16 }}
            />
          </div>
        </article>
      )}

      <article css={{ marginTop: 32, flexDirection: 'column' }}>
        <header css={{ marginLeft: 32, marginBottom: 8 }}>
          <h3 css={{ fontWeight: 'normal' }}>{_('benefits:voucher.typePage.headerType')}</h3>
        </header>
        <section
          css={{
            maxWidth: 200 * 3 + 3 * 32,
            margin: '0 auto',
            flexWrap: 'wrap',
          }}
        >
          {VOUCHER_TYPES.filter((voucherType) => {
            if (VXPAGES_ONLY_VOUCHER_TYPES.includes(voucherType)) {
              return platform === 'VXPAGES';
            } else return voucherType !== 'SHOW_TICKET';
          }).map((voucherType) => {
            return (
              <BenefitsType
                key={voucherType}
                type={voucherType}
                title={_(`benefits:voucher.${voucherType.toLowerCase()}.name`)}
                description={_(`benefits:voucher.${voucherType.toLowerCase()}.description`)}
                onClick={handleVoucherTypeSelect}
                css={{ marginLeft: 32, marginBottom: 32 }}
              />
            );
          })}
          {platform !== 'VXPAGES' && (
            <BenefitsType
              type={ticketshow}
              title={_(`benefits:voucher.${ticketshow.toLowerCase()}.name`)}
              description={''}
              onClick={handleVoucherTypeSelect}
              css={{ marginLeft: 32, marginBottom: 32 }}
            />
          )}
        </section>
      </article>
    </article>
  );
};

export default StepBenefitsType;
