import React, { useEffect, useRef, useState } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import styled from '@emotion/styled';

import TopBarNotificationsEntry from './TopBarNotificationsEntry';

import InfiniteScroll from 'react-infinite-scroller';
import { EmptyContent, Spinner } from '../../index';
import {
  MUTATION_NOTIFICATIONS_MARKREAD,
  NOTIFICATIONS_LIMIT,
  QUERY_UNREAD_NOTIFICATIONS,
  updateQuery,
  updateQueryAfterMarkRead,
} from '../../../util/NotificationsHelper';
import Translation from '../../Translation/Translation';
import { BLACK, RED, WHITE } from '../../../camtool-styles';
import { StyledButton } from '../styles';
import { _ } from '../../../util/translate';
import { getTimezone } from '../../../util/timezone';
import { BREAKPOINT_NOTIFICATIONS_POPUP_OFFSET, TOP_BAR_BUTTON_WIDTH } from '../constants';

const Container = styled.div`
  position: relative;
`;

const PopupContainer = styled.div`
  position: absolute;
  right: 0;
  top: 49px;
  flex-direction: column;
  align-items: center;
  background-color: ${WHITE};
  color: ${BLACK};
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  min-width: 300px;
  max-height: 400px;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINT_NOTIFICATIONS_POPUP_OFFSET}px) {
    right: -${TOP_BAR_BUTTON_WIDTH * 2}px;
  }
`;

function TopBarNotifications2(props) {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('click', handlePageClick);
    return () => {
      window.removeEventListener('click', handlePageClick);
    };
  }, []);

  const open = () => setIsOpen(true);

  const close = () => setIsOpen(false);

  const handlePageClick = (event) => {
    // If the button is clicked, we let the button handle stuff. Otherwise, we handle it.
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      return null;
    } else {
      // !!containerRef.current means whether isOpen is true or false. If it
      // is open but the user clicked outside it, then we set isOpen to false.
      // otherwise we do nothing
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        close();
      }
    }
  };

  const timezone = getTimezone();

  return (
    <Query
      query={QUERY_UNREAD_NOTIFICATIONS}
      variables={{
        notificationsLimit: NOTIFICATIONS_LIMIT,
        notificationsOffset: 0,
        timezone,
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data, fetchMore }) => {
        const count =
          data && data.model && data.model.unreadNotifications.count
            ? data.model.unreadNotifications.count
            : null;

        const items =
          data && data.model && data.model.unreadNotifications.items
            ? data.model.unreadNotifications.items
            : [];

        return (
          <Mutation
            mutation={MUTATION_NOTIFICATIONS_MARKREAD}
            update={updateQueryAfterMarkRead(
              'unreadNotifications',
              QUERY_UNREAD_NOTIFICATIONS,
              timezone
            )}
          >
            {(markRead) => (
              <Container>
                <StyledButton ref={buttonRef} onClick={isOpen ? close : open} isOpen={isOpen}>
                  <div>
                    <span className="icon-bell" css={{ fontSize: 16 }} />
                    {count && (
                      <span
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          right: 8,
                          top: 4,
                          height: 19,
                          width: 19,
                          fontSize: 10,
                          color: WHITE,
                          background: RED,
                          borderRadius: '50%',
                        }}
                      >
                        {count > 99 ? '+99' : count}
                      </span>
                    )}
                  </div>
                </StyledButton>

                {isOpen && (
                  <PopupContainer ref={containerRef}>
                    <InfiniteScroll
                      className="grid__box__item__content"
                      initialLoad={false}
                      loadMore={() =>
                        fetchMore({
                          variables: { notificationsOffset: items.length },
                          updateQuery: updateQuery('unreadNotifications'),
                        })
                      }
                      hasMore={items.length < count}
                      loader={<Spinner key="loading" size="xs" inline={true} />}
                      useWindow={false}
                    >
                      {error && (
                        <EmptyContent
                          css={{ div: { fontSize: '16px !important' } }}
                          icon="icon-bell"
                          title={
                            <Translation t={'dashboard:app.notification.emptyContent.title'} />
                          }
                        />
                      )}
                      {items.length > 0 &&
                        items.reduce((render, item) => {
                          if (item.read === null) {
                            render.push(
                              <TopBarNotificationsEntry
                                className="topbar__notification__entry"
                                key={item.id}
                                {...item}
                                onMarkRead={item.type.deletable ? markRead : null}
                              />
                            );
                          }
                          return render;
                        }, [])}

                      {!error && items.length === 0 && (
                        <EmptyContent
                          icon="icon-bell"
                          title={_('dashboard:app.notification.emptyContent.title')}
                        />
                      )}
                    </InfiniteScroll>
                  </PopupContainer>
                )}
              </Container>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
}

export default TopBarNotifications2;
