import React, { FC, useMemo, useState } from 'react';
import { VoucherTypeEnum, VXPagesUser } from '../../../../../graphql/VXModels/types';
import { _ } from '../../../../../util/translate';
import { SelectLabel } from '../../styles';
import { Select } from '../../../../../atoms';
import ChangeVoucherAmount from '../../components/ChangeVoucherAmount/ChangeVoucherAmount';
import GiftContent from '../../components/GiftContent/GiftContent';
import InnerSection from '../../components/InnerSection';
import useVXPagesAlbumData from '../../hooks/useVXPagesAlbumData';

interface IProps {
  user: VXPagesUser;
}

enum VoucherChangeType {
  freeMessages = 'freeMessages',
  freeChatSeconds = 'freeChatSeconds',
  freeVideos = 'freeVideos',
  freePictureAlbums = 'freePictureAlbums',
}

enum ContentChangeType {
  video = 'video',
  photo = 'photo',
}

type ChangeType = VoucherChangeType | ContentChangeType;

type VoucherChangeTypeToVoucherTypeMapping = { [key in VoucherChangeType]: VoucherTypeEnum };

const voucherTypeByChangeType: VoucherChangeTypeToVoucherTypeMapping = {
  [VoucherChangeType.freeMessages]: VoucherTypeEnum.messages,
  [VoucherChangeType.freeChatSeconds]: VoucherTypeEnum.chatSeconds,
  [VoucherChangeType.freeVideos]: VoucherTypeEnum.videos,
  [VoucherChangeType.freePictureAlbums]: VoucherTypeEnum.galleries,
};

const EditVouchersGiftContent: FC<IProps> = ({ user }) => {
  const [type, setSelectedType] = useState<ChangeType>(VoucherChangeType.freeMessages);

  const typeOptions = useMemo(
    () =>
      (Object.values(VoucherChangeType) as ChangeType[])
        .concat(Object.values(ContentChangeType))
        .map((changeType: ChangeType) => ({
          label: _(
            `userManagement:details.section.giftContent.changeType.${changeType.substr(0, 14)}`
          ),
          value: changeType,
        })),
    []
  );

  const onSelectedTypeChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedType(e.target.value as ChangeType);
  };

  const { contentData, contentLoading, contentError } = useVXPagesAlbumData();

  return (
    <InnerSection title={_('userManagement:details.section.giftContent.editSubtitle')}>
      <SelectLabel>{_('userManagement:details.section.giftContent.selectType2')}</SelectLabel>
      <Select options={typeOptions} value={type} onChange={onSelectedTypeChange} />

      {(Object.values(VoucherChangeType) as ChangeType[]).includes(type) ? (
        <ChangeVoucherAmount
          user={user}
          voucherType={voucherTypeByChangeType[type as VoucherChangeType]}
        />
      ) : (
        <GiftContent
          user={user}
          video={type === ContentChangeType.video}
          contentData={contentData}
          contentLoading={contentLoading}
          contentError={contentError}
        />
      )}
    </InnerSection>
  );
};

export default EditVouchersGiftContent;
