import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import { Query, VXPagesStatsCustomers } from '../../../graphql/VXModels/types';
import { QUERY_MODEL_VXPAGES_STATS_CUSTOMERS } from '../graphql';

type Result = Omit<QueryResult<Query>, 'data'> & {
  data: Partial<VXPagesStatsCustomers>;
};

export const useCustomerManagementStatsOverview = (options?: QueryHookOptions<Query>): Result => {
  const { data, ...result } = useQuery<Query>(QUERY_MODEL_VXPAGES_STATS_CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  return { data: data?.model?.vxpages?.stats?.customers || {}, ...result };
};
