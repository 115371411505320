import { Mutation, VXPagesUserNote } from '../../../graphql/VXModels/types';
import { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_VXPAGES_USER_UPDATE_USER_NOTE } from '../../../graphql/VXModels/mutations';
import omit from 'lodash/omit';
import { log } from '../../../util';
import { ApolloError } from '@apollo/client';
import { MutationTuple } from '@apollo/react-hooks/lib/types';

export const reducer = (
  note: VXPagesUserNote,
  { type, value }: { type: string; value: unknown }
): VXPagesUserNote => {
  if (type === 'init') {
    return { ...(value as VXPagesUserNote) };
  }

  return {
    ...note,
    [type]: value,
  };
};

export const useOnNoteChange = (
  note: VXPagesUserNote,
  onChange: (previousNote: VXPagesUserNote | undefined, newNote: VXPagesUserNote) => unknown
): void => {
  const previousNote = useRef<VXPagesUserNote>();
  useEffect(() => {
    onChange(previousNote.current, note);
    previousNote.current = note;
  }, [note]);
};

export const useUpdateTopUser = (
  userId: number,
  note: VXPagesUserNote
): MutationTuple<Mutation, { userId: number }> => {
  const [mutation, result] = useMutation(MUTATION_MODEL_VXPAGES_USER_UPDATE_USER_NOTE, {
    // optimisticResponse:
    onError: (error) => log('notice', error.message, { context: 'Usernotes', error }),
  });

  const updateTopUser = (isTopUser: boolean) =>
    mutation({
      variables: {
        vxPagesUserId: userId,
        note: {
          ...omit({ ...note, isRegularCustomer: isTopUser }, '__typename'),
        },
      },
    });

  return [updateTopUser, result];
};

export const useSaveNote = (
  note: VXPagesUserNote,
  id: number,
  isTopUser: boolean
): { save: Promise<void>; loading: boolean; error: ApolloError | undefined } => {
  const [mutateUserNote, { loading, error }] = useMutation(
    MUTATION_MODEL_VXPAGES_USER_UPDATE_USER_NOTE
  );
  const save = async (): Promise<void> => {
    const data = { ...note, isRegularCustomer: isTopUser };
    error
      ? log('notice', error.message, { context: 'Usernotes', error })
      : await mutateUserNote({
          variables: {
            vxPagesUserId: id,
            note: {
              ...omit(data, '__typename'),
            },
          },
        });
  };

  return { save, loading, error };
};
