import React, { FC, ReactElement } from 'react';
import { Field, FieldProps, Form, Formik, FormikActions, FormikProps } from 'formik';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
// Outside Wizard
import { GRAY_2, RED } from '../../../../camtool-styles';
import { Button } from '../../../../atoms';
// Inside Wizard
import useBonusCode from '../../hooks/useBonusCode';
import { IFormData, IStepProps, IText } from '../../types';
import FieldCode from '../FieldCode';
import { dateTimeISOtoATOM, extractDateFromDateTimeISO } from '../../utils';
import { _ } from '../../../../util/translate';
import { useHasRole } from '../../../../util/UserData';
import { SecurityRole } from '../../../../graphql/VXModels/types';
import VoucherExpiration from './VoucherExpiration/VoucherExpiration';
import TargetGroups from './TargetGroups/TargetGroups';
import VoucherAmount from './VoucherAmount/VoucherAmount';
import VoucherName from './VoucherName/VoucherName';
import VoucherDescription from './VoucherDescription/VoucherDescription';
import { getVoucherValidationSchema } from './utils';
import Hintsection from '../../components/Hint/HintSection';

const initialValues: IFormData = {
  name: { de: '', en: '' },
  description: { de: '', en: '' },
  targetGroups: ['allCustomers'],
  code: '',
  voucherUseUnlimited: true,
  voucherUseMax: 100,
  validityDateRange: ['', ''],
};

/**
 * Form for the details of the voucher.
 * @param dispatch
 * @param state
 * @param client
 */
const StepBenefitsDetails: FC<IStepProps> = ({ dispatch, state, bonusCodeClient }) => {
  const { createCode, createConfig, generateCode, isCodeAvailable } = useBonusCode(bonusCodeClient);
  const { loading, error, data } = useQuery(gql`{ auth { id username } }`, { fetchPolicy: 'cache-only' }); // prettier-ignore
  const hasVXPageInLandingPageMode = useHasRole(SecurityRole.VX_USER_VXPAGES_LP);
  const isCreatingVisitXCode = state.platforms.includes('VISIT-X');
  function getModelUsername() {
    if (!loading && !error && data) {
      const { auth: { username } } = data; // prettier-ignore
      return username;
    }
  }
  const username = getModelUsername();

  function handleBackClick() {
    dispatch({ type: 'GO_BACK', payload: {} });
  }

  function getModelId() {
    if (!loading && !error && data) {
      const { auth: { id } } = data; // prettier-ignore
      return id;
    }
  }

  function transformPlatformsForQuery(platformNames: string[]) {
    const platforms: Array<{ pfmId: number; pfmSubref: string }> = [];

    for (const name of platformNames) {
      switch (name) {
        case 'VISIT-X':
          platforms.push({ pfmId: 927, pfmSubref: '' });
          if (hasVXPageInLandingPageMode) platforms.push({ pfmId: 932, pfmSubref: '' }); // VISIT-X codes need to work on the model's VXPage in landing page mode, too
          break;
        case 'VXPAGES':
          platforms.push({ pfmId: 932, pfmSubref: '' });
          break;
        default:
          throw new Error(`Unrecognized platform name: ${name}`);
      }
    }

    return platforms;
  }

  function transformTextStructure(type: 'codeName' | 'codeDescription', text?: IText) {
    if (!text) return [];
    return Object.keys(text)
      .map((language) => ({ type, language, value: text[language] }))
      .filter((textItem) => !!textItem.value);
  }

  function getQueryVariables(formValues: IFormData, benefits: any[]) {
    const validFrom: string = extractDateFromDateTimeISO(formValues.validityDateRange[0]);
    const validTo: string = extractDateFromDateTimeISO(formValues.validityDateRange[1]);

    return {
      validFrom,
      validTo,
      conditions: {
        firstPayment: formValues.targetGroups.includes('newCustomers'),
        platforms: transformPlatformsForQuery(state.platforms),
      },
      limitInstance: formValues.voucherUseUnlimited ? null : formValues.voucherUseMax,
      benefits,
      texts: [
        ...transformTextStructure('codeName', formValues.name),
        ...transformTextStructure('codeDescription', formValues.description),
      ],
    };
  }

  // todo: refactor functions to own useSubmit hook!
  async function handleSubmit(values: IFormData, actions: FormikActions<IFormData>) {
    const { validityDateRange } = values;
    if (!validityDateRange[0]) {
      validityDateRange[0] = dateTimeISOtoATOM(new Date().toISOString(), true);
    }

    const editedValues = {
      ...values,
      code: values.code.toUpperCase().trim(),
      validityDateRange,
    };

    if (await isCodeAvailable(editedValues.code)) {
      let configId: number;

      try {
        switch (state.currentVoucherType) {
          case 'MEDIA':
            const benefits = state.voucherContent.map((media) => ({
              media:
                media.redemptionType === 'ON_TOP'
                  ? { albumIdStaggering: [{ from: media.redemptionAmount, id: media.contentId }] }
                  : { albumId: media.contentId },
            }));

            configId = await createConfig(getQueryVariables(editedValues, benefits));
            break;
          case 'VIDEO':
            const videoContent = state.voucherContent[0];
            const videoRedemption =
              videoContent.redemptionType === 'ON_TOP'
                ? { voucherVideoAmountStaggering: [{ from: videoContent.redemptionAmount, amount: videoContent.contentData }] } // prettier-ignore
                : { voucherVideoAmount: videoContent.contentData }; // redemptionType === 'GRATIS'

            configId = await createConfig(
              getQueryVariables(editedValues, [
                { voucherVideo: { ...videoRedemption, actorIds: [getModelId()] } },
              ])
            );
            break;
          case 'TICKET_SHOW':
            const ticketShowContent = state.voucherContent[0];
            const ticketshowRedemption =
              ticketShowContent.redemptionType === 'ON_TOP'
                ? { liveShowActorIdStaggering: [{ from: ticketShowContent.redemptionAmount, amount: ticketShowContent.contentData, id: getModelId() }] } // prettier-ignore
                : undefined;

            configId = await createConfig(
              getQueryVariables(editedValues, [
                { liveShow: { ...ticketshowRedemption, liveShowActorId: getModelId() } },
              ])
            );
            break;
          case 'PHOTOS':
            const photosContent = state.voucherContent[0];
            const photosRedemption =
              photosContent.redemptionType === 'ON_TOP'
                ? { voucherPhotoAmountStaggering: [{ from: photosContent.redemptionAmount, amount: photosContent.contentData }] } // prettier-ignore
                : { voucherPhotoAmount: photosContent.contentData }; // redemptionType === 'GRATIS'

            configId = await createConfig(
              getQueryVariables(editedValues, [
                { voucherPhoto: { ...photosRedemption, actorIds: [getModelId()] } },
              ])
            );
            break;
          case 'MESSAGES':
            const messagesContent = state.voucherContent[0];
            const messagesRedemption =
              messagesContent.redemptionType === 'ON_TOP'
                ? { freeMessagesCountStaggering: [{ from: messagesContent.redemptionAmount, amount: messagesContent.contentData }] } // prettier-ignore
                : { freeMessagesCount: messagesContent.contentData }; // redemptionType === 'GRATIS'

            configId = await createConfig(
              getQueryVariables(editedValues, [
                { freeMessages: { ...messagesRedemption, actorId: getModelId() } },
              ])
            );
            break;
          case 'CHAT_MINUTES':
            const chatMinutesContent = state.voucherContent[0];
            const chatMinutesRedemption =
              chatMinutesContent.redemptionType === 'ON_TOP'
                ? { freeMinutesCountStaggering: [{ from: chatMinutesContent.redemptionAmount, amount: chatMinutesContent.contentData }] } // prettier-ignore
                : { freeMinutesCount: chatMinutesContent.contentData }; // redemptionType === 'GRATIS'

            configId = await createConfig(
              getQueryVariables(editedValues, [
                { freeMinutes: { ...chatMinutesRedemption, actorId: getModelId() } },
              ])
            );
            break;
          case 'VXPAGES_CREDITS':
            const vxpagesCreditsContent = state.voucherContent[0];
            const vxpagesCreditsRedemption =
              vxpagesCreditsContent.redemptionType === 'ON_TOP'
                ? { bonusTopUpCreditsStaggering: [{ from: vxpagesCreditsContent.redemptionAmount, amount: vxpagesCreditsContent.contentData }] } // prettier-ignore
                : { bonusFlatCredits: vxpagesCreditsContent.contentData }; // redemptionType === 'GRATIS'

            configId = await createConfig(
              getQueryVariables(editedValues, [
                { bonusCredits: { ...vxpagesCreditsRedemption /*actorId: getModelId()*/ } },
              ])
            );
            break;
          default:
            return -1;
        }
      } catch (e) {
        throw new Error(e);
      }

      if (configId !== -1 && editedValues.code) {
        await createCode(configId, editedValues.code);
        dispatch({ type: 'CREATE_VOUCHER', payload: editedValues });
        dispatch({ type: 'GO_TO_STEP_VOUCHER_DONE', payload: {} });
      }
    } else {
      actions.setFieldError('code', _('benefits:voucher.detailsPage.formErrors.codeTaken'));
    }
  }

  return (
    <article css={{ flexDirection: 'column' }}>
      <header css={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <h2>{_('benefits:voucher.detailsPage.title')}</h2>
        <p css={{ maxWidth: 438 }}>{_('benefits:voucher.detailsPage.description')}</p>
      </header>

      <section css={{ marginTop: 48 }}>
        <Formik
          initialValues={{ ...initialValues, code: `${getModelUsername()}-` }}
          validationSchema={() => getVoucherValidationSchema(username)}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            setFieldValue,
            setFieldTouched,
          }: FormikProps<IFormData>): ReactElement => {
            const autoGenerateCode = async () => {
              setFieldTouched('code', true);
              setFieldValue('code', `${getModelUsername()}-${await generateCode(6)}`);
            };

            return (
              <Form
                css={{
                  '& > *': { marginBottom: 24 },
                  flex: 1,
                  justifyContent: 'stretch',
                  flexDirection: 'column',
                }}
              >
                {/* Voucher Name */}
                <VoucherName />

                {/* Voucher description */}
                <VoucherDescription />

                {/* Do Voucher Expire */}
                <VoucherExpiration setFieldValue={setFieldValue} />

                {/* Target group for voucher */}
                <TargetGroups values={values} setFieldValue={setFieldValue} />

                {!isCreatingVisitXCode && (
                  <>
                    {/* Amount of Vouchers (unlimited vs. limited) */}
                    <VoucherAmount values={values} setFieldValue={setFieldValue} />
                  </>
                )}

                {/* Voucher Code */}
                <Field
                  name="code"
                  render={({ field }: FieldProps) => {
                    return (
                      <div
                        css={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          textAlign: 'center',
                          marginTop: 24,
                          maxWidth: 596, // avoid stuff getting too big because of long text/error-messages
                        }}
                      >
                        <div css={{ flexDirection: 'column' }}>
                          <p css={{ textTransform: 'uppercase', fontSize: 16 }}>
                            {_('benefits:voucher.detailsPage.specifyCodeTitle')}
                          </p>
                          <p css={{ color: GRAY_2 }}>
                            {_('benefits:voucher.detailsPage.specifyCodeDescription')}
                          </p>
                        </div>

                        <FieldCode
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          placeholder="z.B. LC-A0919"
                          error={touched.code && errors.code ? errors.code : ''}
                          autoComplete="off"
                        />

                        {touched.code && errors.code && (
                          <div css={{ color: RED, padding: 4 }}>{errors.code}</div>
                        )}

                        <div css={{ alignItems: 'center', marginTop: '20px' }}>
                          <Button
                            theme="link-gray"
                            css={{ color: GRAY_2 }}
                            onClick={autoGenerateCode}
                            type="button"
                          >
                            {_('benefits:voucher.texts.autoGenerate')}
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                />

                {state.currentVoucherType === 'TICKET_SHOW' && <Hintsection />}

                <nav css={{ marginTop: 48, marginBottom: 64, justifyContent: 'center' }}>
                  <Button theme="border-gray" onClick={handleBackClick} type="button">
                    <span className="icon-chevron-left" css={{ marginRight: 8, fontSize: 8 }} />
                    <span>{_('benefits:voucher.texts.back')}</span>
                  </Button>

                  <Button
                    label={_('benefits:voucher.texts.createVoucher')}
                    theme="blue"
                    type="submit"
                    css={{ marginLeft: 32 }}
                  />
                </nav>
              </Form>
            );
          }}
        </Formik>
      </section>
    </article>
  );
};

export default StepBenefitsDetails;
