import React from 'react';
import Formsy from 'formsy-react';

import { Button, Dropdown, EarningOverview, Footer, Spinner, T } from '../../../components';

import VXCashUsers from '../../Lists/VXCashUsers/VXCashUsers';
import VXCashRecruitedHosts from '../../Lists/VXCashRecruitedHosts/VXCashRecruitedHosts';

import { VXCashStore } from '../../../stores/VXCash/VXCashStore';
import { VXCashActionCreators } from '../../../stores/VXCash/VXCashActionCreators';

import { getFormattedAmount, getFormattedDate } from '../../../util/Formatter';
import { withAppState } from '../../../util/AppState';
import { _ } from '../../../util/translate';
import { GREEN, RED, WHITE } from '../../../camtool-styles';

const STORE = 'fullstat';

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = VXCashStore.get(STORE);
    this.chartNodeRef = React.createRef();
  }

  componentDidMount() {
    VXCashStore.addChangeListener(STORE, this.onStoreChange);
    VXCashActionCreators.getVXCashFullStat();
  }

  componentWillUnmount() {
    VXCashStore.removeChangeListener(STORE, this.onStoreChange);
  }

  componentDidUpdate() {
    this.renderChart('promolink');
  }

  getStateFromStores = () => {
    return VXCashStore.get(STORE);
  };

  onStoreChange = () => {
    let stateFromStore = this.getStateFromStores();
    stateFromStore.isDataLoading = false;
    stateFromStore.isSubmitButtonDisabled = false;
    this.setState(stateFromStore);
  };

  renderChart = (type) => {
    let values = [];
    let node = null;
    if (type === 'promolink') {
      values = this.state.promolink.dailyStat;
      // eslint-disable-next-line react/no-string-refs
      if (this.refs.chartNodePromolink) {
        // eslint-disable-next-line react/no-string-refs
        node = this.refs.chartNodePromolink;
      }
    }

    if (node) {
      let xValues = [];
      let yValues = [];
      for (let key in values) {
        xValues.push(key);
        yValues.push(values[key]);
      }
      let data = [];
      data.push({
        name: '',
        color: '#ff00ff',
        data: yValues,
      });

      // eslint-disable-next-line react/prop-types
      const [{ lang }] = this.props.appState;

      let chart = new Highcharts.Chart({
        accessibility: {
          enabled: false,
        },
        chart: {
          backgroundColor: WHITE,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'column',
          renderTo: node,
          pointWidth: '16px',
        },
        title: {
          text: '',
          display: 'none !important',
        },
        xAxis: {
          categories: xValues,
          crosshair: true,
          labels: {
            formatter: function () {
              return this.value.substr(8, 2);
            },
          },
        },
        yAxis: {
          stackLabels: {
            enabled: false,
          },
        },
        tooltip: {
          formatter: function () {
            return (
              '<b>' +
              getFormattedDate(this.x, lang) +
              '</b><br/><b>' +
              getFormattedAmount(this.y, lang) +
              '</b>'
            );
          },
          //pointFormat: '<b>{series.name}</b><br /><b>{point.y:.2f} EUR</b><br /><b>{point.percentage:.1f}%</b>'
          //headerFormat: '<b>{point.key}</b><br />',
          //pointFormat: '<b>{point.y:.2f} EUR</b>'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
            showInLegend: false,
          },
        },
        series: data,
      });
    }
  };

  submitForm = (model) => {
    this.setState({
      isDataLoading: true,
      isSubmitButtonDisabled: true,
    });
    VXCashActionCreators.getVXCashFullStat(model);
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const [{ lang }] = this.props.appState;

    return (
      <main className="grid vxcash-statistics">
        <div className="grid__row">
          <div className="grid__column grid__box">
            <div className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'vxcash:statistic.header.title'} />
              </div>
              <div className="grid__box__header__spacer" />

              <Formsy.Form
                className={`grid__box__header__item popupmenu ${this.toggleStatus}`}
                // eslint-disable-next-line react/no-string-refs
                ref="filter"
                onValidSubmit={this.submitForm}
              >
                <div className="finances-overview__filter">
                  <Dropdown
                    className="dropdown--min"
                    name="month"
                    value={this.state.selectedMonth}
                    options={this.state.months}
                  />
                  <Dropdown
                    className="dropdown--min"
                    name="year"
                    value={this.state.selectedYear}
                    options={this.state.years}
                  />
                  <Button
                    type="submit"
                    label={_('common:button.show')}
                    disabled={this.state.isSubmitButtonDisabled}
                  />
                </div>
              </Formsy.Form>
            </div>

            <div className="grid__box__row grid__row--wrap spinner-container">
              {this.state.isDataLoading && <Spinner />}

              <div className="grid__box__column grid__box__sub" style={{ flexBasis: 'auto' }}>
                <div className="grid__box__sub__header">
                  <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                    <T _={'vxcash:statistic.promolink.turnover.header.title'} />
                  </div>
                </div>

                <div className="grid__box__sub__item">
                  <div
                    className="grid__box__sub__item__content vx-cash-totalsales"
                    style={{ flexBasis: 'auto', backgroundColor: WHITE }}
                  >
                    <EarningOverview
                      earning={getFormattedAmount(this.state.promolink.turnover.amountTotal, lang)}
                      earningColor={
                        this.state.promolink.turnover.amountTotal < 0 ? `${RED}` : `${GREEN}`
                      }
                      earningDetails={[
                        {
                          label: _('vxcash:statistic.promolink.turnover.body.visitors'),
                          icon: 'icon-group',
                          iconColor: '#1f93e9',
                          data: this.state.promolink.turnover.visitors,
                        },
                        {
                          label: _('vxcash:statistic.promolink.turnover.body.freeSignups'),
                          icon: 'icon-notes-2',
                          iconColor: '#ff4d3c',
                          data: this.state.promolink.turnover.freeSignups,
                        },
                        {
                          label: _('vxcash:statistic.promolink.turnover.body.paidSignups'),
                          icon: 'icon-user-add',
                          iconColor: '#ffb033',
                          data: this.state.promolink.turnover.paidSignups,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="grid__box__column grid__box__sub" style={{ flexBasis: 'auto' }}>
                <div className="grid__box__sub__header">
                  <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                    <T _={'vxcash:statistic.promolink.dailychart.header.title'} />
                  </div>
                </div>

                <div className="grid__box__sub__item">
                  <div
                    className="grid__box__sub__item__content"
                    style={{ minWidth: 450, backgroundColor: WHITE }}
                  >
                    <div
                      // eslint-disable-next-line react/no-string-refs
                      ref="chartNodePromolink"
                      style={{ width: '100%', height: 250 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid__row">
          <div className="grid__column grid__box min-width--0">
            <div className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'vxcash:statistic.details.title'} />
              </div>
            </div>

            <div />

            <div className="grid__box__row">
              <VXCashUsers type="promolinkUsers" />
            </div>

            <div className="grid__box__row">
              <VXCashRecruitedHosts />
            </div>
          </div>
        </div>
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default withAppState(Statistics);
