import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Stat } from '../../../molecules';
import { CheckMarkIcon, UserGroupIcon, UserIcon } from '../../../atoms';
import {
  BLUE,
  BREAKPOINT_PHONE_CONDITION,
  DARK_GRAY,
  GREEN,
  RED,
  WHITE,
} from '../../../camtool-styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useCustomerManagementStatsOverview } from '../hooks';
import { _ } from '../../../util/translate';

const useStyles = makeStyles(() =>
  createStyles({
    root: (props: { isMobile: boolean }) => ({
      backgroundColor: WHITE,
      ...(props.isMobile
        ? {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }
        : null),
    }),
  })
);

export const CustomerStats: FC = () => {
  const isMobile = useMediaQuery(BREAKPOINT_PHONE_CONDITION);
  const { root } = useStyles({ isMobile });
  const {
    data: { active, online, regular, verified },
    loading,
  } = useCustomerManagementStatsOverview();

  return (
    <>
      <Grid item md={3} xs={6}>
        <Stat
          classes={{ root }}
          icon={<UserGroupIcon htmlColor={BLUE} />}
          label={_('userManagement:stats.activeGuests.label')}
          value={loading ? '-' : active}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <Stat
          classes={{ root }}
          icon={<FavoriteIcon htmlColor={RED} />}
          label={_('userManagement:stats.regularGuests.label')}
          value={loading ? '-' : regular}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <Stat
          classes={{ root }}
          icon={<UserIcon htmlColor={DARK_GRAY} />}
          label={_('userManagement:stats.onlineGuests.label')}
          value={loading ? '-' : online}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <Stat
          classes={{ root }}
          icon={<CheckMarkIcon htmlColor={GREEN} />}
          label={_('userManagement:stats.verifiedGuests.label')}
          value={loading ? '-' : verified}
        />
      </Grid>
    </>
  );
};
