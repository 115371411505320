import React from 'react';
import PropTypes from 'prop-types';
import MediaWrapper from '../../../components/MediaWrapper/MediaWrapper';
import { PropContestToplist } from '../../../components/RankingList/RankingList';
import { T } from '../../../components';

const CampaignMediaPhoto = ({ uploadedPictures, toplist }) => {
  toplist = toplist || [];

  const topUmpIds = toplist.slice(0, 3).map((entry) => {
    return entry.umxId;
  });

  const formattedToplist = {};
  for (let entry of toplist) {
    formattedToplist[entry.umxId] = entry;
  }

  return [
    <div key={0} className="marketing-campaign__item__title">
      <T _={'marketing:campaigns.type.photos.title'} />
    </div>,
    <div key={1} className="grid__box__row">
      <div className="marketing-campaign__item__photo-grid">
        {uploadedPictures &&
          uploadedPictures.map((photo) => (
            <MediaWrapper
              key={photo.id}
              contentType="photo"
              source={photo.url}
              likes={photo.likes}
              ageRating={photo.ageRating}
              isTopPhoto={topUmpIds.indexOf(parseInt(photo.id)) !== -1}
              rank={formattedToplist[photo.id] ? formattedToplist[photo.id].rank : 0}
            />
          ))}
      </div>
    </div>,
  ];
};

CampaignMediaPhoto.propTypes = {
  uploadedPictures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      ageRating: PropTypes.number.isRequired,
      likes: PropTypes.number.isRequired,
    })
  ),
  toplist: PropContestToplist,
};

CampaignMediaPhoto.defaultProps = {};

export default CampaignMediaPhoto;
