import {
  Box,
  Card,
  CardContent,
  Container,
  createStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { useStyleFix } from '../../app/Theme/stylefix';
import { CardHeaderInverted, Main } from '../../atoms';
import NewButton from '../../atoms/Button/NewButton';
import { _ } from '../../util/translate';
import IconMerchandise from './assets/Icon-Merchandise.png';
import IconOnlineshop from './assets/Icon-Onlineshop.png';
import IconVXPages from './assets/Icon-VXPages.png';
import { TextColumn } from './components/TextColumn';

const useStyles = makeStyles(() =>
  createStyles({
    whiteBoxes: {
      // background: WHITE,
    },
    headerStyle: {
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 700,
      // lineHeight: '25px',
      letterSpacing: '0em',
    },
  })
);

const formUrl = 'https://vxmodels.zendesk.com/hc/de/requests/new?ticket_form_id=360003604739';

const OtherServices: FC = () => {
  const { headerStyle } = useStyles();
  const { gridBoundries, containerBoundries } = useStyleFix({ spacing: 3 });

  return (
    <Main isMobileEnabled>
      <Container disableGutters classes={{ root: containerBoundries }}>
        <Card square variant={'elevation'} elevation={0}>
          <CardHeaderInverted title={_('homepage:otherServices.page.title')} />
          <CardContent classes={{ root: gridBoundries }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  maxWidth={767}
                  alignContent={'center'}
                  alignSelf={'center'}
                  mt={8}
                  mb={10}
                  m={'auto'}
                >
                  <Box ml={3} mr={3} mb={3}>
                    <Typography variant={'h3'} align={'center'} classes={{ root: headerStyle }}>
                      {_('homepage:otherServices.teaser.title')}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant={'body1'} align={'center'}>
                      {_('homepage:otherServices.teaser.subtitle')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextColumn
                  icon={<img src={IconVXPages} alt={'VXPages Icon'} width={54} height={54} />}
                  title={_('homepage:otherServices.vxpages.title')}
                  subtitle={_('homepage:otherServices.vxpages.subtitle')}
                  body={_('homepage:otherServices.vxpages.body')}
                  info={{
                    title: _('homepage:otherServices.vxpages.info.title'),
                    body: _('homepage:otherServices.vxpages.info.body'),
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextColumn
                  icon={<img src={IconOnlineshop} alt={'Onlineshop Icon'} width={54} height={54} />}
                  title={_('homepage:otherServices.onlineshop.title')}
                  subtitle={_('homepage:otherServices.onlineshop.subtitle')}
                  body={_('homepage:otherServices.onlineshop.body')}
                  info={{
                    title: _('homepage:otherServices.onlineshop.info.title'),
                    body: _('homepage:otherServices.onlineshop.info.body'),
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextColumn
                  icon={
                    <img src={IconMerchandise} alt={'Merchandise Icon'} width={54} height={54} />
                  }
                  title={_('homepage:otherServices.merchandise.title')}
                  subtitle={_('homepage:otherServices.merchandise.subtitle')}
                  body={_('homepage:otherServices.merchandise.body')}
                  info={{
                    title: _('homepage:otherServices.merchandise.info.title'),
                    body: _('homepage:otherServices.merchandise.info.body'),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  maxWidth={767}
                  alignContent={'center'}
                  alignSelf={'center'}
                  mt={8}
                  mb={10}
                  m={'auto'}
                >
                  <Box ml={3} mr={3} mb={1}>
                    <Typography variant={'h3'} align={'center'} classes={{ root: headerStyle }}>
                      {_('homepage:otherServices.footer.title')}
                    </Typography>
                  </Box>
                  <Box textAlign={'center'} mb={8}>
                    <Typography variant={'body1'} align={'center'}>
                      {_('homepage:otherServices.footer.subtitle')}
                    </Typography>
                  </Box>
                  <Box textAlign={'center'}>
                    <NewButton color={'primary'} onClick={() => window.open(formUrl, '_blank')}>
                      {_('homepage:otherServices.contactButton.text')}
                    </NewButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Main>
  );
};

export { OtherServices };
