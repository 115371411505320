import React, { ReactElement } from 'react';
import { WHITE } from '../../../camtool-styles';

const GEMA = (): ReactElement => {
  return (
    <g transform="translate(-3730.8 664.2)">
      <g transform="translate(3741 -656.2)">
        <g>
          <g>
            <path
              fill={WHITE}
              d="M0.5,22c-0.1,0-0.2,0-0.3-0.1C0,21.8,0,21.7,0,21.5V0.5c0-0.1,0-0.2,0.1-0.3
					C0.2,0.1,0.4,0,0.5,0h10v6.9c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h7v14.2c0,0.1,0,0.2-0.1,0.3C17.8,22,17.6,22,17.5,22
					L0.5,22z M11.9,6.4c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V0L18,6.4H11.9z"
            />
          </g>
        </g>
      </g>
    </g>
  );
};

export default GEMA;
