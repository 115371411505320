import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BLACK_2, GRAY_3, RED, WHITE } from '../../camtool-styles';
import { css } from '@emotion/core';
import { Page, SpecialPagesEnum } from './types';

interface Props {
  page: Page;
  isSelected: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (page: Page) => any;
  colors?: PaginationItemSelectionStateColors;
  leftRightMargin?: number;
}

export interface PaginationItemSelectionStateColors {
  normal?: PaginationItemHoverStateColors;
  selected?: PaginationItemHoverStateColors;
}

export interface PaginationItemHoverStateColors {
  normal?: PaginationItemColors;
  hover?: PaginationItemColors;
}

export interface PaginationItemColors {
  color?: string;
  backgroundColor?: string;
}

interface PaginationItemDivProps {
  isSelected: boolean;
  clickable: boolean;
  colors: PaginationItemSelectionStateColors;
  leftRightMargin: number;
}

const ITEM_SIZE = '44px';

const PaginationItemDiv = styled.div(
  ({ isSelected, clickable, colors, leftRightMargin }: PaginationItemDivProps) => [
    css`
      display: block !important;
      height: ${ITEM_SIZE};
      width: ${ITEM_SIZE};
      margin: 0 ${leftRightMargin}px;
      line-height: ${ITEM_SIZE};
      text-align: center;
      font-size: 1.4rem;
      cursor: ${clickable ? 'pointer' : 'default'};
      background-color: ${isSelected
        ? colors.selected.normal.backgroundColor
        : colors.normal.normal.backgroundColor};
      color: ${isSelected ? colors.selected.normal.color : colors.normal.normal.color};
    `,
    clickable
      ? css`
          &:hover {
            background-color: ${isSelected
              ? colors.selected.hover.backgroundColor
              : colors.normal.hover.backgroundColor};
            color: ${isSelected ? colors.selected.hover.color : colors.normal.hover.color};
          }
        `
      : {},
  ]
);

const PaginationIcon = styled.div`
  width: ${ITEM_SIZE};
  height: ${ITEM_SIZE};
  line-height: ${ITEM_SIZE};
  text-align: center;

  &::before {
    width: ${ITEM_SIZE};
    line-height: ${ITEM_SIZE};
  }
`;

const getColorsWithDefaults = (
  colors: PaginationItemSelectionStateColors = {}
): PaginationItemSelectionStateColors => {
  const { normal: normalColors = {}, selected: selectedColors = {} } = colors;
  const { normal: normalNormalColors = {}, hover: normalHoverColors = {} } = normalColors;
  const { normal: selectedNormalColors = {}, hover: selectedHoverColors = {} } = selectedColors;

  return {
    normal: {
      normal: {
        color: BLACK_2,
        backgroundColor: 'transparent',
        ...normalNormalColors,
      },
      hover: {
        color: BLACK_2,
        backgroundColor: GRAY_3,
        ...normalHoverColors,
      },
    },
    selected: {
      normal: {
        color: WHITE,
        backgroundColor: RED,
        ...selectedNormalColors,
      },
      hover: {
        color: WHITE,
        backgroundColor: RED,
        ...selectedHoverColors,
      },
    },
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getDisplayItemForPage = (page: Page) => {
  switch (page) {
    case SpecialPagesEnum.ellipsis:
      return '…';
    case SpecialPagesEnum.back:
      return <PaginationIcon className={'vxicon icon-single-arrow-left-line'} />;
    case SpecialPagesEnum.forward:
      return <PaginationIcon className={'vxicon icon-single-arrow-right-line'} />;
    default:
      return page + 1;
  }
};

const PaginationItem: FC<Props> = ({
  page,
  isSelected,
  onClick,
  colors,
  leftRightMargin = 0,
}: Props) => {
  const clickable = page !== SpecialPagesEnum.ellipsis;
  const handleClick = clickable ? (): void => onClick(page) : null;

  return (
    <PaginationItemDiv
      isSelected={isSelected}
      clickable={clickable}
      onClick={handleClick}
      colors={getColorsWithDefaults(colors)}
      leftRightMargin={leftRightMargin}
    >
      {getDisplayItemForPage(page)}
    </PaginationItemDiv>
  );
};

export default PaginationItem;
