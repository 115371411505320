import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { _ } from '../../../../util/translate';
import { listAlt } from '../../../../atoms/Icon/libraries/glyph';
import { useUserData } from '../../../../util/UserData';
import { LeftMenuItemBadge } from '../../components';

const NewscenterItem: FC = () => {
  const userData = useUserData();
  const unreadCount = userData?.model?.newscenterNotifications?.count;

  return (
    <LeftMenuItem title={_('navigation:main.newscenter')} icon={listAlt} uri="/newscenter">
      {unreadCount && <LeftMenuItemBadge>{unreadCount}</LeftMenuItemBadge>}
    </LeftMenuItem>
  );
};

export default NewscenterItem;
