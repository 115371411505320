import React, { FC, useEffect, useState } from 'react';

import { UploadManagerContext } from './UploadManagerContext';
import { UploadManagerStore } from '../store/UploadManagerStore';
import { useUserData } from '../../../util/UserData';
import { useAppState } from '../../../util/AppState';
import { QueueItem, StateEnum, UserData } from '../types';
import { useTranscodingQueue } from '../hooks';
import { hasConfirmExit, registerConfirmExit, unregisterConfirmExit } from '../util';
import { _ } from '../../../util/translate';

export const UploadManagerProvider: FC = ({ children }) => {
  const [{ sessionId }] = useAppState();
  const { auth, model } = useUserData();
  const picturePoolUmaId = model?.extra?.picturePoolUmaId;
  const userData: UserData = { sessionId: sessionId as string, userId: auth?.id, picturePoolUmaId };
  const [polling, setPolling] = useState<number | undefined>();
  const [queue, setQueue] = useState<QueueItem[]>([]);

  useEffect(() => {
    UploadManagerStore.queueSubscribe((queue: QueueItem[] = []) => setQueue(queue));
  }, []);

  const { transcodingQueue, startPolling, stopPolling } = useTranscodingQueue();

  useEffect(() => {
    const hasTranscoding = !!queue.find((i) => i.state === StateEnum.transcoding);
    const hasActiveUploads = !!queue.find((i) =>
      [StateEnum.uploading, StateEnum.verifying, StateEnum.waiting].includes(i.state)
    );
    const confirmExit = hasConfirmExit();

    if (hasTranscoding) {
      setPolling(5000);
    } else {
      setPolling(undefined);
    }

    if (hasActiveUploads && !confirmExit) {
      registerConfirmExit(_('mediamanagement:multiuploader.reloadMessage'));
    }
    if (!hasActiveUploads && confirmExit) {
      unregisterConfirmExit();
    }
  }, [queue]);

  useEffect(() => {
    if (polling !== undefined) {
      startPolling(polling);
    } else {
      stopPolling();
    }
  }, [polling]);
  useEffect(() => UploadManagerStore.updateUserData(userData), [userData]);
  useEffect(() => UploadManagerStore.updateExternalQueue(transcodingQueue), [transcodingQueue]);

  return (
    <UploadManagerContext.Provider value={UploadManagerStore}>
      {children}
    </UploadManagerContext.Provider>
  );
};

UploadManagerProvider.displayName = 'UploadManagerProvider';
