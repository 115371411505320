/* eslint-disable  react/prop-types*/
import React, { Fragment, memo } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { VIDEOS_CATEGORIES } from '../../../util/env';
import {
  ExplorerNavItem,
  ExplorerNavSpacer,
  MediaMultiUploader,
  ProfilePictureInfobox,
  T,
} from '../../../components';

import { stripSlash } from '../../../util/urlHelper';

import AcademyTooltipAndModal from './AcademyTooltipAndModal';
import VideosList from './View/VideosList';
import ProtectedRoute from '../../../components/Routing/ProtectedRoute';
import View from './View';
import { _ } from '../../../util/translate';
import styled from '@emotion/styled';
import { BREAKPOINT_DESKTOP_CONDITION, BREAKPOINT_SPHONE_CONDITION } from '../../../camtool-styles';
import { MediaTypeEnum, UploadFieldAndUploadQueue } from '../../../packages/UploadManager';
import { useHasRole } from '../../../util/UserData';
import { SecurityRole } from '../../../graphql/VXModels/types';

const URL_ALL = '/all';
const URL_POOL = '/pool';
const URL_SHOP = '/shop';
const URL_FREE = '/free';
const URL_PROFILE = '/profile';
const URL_CONTESTS = '/contest';
const URL_VIDEO_VIEW = '/:collection/:umaId';

const Row = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 100%;
  }
  @media ${BREAKPOINT_SPHONE_CONDITION} {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
  }
`;

const VideoContainer = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex: 1 1 100%;
    width: 100%;
  }
`;

// eslint-disable-next-line react/display-name
const Video = memo(({ match }) => {
  const path = stripSlash(match.url);
  const indexRoute = path + URL_ALL;
  const hasUnusedVideos = false; // @todo get from userData

  return (
    <Fragment>
      <UploadFieldAndUploadQueue mediaType={MediaTypeEnum.video} />

      <Row className="grid__row">
        <div className="grid__column grid__box profile-infobox grid__box--video__infobox">
          <ProfilePictureInfobox
            className="video__infobox"
            text={_('mediamanagement:video.infobox')}
          />
        </div>
      </Row>

      <Row className="grid__row">
        <VideoContainer className="grid__column grid__box video-explorer">
          <div className="grid__box__header video-explorer__header">
            <div className="grid__box__header__title">
              <T _={'mediamanagement:video.title.videos'} />
            </div>
            <AcademyTooltipAndModal />
          </div>

          <Row className="grid__box__row">
            <Row className="grid__box__column mediamanagement__explorer__nav">
              <div className="grid__box__item">
                <Row className="grid__box__item__content mediamanagement__explorer__nav__content">
                  <ExplorerNavItem
                    to={path + URL_ALL}
                    icon="icon-folder-closed"
                    tooltip={_('mediamanagement:video.nav.allVideosToolTipText')}
                  >
                    <T _={'mediamanagement:video.nav.allVideos'} />
                  </ExplorerNavItem>

                  <ExplorerNavItem
                    to={path + URL_POOL}
                    icon="icon-folder-new"
                    iconFlash={hasUnusedVideos}
                    tooltip={_('mediamanagement:video.nav.unusedVideosTooltipText')}
                  >
                    <T _={'mediamanagement:video.nav.unusedVideos'} />
                  </ExplorerNavItem>

                  <ExplorerNavSpacer />

                  <ExplorerNavItem
                    to={path + URL_SHOP}
                    icon="icon-shopping-cart"
                    tooltip={_('mediamanagement:video.nav.shopTooltipText')}
                  >
                    <T _={'mediamanagement:video.nav.shop'} />
                  </ExplorerNavItem>

                  <ExplorerNavItem
                    to={path + URL_FREE}
                    icon="icon-gift"
                    tooltip={_('mediamanagement:video.nav.freeTooltipText')}
                  >
                    <T _={'mediamanagement:video.nav.free'} />
                  </ExplorerNavItem>

                  {VIDEOS_CATEGORIES.includes('profile') && (
                    <ExplorerNavItem
                      to={path + URL_PROFILE}
                      icon="icon-nameplate"
                      tooltip={_('mediamanagement:video.nav.profileTooltipText')}
                    >
                      <T _={'mediamanagement:video.nav.profile'} />
                    </ExplorerNavItem>
                  )}

                  {VIDEOS_CATEGORIES.includes('campaigns') && (
                    <ExplorerNavItem
                      to={path + URL_CONTESTS}
                      icon="icon-fire"
                      tooltip={_('mediamanagement:video.nav.campaignTooltipText')}
                    >
                      <T _={'mediamanagement:video.nav.campaign'} />
                    </ExplorerNavItem>
                  )}
                </Row>
              </div>
            </Row>

            <Switch>
              <Redirect exact from={`${path}/`} to={indexRoute} />
              <ProtectedRoute
                exact
                path={path + URL_ALL}
                component={(props) => <VideosList collection={'all'} {...props} />}
              />
              <ProtectedRoute
                exact
                path={path + URL_POOL}
                component={(props) => <VideosList collection={'pool'} {...props} />}
              />
              <ProtectedRoute
                exact
                path={path + URL_SHOP}
                component={(props) => <VideosList collection={'shop'} {...props} />}
              />
              <ProtectedRoute
                exact
                path={path + URL_FREE}
                component={(props) => <VideosList collection={'free'} {...props} />}
              />
              <ProtectedRoute
                exact
                path={path + URL_PROFILE}
                component={(props) => <VideosList collection={'profile'} {...props} />}
              />
              <ProtectedRoute
                exact
                path={path + URL_CONTESTS}
                component={(props) => <VideosList collection={'contest'} {...props} />}
              />
              <Route path={path + URL_VIDEO_VIEW} component={View} />
              <Redirect to={indexRoute} />
            </Switch>
          </Row>
        </VideoContainer>
      </Row>
    </Fragment>
  );
});

export default Video;
