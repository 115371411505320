import React, { ReactElement } from 'react';
import { WHITE } from '../../../camtool-styles';

const AccountOwnerChange = (): ReactElement => {
  return (
    <g transform="translate(0)">
      <g transform="translate(-3730.8 664.2)">
        <g transform="translate(3735 -654.2)">
          <g>
            <path
              fill={WHITE}
              d="M-0.3,13.6C-0.3,13.6-0.4,13.6-0.3,13.6c-0.1,0-0.1,0-0.2-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1v-1.1
					c0-0.2,0-0.4,0.1-0.5s0.2-0.2,0.4-0.3c1.6-0.9,2.6-1.5,3.2-1.7V8C2.8,7.8,2.7,7.4,2.7,7V5.6c0-0.3,0-0.6,0.1-0.8S3,4.3,3.2,4.2
					C3.3,4,3.6,3.8,3.8,3.7c0.3-0.1,0.6-0.2,0.9-0.2s0.6,0.1,0.9,0.2C5.9,3.8,6.1,4,6.3,4.2c0.2,0.2,0.3,0.4,0.4,0.7
					c0.1,0.2,0.1,0.5,0.1,0.8V7c0,0.5-0.2,0.8-0.5,1v1.7c0.1,0.1,0.3,0.1,0.5,0.2C7,10,7.2,10.1,7.4,10.2s0.4,0.2,0.6,0.3
					c0.2,0.1,0.4,0.2,0.6,0.3s0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.2,0.1c0.2,0.1,0.3,0.2,0.4,0.3
					c0.1,0.1,0.1,0.3,0.1,0.5v1.1c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1H-0.3z"
            />
          </g>
          <g>
            <path
              fill={WHITE}
              d="M14.6,13C14.6,13,14.6,13.1,14.6,13c-0.1,0-0.1,0-0.1-0.1v-1.5h-2.5c0,0,0,0-0.1,0s0,0-0.1-0.1
					c0,0,0,0,0-0.1V9.9c0,0,0-0.1,0-0.1s0.1,0,0.1,0h2.5V8.2c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0.1,0l3,2.4c0,0,0,0.1,0,0.1s0,0.1,0,0.1
					L14.6,13z"
            />
          </g>
          <g>
            <path
              fill={WHITE}
              d="M19.8,13.6C19.8,13.6,19.8,13.6,19.8,13.6c-0.1,0-0.1,0-0.2-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1v-1.1
					c0-0.2,0-0.4,0.1-0.5s0.2-0.2,0.4-0.3c1.6-0.9,2.6-1.5,3.2-1.7V8c-0.3-0.2-0.5-0.6-0.5-1V5.6c0-0.3,0-0.6,0.1-0.8
					s0.2-0.5,0.4-0.7C23.5,4,23.7,3.8,24,3.7c0.3-0.1,0.6-0.2,0.9-0.2s0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.3,0.7,0.4
					c0.2,0.2,0.3,0.4,0.4,0.7C26.9,5.1,27,5.3,27,5.6V7c0,0.5-0.2,0.8-0.5,1v1.7c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.4,0.2,0.6,0.3
					s0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.3s0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.2,0.1
					c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.1c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1H19.8z"
            />
          </g>
        </g>
      </g>
    </g>
  );
};

export default AccountOwnerChange;
