import React, { FC } from 'react';
import {
  AffiliateStatsItem,
  GoToVXCashItem,
  InviteModelsItem,
  InviteUsersItem,
} from '../items/Affiliate';

const AffiliateFragment: FC = () => (
  <>
    <InviteUsersItem />
    <InviteModelsItem />
    <AffiliateStatsItem />
    <GoToVXCashItem />
  </>
);

export default AffiliateFragment;
