import React, { FC } from 'react';
import { SectionLabel, StyledDiv, StyledSection } from '../../../styles';
import { Translation } from '../../../../../../components';
import { ErrorMessage, Field } from 'formik';
import FieldActionButton from '../../../../../../molecules/Formik/Fields/FieldActionButton';
import { ActionEnum } from '../../../../types';
import { RED } from '../../../../../../camtool-styles';

const FieldSendTestEmail: FC = () => {
  return (
    <StyledSection>
      <div css={{ flexDirection: 'column', flex: '0 1 auto' }}>
        <div css={{ justifyContent: 'space-between' }}>
          <SectionLabel>
            <Translation t={'mailings:email.form.testAnEmail'} />
          </SectionLabel>
        </div>

        <div css={{ flexDirection: 'column' }}>
          <StyledDiv
            css={{
              borderRadius: 4,
              border: '1px solid #c0c0c0',
              flex: 1,
              padding: 0,
            }}
          >
            <Field
              type="text"
              name="recipient"
              placeholder="model@modelpage.com"
              css={{ margin: 8, flex: 1 }}
            />

            <FieldActionButton
              name={'action'}
              action={ActionEnum.TEST}
              theme={'gray'}
              css={{ textTransform: 'none', marginLeft: 8, marginRight: 0 }}
              label={'Testmail senden'}
            />
          </StyledDiv>
          <ErrorMessage name="recipient" component="span" css={{ color: RED }} />
        </div>
      </div>
    </StyledSection>
  );
};

export default FieldSendTestEmail;
