import React from 'react';
import PropTypes from 'prop-types';

import Modalbox from '../../../components/Modalbox/Modalbox';
import Markdown from '../../../components/Markdown/Markdown';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import Button from '../../../components/Button/Button';
import { useAppState } from '../../../util/AppState';
import { ApiLang } from '../../../graphql/VXModels/types';
import { _ } from '../../../util/translate';

const ModalDescription = props => {
  const [appState] = useAppState();

  let tutorialImage;
  if (props.type === 'video') {
    tutorialImage =
      appState.lang === ApiLang.de
        ? require('./__assets__/tutorial_videos_campaign.png')
        : require('./__assets__/tutorial_videos_campaign_en.png');
  } else {
    tutorialImage =
      appState.lang === ApiLang.de
        ? require('./__assets__/tutorial_photos_campaign.png')
        : require('./__assets__/tutorial_photos_campaign_en.png');
  }

  return (
    <Modalbox name={props.name} state={props.state} onKeyPressESC={props.onClose}>
      <div className="modalgrid__box">
        <div className="modalgrid__box__header">
          <div className="icon-money" />
          <div>{props.title}</div>
          <div className="seperator" />
          <div className="icon-remove options" onClick={props.onClose} />
        </div>

        <div className="modalgrid__box__content">
          <div className="modalgrid__box__content__column">
            <Markdown className="marketing-campaign__item__text" source={props.description} />

            <img src={tutorialImage} alt="" />

            <ButtonGroup position="right">
              <Button
                className="button--blue button--max-width"
                label={_('marketing:campaigns.startnow')}
                onClick={props.onClickStartNow}
                type="button"
              />
            </ButtonGroup>
          </div>
        </div>
      </div>
    </Modalbox>
  );
};

ModalDescription.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickStartNow: PropTypes.func.isRequired,
};

ModalDescription.defaultProps = {};

export default ModalDescription;
