import { useMemo } from 'react';
import { getTimeframes } from './timeframes';
import { Timeframes } from '../types';

const useTimeframes = (customMonthStart: Date | undefined): Timeframes => {
  const currentDate = new Date();
  const currentDayStart = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  return useMemo(() => getTimeframes(currentDayStart, customMonthStart), [
    currentDayStart,
    customMonthStart,
  ]);
};

export default useTimeframes;
