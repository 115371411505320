import React, { FC } from 'react';
import styled from '@emotion/styled';
import { _ } from '../../../util/translate';
import { vxLiveLogo } from '../../../atoms/Icon/libraries/svg';
import { Box, Grid, Typography } from '@material-ui/core';
import NewButton from '../../../atoms/Button/NewButton';
import vxLiveScreen from '../../GetOnline/img/vxliveScreenSmall.png';
import { GRAY_2 } from '../../../camtool-styles';
import { NEW_MESSENGER_URL } from '../../../config';
import { makeStyles } from '@material-ui/styles';
import { useUserData } from '../../../util/UserData';

interface Props {
  loading: boolean;
  className?: string;
  imageSize?: 'sm' | 'md';
}

const Container = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;
const chatOnlineStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
  },
});
const ChatOnline: FC<Props> = ({ loading, className, imageSize }) => {
  const classes = chatOnlineStyles();
  const userData = useUserData();
  const isVerified = userData?.model?.account.isVerified;

  return (
    <Container>
      <Grid
        container
        direction={'column'}
        justify={'center'}
        alignItems={'center'}
        style={{ minHeight: '308px' }}
      >
        <Grid container item xs={12} direction={'row'} justify={'center'} alignItems={'center'}>
          <Grid item xs={12} md={6}>
            <Box display={'flex'} justifyContent={'center'}>
              <Box>
                <span css={{ marginTop: '10px', color: GRAY_2, fontSize: 12, textAlign: 'center' }}>
                  {_('dashboard:app.chatOnline.text')}
                </span>
                <Box textAlign={'center'}>{vxLiveLogo && <img src={vxLiveLogo} alt="" />}</Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display={'flex'} justifyContent={'center'}>
              <Box style={{ minWidth: '0' }}>
                <img style={{ width: '100%', height: 'auto' }} src={vxLiveScreen} alt="" />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} direction={'row'} justify={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <Box className={classes.buttonContainer}>
              <NewButton
                color="secondary"
                disabled={!isVerified}
                onClick={(): string => (window.location.href = NEW_MESSENGER_URL)}
              >
                <span>{_('dashboard:app.chatOnline.buttonText')}</span>
              </NewButton>
              {!isVerified && (
                <Typography align="center">{_('common:text.notVerified')}</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChatOnline;
