import React, { FC } from 'react';
import styled from '@emotion/styled';
import { TableCell, TableRow } from '../../../atoms/Table';
import { ITableColumn } from '../../../atoms/Table/types';
import { ICreditsUsageDisplayData } from './types';

const StyledTableRow = styled(TableRow)`
  flex-basis: 51px;
`;

const ColoredDot = styled.div`
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: ${props => props.color};
`;

const LabelSpan = styled.span`
  align-items: center;
`;

interface IProps {
  creditsUsageDisplayData: ICreditsUsageDisplayData;
  columns: ITableColumn[];
}

const Row: FC<IProps> = ({ creditsUsageDisplayData: { label, amountString, color }, columns }) => (
  <StyledTableRow>
    <TableCell columns={columns} index={0}>
      <LabelSpan>
        <ColoredDot color={color} />
        {label}
      </LabelSpan>
    </TableCell>
    <TableCell columns={columns} index={1}>
      {amountString}
    </TableCell>
  </StyledTableRow>
);

export default Row;
