import React, { FC } from 'react';
import { GREEN, RED } from '../../../../camtool-styles';

interface IProps {
  label?: string;
  check: boolean;
}

export const CheckMarkLabel: FC<IProps> = ({ label, check }: IProps) => (
  <span>
    <span className={`icon-${check ? 'ok' : 'remove'}`} css={{ color: check ? GREEN : RED }} />
    &nbsp;{!!label && label}
  </span>
);
