import { Brands } from './components';

export const NEW_MESSENGER_URL = 'https://www.vxlive.net';
export const NEW_MESSENGER_WINDOW_NAME = 'VX_MODELS_LIVE_CHAT';
export const MOMENTUM_URL = 'https://momentumchat.app/';
export const MOMENTUM_WINDOW_NAME = 'VX_MODELS_MOMENTUM_CHAT';
export const MESSENGER_URL = 'https://www.visit-x.net/vxsoftware'; // previously https://www.vxlive.net/
export const SSW_URL = 'https://www.visit-x.net/downloads/VXTools/VX-Software.Latest.Setup.exe';
export const CLASSICVXLIVE = 'https://classic.vxlive.net';
export const VXSPLITTER =
  'https://www.visit-x.net/downloads/VXTools/VISIT-X-Video-Splitter-Latest.exe';
export const VXSPLITTER_HELPCENTER_VXMODELS =
  'https://app.vxmodels.com/camtool/help/helpcenter/categories/360002286979/articles/360018440740';
export const VXSPLITTER_HELPCENTER_VXPAGES =
  'https://modelcenter.vxpages.com/camtool/help/helpcenter/categories/360001679039/articles/360018765900';

export const ZENDESK_CHAT_TOKEN = '75f2abd4-6013-4811-a010-38a64bd21d3d';
export const ZENDESK_CHAT_DEPARTMENTS = [Brands.MODELSERVICE, Brands.PAYMENT];
export const ZENDESK_CHAT_DENY_ROUTES = [
  '/camtool/telegram/chat/mobile',
  '/camtool/telegram/abos/active/modalabo',
  '/camtool/telegram/chat/desktop',
];

export const MEDIA_PICTURE_UPLOAD_MIN_WIDTH = 520;
export const MEDIA_PICTURE_UPLOAD_MIN_HEIGHT = 520;
export const COMMUNIPAY_MERCHANT = '222';
