import axios, { AxiosResponse } from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { log } from '../../../../util/errorHandling';
import {
  Action,
  ActionEnum,
  ErrorMessageEnum,
  FileId,
  MediaTypeEnum,
  QueueItem,
  StateEnum,
} from '../../types';
import { UPLOAD_CANCELED } from '../../util/constants';
import { parsePictureErrorcode, parseVideoException } from '../../util/errorHandling';
import { getPictureUploadUrl, getVideoUploadUrl } from '../../util/genericUpload';
import {
  addExternalQueueItem,
  dispatch,
  getCurrentUpload,
  getFileWrapper,
  getNextUploadQueueItem,
  getQueCount,
  getUserData,
  updateCurrentUpload,
  updateQueueItem,
} from '../UploadManagerStore';

type ResponseHandler = (res: AxiosResponse<any>) => {
  successful: boolean;
  errorMessage: ErrorMessageEnum | undefined;
};

type UploadOptions = {
  url: string;
  formData: FormData;
  responseHandler: ResponseHandler;
  nextState: StateEnum;
};

// This sets the mock adapter on the default instance
const mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });
mock.onPost('/upload').reply(function (config) {
  return new Promise(function (resolve) {
    let i = 0;
    const iv = setInterval(() => {
      const { onUploadProgress } = config;
      if (onUploadProgress) {
        onUploadProgress({ lengthComputable: true, total: 100, loaded: i });
      }
      if (i >= 100) {
        clearInterval(iv);
        if (Math.random() > 0.1) {
          resolve([200, { id: 4, name: 'foo' }]);
        } else {
          // reject() reason will be passed as-is.
          // Use HTTP error status code to simulate server failure.
          resolve([500, { success: false }]);
        }
      }
      i = i + 5;
    }, 500);
  });
});

const getPictureUploadOptions = async (id: FileId): Promise<UploadOptions> => {
  // https://upload.cp1.campoints.net/picture?sid=017826400632829950223699&type=14&uma_id=1243915
  // REQUEST:
  //   type: 14
  //   umaId: 1243915
  //   file: (binary)
  const type = 14; // fixed value, pool
  const { picturePoolUmaId } = getUserData();
  const fileWrapper = getFileWrapper(id);

  if (!picturePoolUmaId) {
    throw new Error('picture pool umaId not set');
  }
  if (!fileWrapper) {
    throw new Error('invalid file wrapper with id: ' + id);
  }

  const formData = new FormData();
  formData.append('type', type.toString());
  formData.append('umaId', picturePoolUmaId.toString());
  formData.append('file', fileWrapper.file);

  const url = await getPictureUploadUrl(picturePoolUmaId, type, 0);
  return {
    url,
    formData,
    responseHandler: ({ data }: AxiosResponse) => ({
      successful: /id=(\d+)/.test(data),
      errorMessage: parsePictureErrorcode(data),
    }),
    nextState: StateEnum.finished,
  };
};

const getVideoUploadOptions = async (id: FileId): Promise<UploadOptions> => {
  const umaSubtype = 6; // fixed value, pool
  const fileWrapper = getFileWrapper(id);
  const { md5hash, verified, mediaType } = fileWrapper;

  if (!fileWrapper) {
    throw new Error('invalid file wrapper with id: ' + id);
  }

  const { file: file } = fileWrapper;
  const formData = new FormData();
  formData.append('uma_subtype', umaSubtype.toString());
  formData.append('uma_title', file.name);
  formData.append('file', file);

  const url = await getVideoUploadUrl(umaSubtype);
  return {
    url: url,
    formData,
    responseHandler: ({ data }: AxiosResponse) => {
      const successful = data.success === true;
      if (successful) {
        addExternalQueueItem({
          id,
          displayName: file.name,
          state: StateEnum.transcoding,
          md5hash,
          progress: 0,
          mediaType,
          verified,
          fileId: id,
        });
      }
      return { successful, errorMessage: parseVideoException(data) };
    },
    nextState: StateEnum.finished,
  };
};

const getUploadOptions = async ({ id, mediaType }: QueueItem): Promise<UploadOptions> => {
  switch (mediaType) {
    case MediaTypeEnum.picture:
      return await getPictureUploadOptions(id);
    case MediaTypeEnum.video:
      return await getVideoUploadOptions(id);
    default:
      throw new Error('Invalid media type: ' + mediaType);
  }
};

let itemCount = 1;
export const handleUpload = async ({ type }: Action) => {
  if ([ActionEnum.verified, ActionEnum.uploadFinished, ActionEnum.uploadFailed].includes(type)) {
    if (getCurrentUpload() === null) {
      const nextUploadQueueItem = getNextUploadQueueItem();
      if (nextUploadQueueItem) {
        const { id } = nextUploadQueueItem;
        const cancelTokenSource = axios.CancelToken.source();

        updateCurrentUpload(id);
        updateQueueItem({ id, state: StateEnum.uploading, cancelTokenSource });
        if (itemCount <= getQueCount()) {
          const { url, formData, responseHandler, nextState } = await getUploadOptions(
            nextUploadQueueItem
          );

          axios
            .post(url, formData, {
              cancelToken: cancelTokenSource.token,
              onUploadProgress: (progressEvent: ProgressEvent) =>
                updateQueueItem({
                  id,
                  progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
                }),
            })
            .then((res) => {
              const { successful, errorMessage } = responseHandler(res);
              const queueItem = {
                ...nextUploadQueueItem,
                progress: 100,
                state: successful ? nextState : StateEnum.error,
                errorMessage,
                cancelTokenSource: undefined,
              } as QueueItem;

              updateCurrentUpload(null);
              updateQueueItem(queueItem);
              dispatch(ActionEnum.uploadFinished, { queueItem });
            })
            .catch((err) => {
              const { message } = err;
              log('error', `Store handler error: ${JSON.stringify(err)}`, {
                context: 'UploadManager',
              });
              // handle manual cancel upload
              const canceled = message === UPLOAD_CANCELED;
              const queueItem = {
                ...nextUploadQueueItem,
                state: canceled ? StateEnum.canceled : StateEnum.error,
                cancelTokenSource: undefined,
                errorMessage: canceled ? undefined : { de: message, en: message },
              };

              updateCurrentUpload(null);
              updateQueueItem(queueItem);
              dispatch(ActionEnum.uploadFailed, { queueItem });
            });
        }
        itemCount++;
      }
    }
  }
};
