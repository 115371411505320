/* eslint-disable  react/prop-types*/
import React, { Fragment, memo, useState } from 'react';
import { Redirect, Switch } from 'react-router';

import { PICTURES_CATEGORIES } from '../../../util/env';
import {
  MediaMultiUploader,
  T,
  ExplorerNavItem,
  ExplorerNavSpacer,
  ProtectedRoute,
} from '../../../components';
import { stripSlash } from '../../../util/urlHelper';
import { BREAKPOINT_DESKTOP_CONDITION } from '../../../camtool-styles';

import View from './View';
import PicturesPool from './View/PicturesPool';
import AlbumOverview from './View/AlbumOverview';
import AlbumsProfiles from './View/AlbumsProfiles';
import AlbumCreate from './View/AlbumCreate';
import { _ } from '../../../util/translate';

import styled from '@emotion/styled';
import { UploadFieldAndUploadQueue, MediaTypeEnum } from '../../../packages/UploadManager';
import { useHasRole } from '../../../util/UserData';
import { SecurityRole } from '../../../graphql/VXModels/types';

const URL_POOL = '/pool';
const URL_CREATE = '/create';
const URL_SHOP = '/shop';
const URL_FREE = '/free';
const URL_PROFILE = '/profile';
const URL_CAMPAIGN = '/campaign';
const URL_ALBUM = '/album/:umaId';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Col = styled.div`
  display: flex;
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex: 0 0 100%;
  }
`;

const ContentContainer = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

// eslint-disable-next-line react/display-name
const Picture = memo(({ match }) => {
  const [currentAlbumType, setCurrentAlbumType] = useState('create');

  const path = stripSlash(match.url);
  const shouldBeActive = (match, location, albumTypeLink) => !!match || albumTypeLink === currentAlbumType; // prettier-ignore

  return (
    <Fragment>
      <UploadFieldAndUploadQueue mediaType={MediaTypeEnum.picture} />

      <div className="grid__row">
        <div className="grid__column grid__box mediamanagement__pic__explorer">
          <div className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'mediamanagement:picfolder.title.pictures'} />
            </div>
          </div>

          <Row className="grid__box__row">
            <Col className="grid__box__column mediamanagement__explorer__nav">
              <div className="grid__box__item">
                <ContentContainer className="grid__box__item__content mediamanagement__explorer__nav__content">
                  <ExplorerNavItem
                    to={path + URL_POOL}
                    icon="icon-folder-closed"
                    tooltip={_('mediamanagement:picfolder.nav.unusedPicturesTooltipText')}
                  >
                    {<T _={'mediamanagement:picfolder.nav.unusedPictures'} />}
                  </ExplorerNavItem>

                  <ExplorerNavSpacer />

                  <ExplorerNavItem
                    to={path + URL_CREATE}
                    icon="icon-folder-plus"
                    tooltip={_('mediamanagement:picfolder.nav.createNewAlbumTooltipText')}
                  >
                    {<T _={'mediamanagement:picfolder.nav.createNewAlbum'} />}
                  </ExplorerNavItem>

                  <ExplorerNavSpacer />

                  <ExplorerNavItem
                    to={path + URL_SHOP}
                    icon="icon-shopping-cart"
                    tooltip={_('mediamanagement:picfolder.nav.shopTooltipText')}
                    isActive={(match, location) => shouldBeActive(match, location, 'shop')}
                  >
                    {<T _={'mediamanagement:picfolder.nav.shop'} />}
                  </ExplorerNavItem>

                  <ExplorerNavItem
                    to={path + URL_FREE}
                    icon="icon-gift"
                    tooltip={_('mediamanagement:picfolder.nav.freeTooltipText')}
                    isActive={(match, location) => shouldBeActive(match, location, 'free')}
                  >
                    {<T _={'mediamanagement:picfolder.nav.free'} />}
                  </ExplorerNavItem>

                  {PICTURES_CATEGORIES.includes('profile') && (
                    <ExplorerNavItem
                      to={path + URL_PROFILE}
                      icon="icon-nameplate"
                      tooltip={_('mediamanagement:picfolder.nav.profileTooltipText')}
                      isActive={(match, location) => shouldBeActive(match, location, 'profile')}
                    >
                      {<T _={'mediamanagement:picfolder.nav.profile'} />}
                    </ExplorerNavItem>
                  )}

                  {PICTURES_CATEGORIES.includes('campaigns') && (
                    <ExplorerNavItem
                      to={path + URL_CAMPAIGN}
                      icon="icon-fire"
                      tooltip={_('mediamanagement:picfolder.nav.campaignTooltipText')}
                      isActive={(match, location) => shouldBeActive(match, location, 'campaign')}
                    >
                      {<T _={'mediamanagement:picfolder.nav.campaign'} />}
                    </ExplorerNavItem>
                  )}
                </ContentContainer>
              </div>
            </Col>

            <Col className="grid__box__column spinner-container">
              <Switch>
                <ProtectedRoute
                  exact
                  path={path + URL_POOL}
                  component={(props) => (
                    <PicturesPool setCurrentAlbumType={setCurrentAlbumType} {...props} />
                  )}
                />

                <ProtectedRoute
                  exact
                  path={path + URL_CREATE}
                  component={(props) => (
                    <AlbumCreate setCurrentAlbumType={setCurrentAlbumType} {...props} />
                  )}
                />

                <ProtectedRoute
                  exact
                  path={path + URL_SHOP}
                  component={(props) => (
                    <AlbumOverview
                      setCurrentAlbumType={setCurrentAlbumType}
                      albumType="shop"
                      {...props}
                    />
                  )}
                />

                <ProtectedRoute
                  exact
                  path={path + URL_FREE}
                  component={(props) => (
                    <AlbumOverview
                      setCurrentAlbumType={setCurrentAlbumType}
                      albumType="free"
                      {...props}
                    />
                  )}
                />

                <ProtectedRoute
                  exact
                  path={path + URL_CAMPAIGN}
                  component={(props) => (
                    <AlbumOverview
                      setCurrentAlbumType={setCurrentAlbumType}
                      albumType="campaign"
                      {...props}
                    />
                  )}
                />

                <ProtectedRoute
                  exact
                  path={path + URL_PROFILE}
                  component={(props) => (
                    <AlbumsProfiles setCurrentAlbumType={setCurrentAlbumType} {...props} />
                  )}
                />

                <ProtectedRoute
                  path={path + URL_ALBUM}
                  component={(props) => (
                    <View setCurrentAlbumType={setCurrentAlbumType} {...props} />
                  )}
                />

                <Redirect from={path} to={path + URL_POOL} />
              </Switch>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
});

export default Picture;
