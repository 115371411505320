import React, { FC } from 'react';
import { ButtonLink } from '../../../../../atoms';
import { _ } from '../../../../../util/translate';
import { getPhoneSexArticleUrl } from '../../../../../util/helpcenterLinks';

const BA6SettingsHeader: FC = () => {
  const scopedPhoneSexUrl = getPhoneSexArticleUrl();
  return (
    <header css={{ justifyContent: 'center', marginBottom: '24px' }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '5px',
        }}
      >
        <span css={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '7px' }}>
          {_('service0900:PhoneSex.active.profile.title')}
        </span>
        <span css={{ fontSize: '16px', marginBottom: '7px' }}>
          {_('service0900:PhoneSex.active.profile.headerText')}
        </span>
        <ButtonLink
          to={scopedPhoneSexUrl}
          theme="link-blue"
          css={{
            fontSize: 12,
            textDecoration: 'none',
            fontWeight: 'normal',
            justifyContent: 'center',
          }}
        >
          <span css={{ fontSize: '14px' }}>{_('service0900:PhoneSex.active.profile.tips')}</span>
          <span css={{ marginLeft: 8 }} className="icon-arrow-right" />
        </ButtonLink>
      </div>
    </header>
  );
};

export default BA6SettingsHeader;
