import { QueryResult } from '@apollo/react-common';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_HOOK_AUTH_DEEPLINK_BETA_FEED } from '../graphql/VXModels/queries';
import { Query, VXModelsMobileTargetEnum } from '../graphql/VXModels/types';

const useDeepLinkBeta = (
  target: VXModelsMobileTargetEnum
): Pick<QueryResult<Query>, 'data' | 'loading' | 'error' | 'refetch'> => {
  const { data, loading, error, refetch } = useQuery(QUERY_HOOK_AUTH_DEEPLINK_BETA_FEED, {
    variables: { target: target },
    fetchPolicy: 'network-only',
  });

  return { data, loading, error, refetch };
};

export default useDeepLinkBeta;
