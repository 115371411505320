/* eslint-disable react/no-string-refs */

import React from 'react';
import Formsy from 'formsy-react';
import cloneDeep from 'lodash/cloneDeep';

import {
  Button,
  ButtonGroup,
  Checkbox,
  Footer,
  GroupRow,
  Markdown,
  Separator,
  Spinner,
  T,
} from '../../components';

import { SettingsStore } from '../../stores/Settings/SettingsStore';
import { SettingsActionCreators } from '../../stores/Settings/SettingsActionCreators';
import { AlertsStore } from '../../stores/Alerts/AlertsStore';
import { _ } from '../../util/translate';
import Navigation from './Navigation';

let STORE = 'notifications';

function getStateFromStores() {
  return {
    storeData: SettingsStore.get(STORE),
    hasChanges: false,
  };
}

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = getStateFromStores();
    this.loadingDone = false;
  }

  componentDidMount() {
    SettingsStore.addChangeListener(STORE, this.onStoreChange);
    SettingsActionCreators.getNotificationSettings();
  }

  componentWillUnmount() {
    SettingsStore.removeChangeListener(STORE, this.onStoreChange);
  }

  onStoreChange = () => {
    this.loadingDone = true;
    this.setState(cloneDeep(getStateFromStores()));

    // ALERTS
    if (this.state.storeData.savingStatus === 'ok') {
      AlertsStore.add({ type: 'info', message: _('common:alerts.saveSuccess') });
    } else if (this.state.storeData.savingStatus === 'fail') {
      AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
    }
  };

  submitForm = (model) => {
    SettingsActionCreators.saveNotificationSettings(model);
  };

  onFormChange = (model, hasChanges) => {
    this.setState({ hasChanges });
  };

  resetForm = () => {
    this.refs.notificationSettingsForm.reset(
      this.refs.notificationSettingsForm.getPristineValues()
    );
  };

  render() {
    return (
      <main id="settings-notification" className="grid marketing settings-notification">
        <Navigation />

        <div className="grid__row">
          <div className="grid__column grid__box h--pos-rel min-width--0">
            <div className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'settings:notifications.pageTitle.label'} />
              </div>
            </div>

            <div className="grid__box__item">
              <div className="grid__box__item__content">
                <div className="warning-text">
                  <div className="warning-text__column">
                    <div className="title">
                      <T _={'settings:notifications.info.header'} />
                    </div>
                    <Markdown className="subtitle" source={_('settings:notifications.info.body')} />
                  </div>
                </div>
              </div>
            </div>

            <Formsy.Form
              className="grid__box__item spinner-container"
              ref="notificationSettingsForm"
              onChange={this.onFormChange}
              onValidSubmit={this.submitForm}
            >
              {!this.loadingDone && <Spinner />}

              <div className="grid__box__item__content settings-videochat__formwidth">
                <GroupRow tooltip="none">
                  <Checkbox
                    name="isNewsletterAllowed"
                    label={_('settings:notifications.newsletter.label')}
                    value={this.state.storeData.isNewsletterAllowed}
                  />
                </GroupRow>

                <GroupRow tooltip="none">
                  <Separator className="transparent" />
                </GroupRow>

                <ButtonGroup position="right">
                  <Button
                    label={_('common:button.cancel')}
                    type="button"
                    onClick={this.resetForm}
                    disabled={!this.state.hasChanges}
                  />
                  <Button label={_('common:button.save')} />
                </ButtonGroup>
              </div>
            </Formsy.Form>
          </div>
        </div>
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default Settings;
