import React, { Fragment, FunctionComponent } from 'react';
import {
  MediaAlbum,
  MediaPicture,
  SecurityRole,
  VXGChallengeTypeEnum,
  VXGChallengeUserStatusEnum,
} from '../../../graphql/VXModels/types';
import { Box, Container, Grid } from '@material-ui/core';
import { ChatHoldingTimes, ChatRatings, Finance, OnlineTimeThisMonth } from '../WidgetsSmall';
import TelegramTile from '../Telegram/TelegramTile';
import { useHasRole, UserData } from '../../../util/UserData';
import { TopAmateur } from '../WidgetsLarge';
import Calendar from '../Calendar/Calendar';
import { ProfileStatus, WelcomeBack, MessengerStat } from '../WidgetsMedium';
import SalesStatistics from '../SalesStatistics/SalesStatistics';
import OnlineTimeAndUsers from '../OnlineTimeAndUsers/OnlineTimeAndUsers';
import IncomeSources from '../IncomeSources/IncomeSources';
import VXCashStatistics from '../VXCashStatistics/VXCashStatistics';
import { _ } from '../../../util/translate';
import { Spinner } from '../../../components';
import GraphIcon from '../../../atoms/Icon/VXCash/GraphIcon';
import GridTileItem from '../../../atoms/DashboardTile/GridTileItem';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import styled from '@emotion/styled';
import { useCanUseTelegram } from '../../../packages/Telegram';
import { VXGamesBanner } from '../WidgetsXL';
import NewVXMSwitch from '../Notifications/NewVXMSwitch';

interface OwnProps {
  loading: boolean;
  dashboard: never;
  userPrivileged: boolean;
  wasOnlineWithSSW: boolean;
  isOnlineWithSSW: boolean;
  picture: never | MediaPicture;
  album: never | MediaAlbum;
  commonProfileFillingPercentage: number;
  userData: UserData;
  hasTopAmateurSlots: number | boolean;
  tvshows: never;
  topAmateur: never;
  vxChallenge: {
    type: VXGChallengeTypeEnum;
    targetValue: number;
    userStatistic: {
      status: VXGChallengeUserStatusEnum;
      actualValue: number;
      isTocAccepted: boolean;
    };
    texts: { title: string; unit: string };
  };
}

const DashboardSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = OwnProps;

const VXModelsDashboard: FunctionComponent<Props> = ({
  loading,
  dashboard,
  userPrivileged,
  wasOnlineWithSSW,
  commonProfileFillingPercentage,
  hasTopAmateurSlots,
  tvshows,
  topAmateur,
  vxChallenge,
}: OwnProps) => {
  const canUseTelegram = useCanUseTelegram();
  const challengeActive = !!vxChallenge;
  const isTocAccepted = vxChallenge?.userStatistic.isTocAccepted;
  const showMessengerStat = userPrivileged;
  const tvshow = tvshows?.enabled;
  const isTopModel = hasTopAmateurSlots;

  const dashboardSpinner = (size: string) => {
    return (
      <DashboardSpinner>
        <Spinner inline={true} size={size} />
      </DashboardSpinner>
    );
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2} justify="space-between" alignItems="stretch">
        {isTocAccepted && challengeActive && (
          <GridTileItem
            xs={12}
            noHeader={true}
            noSmallCardLayout={true}
            content={loading ? dashboardSpinner('s') : <VXGamesBanner vxChallenge={vxChallenge} />}
          />
        )}
        <GridTileItem
          xs={12}
          md={6}
          lg={3}
          content={
            loading ? (
              dashboardSpinner('s')
            ) : (
              <ChatRatings loading={loading} chatRating={dashboard.chatRating} />
            )
          }
          noHeader={true}
        />
        <GridTileItem
          xs={12}
          md={6}
          lg={3}
          content={
            loading ? (
              dashboardSpinner('s')
            ) : (
              <ChatHoldingTimes loading={loading} chatHoldingTime={dashboard.chatHoldingTime} />
            )
          }
          noHeader={true}
        />
        <GridTileItem
          xs={12}
          md={6}
          lg={3}
          content={
            loading ? (
              dashboardSpinner('s')
            ) : (
              <OnlineTimeThisMonth
                loading={loading}
                monthOnlineTime={dashboard.onlineTimeCurrentMonth}
              />
            )
          }
          noHeader={true}
        />
        <GridTileItem
          xs={12}
          md={6}
          lg={3}
          content={
            loading ? (
              dashboardSpinner('s')
            ) : (
              <Finance
                loading={loading}
                amount={userPrivileged && dashboard.payableAmount}
                error={userPrivileged ? null : _('common:forbidden.text')}
              />
            )
          }
          noHeader={true}
        />
        {canUseTelegram && (
          <GridTileItem
            xl={isTopModel ? 3 : 6}
            lg={6}
            md={6}
            xs={12}
            content={loading ? dashboardSpinner('m') : <TelegramTile loading={loading} />}
            title={loading ? '' : _('dashboard:app.telegram.title')}
          />
        )}
        <Fragment>
          {loading && (
            <Fragment>
              <GridTileItem xl={6} lg={6} md={6} xs={12} content={dashboardSpinner('m')} />
              {!isTopModel && !tvshow ? (
                <></>
              ) : (
                <GridTileItem
                  xl={canUseTelegram ? 3 : 6}
                  md={6}
                  xs={12}
                  content={dashboardSpinner('m')}
                />
              )}
            </Fragment>
          )}

          {wasOnlineWithSSW && (
            <Fragment>
              {isTopModel && tvshow && (
                <Fragment>
                  <GridTileItem
                    xl={canUseTelegram ? 3 : 6}
                    md={6}
                    xs={12}
                    icon={<RecentActorsIcon fontSize="large" />}
                    title={loading ? '' : _('dashboard:app.topAmateur.title')}
                    content={
                      loading ? (
                        <DashboardSpinner>
                          <Box>TopAmateur slots und show</Box>
                          <Spinner inline={true} size={'m'} />
                        </DashboardSpinner>
                      ) : (
                        <TopAmateur data={topAmateur} />
                      )
                    }
                  />

                  <GridTileItem
                    xl={canUseTelegram ? 3 : 6}
                    md={6}
                    xs={12}
                    icon={<span className="icon-calendar" />}
                    title={loading ? '' : _('dashboard:app.calendar.header')}
                    content={
                      loading ? (
                        <DashboardSpinner>
                          <Box>Calendar slots und show</Box>
                          <Spinner inline={true} size={'m'} />
                        </DashboardSpinner>
                      ) : (
                        <Calendar loading={loading} {...tvshows} />
                      )
                    }
                  />
                </Fragment>
              )}

              {isTopModel && !tvshow && (
                <Fragment>
                  {showMessengerStat ? (
                    <GridTileItem
                      xl={canUseTelegram ? 3 : 6}
                      md={6}
                      xs={12}
                      title={loading ? '' : _('dashboard:app.messengerStat.header')}
                      content={
                        loading ? dashboardSpinner('m') : <MessengerStat loading={loading} />
                      }
                    />
                  ) : (
                    <GridTileItem
                      xl={canUseTelegram ? 3 : 6}
                      md={6}
                      xs={12}
                      title={loading ? '' : _('dashboard:app.welcomeBack.header')}
                      content={loading ? dashboardSpinner('m') : <WelcomeBack loading={loading} />}
                    />
                  )}
                  <GridTileItem
                    xl={canUseTelegram ? 3 : 6}
                    md={6}
                    xs={12}
                    icon={<RecentActorsIcon fontSize="large" />}
                    title={loading ? '' : _('dashboard:app.topAmateur.title')}
                    content={loading ? dashboardSpinner('m') : <TopAmateur data={topAmateur} />}
                  />
                </Fragment>
              )}
              {!isTopModel && tvshow && (
                <Fragment>
                  {showMessengerStat ? (
                    <GridTileItem
                      xl={canUseTelegram ? 3 : 6}
                      md={6}
                      xs={12}
                      title={loading ? '' : _('dashboard:app.messengerStat.header')}
                      content={
                        loading ? dashboardSpinner('m') : <MessengerStat loading={loading} />
                      }
                    />
                  ) : (
                    <GridTileItem
                      xl={canUseTelegram ? 3 : 6}
                      md={6}
                      xs={12}
                      title={loading ? '' : _('dashboard:app.welcomeBack.header')}
                      content={loading ? dashboardSpinner('m') : <WelcomeBack loading={loading} />}
                    />
                  )}
                  <GridTileItem
                    xl={
                      commonProfileFillingPercentage === 100
                        ? canUseTelegram
                          ? 3
                          : 6
                        : canUseTelegram
                        ? 3
                        : 6
                    }
                    md={6}
                    xs={12}
                    centerItems={true}
                    icon={<span className="icon-calendar" />}
                    title={loading ? '' : _('dashboard:app.calendar.header')}
                    content={
                      loading ? dashboardSpinner('m') : <Calendar loading={loading} {...tvshows} />
                    }
                  />
                </Fragment>
              )}

              {!isTopModel && !tvshow && (
                <Fragment>
                  {showMessengerStat ? (
                    <GridTileItem
                      md={6}
                      xs={12}
                      title={loading ? '' : _('dashboard:app.messengerStat.header')}
                      content={
                        loading ? dashboardSpinner('m') : <MessengerStat loading={loading} />
                      }
                    />
                  ) : (
                    <GridTileItem
                      md={6}
                      xs={12}
                      title={loading ? '' : _('dashboard:app.welcomeBack.header')}
                      content={loading ? dashboardSpinner('m') : <WelcomeBack loading={loading} />}
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
        {commonProfileFillingPercentage === 100 ? (
          <GridTileItem
            md={6}
            xs={12}
            noCardLayout={true}
            content={loading ? dashboardSpinner('m') : <NewVXMSwitch />}
          />
        ) : (
          <GridTileItem
            xl={(() => {
              if (canUseTelegram && !isTopModel && tvshow) return 3;
              if (canUseTelegram && !isTopModel && !tvshow) return 6;
              return 6;
            })()}
            md={6}
            xs={12}
            title={loading ? '' : _('dashboard:app.profileStatus.header')}
            content={
              loading ? (
                dashboardSpinner('m')
              ) : (
                <ProfileStatus progress={commonProfileFillingPercentage} loading={loading} />
              )
            }
          />
        )}
        <GridTileItem
          xl={6}
          xs={12}
          icon={<span className="icon-charts" />}
          centerItems={true}
          content={
            loading ? (
              dashboardSpinner('m')
            ) : (
              <SalesStatistics loading={loading} userAllowed={userPrivileged} />
            )
          }
          title={loading ? '' : _('dashboard:app.salesStatistics.header')}
        />
        <GridTileItem
          xl={6}
          xs={12}
          icon={<GraphIcon />}
          title={loading ? '' : _('dashboard:app.vxCashStat.header')}
          content={
            loading ? (
              dashboardSpinner('m')
            ) : (
              <VXCashStatistics loading={loading} userAllowed={userPrivileged} />
            )
          }
        />
        <GridTileItem
          xl={6}
          xs={12}
          icon={<span className="icon-clock" />}
          title={loading ? '' : _('dashboard:app.onlineTime.header')}
          content={loading ? dashboardSpinner('m') : <OnlineTimeAndUsers loading={loading} />}
        />
        <GridTileItem
          xl={6}
          xs={12}
          icon={<span className="icon-pie-chart" />}
          title={loading ? '' : _('dashboard:app.incomingSources.header')}
          centerItems={true}
          content={
            loading ? (
              dashboardSpinner('m')
            ) : (
              <IncomeSources loading={loading} userAllowed={userPrivileged} />
            )
          }
        />
      </Grid>
    </Container>
  );
};

export default VXModelsDashboard;
