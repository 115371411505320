import React, { FC } from 'react';
import { _ } from '../../../../../util/translate';
import { ZendeskRenderContainer } from '../../../../../components';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { APP_BASE_PATH } from '../../../../../util/env';
import {
  HelpCentersearchArgs,
  HelpCenterSearchResult,
} from '../../../../../graphql/VXModels/types';
import ResultBreadcrumbs from './ResultBreadcrumbs/ResultBreadcrumbs';
import { SEARCH_LIMIT_PER_PAGE } from './utils';
import { Pagination } from '../../../../../atoms/Pagination';
import {
  SearchResultContainer,
  Divider,
  CategoriesWrapper,
  CategoryWrapper,
  CategoryBulletTitle,
  CategoryContent,
  ZendeskExcerpt,
  StyledLink,
} from './styles';

interface Props {
  searchTerm: string;
  results: HelpCenterSearchResult;
  page: number;
  onSelect: (variables: HelpCentersearchArgs) => void;
}

const SearchResults: FC<Props> = ({ searchTerm, results, onSelect }) => {
  return (
    <SearchResultContainer>
      <Divider>
        <h2>
          {_('help:helpcenter.search.results', { sprintf: [results.articleCount, searchTerm] })}
        </h2>
      </Divider>
      <CategoriesWrapper>
        {results.articles.map((article) => (
          <CategoryWrapper key={article.id}>
            <CategoryBulletTitle>
              <strong>{_('help:helpcenter.search.category')} </strong>
              <ResultBreadcrumbs
                article={article}
                category={article.category}
                section={article.section}
                searchTerm={searchTerm}
              />
            </CategoryBulletTitle>
            <CategoryContent>
              <ZendeskExcerpt>
                <ZendeskRenderContainer htmlContent={article.body} />
              </ZendeskExcerpt>
              <StyledLink
                to={`${APP_BASE_PATH}/help/helpcenter/categories/${article.category.id}/articles/${article.id}`}
              >
                <span css={{ marginRight: '3px' }}>{_('help:helpcenter.search.toArticle')}</span>{' '}
                <TrendingFlatIcon />
              </StyledLink>
            </CategoryContent>
          </CategoryWrapper>
        ))}
      </CategoriesWrapper>
      {results.pageCount > 1 && (
        // pagination starts counting at 0, search query does at 1
        <Pagination
          count={results.pageCount}
          currentPage={results.currentPage - 1}
          onSelect={(page): void =>
            onSelect({
              query: searchTerm,
              page: page + 1,
              limit: SEARCH_LIMIT_PER_PAGE,
            })
          }
        />
      )}
    </SearchResultContainer>
  );
};

export default SearchResults;
