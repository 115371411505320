import React, { Fragment } from 'react';
import Formsy from 'formsy-react';

import NavSubFinances from '../NavSubFinances';
import { Button, Dropdown, EmptyContent, Footer, Spinner, T } from '../../../components';

import CampointBillings from '../../Lists/CampointBillings/CampointBillings';
import VXCashBillings from '../../Lists/VXCashBillings/VXCashBillings';

import { hasRole } from '../../../util/UserData';
import { SecurityRole } from '../../../graphql/VXModels/types';
import { _ } from '../../../util/translate';
import { WHITE } from '../../../camtool-styles';

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: '',
    };
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    if (this.state.selectedYear === '') {
      let dt = new Date();
      this.setState({
        selectedYear: dt.getFullYear(),
      });
    }
  }

  submitForm(model) {
    if (this.state.selectedYear !== model.year) {
      this.setState({
        selectedYear: model.year,
      });
    }
  }

  getAllowedYears() {
    let years = [];
    let dt = new Date();
    for (let i = dt.getFullYear(); i >= 2015; i--) {
      years.push({ label: i, value: i });
    }
    return years;
  }

  userPrivileged() {
    return hasRole(SecurityRole.VX_USER_MASTER);
  }

  render() {
    return (
      <main className="grid finances-billing">
        <NavSubFinances />

        <div className="grid__row">
          <div className="grid__column grid__box min-width--0">
            <div className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'finances:billing.header'} />
              </div>

              {this.userPrivileged() && (
                <Fragment>
                  <div className="grid__box__header__spacer" />

                  <Formsy.Form
                    className="grid__box__header__item"
                    // eslint-disable-next-line react/no-string-refs
                    ref="filter"
                    onValidSubmit={this.submitForm}
                  >
                    <div className="finances-overview__filter">
                      <Dropdown
                        name="year"
                        value={this.state.selectedYear.toString()}
                        options={this.getAllowedYears()}
                      />
                      <Button label={_('common:button.show')} />
                    </div>
                  </Formsy.Form>
                </Fragment>
              )}
            </div>

            {!this.userPrivileged() && <EmptyContent title={_('common:forbidden.text')} />}

            {this.userPrivileged() && (
              <Fragment>
                <div className="grid__box__row spinner-container">
                  {this.state.isDataLoading && <Spinner />}
                  <div className="grid__box__column grid__box__sub min-width--0">
                    <div className="grid__box__sub__header">
                      <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                        <T _={'lists:campointbillings.info.boxTitle'} />
                      </div>
                    </div>

                    <div className="grid__box__sub__item">
                      <div className="grid__box__sub__item__content finances-overview__cpbilling min-width--0">
                        <CampointBillings year={this.state.selectedYear} />
                      </div>
                    </div>
                  </div>
                </div>

                {!hasRole(SecurityRole.VX_USER_SEXOLE) && (
                  <Fragment>
                    <div className="grid__box__row spinner-container">
                      {this.state.isDataLoading && <Spinner />}
                      <div className="grid__box__column grid__box__sub min-width--0">
                        <div className="grid__box__sub__header">
                          <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                            <T _={'lists:vxcashbillings.info.boxTitle'} />
                          </div>
                        </div>

                        <div className="grid__box__sub__item">
                          <div className="grid__box__sub__item__content finances-overview__vxcashbilling min-width--0">
                            <VXCashBillings year={this.state.selectedYear} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default Billing;
