import { ALIGNMENT_DATA_KEY } from './ExtendedRichUtils';

const blockStyleFn = contentBlock => {
  const textAlignStyle = contentBlock.getData().get(ALIGNMENT_DATA_KEY);

  if (textAlignStyle) {
    switch (textAlignStyle) {
      case 'right':
        return `align-right`;
      case 'center':
        return `align-center`;
      case 'left':
        return `align-left`;
      case 'justify':
        return `align-justify`;
      default:
        return null;
    }
  } else {
    switch (contentBlock.getType()) {
      case 'blockquote':
        return `text-editor-blockquote`;
      default:
        return null;
    }
  }
};

export default blockStyleFn;
