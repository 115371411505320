/**
 * aka Zendesk Departments
 */
export enum Brands {
  MODELSERVICE = 7937000565522,
  PAYMENT = 7936986237330,
  BREZZELS = 7936986227602,
}

export enum BrandsName {
  MODELSERVICE = 'Model Support',
}

export type WindowWithZopim = Window & {
  $zopim?: Zopim;
};

export type Zopim = {
  livechat: Livechat;
};

export type Livechat = {
  button: { setHideWhenOffline: (b: boolean) => void };
  departments: {
    filter: (...deps: number[]) => void;
    setLabel: (label: string) => void;
    getAllDepartments: () => Department[];
  };
  hideAll: () => void;
  setGreetings: (greetings: { online: string; offline: string }) => void;
  setLanguage: (lang: string) => void;
  setName: (name?: string) => void;
  setEmail: (email?: number | string) => void;
  addTags: (tags?: string) => void;
  setOnConnected: (cb: () => void) => void;
  window: {
    setTitle: (t: string) => void;
    show: () => void;
  };
};

export type Department = {
  id: Brands;
  name: string;
  status: 'online' | 'offline';
};
