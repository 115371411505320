import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { heart } from '../../../../../atoms/Icon/libraries/glyph';
import { useHasRole, useSpeaksGerman } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';
import { hasScope, Scopes } from '../../../../../util/scope';

const LustagentenProfileItem: FC = () => {
  const hasScopeVXPages = hasScope([Scopes.VXPages]);
  const isSexoleModel = useHasRole(SecurityRole.VX_USER_SEXOLE);
  const speaksGerman = useSpeaksGerman();

  return speaksGerman && !isSexoleModel ? (
    <LeftMenuItem
      title={_(
        hasScopeVXPages
          ? 'navigation:visitx.profileLustagenten'
          : 'navigation:main.profile.lustagenten'
      )}
      icon={heart}
      uri="/onlineprofile/lustagenten"
    />
  ) : null;
};

export default LustagentenProfileItem;
