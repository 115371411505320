import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button } from '../../../atoms';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';
import withSlideContainer, { GetBackgroundImageCallback } from './withSlideContainer';
import { bgWelcomeToVXModels, logoWelcomeToVXModels } from './assets';
import { useHistory } from 'react-router';

const Container = styled.div`
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 7px;
`;

const Logo = styled.img`
  margin-bottom: 50px;
`;

const BUTTON_ROUTE = `${APP_BASE_PATH}/help/`;

const getBackgroundImage: GetBackgroundImageCallback = () => bgWelcomeToVXModels;

const SlideWelcomeToVXModels: FC = () => {
  const history = useHistory();

  return (
    <Container>
      <Title>{_('dashboard:newsticker.slides.welcomeVXModels.title')}</Title>
      <Logo src={logoWelcomeToVXModels} />
      <Button theme="blue" size="lg" onClick={() => history.push(BUTTON_ROUTE)}>
        {_('dashboard:newsticker.slides.welcomeVXModels.button')}
      </Button>
    </Container>
  );
};

export default withSlideContainer(SlideWelcomeToVXModels, getBackgroundImage);
