import React, { FC, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import MessageItem from './MessageItem';
import { Spinner } from '../../../../../components';
import FetchMoreLine from '../FetchMoreLine';
import { GroupedChat } from '../../../../../graphql/VXServicesTelegram/types';
import { updateQuery } from '../../../../../util/NotificationsHelper';
import { useTelegramSetMessagesRead } from '../../../hooks';
import { _ } from '../../../../../util/translate';
import format from 'date-fns/format';
import { de } from 'date-fns/locale';
import { enUS } from 'date-fns/locale';
import { useLang } from '../../../../../util/AppState';
import { ApiLang } from '../../../../../graphql/VXModels/types';
import { WHITE } from '../../../../../camtool-styles';
import { getFormattedAmount } from '../../../../../util/Formatter';
import { EventEnum } from './ChannelItem';
import { sanitizeLang } from '../../../../../util/Translator';
import TippingBubble from '../TippingBubble';

const DateBubble = styled.span`
  background-color: rgba(136,136,136,0.6);
  width: fit-content;
  align-self: center;
  border-radius: 24px;
  padding: 10px;
  margin: 10px 0px;
  color: ${WHITE};
}
`;

const ScrollWrapper = styled.div`
  display: block !important; // Fixes a Firefox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1042151#c55
  overflow-y: auto;
  min-height: 400px;
`;

const MessagesContainer = styled.div`
  flex-direction: column-reverse;
  flex: 1;
`;

interface IProps {
  userId: number;
  groupedChat: GroupedChat[];
  fetchMore?: () => void;
  currency: string | undefined;
  loading: boolean;
}

const Thread: FC<IProps> = ({ userId, groupedChat, currency, fetchMore, loading }) => {
  const [lang] = useLang();
  const locale = lang === ApiLang.de ? de : enUS;
  const intlLang = sanitizeLang(lang); // international lang ('de-DE'/'en-US')
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const lastMessage = groupedChat?.[0].events?.[0];
  const lastMessageId = lastMessage && lastMessage.id;

  const setMessagesRead = useTelegramSetMessagesRead(userId);

  // TODO Research how to trigger function after last render of lastMesage
  useEffect(() => {
    if (lastMessageId && !loading) {
      setTimeout(function timer() {
        messagesEndRef.current.scrollIntoView({});
      }, 1000);
    }
  }, [lastMessage]);

  useEffect(() => {
    setMessagesRead();
  }, []);

  const length = groupedChat?.[0]?.events?.length;
  const hasMessages = length > 0;

  if (loading && !hasMessages) return <Spinner />;
  return (
    <ScrollWrapper>
      {hasMessages && (
        // visual display is reversed - therefore code reversed as well
        <MessagesContainer>
          <div ref={messagesEndRef} css={{ height: '4px' }} />
          {groupedChat?.map((groupedMessage, groupIndex) => (
            <React.Fragment key={groupIndex}>
              {groupedMessage?.events?.map((event) => {
                const type = event?.__typename;

                switch (type) {
                  case EventEnum.Message:
                    return <MessageItem key={event?.id} {...event} />;
                  case EventEnum.Tipping:
                    return (
                      <TippingBubble key={event?.id}>
                        <span>💸 </span>
                        {_('telegram:chat.system.tippingmessage', {
                          amount: getFormattedAmount(event?.amount, intlLang, currency),
                        })}
                      </TippingBubble>
                    );
                  default:
                    return null;
                }
              })}

              <DateBubble>{`${format(new Date(groupedMessage?.date), 'dd.MMMM yyyy', {
                locale: locale,
              })}`}</DateBubble>
            </React.Fragment>
          ))}
          {fetchMore && (
            <FetchMoreLine
              loading={loading}
              label={_('telegram:chat.button.fetchMore')}
              onClick={() => fetchMore({ updateQuery, variables: { lastSeen } })}
            />
          )}
        </MessagesContainer>
      )}
    </ScrollWrapper>
  );
};

export default Thread;
