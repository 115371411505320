import React, { FC } from 'react';
import { ListItem } from './styles';
import { _ } from '../../../../util/translate';

const AdvantagesListLocked: FC = () => {
  return (
    <>
      <ListItem>{_('telegram:requestTelegram.request.advantages.unlocked.firstArgument')}</ListItem>
      <ListItem>
        {_('telegram:requestTelegram.request.advantages.unlocked.secondArgument')}
      </ListItem>
      <ListItem>{_('telegram:requestTelegram.request.advantages.unlocked.thirdArgument')}</ListItem>
      <ListItem>
        {_('telegram:requestTelegram.request.advantages.unlocked.fourthArgument')}
      </ListItem>
    </>
  );
};

export default AdvantagesListLocked;
