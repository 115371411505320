import React, { FC, useState } from 'react';
import { Paper, Container, Box, IconButton } from '@material-ui/core';
import { bannerUniversal, OnboardingStepper, useStepperStyles } from '.';
import { WHITE } from '../../../../../camtool-styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import FirstStepChild from './OnboardingStepper/FirstStepChild';
import SecondStepChild from './OnboardingStepper/SecondStepChild';
import LastStepChild from './OnboardingStepper/LastStepChild';
import NewButton from '../../../../../atoms/Button/NewButton';

const CompetitionOnboarding: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStepperStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepContent = [
    {
      headline: '💯 Mehr Transparenz',
      subHeadline:
        'Bleibe up-to-date mit Hilfe vieler Zusatzinfos und Statistiken. Auf deinem Dashboard kannst Du immer den aktuellen Stand der Challenge einsehen.',
      children: <FirstStepChild />,
    },
    {
      headline: '👏 Gewinnchancen für Alle',
      subHeadline: `Egal ob VISIT-X Newbie oder Profi. Jährlich kann jede Teilnehmerin gewinnen, die eine Herausforderung erfolgreich abgeschlossen hat. Mit Hilfe eines eigens programmierten Algorithmus wenn die Gewinnerinnen per Zufall ausgelost. Insgesamt werden Geldprämien im Gesamtwert von über 30.000 € verlost.`,
      children: <SecondStepChild />,
    },
    {
      headline: '😎 Bist du bereit für Deine erste Herausforderung?',
      subHeadline:
        'Bestätige nur noch die Teilnahmebedingungen und starte mit Deiner ersten Herausforderung.',
      children: <LastStepChild />,
    },
  ];

  return (
    <Container>
      <Paper variant="elevation" style={{ backgroundColor: WHITE, padding: '40px' }}>
        <img css={{ width: '100%' }} src={bannerUniversal} />
        <Box display="flex" alignItems="center" flexDirection="column" mt={3}>
          <OnboardingStepper {...stepContent[activeStep]} />
          <Box display="flex" flexDirection={'column'} mt={2} maxWidth="300px">
            {activeStep < stepContent.length - 1 && (
              <NewButton onClick={handleNext}>Weiter</NewButton>
            )}
            <MobileStepper
              classes={classes}
              variant="dots"
              steps={3}
              position="static"
              activeStep={activeStep}
              backButton={
                <IconButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                  <KeyboardArrowLeft fontSize="large" />
                </IconButton>
              }
              nextButton={
                <IconButton size="small" onClick={handleNext} disabled={activeStep === 2}>
                  <KeyboardArrowRight fontSize="large" />
                </IconButton>
              }
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export { CompetitionOnboarding };
