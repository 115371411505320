import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BLACK_2, BREAKPOINT_PHONE, WHITE } from '../../../../camtool-styles';

const ContainerDiv = styled.div`
  flex-direction: column;
  width: 100%;
  padding: 24px 41px 27px;
  background-color: ${WHITE};
  font-size: 1.3333rem;
  color: ${BLACK_2};
  ${BREAKPOINT_PHONE} {
    flex-wrap: wrap;
    padding: 24px;
  }
`;

const Header = styled.div`
  font-weight: bold;
`;

interface IProps {
  title: string;
}

const InnerSection: FC<IProps> = ({ title, children }) => (
  <ContainerDiv>
    <Header>{title}</Header>
    {children}
  </ContainerDiv>
);

export default InnerSection;
