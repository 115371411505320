import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from '../../../../util/Formatter';
import { EmptyContent, T } from '../../../../components';

import { Row, RowHeaderNoIcon, RowContent, Cell, BorderButton } from '../components';

import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { withAppState } from '../../../../util/AppState';
import { _ } from '../../../../util/translate';

const GET_DOCUMENTS = gql`
  query GET_DOCUMENTS {
    model {
      documents {
        history {
          id
          status
          fileName
          insertDate
          rejectionReason
          url
        }
      }
    }
  }
`;

const DELETE_DOC = gql`
  mutation Mutation($id: Int!) {
    model {
      documents {
        deleteDocument(id: $id) {
          id
          status
          fileName
          insertDate
          rejectionReason
          url
        }
      }
    }
  }
`;

class HistoryRowWithoutAppState extends React.Component {
  renderStatusCell = () => {
    switch (this.props.status) {
      case 'rejected':
        return (
          <Cell flex={3} css={{ color: '#ff4d3c', textAlign: 'center' }}>
            <p>
              <span className="icon-remove" /> <T _={'account:documents.status.denied'} />
            </p>
          </Cell>
        );
      case 'new':
      case 'delayed':
      case 'checking':
        return (
          <Cell flex={3} css={{ color: '#ffb033' }}>
            <p>
              <span className="icon-roundabout" css={{ marginRight: '5px' }} />
              <T _={'account:documents.status.checking'} />
            </p>
          </Cell>
        );
      default:
        return null;
    }
  };

  render() {
    const {
      fileName,
      insertDate,
      rejectionReason,
      url,
      type,
      deleteDocument,
      id,
      status,
      appState: [{ lang }],
    } = this.props;

    return (
      <Row css={{ flex: '0 0 auto' }}>
        <RowContent>
          <Cell contentAsColum={true} flex={4} css={{ alignItems: 'flex-start' }}>
            <div css={{ fontWeight: type ? 'bold' : '', fontSize: '1.2em' }}>
              <T _={`account:documents.name.${type}`} />
            </div>
            <div css={{ fontSize: '.9em' }}>{`${fileName} (ID: ${id})`}</div>
          </Cell>

          <Cell flex={2}>{getFormattedDate(insertDate, lang)}</Cell>

          {this.renderStatusCell()}

          <Cell flex={2} css={{ textAlign: 'center' }}>
            {rejectionReason || ''}
          </Cell>
          <Cell flex={2}>
            <BorderButton onClick={() => window.open(url).focus()}>
              <T _={'account:documents.button.open'} />
            </BorderButton>
          </Cell>
          <Cell flex={2} css={{ color: '#ff4d3c', a: { color: '#ff4d3c' } }}>
            {status === 'rejected' && (
              <Fragment>
                <span className="icon-remove" />
                {` `}
                <a href="#" onClick={() => deleteDocument()}>
                  <T _={'account:documents.button.remove'} />
                </a>
              </Fragment>
            )}
          </Cell>
        </RowContent>
      </Row>
    );
  }
}

const HistoryRow = withAppState(HistoryRowWithoutAppState);

class History extends React.Component {
  render() {
    return (
      <div className="grid__column grid__box" css={{ overflow: 'auto' }}>
        <div className="grid__box__header profiles-overview__header">
          <div className="grid__box__header__title profiles-overview__header__text">
            <T _={'account:documents.history.header'} />
          </div>
        </div>

        <article
          className="grid__box__row"
          css={{
            flex: 'none',
            color: '#3c3d3e',
            padding: '32px 16px 0 16px',
            backgroundColor: '#f5f5f5',
            maxHeight: 278,
            flexDirection: 'column',
            overflow: 'auto',
            borderBottom: '32px solid #f5f5f5',
          }}
        >
          {this.props.data.length < 1 ? (
            <EmptyContent title={_('account:documents.history.emptyContent')} icon="" />
          ) : (
            <Fragment>
              <RowHeaderNoIcon />

              <Mutation
                mutation={DELETE_DOC}
                update={(cache, { data: deleteDocument }) => {
                  const cachedData = cache.readQuery({ query: GET_DOCUMENTS });
                  cachedData.model.documents.history =
                    deleteDocument.model.documents.deleteDocument;
                  cache.writeQuery({ query: GET_DOCUMENTS, data: cachedData });
                }}
              >
                {(deleteDocument) =>
                  this.props.data.map((doc) => (
                    <HistoryRow
                      key={doc.fileName}
                      status={doc.status}
                      fileName={doc.fileName}
                      insertDate={doc.insertDate}
                      rejectionReason={doc.rejectionReason}
                      url={doc.url}
                      type={doc.type}
                      id={doc.id}
                      deleteDocument={() => deleteDocument({ variables: { id: doc.id } })}
                    />
                  ))
                }
              </Mutation>
            </Fragment>
          )}
        </article>
      </div>
    );
  }
}

History.propTypes = {
  data: PropTypes.array.isRequired,
};

HistoryRowWithoutAppState.propTypes = {
  fileName: PropTypes.string,
  insertDate: PropTypes.string,
  rejectionReason: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  deleteDocument: PropTypes.func,
  id: PropTypes.number,
  status: PropTypes.string,
  appState: PropTypes.array,
};

export default History;
