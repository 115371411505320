import React, { FC } from 'react';
import styled from '@emotion/styled';
import { _ } from '../../../../../util/translate';
import { useHasRole } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';
import { BREAKPOINT_TABLET, GRAY_2 } from '../../../../../camtool-styles';
import VersionString from '../../../../VersionString/index';
import FooterNavLink from './FooterNavLink';
import { useCanUseTelegram } from '../../../../../packages/Telegram';

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 16px;
  flex: 0 0 auto; // Prevents the div from losing its margins on macOS Safari
  -webkit-flex: 0 0 auto;

  ${BREAKPOINT_TABLET} {
    display: none !important;
  }
`;

const Divider = styled.div`
  flex: 0 0 auto;
  width: calc(100% - 30px);
  margin: 0 15px 15.5px;
  border-top: 0.5px solid ${GRAY_2};
`;

const Version = styled.div`
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
  color: ${GRAY_2};
  opacity: 0.5;
`;

const Footer: FC = () => {
  const hasRoleVXPages = useHasRole(SecurityRole.VX_USER_VXPAGES);
  const hasRoleVXModels = useHasRole(SecurityRole.VX_USER_VXMODELS);
  const canUseTelegram = useCanUseTelegram();

  return (
    <Container>
      <Divider />
      {hasRoleVXModels && (
        <FooterNavLink to={'/legal/houserule'}>
          {_('navigation:main.legal.houserule')}
        </FooterNavLink>
      )}
      <FooterNavLink to={'/legal/terms'}>{_('navigation:main.legal.terms')}</FooterNavLink>
      {canUseTelegram && (
        <FooterNavLink to={'/legal/telegram-terms'}>
          {_('navigation:main.legal.telegram.terms')}
        </FooterNavLink>
      )}
      <FooterNavLink to={'/legal/gdpr'}>{_('common:legal.privacy')}</FooterNavLink>
      <FooterNavLink to={'/legal/imprint'}>{_('navigation:main.legal.imprint')}</FooterNavLink>
      {hasRoleVXPages && (
        <FooterNavLink to={'/legal/terms-of-use'}>
          {_('navigation:main.legal.termsOfUse')}
        </FooterNavLink>
      )}
      <Version>
        <VersionString />
      </Version>
    </Container>
  );
};

export default Footer;
