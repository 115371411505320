import React from 'react';
import { Redirect, Switch } from 'react-router';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import { stripSlash } from '../../util/urlHelper';
import { hasRole } from '../../util/UserData';
import PersonalData from './PersonalData/PersonalData';
import Documents from './Docs/Documents';
import PayoutData from './PayoutData/PayoutData';
import Passwords from './Passwords/Passwords';
import NavSub from '../../components/NavSub/NavSub';
import NavSubItem from '../../components/NavSubItem/NavSubItem';
import { Footer } from '../../components';
import { APP_BASE_PATH } from '../../util/env';
import { SecurityRole } from '../../graphql/VXModels/types';
import { _ } from '../../util/translate';

// eslint-disable-next-line react/prop-types
const Account = ({ match: { url } }) => {
  const path = stripSlash(url);

  const verify = () => hasRole(SecurityRole.VX_USER_MASTER);
  const indexPath = `${path}/${verify() ? 'personaldata' : 'passwords'}`;

  return (
    <main className="grid spinner-container">
      <NavSub>
        {verify() && (
          <NavSubItem
            link={`${APP_BASE_PATH}/account/personaldata`}
            icon="icon-user"
            label={_('account:navigation.personaldata')}
          />
        )}
        {verify() && (
          <NavSubItem
            link={`${APP_BASE_PATH}/account/payoutdata`}
            icon="icon-usd"
            label={_('account:navigation.payoutdata')}
          />
        )}
        {verify() && (
          <NavSubItem
            link={`${APP_BASE_PATH}/account/documents`}
            icon="icon-briefcase"
            label={_('account:navigation.documents')}
          />
        )}
        <NavSubItem
          link={`${APP_BASE_PATH}/account/passwords`}
          icon="icon-keys"
          label={_('account:navigation.passwords')}
        />
      </NavSub>

      <Switch>
        <Redirect exact from={`${path}/`} to={indexPath} />
        <ProtectedRoute
          path={`${path}/personaldata`}
          component={PersonalData}
          redirectTo={indexPath}
          verify={verify}
        />
        <ProtectedRoute
          path={`${path}/documents`}
          component={Documents}
          redirectTo={indexPath}
          verify={verify}
        />
        <ProtectedRoute
          path={`${path}/payoutdata`}
          component={PayoutData}
          redirectTo={indexPath}
          verify={verify}
        />
        <ProtectedRoute path={`${path}/passwords`} component={Passwords} />
        <Redirect to={indexPath} />
      </Switch>

      <div className="grid__row grid__box">
        <Footer className="grid__box__item" />
      </div>
    </main>
  );
};
export default Account;
