import React from 'react';
import { SessionStore } from '../../../stores/Session/Session';
import { Markdown, T } from '../../../components';

import rulesTextDE from '../../../assets/markdown/de-DE/rules.md';
import rulesTextEN from '../../../assets/markdown/en-US/rules.md';
import { ApiLang } from '../../../graphql/VXModels/types';
import { withAppState } from '../../../util/AppState';

class HouseRule extends React.Component {
  state = { userData: SessionStore.get() };

  getText = () => {
    const [{ lang }] = this.props.appState;

    return lang === ApiLang.de ? rulesTextDE : rulesTextEN;
  };

  render() {
    return (
      <div className="grid__row">
        <div className="grid__column grid__box">
          <div className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'common:legal.houserule'} />
            </div>
          </div>
          <div className="grid__box__item">
            <div className="grid__box__item__content">
              <Markdown className="legal__box" source={this.getText()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAppState(HouseRule);
