import React, { FC } from 'react';
import { hasScope, Scopes } from '../../../util/scope';
import { useHasRole } from '../../../util/UserData';
import { SecurityRole } from '../../../graphql/VXModels/types';
import { LeftMenuWhitelabel, LeftMenuVXPages, LeftMenuVXModels } from './roots';

const LeftMenu: FC = () => {
  const isSexoleModel = useHasRole(SecurityRole.VX_USER_SEXOLE);
  const hasScopeVXPages = hasScope([Scopes.VXPages]);

  return isSexoleModel ? (
    <LeftMenuWhitelabel />
  ) : hasScopeVXPages ? (
    <LeftMenuVXPages />
  ) : (
    <LeftMenuVXModels />
  );
};

export default LeftMenu;
