import React, { FC } from 'react';
import styled from '@emotion/styled';
import { VXPagesUser } from '../../../../../graphql/VXModels/types';
import { useUserData } from '../../../../../util/UserData';
import ForcedPaymentToggle from './ForcedPaymentToggle';
import TransactionsTable from './Table/TransactionsTable';
import { NoTransactionsPlaceholder, LoadingSpinner } from './Placeholders';

interface IProps {
  user: VXPagesUser;
  loadMore: (page: number) => Promise<void> | void;
}

const Container = styled.div`
  flex-direction: row;
  flex: 1;
  align-items: ${(props): string => (props.hasTransactions ? 'start' : 'center')};
  justify-content: center;
`;

const TransactionsContainer = styled.div`
  flex-direction: column;
  width: 100%;
`;

const Transactions: FC<IProps> = ({ user, loadMore }: IProps) => {
  const { username, transactions, isPayingCustomer, isLocked } = user;

  const userData = useUserData();
  const { isInForcePaymentMode = false } = userData?.model?.vxpages?.info || {};

  return (
    <Container hasTransactions={transactions && transactions.total > 0}>
      {transactions ? (
        <TransactionsContainer>
          {isInForcePaymentMode && !isPayingCustomer && !isLocked && (
            <ForcedPaymentToggle user={user} />
          )}
          {transactions.total > 0 ? (
            <TransactionsTable transactions={transactions} loadMore={loadMore} />
          ) : (
            <NoTransactionsPlaceholder username={username} />
          )}
        </TransactionsContainer>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
};

export default Transactions;
