import React, { FC, Fragment } from 'react';
import styled from '@emotion/styled';
import { Redirect, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import { Main } from '../../../atoms';
import { BLUE, BLUE_HOVER, BREAKPOINT_TABLET } from '../../../camtool-styles';
import SectionGeneral from './Sections/SectionGeneral/SectionGeneral';
import { EmptyContent, Spinner } from '../../../components';
import { REACT_APP_BASE_PATH } from '../../../util/env';
import BackArrow from './BackArrow';
import { FRAGMENT_VXPAGES_USER } from '../../../graphql/VXModels/fragments';
import SectionCredits from './Sections/SectionCredits/SectionCredits';
import SectionNotes from './Sections/SectionNotes';
import SectionTransactions from './Sections/SectionTransactions/SectionTransactions';
import { VXPagesUser } from '../../../graphql/VXModels/types';
import SectionEditVouchersGiftContent from './Sections/SectionEditVouchersGiftContent/SectionEditVouchersGiftContent';
import { _ } from '../../../util/translate';
import { dataIdFromObject } from '../../../graphql/VXModels';
import { useVXPagesUser } from './hooks';

interface IRouteParameters {
  id?: string | undefined;
}

const OverviewLink = styled(Link)`
  color: ${BLUE};
  margin: 8px 8px 7px;
  text-decoration: none;
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    color: ${BLUE_HOVER};
  }

  & svg {
    fill: ${BLUE};
  }

  &:hover svg {
    fill: ${BLUE_HOVER};
  }
`;

const WrapperDiv = styled.div`
  ${BREAKPOINT_TABLET} {
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    section {
      flex-wrap: wrap;
      width: 100%;
    }
  }
`;

const StyledSection = styled(SectionNotes)`
  ${BREAKPOINT_TABLET} {
    padding: 24px;
  }
`;

const OverviewLinkSpan = styled.span`
  margin-left: 8px;
`;

const getParsedParams = ({ id }: IRouteParameters): { id: number } => {
  let numericalId = 0;

  if (id) {
    const parsedId = parseInt(id);
    if (!isNaN(parsedId) && parsedId > 0) {
      numericalId = parsedId;
    }
  }

  return { id: numericalId };
};

const getUserFromData = (data: object): VXPagesUser | null => {
  return data?.model?.vxpages?.user || null;
};

const OVERVIEW_ROUTE = `${REACT_APP_BASE_PATH}/customers/`;
export const TRANSACTIONS_PAGE_SIZE = 100;

const Details: FC<RouteComponentProps<IRouteParameters>> = ({ match: { params } }) => {
  const { id } = getParsedParams(params);

  const { error, data, fetchMore, loading } = useVXPagesUser(id);

  const networkUser = getUserFromData(data);

  const fragmentCacheId = dataIdFromObject({ id, __typename: 'VXPagesUser' });

  const apolloClient = useApolloClient();
  const cachedFragment = fragmentCacheId
    ? apolloClient.readFragment({
        id: fragmentCacheId,
        fragment: FRAGMENT_VXPAGES_USER,
      })
    : undefined;
  const cachedUser =
    cachedFragment && typeof cachedFragment === 'object' && Object.keys(cachedFragment).length > 0
      ? cachedFragment
      : null;

  const user = networkUser || cachedUser || null;

  const loadMoreTransactions = (page: number) => {
    if (error) return;

    return fetchMore({
      variables: {
        transactionsOffset: page * TRANSACTIONS_PAGE_SIZE,
      },
      updateQuery: (prev: VXPagesUser, { fetchMoreResult }: { fetchMoreResult?: VXPagesUser }) => {
        if (!fetchMoreResult) return prev;

        const prevUser = getUserFromData(prev);
        const fetchMoreUser = getUserFromData(fetchMoreResult);

        return {
          ...fetchMoreResult,
          model: {
            ...fetchMoreResult.model,
            vxpages: {
              ...fetchMoreResult.model.vxpages,
              user: {
                ...fetchMoreUser,
                transactions: {
                  ...fetchMoreUser.transactions,
                  items: [...prevUser.transactions.items, ...fetchMoreUser.transactions.items],
                },
              },
            },
          },
        };
      },
    });
  };

  return (
    <Main isMobileEnabled>
      {!id && <Redirect to={OVERVIEW_ROUTE} />}
      <OverviewLink to={OVERVIEW_ROUTE}>
        <BackArrow />
        <OverviewLinkSpan>{_('userManagement:details.backToOverview')}</OverviewLinkSpan>
      </OverviewLink>
      {error ? (
        <EmptyContent icon={'icon-warning-sign'} title={_('userManagement:details.error')} />
      ) : user ? (
        <Fragment>
          <SectionGeneral user={user} />
          <WrapperDiv>
            <SectionCredits user={user} />
            <StyledSection user={user} />
          </WrapperDiv>
          <WrapperDiv>
            <SectionTransactions user={user} loadMore={loadMoreTransactions} />
            <SectionEditVouchersGiftContent user={user} />
          </WrapperDiv>
        </Fragment>
      ) : loading ? (
        <Spinner noBackground={true} align={'center'} />
      ) : (
        <EmptyContent
          icon={'icon-ban-circle'}
          title={_('userManagement:details.inactiveUserFound')}
        />
      )}
    </Main>
  );
};

export default Details;
