import React, { Fragment } from 'react';
import AbstractComponent from '../../../components/AbstractComponent';
import { FinancesActionCreators, FinancesStore } from '../../../stores/Finance/Finances';
import { useCanUseTelegram } from '../../../packages/Telegram';
import {
  Button,
  Dropdown,
  EarningOverview,
  EmptyContent,
  Footer,
  FormatCurrency,
  FormatDatePeriod,
  PopUpMenu,
  Spinner,
  T,
  Tooltip,
} from '../../../components';

import NavSubFinances from '../NavSubFinances';
import OverviewDataList from './OverviewDataList';
import {
  getFormattedAmount,
  getFormattedDuration,
  getFormattedPeriod,
} from '../../../util/Formatter';
import Cake42 from '../../../atoms/Charts/Cake42';
import { Section } from '../../../atoms';
import { BLACK, GREEN, RED, WHITE, WHITE_3 } from '../../../camtool-styles';
import Formsy from 'formsy-react';
import ProvisionInfo from './ProvisionInfo';
import { getUserData, hasRole } from '../../../util/UserData';
import { SecurityRole } from '../../../graphql/VXModels/types';
import { withAppState } from '../../../util/AppState';
import { _ } from '../../../util/translate';
import { hasScope, Scopes } from '../../../util/scope';
import { ConsentTypeEnum, isCookieConsentSet } from '../../../util/CookieConsent';

const STORE = 'overview';

class Overview extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      overview: FinancesStore.get(STORE),
      openedTab: 0,
      isOpened: false,
      isDataLoading: true,
      isMonthAndYearSelectorDisabled: false,
    };
  }

  componentDidMount() {
    FinancesStore.addChangeListener(STORE, this.onStoreChange);
    window.addEventListener('mouseup', this.onPageClick, false);
    this._getData();
  }

  componentWillUnmount() {
    FinancesStore.removeChangeListener(STORE, this.onStoreChange);
    window.removeEventListener('mouseup', this.onPageClick, false);
  }

  submitForm(model) {
    const oldPeriod = window.sessionStorage.getItem('FOSP');
    const newPeriod = `${model.finances.overview.year}-${model.finances.overview.month}-${model.finances.overview.period}`;
    if (oldPeriod !== newPeriod) {
      if (isCookieConsentSet(ConsentTypeEnum.functional)) {
        window.sessionStorage.setItem('FOSP', newPeriod);
      }
      this._getData(model);
    }
    this.toggleClose();
  }

  toggleOpen() {
    this.setState({ isOpened: true });
  }

  toggleClose() {
    this.setState({ isOpened: false });
  }

  onPageClick() {
    if (this.state.isOpened) {
      this.toggleClose();
    }
  }

  isTabOpened(tabId) {
    return this.state.openedTab === tabId;
  }

  openTab(tabId) {
    this.setState({ openedTab: tabId });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.isDataLoading !== prevState.isDataLoading ||
      this.state.openedTab !== prevState.openedTab
    ) {
      switch (this.state.openedTab) {
        case 1:
          this.renderDailyStats();
          break;
        case 0:
        default:
          this.renderChart();
      }
    }
  }

  onChangePeriod(event) {
    const state = this.state;
    state.isMonthAndYearSelectorDisabled = event.currentTarget.value > 3;
    if (event.currentTarget.value > 3) {
      state.overview.selectedMonth = state.overview.currentMonth;
      state.overview.selectedYear = state.overview.currentYear;
    }
    this.setState(state);
  }

  renderDailyStats() {
    if (this.refs.chartNode2) {
      const node = this.refs.chartNode2;
      const data = [];
      for (const key in this.state.overview.turnoverDetails) {
        data.push({
          name: _(`finances:TurnoverGroup.${this.state.overview.turnoverDetails[key].group}`),
          color: this.state.overview.turnoverDetails[key].color,
          data: this.state.overview.turnoverDetails[key].daily,
        });
      }

      const chart = new Highcharts.Chart({
        accessibility: {
          enabled: false,
        },
        chart: {
          backgroundColor: '#f5f5f5',
          plotBorderWidth: null,
          plotShadow: false,
          type: 'column',
          renderTo: node,
          pointWidth: '16px',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: '',
          display: 'none !important',
        },
        xAxis: {
          allowDecimals: false,
        },
        yAxis: {
          stackLabels: {
            enabled: false,
          },
        },
        tooltip: {
          // pointFormat: _('finances:overview.chart.share') + ': <b>{point.y:.2f} EUR</b>' + ': <b>{point.percentage:.1f}%</b>'
          // pointFormat: '<b>{series.name}</b><br /><b>{point.y:.2f} EUR</b><br /><b>' + _('finances:overview.box.totalsales.sum') + ': {point.percentage:.1f}%</b>'
          formatter() {
            let total = Math.round(this.point.stackTotal * 100) / 100,
              sum = Math.floor(this.y * 100) / 100,
              percentage = Math.round(this.point.percentage * 100) / 100;
            return (
              `<b>${this.x}</b><br/>` +
              `<b>${this.series.name}: ${sum} EUR` +
              ` (${percentage}%)` +
              '</b><br/>' +
              `<b>${_('finances:overview.box.totalsales.sum')}: ${total} EUR</b>`
            );
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
            showInLegend: false,
          },
        },
        series: data,
      });
    }
  }

  renderChart() {
    if (this.refs.chartNode) {
      const node = this.refs.chartNode;
      const data = [];
      for (const key in this.state.overview.turnoverDetails) {
        data.push({
          name: _(`finances:TurnoverGroup.${this.state.overview.turnoverDetails[key].group}`),
          color: this.state.overview.turnoverDetails[key].color,
          borderColor:
            this.state.overview.turnoverDetails[key].provision < 0 ? '#000000' : '#FFFFFF',
          realAmount: this.state.overview.turnoverDetails[key].provision,
          y: Math.abs(this.state.overview.turnoverDetails[key].provision),
          dashStyle: this.state.overview.turnoverDetails[key].provision < 0 ? 'Dash' : 'Solid',
          opacity: this.state.overview.turnoverDetails[key].provision < 0 ? 0.1 : 1,
        });
      }

      const chart = new Highcharts.Chart({
        accessibility: {
          enabled: false,
        },
        chart: {
          backgroundColor: WHITE,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          renderTo: node,
          pointWidth: '16px',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: '',
          display: 'none !important',
        },
        tooltip: {
          pointFormat: `<b>{point.realAmount:.2f} EUR</b><br />${_(
            'finances:overview.chart.share'
          )}: <b>{point.percentage:.1f}%</b>`,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
        },
        series: [
          {
            name: 'Brands',
            colorByPoint: true,
            data,
          },
        ],
      });
    }
  }

  onStoreChange() {
    this.setState({
      overview: FinancesStore.get(STORE),
      isDataLoading: false,
    });
  }

  _getData(model) {
    const periods = [1, 2, 3, 4, 5];
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    let isMonthAndYearSelectorDisabled = false;
    const dataFromSessionStorage = window.sessionStorage.getItem('FOSP');
    if (dataFromSessionStorage) {
      const tmp = dataFromSessionStorage.split('-');
      if (tmp && tmp.length === 3) {
        const selectedYear = parseInt(tmp[0]);
        const selectedMonth = parseInt(tmp[1]);
        const selectedPeriod = parseInt(tmp[2]);
        if (
          selectedYear >= 2013 &&
          months.indexOf(selectedMonth) !== -1 &&
          periods.indexOf(selectedPeriod) !== -1
        ) {
          isMonthAndYearSelectorDisabled = selectedPeriod > 3;
          model = {
            finances: {
              overview: {
                year: selectedYear,
                month: selectedMonth,
                period: selectedPeriod,
              },
            },
          };
        }
      }
    }

    this.setState({
      isDataLoading: true,
      isMonthAndYearSelectorDisabled,
    });
    FinancesActionCreators.getOverviewData(model);
  }

  userPrivileged() {
    return (
      hasRole(SecurityRole.VX_USER_MASTER) ||
      getUserData().model.permissions.isFinancesVisibleWithoutMasterPassword
    );
  }

  render() {
    this.toggleStatus = this.state.isOpened ? 'show' : 'hide';
    const [{ lang }] = this.props.appState;
    const userData = getUserData();
    const hasTelegram = !!userData?.model?.instantMessaging?.account?.active;

    return (
      <main className="grid finances-overview">
        <NavSubFinances />

        <div className="grid__row">
          <div className="grid__column grid__box">
            <div
              className="grid__box__header"
              style={{
                flexBasis: 'auto',
                justifyContent: 'space-between',
                minWidth: 0,
                minHeight: 48,
              }}
            >
              <div className="grid__box__header__title" style={{ paddingRight: 24 }}>
                <T _={'finances:overview.header'} />
              </div>

              {this.userPrivileged() && this.state.overview.selectedPeriodStart && (
                <div
                  style={{
                    minWidth: 0,
                    width: '100%',
                    justifyContent: 'flex-end',
                    paddingRight: 16,
                  }}
                >
                  <div style={{ alignItems: 'center' }}>
                    <Formsy.Form
                      className={`grid__box__header__item popupmenu ${this.toggleStatus}`}
                      /* eslint-disable-next-line react/no-string-refs */
                      ref="filter"
                      onValidSubmit={this.submitForm}
                      onClick={this.toggleOpen}
                      style={{ width: '100%' }}
                    >
                      <div
                        className="dropdown dropdown--min"
                        style={{ justifyContent: 'flex-end', flexWrap: 'wrap' }}
                      >
                        <div className="finances-overview__filter__label">
                          <T _={'finances:overview.selectedTimespan'} /> :
                        </div>
                        <div className="dropdown__column">
                          <div className="dropdown__select">
                            <FormatDatePeriod
                              from={this.state.overview.selectedPeriodStart}
                              to={this.state.overview.selectedPeriodEnd}
                            />
                          </div>
                        </div>
                      </div>

                      <PopUpMenu position="bottom" type="window">
                        <Dropdown
                          name="finances.overview.period"
                          value={this.state.overview.selectedMonthPart}
                          forceTranslate={true}
                          options={this.state.overview.allowedPeriods}
                          onChange={this.onChangePeriod}
                        />

                        <Dropdown
                          name="finances.overview.month"
                          value={this.state.overview.selectedMonth}
                          forceTranslate={true}
                          options={this.state.overview.allowedMonths}
                          disabled={this.state.isMonthAndYearSelectorDisabled}
                        />

                        <Dropdown
                          name="finances.overview.year"
                          value={this.state.overview.selectedYear}
                          options={this.state.overview.allowedYears}
                          forceTranslate={false}
                          disabled={this.state.isMonthAndYearSelectorDisabled}
                        />

                        <Button
                          label={_('common:button.show')}
                          type="submit"
                          onClick={this.toggleClose}
                        />
                      </PopUpMenu>
                    </Formsy.Form>

                    <Tooltip
                      position="bottom"
                      text={_('finances:overview.selectedTimespanTooltip')}
                    />
                  </div>
                </div>
              )}
            </div>

            {/*  KONTOÜBERSICHT */}
            {this.userPrivileged() && (
              <div className="grid__box__row grid__row--wrap spinner-container">
                {this.state.isDataLoading && <Spinner />}
                <div className="grid__box__column grid__box__sub" style={{ flexBasis: 'auto' }}>
                  <div className="grid__box__sub__header">
                    <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                      <T _={'finances:overview.box.totalsales.header'} />
                    </div>
                    <Tooltip
                      position="right"
                      text={_('finances:overview.box.totalsales.headerTooltip')}
                      className="grid__box__sub__header__item"
                    />
                  </div>

                  {/* VERDIENST */}
                  <div className="grid__box__sub__item">
                    <div
                      className="grid__box__sub__item__content finances-overview__totalsales"
                      style={{ flexBasis: 'auto', backgroundColor: WHITE }}
                    >
                      <EarningOverview
                        earning={getFormattedAmount(
                          this.state.overview.totalAmountForSelectedPeriod,
                          lang
                        )}
                        earningColor={
                          this.state.overview.totalAmountForSelectedPeriod < 0
                            ? `${RED}`
                            : `${GREEN}`
                        }
                        earningDetails={[
                          {
                            label: _('finances:overview.box.totalsales.videochats'),
                            icon: 'icon-clock',
                            iconColor: '#1f93e9',
                            data: getFormattedDuration(this.state.overview.totalVideochatDuration),
                          },
                          {
                            label: _('finances:overview.box.totalsales.contentsales'),
                            icon: 'icon-film',
                            iconColor: '#ff4d3c',
                            data: this.state.overview.totalContentSales,
                          },
                          {
                            label: _('finances:overview.box.totalsales.service0900'),
                            icon: 'icon-earphone',
                            iconColor: '#ffb033',
                            data: getFormattedDuration(this.state.overview.totalVX0900Duration),
                          },
                          {
                            label: _('finances:overview.box.totalsales.ba6'),
                            icon: 'icon-earphone',
                            iconColor: '#ffb033',
                            data: getFormattedDuration(this.state.overview.totalBA6Duration),
                          },
                        ]}
                        footnote={`( ${_(
                          'finances:overview.selectedTimespan'
                        )}: ${getFormattedPeriod(
                          this.state.overview.selectedPeriodStart,
                          this.state.overview.selectedPeriodEnd,
                          lang
                        )} )`}
                      />
                    </div>
                  </div>
                </div>

                {/* UMSATZVERTEILUNG */}
                <div className="grid__box__column grid__box__sub" style={{ flexBasis: 'auto' }}>
                  <div className="grid__box__sub__header">
                    <div
                      className={`${
                        this.isTabOpened(0) ? 'grid__box__sub__header__item--active' : ''
                      } grid__box__sub__header__item--tab`}
                      onClick={this.openTab.bind(this, 0)}
                    >
                      <T _={'finances:overview.box.charts.breakdownOfSales.header'} />
                    </div>
                    <Tooltip
                      position="bottom"
                      text={_('finances:overview.box.charts.breakdownOfSales.tooltip')}
                      className="grid__box__sub__header__item"
                    />

                    <div
                      className={`grid__box__sub__header__item--tab ${
                        this.isTabOpened(1) ? 'grid__box__sub__header__item--active' : ''
                      }`}
                      onClick={this.openTab.bind(this, 1)}
                    >
                      <T _={'finances:overview.box.charts.dailyStat.header'} />
                    </div>
                    <Tooltip
                      position="bottom"
                      text={_('finances:overview.box.charts.dailyStat.tooltip')}
                      className="grid__box__sub__header__item"
                    />
                  </div>

                  <div className="grid__box__sub__item">
                    <div
                      className="grid__box__sub__item__content"
                      style={{ minWidth: 450, backgroundColor: WHITE }}
                    >
                      {this.state.overview.turnoverGroups.length <= 0 && (
                        <EmptyContent
                          icon="icon-charts"
                          title={_('finances:overview.overview.turnoverNotFound')}
                        />
                      )}
                      {this.state.overview.turnoverGroups.length > 0 && (
                        <Fragment>
                          {/* Umsatzverteilung */}
                          {this.isTabOpened(0) && (
                            <article>
                              {/* eslint-disable-next-line react/no-string-refs */}
                              <div ref="chartNode" style={{ width: '100%', height: 250 }} />
                            </article>
                          )}

                          {/* Tagesstatistik */}
                          {this.isTabOpened(1) && (
                            <article>
                              {/* eslint-disable-next-line react/no-string-refs */}
                              <div ref="chartNode2" style={{ width: '100%', height: 250 }} />
                            </article>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!this.userPrivileged() ? (
              <EmptyContent title={_('common:forbidden.text')} />
            ) : (
              /* UMSATZ LISTE */
              <div className="grid__box__item">
                {this.state.overview.turnoverGroups.length > 0 && (
                  <div
                    className="grid__box__item__content drop-down-table min-width--0"
                    style={{ flexBasis: 0 }}
                  >
                    <div className="drop-down-table__header">
                      <div className="drop-down-table__header__item column_cat-name">
                        <T _={'finances:overview.datalist.header.turnoverType'} />
                      </div>
                      <div className="drop-down-table__header__item column_cat-amount">
                        <T _={'finances:overview.datalist.header.amount'} />
                      </div>
                    </div>

                    {this.state.overview.turnoverGroups.map((item) => (
                      <OverviewDataList
                        key={item.group}
                        label={item.group}
                        color={item.color}
                        data={this.state.overview.turnoverDetails[item.group]}
                      />
                    ))}

                    <div className="drop-down-table__footer">
                      <div className="drop-down-table__footer__item column_cat-name">
                        <T _={'finances:overview.box.totalsales.sum'} />
                      </div>

                      <div className="drop-down-table__footer__item column_cat-amount">
                        <FormatCurrency value={this.state.overview.totalAmountForSelectedPeriod} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* VISIT-X VERDIENSTMOEGLICHKEITEN */}
        {this.state.overview.showVISITXIncomeSources && (
          <div className="grid__row">
            <div className="grid__column grid__box">
              <div className="grid__box__header">
                <div className="grid__box__header__title">
                  {_('finances:overview.info.header')} VXModels
                </div>
              </div>

              {!this.userPrivileged() && <EmptyContent title={_('common:forbidden.text')} />}

              <div className="grid__box__row finances-overview grid__row--wrap">
                {this.userPrivileged() && (
                  <Fragment>
                    {/* VIDEOCHATS */}
                    <div className="grid__box__column grid__box__sub finances-overview__income-source__item">
                      <div className="grid__box__sub__header">
                        <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                          <T _={'finances:overview.info.box.videochats.header'} />
                        </div>
                        <Tooltip
                          position="right"
                          text={_('finances:overview.info.box.videochats.headerTooltip')}
                        />
                      </div>

                      <div className="grid__box__sub__item">
                        <div
                          className="grid__box__sub__item__content finances-overview__salestypes"
                          style={{ backgroundColor: WHITE }}
                        >
                          <div>
                            <div>
                              <T _={'finances:overview.info.box.videochats.chatVX'} />
                            </div>
                            <div>
                              <T
                                _={'finances:overview.info.box.videochats.chatVXProvision'}
                                sprintf={[this.state.overview.sharingChat]}
                              />
                            </div>
                          </div>
                          <div>
                            <div>
                              <T _={'finances:overview.info.box.videochats.chatPartner'} />
                            </div>
                            <div>
                              {_('finances:overview.info.box.videochats.chatPartnerProvision')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* MEHWERTDIENST */}
                    <div className="grid__box__column grid__box__sub finances-overview__income-source__item">
                      <div className="grid__box__sub__header">
                        <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                          <T _={'finances:overview.info.box.service0900.header'} />
                        </div>
                        <Tooltip
                          position="bottom"
                          text={_('finances:overview.info.box.service0900.headerTooltip')}
                        />
                      </div>

                      <div className="grid__box__sub__item">
                        <div
                          className="grid__box__sub__item__content finances-overview__salestypes"
                          style={{ backgroundColor: WHITE }}
                        >
                          <div>
                            <div>
                              <T _={'finances:overview.info.box.service0900.nbr0900'} />
                            </div>
                            <div>
                              {_('finances:overview.info.box.service0900.nbr0900Provision')}
                            </div>
                          </div>
                          <div>
                            <div>
                              <T _={'finances:overview.info.box.service0900.nbr0900tv'} />
                            </div>
                            <div>
                              {_('finances:overview.info.box.service0900.nbr0900tvProvision')}
                            </div>
                          </div>
                          <div>
                            <div>
                              <T _={'finances:overview.info.box.service0900.nbr0900bas'} />
                            </div>
                            <div>
                              {_('finances:overview.info.box.service0900.nbr0900basProvision')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>

              {this.userPrivileged() && (
                <div className="grid__box__row grid__row--wrap">
                  {/* CONTENTVERKÄUFE */}
                  <div className="grid__box__column grid__box__sub finances-overview__income-source__item">
                    <div className="grid__box__sub__header">
                      <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                        <T _={'finances:overview.info.box.contentsales.header'} />
                      </div>
                      <Tooltip
                        position="right"
                        text={_('finances:overview.info.box.contentsales.headerTooltip')}
                      />
                    </div>

                    <div className="grid__box__sub__item">
                      <div
                        className="grid__box__sub__item__content finances-overview__salestypes"
                        style={{ backgroundColor: WHITE }}
                      >
                        <div>
                          <div>
                            <T _={'finances:overview.info.box.contentsales.shopVX'} />
                          </div>
                          <div>
                            <T
                              _={'finances:overview.info.box.contentsales.shopVXProvision'}
                              sprintf={[this.state.overview.sharingShop]}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <T _={'finances:overview.info.box.contentsales.shopPartner'} />
                          </div>
                          <div>
                            {_('finances:overview.info.box.contentsales.shopPartnerProvision')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* SONSTIGES */}
                  <div className="grid__box__column grid__box__sub finances-overview__income-source__item">
                    <div className="grid__box__sub__header">
                      <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                        <T _={'finances:overview.info.box.other.header'} />
                      </div>
                      <Tooltip
                        position="bottom"
                        text={_('finances:overview.info.box.other.headerTooltip')}
                      />
                    </div>

                    <div className="grid__box__sub__item">
                      <div
                        className="grid__box__sub__item__content finances-overview__salestypes"
                        style={{ backgroundColor: WHITE }}
                      >
                        <div>
                          <div>
                            <T _={'finances:overview.info.box.other.TVSMS'} />
                          </div>
                          <div>
                            <T _={'finances:overview.info.box.other.TVSMSProvision'} />
                          </div>
                        </div>
                        {hasTelegram && (
                          <div>
                            <div>
                              <T _={'finances:overview.info.box.other.Telegram'} />
                            </div>
                            <div>{[this.state.overview.sharingTelegram + '%']}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* VXPAGES */}
        {this.state.overview.showVXPAGESIncomeSources && (
          <Section title={_('finances:overview.info.header') + ' VXPAGES'}>
            <div
              css={{
                flexWrap: 'wrap',
                justifyContent: 'center',
                backgroundColor: WHITE_3,
                padding: 24,
              }}
            >
              <div
                css={{
                  flexDirection: 'column',
                  flex: '1 1 345px',
                  maxWidth: 700,
                  margin: '32px 32px 0 32px',
                }}
              >
                <p css={{ fontSize: 16, marginBottom: 32 }}>
                  <T _="finances:overview.vxpages.title" />
                </p>

                <ProvisionInfo
                  title={`${this.state.overview.sharingVXPages} % ${_('finances:overview.vxpages.sharingModelTitle')}`} // prettier-ignore
                  titleStyle={{ color: GREEN }}
                  description={`10% ${_('finances:overview.vxpages.sharingModelText')}`}
                  css={{ marginBottom: 32 }}
                />

                {/* hasScope check instead of hasRole for showReferalLinkBox since only platform is relevant! */}
                <ProvisionInfo
                  title={`${this.state.overview.sharingVXPagesStudio} % ${_('finances:overview.vxpages.sharingVXPagesStudioTitle')}`} // prettier-ignore
                  titleStyle={{ color: BLACK }}
                  description={_('finances:overview.vxpages.sharingVXPagesStudioText', { sprintf: ['10%'] })} // prettier-ignore
                  descriptionStyle={{ maxWidth: '536px' }}
                  showReferalLinkBox={hasScope([Scopes.VXPages])}
                  css={{ marginBottom: 24 }}
                />
              </div>

              <Cake42
                data={this.state.overview.sharingVXPages || 0}
                labels={[_('common:text.modelShare'), 'VXPages']}
                size={400}
              />
            </div>
          </Section>
        )}

        {/* IFACUS VERDIENSTMOEGLICHKEITEN */}
        {this.state.overview.showIFACUSIncomeSources && (
          <div className="grid__row">
            <div className="grid__column grid__box">
              <div className="grid__box__header">
                <div className="grid__box__header__title">IFACUS Network</div>
              </div>

              {!this.userPrivileged() && <EmptyContent title={_('common:forbidden.text')} />}

              <div className="grid__box__row finances-overview grid__row--wrap">
                {this.userPrivileged() && (
                  <Fragment>
                    {/* VIDEOCHATS */}
                    <div className="grid__box__column grid__box__sub finances-overview__income-source__item">
                      <div className="grid__box__sub__header">
                        <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                          Video chats
                        </div>
                      </div>

                      <div className="grid__box__sub__item">
                        <div className="grid__box__sub__item__content finances-overview__salestypes">
                          <div>
                            <div>Chat</div>
                            <div>{this.state.overview.sharingIfacusChat} ct/min</div>
                          </div>
                          <div>
                            <div>Tariff</div>
                            <div>{this.state.overview.tariffIfacusChat}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* PARTY CHAT */}
                    <div className="grid__box__column grid__box__sub finances-overview__income-source__item">
                      <div className="grid__box__sub__header">
                        <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                          Party chats
                        </div>
                      </div>

                      <div className="grid__box__sub__item">
                        <div className="grid__box__sub__item__content finances-overview__salestypes">
                          <div>
                            <div>Chat</div>
                            <div>{this.state.overview.sharingIfacusParty} ct/min</div>
                          </div>
                          <div>
                            <div>Tariff</div>
                            <div>{this.state.overview.tariffIfacusParty}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

Overview.propTypes = {};

Overview.defaultProps = {};

export default withAppState(Overview);
