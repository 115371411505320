import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { FormatDateTime, Markdown } from '../index';
import {
  Actions,
  Container,
  Content,
  DatePlaceholder,
  MediaContainer,
  TemplateBody,
  Text,
  Title,
  BigIcon,
  NotificationImage,
} from './styles';
import { useLang } from '../../util/AppState';
import { _ } from '../../util/translate';
import NotificationButton from './NotificationButton';

function getButtons(button) {
  const buttons = [];

  if (button) {
    buttons.push({
      type: 'button',
      label: _(button.label),
      href: button.href,
      target: button.target,
    });
  }

  buttons.push({
    type: buttons.length > 0 ? 'link' : 'button',
    label: _('common:button.markRead'),
    unreadOnly: true,
  });

  return buttons;
}

const NotificationContent = ({ notification, markRead, history }) => {
  const { title, text, date, params, type, read } = notification;
  const {
    meta: { icon = '', iconStyle, picture, button },
  } = { meta: {}, ...type };

  const [lang] = useLang();
  const imageSrc = (picture && picture[lang]) || params[`picture_${lang}`];
  const buttons = getButtons(button);

  return (
    <Container>
      {(icon || imageSrc) && (
        <MediaContainer>
          {imageSrc ? (
            <NotificationImage src={imageSrc} alt={`Picture of ${title}`} />
          ) : (
            <BigIcon>
              <span className={icon} style={iconStyle} />
            </BigIcon>
          )}
        </MediaContainer>
      )}

      <TemplateBody>
        <Content>
          <DatePlaceholder>
            <FormatDateTime value={date} />
          </DatePlaceholder>
          <Title
            css={{
              fontSize: '1.35rem',
              marginTop: 16,
              marginBottom: 0,
            }}
          >
            {title}
          </Title>
          <Text>
            <Markdown source={text} />
          </Text>
        </Content>

        <Actions>
          {buttons
            .filter(({ unreadOnly }) => !unreadOnly || !read)
            .map((buttonDefinition, index) => (
              <NotificationButton
                key={index}
                notification={notification}
                button={buttonDefinition}
                markRead={markRead}
              />
            ))}
        </Actions>
      </TemplateBody>
    </Container>
  );
};

NotificationContent.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    text: PropTypes.string,
    date: PropTypes.date,
    read: PropTypes.string,
    params: PropTypes.object,
    type: PropTypes.shape({
      meta: PropTypes.shape({
        icon: PropTypes.string,
        iconStyle: PropTypes.object,
        picture: PropTypes.shape({
          de: PropTypes.string,
          en: PropTypes.string,
        }),
        button: PropTypes.shape({
          label: PropTypes.string,
          href: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  markRead: PropTypes.func.isRequired,
  history: PropTypes.any,
};

NotificationContent.defaultProps = {};

export default withRouter(NotificationContent);
