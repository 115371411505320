import gql from 'graphql-tag';

export const QUERY_MODEL_VXPAGES_STATS_CUSTOMERS = gql`
  query QUERY_MODEL_VXPAGES_STATS_CUSTOMERS {
    model {
      vxpages {
        stats {
          customers {
            active
            regular
            online
            verified
          }
        }
      }
    }
  }
`;
