import React from 'react';
import PropTypes from 'prop-types';
import { _ } from '../../util/translate';

const BRAND_ID_MODELSERVICE = 1817679829;
const BRAND_ID_PAYMENT = 1817680663;

// these rejected routes need to have shorter strings than possible window.location.pathname string
const REJECTED_ROUTES_PATHNAMES = [
  '/camtool/telegram/chat/mobile',
  '/camtool/telegram/abos/active/modalabo',
  '/camtool/telegram/desktop',
];

const SupportChat = ({ username, userId, email, token, lang }) => {
  if (!(username && userId && email)) {
    // don't init
    return false;
  }

  window.$zopim ||
    ((d, s) => {
      const z = (window.$zopim = (c) => {
          z._.push(c);
        }),
        $ = (z.s = d.createElement(s)),
        e = d.getElementsByTagName(s)[0];

      z.set = (o) => {
        z.set._.push(o);
      };
      z._ = [];
      z.set._ = [];
      $.async = !0;
      $.setAttribute('charset', 'utf-8');
      $.src = '//v2.zopim.com/?' + token;
      z.t = +new Date();
      $.type = 'text/javascript';
      e.parentNode.insertBefore($, e);
    })(document, 'script');

  window.$zopim(() => {
    $zopim.custom = $zopim.custom || {};
    $zopim.livechat.button.setHideWhenOffline(true);
    $zopim.livechat.setLanguage(lang);
    $zopim.livechat.setGreetings({
      online: _('common:supportchat.greeting_online'),
      offline: _('common:supportchat.greeting_offline'),
    });
    $zopim.livechat.window.setTitle(_('common:supportchat.title'));
    $zopim.livechat.concierge.setName(_('common:supportchat.concierge_name'));
    $zopim.livechat.concierge.setTitle(_('common:supportchat.concierge_title'));
    $zopim.livechat.prechatForm.setGreetings(_('common:supportchat.greeting_prechat'));
    $zopim.livechat.departments.setVisitorDepartment('Modelservice');
    $zopim.livechat.departments.setLabel(_('common:supportchat.departments_title'));
    // Filters Brezzels Support out of options
    // $zopim.livechat.departments.filter(BRAND_ID_MODELSERVICE, BRAND_ID_PAYMENT);

    //$zopim.livechat.concierge.setAvatar('images.visit_x_concierge.png');
    //$zopim.livechat.addTags('pageTag');

    $zopim.livechat.setName(username);
    $zopim.livechat.setEmail(email);
    $zopim.livechat.addTags(userId);

    $zopim.livechat.setOnConnected(function () {
      // check for modelservice and payment and hide supportPicture chat if not both online
      for (let dep of $zopim.livechat.departments.getAllDepartments()) {
        if (dep.id === BRAND_ID_MODELSERVICE) {
          // id of modelservice
          $zopim.custom.modelServiceActive = dep.status === 'online';
        }
        if (dep.id === BRAND_ID_PAYMENT) {
          // id of payment
          $zopim.custom.paymentActive = dep.status === 'online';
        }
      }

      if (
        !$zopim.custom ||
        !$zopim.custom.modelServiceActive ||
        !$zopim.custom.paymentActive ||
        // forbidden routes
        REJECTED_ROUTES_PATHNAMES.some(
          (routeUrl) => window.location.pathname.indexOf(routeUrl) > -1
        )
      ) {
        $zopim.livechat.hideAll();
      }
    });
  });

  return false;
};

SupportChat.propTypes = {
  username: PropTypes.string,
  userId: PropTypes.number,
  email: PropTypes.string,
  token: PropTypes.string.isRequired,
  lang: PropTypes.oneOf(['de', 'en']).isRequired,
};

export default SupportChat;
