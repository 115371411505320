import { PropTypes, Theme } from '@material-ui/core';
import { CustomColor } from '../atoms/Checkbox/NewCheckbox';
import { BLACK, GRAY_2, GRAY_3 } from '../camtool-styles';
import { ValidationType } from './BuildValidationObject';

export const getPaletteColor = (theme: Theme, color?: string): ValidationType['color'] => {
  return (
    (color && theme.palette[color as PropTypes.Color & CustomColor]) || {
      // default color
      main: GRAY_2,
      light: GRAY_3,
      contrastText: BLACK,
    }
  );
};
