import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import NavSub from '../../components/NavSub/NavSub';
import { Box } from '@material-ui/core';
import ServerUtils from '../../util/ServerUtils';
import Footer from '../../components/Footer/Footer';
import HelpCenter from './HelpCenter';

class Help extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hotlineCode: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.getHotlineCode();
  }

  getHotlineCode() {
    ServerUtils.request('GET', '/v1/camtool/help/{userId}/code', null, (response) => {
      this.setState({ hotlineCode: response.token });
    });
  }

  render() {
    return (
      <main className="grid helpcenter">
        {this.state.hotlineCode && (
          <NavSub>
            <Box
              // this id is important for page scroll to top!
              id="helpcenter"
              display="flex"
              gridGap="5px"
              ml="auto"
              p="15px"
              alignSelf="flex-end"
              alignItems="center"
            >
              <span css={{ fontSize: '20px' }} className="icon-qrcode" />
              <span css={{ fontSize: '15px' }}>{`Hotline Code: ${this.state.hotlineCode}`}</span>
            </Box>
          </NavSub>
        )}
        <HelpCenter path={'/helpcenter'} />
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

Help.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Help.defaultProps = {
  children: null,
};

export default Help;
