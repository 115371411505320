import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button } from '../../../atoms';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';
import withSlideContainer, { GetBackgroundImageCallback } from './withSlideContainer';
import { bgBAS, logoBAS } from './assets';
import { useHistory } from 'react-router';

const Container = styled.div`
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  margin-bottom: 16px;
`;

const List = styled.ul`
  margin-bottom: 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-position: inside;
`;

const ListItem = styled.li`
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 8px;
`;

const BUTTON_ROUTE = `${APP_BASE_PATH}/service0900/phone-sex/`;

const getBackgroundImage: GetBackgroundImageCallback = () => bgBAS;

const SlideBAS: FC = () => {
  const history = useHistory();

  return (
    <Container>
      <Logo src={logoBAS} />
      <List>
        <ListItem>{_('dashboard:newsticker.slides.bas.item1')}</ListItem>
        <ListItem>{_('dashboard:newsticker.slides.bas.item2')}</ListItem>
      </List>
      <Button theme="blue" size="lg" onClick={() => history.push(BUTTON_ROUTE)}>
        {_('dashboard:newsticker.slides.bas.button')}
      </Button>
    </Container>
  );
};

export default withSlideContainer(SlideBAS, getBackgroundImage);
