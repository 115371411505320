import React, { FC } from 'react';
import { vxOutline } from '../../../../../atoms/Icon/libraries/svg';
import { _ } from '../../../../../util/translate';
import { LeftMenuItem } from '../../../components';

const OtherServicesItem: FC = () => (
  <LeftMenuItem
    title={_('navigation:homepage.otherServices')}
    icon={vxOutline}
    uri="/website/services"
  />
);

export default OtherServicesItem;
