import React, { FC } from 'react';
import { SectionLabel } from '../../../styles';
import { Translation } from '../../../../../../components';
import FieldCheckbox from '../../../../../../molecules/Formik/Fields/FieldCheckbox';
import FieldDatePicker from '../../../../../../molecules/Formik/Fields/FieldDatePicker';
import Fieldset from '../../../../../../atoms/Fieldset/Fieldset';
import { Box } from '@material-ui/core';

interface Props {
  disabled: boolean;
  checkboxDisabled: boolean;
}

const FieldPublishScheduled: FC<Props> = ({ disabled, checkboxDisabled }) => {
  return (
    <Fieldset
      disabled={disabled}
      css={{ marginBottom: 24 }}
      legend={
        <FieldCheckbox
          name={'publishScheduled'}
          disabled={checkboxDisabled}
          label={<Translation t={'mailings:form.publishScheduled'} />}
        />
      }
    >
      <SectionLabel>
        <Box mt={1}>
          <Translation t={'mailings:email.form.sendDate'} />
        </Box>
      </SectionLabel>
      <FieldDatePicker name={'published'} disabled={disabled} />
    </Fieldset>
  );
};

export default FieldPublishScheduled;
