import React, { FC } from 'react';
import { BLACK, DARK_GRAY, RED } from '../../../../camtool-styles';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { VXPagesUser } from '../../../../graphql/VXModels/types';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '&.Mui-disabled': {
      color: DARK_GRAY,
    },
  },
  label: {
    fontSize: '12px',
    color: BLACK,
    '&.Mui-disabled': {
      color: DARK_GRAY,
    },
  },
  spanLabel: {
    padding: 4,
  },
});

export const FavouriteCustomerRenderer: FC<Pick<VXPagesUser, 'id' | 'note'>> = ({ note }) => {
  const { root } = useStyles();

  return (
    <Box display="flex" justifyContent="center">
      {note.isRegularCustomer ? (
        <Favorite classes={{ root }} htmlColor={RED} fontSize="large" />
      ) : (
        <FavoriteBorder classes={{ root }} htmlColor={DARK_GRAY} fontSize="large" />
      )}
    </Box>
  );
};
