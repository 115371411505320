import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../atoms';
import { Notification, NotificationTypeMetaButtonTarget } from '../../graphql/VXModels/types';
import { Row, TextLink } from './styles';
import { ButtonType, ButtonDefinition } from './types';

export interface Props {
  notification: Notification;
  button: ButtonDefinition;
  markRead: (id: number) => void;
}

const NotificationButton: FC<Props> = ({ button, notification, markRead }) => {
  const { type, label, href, target } = button;
  const { id, read } = notification;

  const history = useHistory();

  const handleHrefClick = (href: string): void => {
    if (target === NotificationTypeMetaButtonTarget._blank)
      window.open(href, '_blank', 'noopener noreferrer', true);
    else history.push(href);
  };

  const onClick = async (): Promise<void> => {
    if (!read) markRead(id);
    if (href) handleHrefClick(href);
  };

  return (
    <Row>
      {type === ButtonType.button ? (
        <Button theme="blue" label={label} onClick={onClick} />
      ) : (
        <TextLink onClick={onClick}>{label}</TextLink>
      )}
    </Row>
  );
};

export default NotificationButton;
