import { hasScope, Scopes } from './scope';
import { APP_BASE_PATH } from './env';

export const helpcenterOverview = `${APP_BASE_PATH}/help/helpcenter/categories/`;

export const getPhoneSexArticleUrl = (): string => {
  return `${APP_BASE_PATH}/help/helpcenter/categories/${
    hasScope([Scopes.VXModels])
      ? `360002286959/articles/360010946640/`
      : `360001679099/articles/360008976399`
  }`;
};

export const getPictureAndVideoTopics = (): string => {
  return `${APP_BASE_PATH}/help/helpcenter/categories/360002285499/articles`;
};

export const getSoftChatArticleUrl = (): string => {
  return `${APP_BASE_PATH}/help/helpcenter/categories/360002382800/articles/360011989219`;
};

export const getConversionChatArticleUrl = (): string => {
  return `${APP_BASE_PATH}/help/helpcenter/categories/360002382800/articles/360011886440`;
};
