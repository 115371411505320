import React, { createRef, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { MaterialTable } from '../../../atoms';
import { useAsyncCustomerListQuery } from '../hooks';
import { buildUrl, getColumnsConfig } from '../config';
import { _ } from '../../../util/translate';
import { BREAKPOINT_PHONE_CONDITION } from '../../../camtool-styles';
import { useMediaQuery } from '@material-ui/core';

interface CustomerListProps {
  canSeeDirectUserData: boolean;
}

const DEBOUNCE_INTERVAL = 300;

export const CustomerList: FC<CustomerListProps> = ({ canSeeDirectUserData }) => {
  const { push } = useHistory();
  const ref = createRef();
  const fetchData = useAsyncCustomerListQuery({ canSeeDirectUserData });

  const onChangePage = (): void => (ref.current.tableContainerDiv.current.scrollTop = 0);
  const isMobile = useMediaQuery(BREAKPOINT_PHONE_CONDITION);

  return (
    <MaterialTable
      onRowClick={(event, { id }): void => push(buildUrl(id))}
      columns={getColumnsConfig({ canSeeDirectUserData, padding: 16 })}
      data={fetchData}
      tableRef={ref}
      options={{
        toolbar: true,
        sorting: true,
        search: true,
        showTitle: false,
        rowStyle: { height: 64 },
        headerStyle: {
          flexDirection: isMobile ? 'column' : 'row',
          padding: '0px 5px',
          wordBreak: 'normal',
        },
        maxBodyHeight: 'calc(100vh - 338px)',
        debounceInterval: DEBOUNCE_INTERVAL,
        searchFieldVariant: 'outlined',
      }}
      localization={{
        toolbar: { searchPlaceholder: _('userManagement:overview.searchPlaceholder') },
      }}
      onChangePage={onChangePage}
    />
  );
};
