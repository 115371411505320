import React, { FC } from 'react';
import { css } from '@emotion/core';
import { VXPagesUser } from '../../../../../graphql/VXModels/types';
import { Section } from '../../../../../atoms';
import { BLACK_2, BREAKPOINT_TABLET, WHITE_3 } from '../../../../../camtool-styles';
import { sectionMargin } from '../../../../../atoms/Grid/Section';
import { _ } from '../../../../../util/translate';
import CurrentBalance from './CurrentBalance';
import EditVouchersGiftContent from './EditVouchersGiftContent';
import UserLockedPlaceholder from './UserLockedPlaceholder';

interface IProps {
  user: VXPagesUser;
}

const sectionStyles = css`
  padding: 37px 30px;
  background-color: ${WHITE_3};
  color: ${BLACK_2};
  ${BREAKPOINT_TABLET} {
    flex: 1 1 100%;
    padding: 0;
  }
`;

const SectionEditVouchersGiftContent: FC<IProps> = ({ user }: IProps) => {
  const { isLocked } = user;

  return (
    <Section
      title={_('userManagement:details.section.giftContent.title')}
      styles={sectionStyles}
      css={{ width: `calc(50% - 2 * ${sectionMargin}px)` }}
    >
      <CurrentBalance user={user} />

      {isLocked ? <UserLockedPlaceholder user={user} /> : <EditVouchersGiftContent user={user} />}
    </Section>
  );
};

export default SectionEditVouchersGiftContent;
