import React, { Fragment } from 'react';
import AbstractProfile from '../../AbstractProfile';
import { PROFILE_VX_OVERVIEW } from '../../../../stores/Profile/Profile';
import { hasRole } from '../../../../util/UserData';
import { SecurityRole } from '../../../../graphql/VXModels/types';

import NavSubVX from '../NavSubVX';

import {
  T,
  ButtonGroup,
  Footer,
  Form,
  GroupRow,
  MultiLangTextareafield,
  MultiLangInputfield,
  OptionBoxRadio,
  OptionBoxCheckbox,
  Separator,
  Spinner,
} from '../../../../components';

import { Button } from '../../../../atoms';
import { _ } from '../../../../util/translate';

class Common extends AbstractProfile {
  getResource() {
    return PROFILE_VX_OVERVIEW;
  }

  render() {
    super.render();
    this.spokenLangs = this.storeValues.profileLanguages;

    return (
      <main id="vx-profile" className="vx-profile grid">
        <NavSubVX />
        <section className="grid__row">
          <section className="grid__column grid__box min-width--0">
            <header className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'profiles:visitx.profile.pagetitle.label'} />
              </div>
            </header>

            <Form
              className="grid__box__item spinner-container"
              // eslint-disable-next-line
              ref="form"
              onValidSubmit={this.submitForm}
              onInvalidSubmit={this.showErrorFields}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              onChange={this.onChange}
            >
              {this.state.isLoading && <Spinner />}

              <div className="grid__box__item__content vx-profile__formwidth">
                {!hasRole(SecurityRole.VX_USER_SEXOLE) && (
                  <Fragment>
                    <GroupRow
                      id="camLocation"
                      label={`${_('profiles:vxShowLocation.label')}:`}
                      tooltipText={_('profiles:vxShowLocation.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxRadio
                        value={this.storeValues.vxShowLocation}
                        name="vxShowLocation"
                        forceTranslate={true}
                        options={this.storeValues.formValues.vxShowLocation}
                        iconPrefix="icon-"
                        onChange={this.onChangeField}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>
                  </Fragment>
                )}

                <Fragment>
                  <GroupRow
                    id="chatMotto"
                    tooltipText={_('profiles:vxShowText.tooltipText')}
                    tooltipPosition="right"
                    label={`${_('profiles:vxShowText.label')}:`}
                    className={this.hasInputError('vxShowText') ? 'error' : ''}
                    errorMessage={this.state.displayErrorInputs['vxShowText']}
                  >
                    <MultiLangInputfield
                      validations="isValidMultiLangField"
                      validationError={_('common:validationMessage.isValidMultiLangField')}
                      displayErrors={this.state.displayErrors}
                      name="vxShowText"
                      value={this.storeValues.vxShowText.texts}
                      placeholder={_('profiles:vxShowText.placeholder')}
                      langs={this.spokenLangs}
                      maxLength={35}
                    />
                  </GroupRow>

                  <GroupRow tooltip="none">
                    <Separator className="transparent" />
                  </GroupRow>
                </Fragment>

                {!hasRole(SecurityRole.VX_USER_SEXOLE) && (
                  <Fragment>
                    <GroupRow
                      id="chatPractices"
                      label={`${_('profiles:vxShowType.label')}:`}
                      tooltipText={_('profiles:vxShowType.tooltipText')}
                      tooltipPosition="right"
                      className={this.hasInputError('vxShowType') ? 'error' : ''}
                      errorMessage={this.state.displayErrorInputs['vxShowType']}
                    >
                      <OptionBoxCheckbox
                        required
                        requiredMessage={_('profiles:vxShowType.requiredMessage')}
                        name="vxShowType"
                        value={this.storeValues.vxShowType}
                        options={this.storeValues.formValues.vxShowType}
                        maxLength={3}
                        disabled={this.storeValues.isVxShowTypeDisabled}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>

                    <GroupRow
                      id="camSchedule"
                      label={`${_('profiles:vxOnlineSchedule.label')}:`}
                      tooltipText={_('profiles:vxOnlineSchedule.tooltipText')}
                      tooltipPosition="right"
                      className={this.hasInputError('vxOnlineSchedule') ? 'error' : ''}
                      errorMessage={this.state.displayErrorInputs['vxOnlineSchedule']}
                    >
                      <MultiLangTextareafield
                        validations="isValidMultiLangField"
                        validationError={_('common:validationMessage.isValidMultiLangField')}
                        displayErrors={this.state.displayErrors}
                        name="vxOnlineSchedule"
                        value={this.storeValues.vxOnlineSchedule.texts}
                        placeholder={_('profiles:vxOnlineSchedule.placeholder')}
                        langs={this.spokenLangs}
                        maxLength={500}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>

                    <GroupRow
                      id="preferredSexType"
                      label={`${_('profiles:vxPreferredSexType.label')}:`}
                      tooltipText={_('profiles:vxPreferredSexType.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxCheckbox
                        name="vxPreferredSexType"
                        value={this.storeValues.vxPreferredSexType}
                        forceTranslate={true}
                        options={this.storeValues.formValues.vxPreferredSexType}
                        onChange={this.onChangeField}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>

                    <GroupRow
                      id="chatOutfit"
                      label={`${_('profiles:vxClothing.label')}:`}
                      tooltipText={_('profiles:vxClothing.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxCheckbox
                        name="vxClothing"
                        value={this.storeValues.vxClothing}
                        options={this.storeValues.formValues.vxClothing}
                        onChange={this.onChangeField}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>

                    <GroupRow
                      id="sexToys"
                      label={`${_('profiles:vxToys.label')}:`}
                      tooltipText={_('profiles:vxToys.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxCheckbox
                        name="vxToys"
                        value={this.storeValues.vxToys}
                        options={this.storeValues.formValues.vxToys}
                        onChange={this.onChangeField}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>

                    <GroupRow
                      id="preferredSexPractices"
                      label={`${_('profiles:vxPreferredPractices.label')}:`}
                      tooltipText={_('profiles:vxPreferredPractices.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxCheckbox
                        name="vxPreferredPractices"
                        value={this.storeValues.vxPreferredPractices}
                        options={this.storeValues.formValues.vxPreferredPractices}
                        maxLength={3}
                        onChange={this.onChangeField}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>
                  </Fragment>
                )}

                <ButtonGroup position="right">
                  <Button
                    theme="gray"
                    label={_('common:button.cancel')}
                    onClick={this.rejectChanges}
                    disabled={!this.state.hasChanges}
                    css={{ marginRight: 16 }}
                  />
                  <Button type="submit" theme="blue" label={_('common:button.save')} />
                </ButtonGroup>
              </div>
            </Form>
          </section>
        </section>

        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default Common;
