import { Mailing, MailingTypeEnum } from '../../graphql/VXModels/types';
import { APP_BASE_PATH } from '../../util/env';

export const buildEditUrl = (mailing: Mailing) => {
  switch (mailing.type) {
    case MailingTypeEnum.VXPAGES_EMAIL:
      return `${APP_BASE_PATH}/mailings/email/${mailing.id || ''}`;
    case MailingTypeEnum.VXPAGES_VXMESSENGER:
      return `${APP_BASE_PATH}/mailings/messenger/${mailing.id || ''}`;
    case MailingTypeEnum.VXPAGES_TELEGRAM:
      return `${APP_BASE_PATH}/mailings/telegram/archive/${mailing.id || ''}`;
    default:
      return `${APP_BASE_PATH}/mailings/`;
  }
};
