import React, { FC } from 'react';
import styled from '@emotion/styled';
import { VXPagesUser } from '../../../../../graphql/VXModels/types';
import UserBalanceLabel from '../../components/UserBalanceLabel';
import { _ } from '../../../../../util/translate';
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_PHONE,
  BREAKPOINT_SPHONE,
  BREAKPOINT_TABLET,
} from '../../../../../camtool-styles';

interface IProps {
  user: VXPagesUser;
}

const Container = styled.div`
  flex-direction: column;
`;

const CurrentAmountsList = styled.div`
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 25px;
  ${BREAKPOINT_PHONE} {
    flex-wrap: wrap;
  }
`;

const CurrentAmountValue = styled.div`
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.3333rem;

  ${BREAKPOINT_TABLET} {
    width: 50%;
    padding: 0 24px;
  }
  ${BREAKPOINT_SPHONE} {
    width: 100%;
  }
`;

const CurrentBalance: FC<IProps> = ({ user }) => {
  const { freeMessages, freeChatSeconds, freeVideos, freePictureAlbums } = user;

  return (
    <Container>
      <UserBalanceLabel user={user} />

      <CurrentAmountsList>
        <CurrentAmountValue>
          {_('userManagement:freeMessages.value', { sprintf: [freeMessages] })}
        </CurrentAmountValue>
        <CurrentAmountValue>
          {_('userManagement:freeChatSeconds.value', { sprintf: [freeChatSeconds] })}
        </CurrentAmountValue>
        <CurrentAmountValue>
          {_('userManagement:freeVideos.value', { sprintf: [freeVideos] })}
        </CurrentAmountValue>
        <CurrentAmountValue>
          {_('userManagement:freePictureAlbums.value', { sprintf: [freePictureAlbums] })}
        </CurrentAmountValue>
      </CurrentAmountsList>
    </Container>
  );
};

export default CurrentBalance;
