import { useLazyQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { Query, HelpCentersearchArgs } from '../../../../../graphql/VXModels/types';
import { QUERY_HELPCENTER_SEARCH } from '../../../../../graphql/VXModels/queries';
import { ApolloError } from 'apollo-client';

type Result = {
  onSearch: (variables: HelpCentersearchArgs) => void;
  data: Query | undefined;
  called: boolean;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useSearchHelpcenter = (
  options?: QueryHookOptions<Query, HelpCentersearchArgs>
): Result => {
  const [search, { data, called, loading, error }] = useLazyQuery<Query, HelpCentersearchArgs>(
    QUERY_HELPCENTER_SEARCH,
    {
      ...options,
    }
  );

  const onSearch = (variables: HelpCentersearchArgs): void =>
    search({ variables: { ...variables } });

  return { onSearch, data, called, loading, error };
};
