import React, { FC } from 'react';
import styled from '@emotion/styled';
import { _ } from '../../../util/translate';
import withSlideContainer from './withSlideContainer';
import { WHITE_2, WHITE_3 } from '../../../camtool-styles';
import { getCurrentScopeSupportMail } from '../../../util/emailAdresses';

const Container = styled.div`
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 4px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: ${WHITE_3} !important;
`;

const Items = styled.div`
  margin-top: 32px;
`;

const Item = styled.a`
  color: ${WHITE_2};
  transition: opacity 250ms ease-in-out;

  &:not(:first-of-type) {
    margin-left: 56px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const SlideHelpVXPages: FC = () => (
  <Container>
    <Title>{_('dashboard:newsticker.slides.helpVXPages.title')}</Title>
    <Subtitle>{_('dashboard:newsticker.slides.helpVXPages.subTitle')}</Subtitle>

    <Items>
      <Item href={`mailto:${getCurrentScopeSupportMail()}`}>
        <span className="fas fa-envelope fa-4x" />
      </Item>
    </Items>
  </Container>
);

export default withSlideContainer(SlideHelpVXPages);
