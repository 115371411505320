import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router';
import { stripSlash } from '../../util/urlHelper';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import { useHasRole } from '../../util/UserData';
import { SecurityRole } from '../../graphql/VXModels/types';
import SiteStatistics from './Site/SiteStatistics';
import { CREDITS, SITE } from './routes';
import Navigation from './Navigation';
import { Main } from '../../atoms/Grid';
import CreditsStatistics from './Credits/CreditsStatistics';

const Statistics: FC<RouteComponentProps> = ({ match: { url } }) => {
  const path = stripSlash(url);
  const siteRoute = `${path}/${SITE}`;

  const hasVXPageInHomepageMode = useHasRole(SecurityRole.VX_USER_VXPAGES_HP);

  return (
    <Main>
      {hasVXPageInHomepageMode && <Navigation basePath={path} />}
      <Switch>
        <ProtectedRoute
          path={siteRoute}
          exact
          component={SiteStatistics}
          verify={() => hasVXPageInHomepageMode}
        />
        <ProtectedRoute
          path={`${path}/${CREDITS}`}
          exact
          component={CreditsStatistics}
          verify={() => hasVXPageInHomepageMode}
        />
        <Redirect to={siteRoute} />
      </Switch>
    </Main>
  );
};

export default Statistics;
