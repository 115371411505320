import React, { FC, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import qs from 'query-string';
import { Footer, IFrame } from '../../../components';
import { REACT_APP_VXTOOL_HOST } from '../../../util/env';
import { useAppState } from '../../../util/AppState';

const Usernotes: FC<RouteComponentProps> = ({ location: { search } }) => {
  const [{ sessionId, lang }] = useAppState();

  const params = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
  const iframeQueryString = qs.stringify({
    ...params,
    lang: lang.toUpperCase(),
    pfm: '001',
    sids: sessionId,
    page: 404,
    nohead: 1,
    debug: 0,
  });

  const iframeUrl = `${REACT_APP_VXTOOL_HOST}/sendertool/index.php?${iframeQueryString}`;

  return (
    <main id="marketing-usernotes" className="grid marketing-usernotes">
      <div className="grid__row">
        <div className="grid__column grid__box">
          <div className="grid__box__item">
            <div className="grid__box__item__content">
              <IFrame height="800" src={iframeUrl} />
            </div>
          </div>
        </div>
      </div>
      <div className="grid__row grid__box">
        <Footer className="grid__box__item" />
      </div>
    </main>
  );
};

export default Usernotes;
