import { addDays, addMonths, min, subDays, subMinutes, subMonths, subSeconds } from 'date-fns';
import {
  DataByTimeframe,
  ITimeframe,
  ITimeframeData,
  RowDataItem,
  TimeframeKey,
  Timeframes,
  TimeframeValue,
} from '../types';

const subOneSecond = (date: Date): Date => subSeconds(date, 1);

/**
 * Example: Converts new Date('2020-01-07T17:05:00.000+0100')
 *                to new Date('2020-01-07T17:05:00.000T')
 * @param date
 */
const utcDateFromLocal = (date: Date) => subMinutes(date, date.getTimezoneOffset());

export const getTimeframes = (
  currentDayStart: Date,
  customMonthStart: Date | undefined
): Timeframes => {
  // We work with local dates as if they were the correct UTC dates first
  // and convert them to UTC after all calculations are done

  const currentMonthStart = new Date(currentDayStart.getFullYear(), currentDayStart.getMonth(), 1);

  return {
    today: {
      start: utcDateFromLocal(currentDayStart),
      end: utcDateFromLocal(subOneSecond(addDays(currentDayStart, 1))),
    },
    yesterday: {
      start: utcDateFromLocal(subDays(currentDayStart, 1)),
      end: utcDateFromLocal(subOneSecond(currentDayStart)),
    },
    twoDaysAgo: {
      start: utcDateFromLocal(subDays(currentDayStart, 2)),
      end: utcDateFromLocal(subOneSecond(subDays(currentDayStart, 1))),
    },
    sevenDays: {
      start: utcDateFromLocal(subDays(currentDayStart, 7)),
      end: utcDateFromLocal(subOneSecond(currentDayStart)),
    },
    thisMonth: {
      start: utcDateFromLocal(currentMonthStart),
      end: utcDateFromLocal(subOneSecond(addDays(currentDayStart, 1))),
    },
    lastMonth: {
      start: utcDateFromLocal(subMonths(currentMonthStart, 1)),
      end: utcDateFromLocal(subOneSecond(currentMonthStart)),
    },
    custom: customMonthStart
      ? {
          start: utcDateFromLocal(customMonthStart),
          end: utcDateFromLocal(
            subOneSecond(min([addMonths(customMonthStart, 1), addDays(currentDayStart, 1)]))
          ),
        }
      : null,
  };
};

const areTimeframesEqual = (timeframe1: TimeframeValue, timeframe2: TimeframeValue) =>
  !timeframe1 || !timeframe2
    ? timeframe1 === null && timeframe1 === timeframe2
    : new Date(timeframe1.start).getTime() === new Date(timeframe2.start).getTime() &&
      new Date(timeframe1.end).getTime() === new Date(timeframe2.end).getTime();

export const getDefaultTimeframesForGraphQLQuery = (timeframes: Timeframes): ITimeframe[] =>
  (Object.keys(timeframes)
    .filter(key => key !== TimeframeKey.custom)
    .map(key => timeframes[key as TimeframeKey])
    .filter(timeframe => timeframe !== null) as ITimeframe[])
    // Remove duplicates
    .filter(
      (timeframe, index, array) =>
        index === array.findIndex(testTimeframe => areTimeframesEqual(timeframe, testTimeframe))
    );

export const getCustomTimeframeForGraphQLQuery = (
  timeframes: Timeframes,
  defaultTimeframesForGraphQLQuery: ITimeframe[]
): ITimeframe | undefined => {
  const customTimeframe = timeframes.custom;

  // No need to send a second query if the custom timeframe is equal to a default one
  const isDefaultTimeframe =
    defaultTimeframesForGraphQLQuery.findIndex(defaultTimeframe =>
      areTimeframesEqual(defaultTimeframe, customTimeframe)
    ) !== -1;

  return isDefaultTimeframe || customTimeframe === null ? undefined : customTimeframe;
};

const getTimeframeDataForKey = <DataType extends any>(
  items: Array<ITimeframeData<DataType>>,
  key: TimeframeKey,
  timeframes: Timeframes
): DataType | undefined => {
  const timeframe = timeframes[key];

  return Object.values(items).find(({ timeframe: testTimeframe }) =>
    areTimeframesEqual(timeframe, testTimeframe)
  )?.data;
};

export const getDataByTimeframe = <DataType extends any>(
  items: Array<ITimeframeData<DataType>>,
  timeframes: Timeframes
): DataByTimeframe<DataType | undefined> => {
  const result: Partial<DataByTimeframe<DataType>> = {};

  Object.values(TimeframeKey).forEach(key => {
    result[key] = getTimeframeDataForKey<DataType>(items, key, timeframes);
  });

  return result as DataByTimeframe<DataType>;
};

const mapDataByTimeframe = <OriginalDataType extends any, ResultDataType extends any>(
  dataByTimeframe: DataByTimeframe<OriginalDataType>,
  callback: (data: OriginalDataType, timeframeKey: TimeframeKey) => ResultDataType
): DataByTimeframe<ResultDataType> => {
  const result: Partial<DataByTimeframe<ResultDataType>> = {};

  (Object.keys(dataByTimeframe) as TimeframeKey[]).forEach(key => {
    result[key] = callback(dataByTimeframe[key], key);
  });

  return result as DataByTimeframe<ResultDataType>;
};

const getRowDataFromDataByTimeframe = (
  dataByTimeframe: DataByTimeframe<RowDataItem>
): RowDataItem[] => Object.values(TimeframeKey).map(key => dataByTimeframe[key]);

export const deriveRowDataFromDataByTimeframe = <OriginalDataType extends any>(
  dataByTimeframe: DataByTimeframe<OriginalDataType>,
  callback: (data: OriginalDataType, timeframeKey: TimeframeKey) => RowDataItem
): RowDataItem[] =>
  getRowDataFromDataByTimeframe(
    mapDataByTimeframe<OriginalDataType, RowDataItem>(dataByTimeframe, callback)
  );

export const deriveRowDataFromIncompleteDataByTimeframe = <OriginalDataType extends any>(
  dataByTimeframe: DataByTimeframe<OriginalDataType | undefined>,
  callback: (data: OriginalDataType, timeframeKey: TimeframeKey) => RowDataItem
): RowDataItem[] =>
  deriveRowDataFromDataByTimeframe<OriginalDataType | undefined>(
    dataByTimeframe,
    (data, timeframeKey) => (data === undefined ? '-' : callback(data, timeframeKey))
  );

export const getDropdownDates = (registrationDate: Date): Date[] => {
  // We work with local dates as if they were the correct UTC dates first
  // and convert them to UTC in getTimeframes()

  const dates: Date[] = [];

  const stopDate = new Date(registrationDate.getUTCFullYear(), registrationDate.getUTCMonth(), 1); // the last month which we return equals the month in the UTC representation of the registrationDate
  const currentDate = new Date();
  let nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  while (nextDate >= stopDate) {
    dates.push(nextDate);
    nextDate = subMonths(nextDate, 1);
  }

  return dates;
};
