import React, { FC } from 'react';
import { Spinner } from '../../../../../../components';
import { PlaceholderContainer } from './styles';

const LoadingSpinner: FC = () => (
  <PlaceholderContainer>
    <Spinner />
  </PlaceholderContainer>
);

export default LoadingSpinner;
