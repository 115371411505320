import React, { FC } from 'react';
import { HELPCENTER_ARTICLE_365 } from '../../../../../util/helpcenterArticles';
import { _ } from '../../../../../util/translate';
import HelpcenterLink from './HelpcenterLink';

interface IProps {
  className?: string;
}

const DailyBonusHelpcenterLink: FC<IProps> = ({ className }) => (
  <HelpcenterLink
    label={_('mediamanagement:video.dailyBonus.helpcenterLink')}
    href={HELPCENTER_ARTICLE_365}
    className={className}
  />
);

export default DailyBonusHelpcenterLink;
