import { clearCookie, clearCookieAsync, getCookie, setCookie } from './CookieHelper';

const EXPIRATION_ONE_DAY = 1 * 24 * 60 * 60;
const EXPIRATION_ONE_YEAR = 365 * EXPIRATION_ONE_DAY;

const COOKIE_AUTH_TOKEN = 'authToken';
export const COOKIE_LANG = 'lang';
const COOKIE_LANG_OLD = 'i18n'; // Deprecation date: 2020-02-06
const COOKIE_XDEBUG_SESSION = 'XDEBUG_SESSION';
const COOKIE_LOGIN_REDIR = 'loginRedir';
export const COOKIE_BACKURL_MOBILE = 'backurlMobile';

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!   Read this data from the AppState,   !!!!
// !!!!    not directly from the cookies!     !!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const getLangCookie = () => getCookie(COOKIE_LANG) || getCookie(COOKIE_LANG_OLD);
export const setLangCookie = (lang: string) => setCookie(COOKIE_LANG, lang, EXPIRATION_ONE_YEAR);

export const getAuthTokenCookie = () => getCookie(COOKIE_AUTH_TOKEN);
export const setAuthTokenCookie = (value: string) =>
  setCookie(COOKIE_AUTH_TOKEN, value, EXPIRATION_ONE_DAY);
export const clearAuthTokenCookie = () => clearCookie(COOKIE_AUTH_TOKEN);

export const clearAuthTokenCookieAsync = () => clearCookieAsync(COOKIE_AUTH_TOKEN);

export const getBackurlMobileCookie = () => getCookie(COOKIE_BACKURL_MOBILE);
export const setBackurlMobileCookie = (value: string) =>
  setCookie(COOKIE_BACKURL_MOBILE, value, EXPIRATION_ONE_DAY);
export const clearBackurlMobileCookie = () => clearCookie(COOKIE_BACKURL_MOBILE);
export const clearBackurlMobileCookieAsync = () => clearCookieAsync(COOKIE_BACKURL_MOBILE);

export const getSessionIdCookie = () => getAuthTokenCookie(); // for now, session id and auth token are the same, that will be changed in future

export const getXdebugSessionCookie = () => getCookie(COOKIE_XDEBUG_SESSION);

export const getLoginRedirCookie = () => getCookie(COOKIE_LOGIN_REDIR);
export const clearLoginRedirCookie = () => clearCookie(COOKIE_LOGIN_REDIR);

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!   Read this data from the AppState,   !!!!
// !!!!    not directly from the cookies!     !!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
