import React, { FC } from 'react';
import { VoucherTypeEnum, VXPagesUser } from '../../../../../graphql/VXModels/types';
import { _ } from '../../../../../util/translate';
import ChangeVoucherAmount from '../../components/ChangeVoucherAmount/ChangeVoucherAmount';
import InnerSection from '../../components/InnerSection';

interface IProps {
  user: VXPagesUser;
}

const EditCredits: FC<IProps> = ({ user }) => {
  return (
    <InnerSection title={_('userManagement:details.section.credits.changeCreditsBalance')}>
      <ChangeVoucherAmount user={user} voucherType={VoucherTypeEnum.credits} />
    </InnerSection>
  );
};

export default EditCredits;
