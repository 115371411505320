import React, { FC } from 'react';
import { LeftMenuGroup } from '../../components';
import { _ } from '../../../../util/translate';
import {
  RankingItem,
  Service0900Item,
  VXGamesItem,
  CompetitionsItem,
  MarketingActionsItem,
  CommonProfileItem,
  VisitXProfileItem,
  LustagentenProfileItem,
} from '../items';
import { useHasRole } from '../../../../util/UserData';
import { SecurityRole } from '../../../../graphql/VXModels/types';
import { AffiliateFragment } from '../fragments';
import { vxOutline } from '../../../../atoms/Icon/libraries/svg';

const VisitXGroup: FC = () => {
  const hasRoleVXModels = useHasRole(SecurityRole.VX_USER_VXMODELS);

  return hasRoleVXModels ? (
    <LeftMenuGroup title={_('navigation:main.visitx')} icon={vxOutline}>
      <CommonProfileItem />
      <VisitXProfileItem />
      <LustagentenProfileItem />
      <Service0900Item />
      <AffiliateFragment />
      <RankingItem />
      <CompetitionsItem />
      <VXGamesItem />
      <MarketingActionsItem />
    </LeftMenuGroup>
  ) : null;
};

export default VisitXGroup;
