import { ErrorMessage, Form, FormikProps } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { RED } from '../../../../camtool-styles';
import {
  Article,
  FieldMailingsRecipientsFilter,
  SectionLabel,
  StyledDiv,
  StyledSection,
} from '../index';

import {
  FileStorageTypeEnum,
  Mailing,
  MailingStatusEnum,
  MailingTypeEnum,
  Query,
} from '../../../../graphql/VXModels/types';

import { FancyError, Spinner } from '../../../../components';
import Translation from '../../../../components/Translation/Translation';

import { useQuery } from '@apollo/react-hooks';
import { Box, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {
  RESPONSIVE_UPLOAD_STYLES_INPUT_HEIGHT,
  RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY_FULL,
  withResponsiveUploadStyles,
} from '../../../../molecules/FileUpload/withResponsiveUploadStyles';
import FieldRadioButtonGroup from '../../../../molecules/Formik/Fields/FieldRadioButtonGroup';
import { _ } from '../../../../util/translate';
import { QUERY_MODEL_MAILINGS_FORM } from '../../graphql/queries/mailings';
import StatusBoxDetailed from '../StatusBoxDetailed';
import FieldMessengerEditor from './Fields/FieldMessengerEditor';
import { FieldGenericUploadAttachment, FieldInlineAttachment } from './Fields/Upload';
import { FormValues } from './FormWrapper';
import {
  PaidContent,
  PublishScheduled,
  SendNotice,
  SendStickyMessage,
  SendTippingLink,
} from './Sections';
import SubmitButtonGroup from './Sections/SubmitButtonGroup';

const ResponsiveFieldInlineAttachment = withResponsiveUploadStyles(FieldInlineAttachment);
const ResponsiveFieldGenericUploadAttachment = withResponsiveUploadStyles(
  FieldGenericUploadAttachment
);

export const IMAGE_MIN_DIMENSION_WIDTH = 520;
export const IMAGE_MIN_DIMENSION_HEIGHT = 520;
export const PAID_CONTENT_PRICE_MIN = 3.0;
export const PAID_CONTENT_PRICE_MAX = 150.0;

export const validationSchema = Yup.object().shape({
  text: Yup.string().when('action', {
    is: (action) => ['publish', 'save'].includes(action),
    then: Yup.string()
      .min(1)
      .required(<Translation t={'mailings:form.text.error.required'} />),
  }),

  price: Yup.number().when('isPaidContent', {
    is: true,
    then: Yup.number()
      .min(
        PAID_CONTENT_PRICE_MIN,
        <Translation
          t={'mailings:form.price.error.min'}
          sprintf={{ min: PAID_CONTENT_PRICE_MIN }}
        />
      )
      .max(
        PAID_CONTENT_PRICE_MAX,
        <Translation
          t={'mailings:form.price.error.max'}
          sprintf={{ max: PAID_CONTENT_PRICE_MAX }}
        />
      ),
  }),

  attachment: Yup.mixed().test({
    name: 'validateAttachmentImageDimensions',
    message: (
      <Translation
        t={'mailings:form.attachment.error.imageDimensions'}
        sprintf={{
          minWidth: IMAGE_MIN_DIMENSION_WIDTH,
          minHeight: IMAGE_MIN_DIMENSION_HEIGHT,
        }}
      />
    ),
    test: (attachment) => {
      const meta = attachment?.meta || {};
      const type = meta.type?.split('/')[0];

      return (
        type !== 'image' ||
        !meta.dimensions ||
        (meta.dimensions.width > IMAGE_MIN_DIMENSION_HEIGHT &&
          meta.dimensions.height > IMAGE_MIN_DIMENSION_HEIGHT)
      );
    },
  }),
});

const FormInstantMessenger: FC<FormikProps<FormValues> & { mailing: Mailing }> = ({
  isSubmitting,
  status,
  setStatus,
  mailing,
  values,
  errors,
}) => {
  const { type, recipientsGroup, published } = values;
  const { editable = true, modified, shipmentRecipientsCount = 0 } = mailing;
  const mailingStatus = mailing.status;
  const [imageUploading, setImageUploading] = useState(false);
  const [recipientsCount, setRecipientsCount] = useState(0);
  const [publishScheduledOk, setPublishScheduledOk] = useState(true);
  const hasPaidContent = mailing.type === MailingTypeEnum.VXPAGES_TELEGRAM;
  const mentionsUserName = mailing?.name?.includes('%USER_FIRST_NAME%');

  useEffect(() => {
    const now = Date.now() + 60000;
    const publishScheduledDate = Date.parse(values.published);
    const timeInterval = 45 * 24 * 60 * 60 * 1000; //45 days

    if (publishScheduledDate - now > timeInterval) {
      setPublishScheduledOk(false);
    } else {
      setPublishScheduledOk(true);
    }
  }, [published]);

  //relatively stupid handling for weird checkbox behaviour
  //set isPaidContent false when there is no attachment...
  if (values.isPaidContent && !values.attachment) {
    values.isPaidContent = false;
  }

  useEffect(() => setStatus(mailingStatus), [mailingStatus]);

  const { data, loading, error } = useQuery<Query>(QUERY_MODEL_MAILINGS_FORM, {
    variables: { type },
  });

  if (error) {
    return <FancyError error={error} />;
  }

  if (!data || !data.model || loading) {
    return <Spinner />;
  }

  const {
    model: {
      mailings: { fileStorageSettings, recipientsGroups },
    },
  } = data;

  return (
    <Form>
      {isSubmitting && <Spinner />}
      <Article>
        <StatusBoxDetailed
          modified={modified}
          status={status}
          mentionsUserName={mentionsUserName}
        />

        {recipientsGroups.options.length > 1 && (
          <StyledSection>
            <div css={{ flexDirection: 'column', flex: 1 }}>
              <SectionLabel>{_('mailings:whatsapp.form.recipientGroup')}</SectionLabel>
              {/*<Button theme="link-blue" label="Vorlage auswählen" />*/}

              <FieldRadioButtonGroup
                name={'recipientsGroup'}
                disabled={!editable}
                render={'div'}
                options={recipientsGroups.options}
              />
            </div>
          </StyledSection>
        )}

        <FieldMailingsRecipientsFilter
          name="recipientsGroupFilters"
          disabled={!editable}
          type={type}
          recipientsGroup={recipientsGroup}
          recipientsCount={status !== MailingStatusEnum.DRAFT ? shipmentRecipientsCount : 0}
          onUpdateCount={setRecipientsCount}
        />

        {fileStorageSettings.type === FileStorageTypeEnum.VXSERVICES_TELEGRAM && (
          <SendTippingLink editable={editable} values={values} />
        )}

        <StyledSection
          css={{
            flexDirection: 'row',
            [RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY_FULL]: { flexDirection: 'column' },
            justifyContent: 'space-between',
          }}
        >
          <div css={{ flexDirection: 'column', flex: 1 }}>
            <div css={{ justifyContent: 'space-between' }}>
              <SectionLabel>{_('mailings:whatsapp.form.message')}</SectionLabel>
              {/*<Button theme="link-blue" label="Vorlage auswählen" />*/}
            </div>
            <StyledDiv
              css={{
                borderRadius: 4,
                border: '1px solid #c0c0c0',
                height: RESPONSIVE_UPLOAD_STYLES_INPUT_HEIGHT,
              }}
            >
              <FieldMessengerEditor emoji name={'text'} disabled={!editable} />
            </StyledDiv>
            <ErrorMessage name="text" component="span" css={{ color: RED }} />
          </div>

          <div
            css={{
              flexDirection: 'column',
              marginLeft: 16,
              maxWidth: 200,
              heigth: '100%',
              [RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY_FULL]: {
                marginLeft: 0,
                maxWidth: 'none',
              },
            }}
          >
            <div css={{ flexDirection: 'column' }}>
              <SectionLabel>{_('mailings:whatsapp.form.attachment')}:</SectionLabel>

              {fileStorageSettings.type === FileStorageTypeEnum.VXSERVICES_TELEGRAM &&
                (values.tipping ? (
                  <Box>
                    <Typography
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        border: '1px solid #c0c0c0',
                        borderRadius: '4px',
                        paddingBottom: '65px',
                      }}
                    >
                      <InfoIcon
                        style={{ width: '16px', marginBottom: '10px', marginTop: '40px' }}
                      />{' '}
                      <Translation t={'mailings:form.telegram.tipping.info'} />
                    </Typography>
                  </Box>
                ) : (
                  <ResponsiveFieldGenericUploadAttachment
                    name={'attachment'}
                    onPreCheck={() => setImageUploading(true)}
                    onUploadFinished={() => setImageUploading(false)}
                    label={_('mailings:whatsapp.form.addFile')}
                    accept={'image/*,video/*,audio/*'}
                    disabled={!editable}
                    fileStorageSettings={fileStorageSettings}
                  />
                ))}

              {fileStorageSettings.type === FileStorageTypeEnum.USER_MEDIA_WRAPPER && (
                <ResponsiveFieldInlineAttachment
                  name={'attachment'}
                  label={_('mailings:messenger.form.addFile')}
                  accept={'image/*'}
                  disabled={!editable}
                  fileStorageSettings={fileStorageSettings}
                />
              )}
            </div>
            <ErrorMessage name="attachment" component="span" css={{ color: RED, marginTop: 10 }} />
          </div>
        </StyledSection>

        <PublishScheduled
          editable={editable}
          values={values}
          publishScheduledOk={publishScheduledOk}
        />

        <SendNotice status={status} />
        {hasPaidContent && <PaidContent editable={editable} values={values} />}

        {fileStorageSettings.type === FileStorageTypeEnum.VXSERVICES_TELEGRAM && (
          <SendStickyMessage editable={editable} values={values} />
        )}

        <SubmitButtonGroup
          values={values}
          status={status}
          isSaveDisabled={imageUploading || isSubmitting}
          isPublishDisabled={
            !publishScheduledOk ||
            imageUploading ||
            (type !== MailingTypeEnum.VXPAGES_TELEGRAM && recipientsCount === 0) ||
            isSubmitting ||
            !!errors.price ||
            // works because price is initially set in every mailing type
            !values.price
          }
        />
      </Article>
    </Form>
  );
};

export default FormInstantMessenger;
