import styled from '@emotion/styled';
import { WHITE, GRAY_3, BLACK_2 } from '../../../../camtool-styles';
import Icon from '../../../../atoms/Icon/Icon';

export const Container = styled.div`
  justify-content: center;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const RequestWrapper = styled.div`
  width: 643px;
  min-height: 300px;
  justify-content: space-around;
  align-items: center;
  background: ${WHITE};
  border: 1px solid ${GRAY_3};
`;

export const LeftSection = styled.div`
  flex-direction: column;
  align-items: center;
  flex: 0.3;
`;

export const RightSection = styled.div`
  flex-direction: column;
  align-items: baseline;
  flex: 0.5;
  height: 100%;
  justify-content: center;
`;

export const SpinnerContainer = styled.div`
  position: relative;
  width: 25px;
`;

export const ListItem = styled.li<{ noBullets: boolean }>`
  font-size: 14px;
  margin-bottom: 15px;
  color: ${BLACK_2};
  ${(props) => (props.noBullets ? `list-style: none;` : {})}
`;

export const ListDiv = styled.div`
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;
