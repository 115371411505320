import React, { FC } from 'react';
import { VXPagesUserTransaction } from '../../../../../../graphql/VXModels/types';
import { TableCell, TableRow } from '../../../../../../atoms/Table';
import { DateTimeFormatter } from '../../../../../../atoms/I18n';
import { _ } from '../../../../../../util/translate';
import { RED } from '../../../../../../camtool-styles';
import { ITableColumn } from '../../../../../../atoms/Table/types';
import formatAmount from './formatAmount';

interface IProps {
  transaction: VXPagesUserTransaction;
  columns: ITableColumn[];
}

const TransactionRow: FC<IProps> = ({ transaction, columns }) => {
  const { amount, canceled, currency, date, type } = transaction;

  return (
    <TableRow>
      <TableCell columns={columns} index={0}>
        <DateTimeFormatter value={date} />
      </TableCell>
      <TableCell columns={columns} index={1}>
        {_(`userManagement:details.section.transactions.type.${type.substr(0, 19)}`)}
      </TableCell>
      <TableCell columns={columns} index={2}>
        <span css={canceled ? { color: RED } : {}}>
          {formatAmount(amount, currency) +
            (canceled
              ? ' ' + _('userManagement:details.section.transactions.cancellationSuffix')
              : '')}
        </span>
      </TableCell>
    </TableRow>
  );
};

export default TransactionRow;
