import React, { FC, Fragment, useState } from 'react';
import { VoucherTypeEnum, VXPagesUser } from '../../../../../graphql/VXModels/types';
import { Button, Select, InputField } from '../../../../../atoms';
import { ModalVoucherAmount } from '../index';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_VXPAGES_USER_UPDATE_VOUCHER_AMOUNT } from '../../../../../graphql/VXModels/mutations';
import styled from '@emotion/styled';
import { BREAKPOINT_PHONE, DARK_GRAY } from '../../../../../camtool-styles';
import { TRANSACTIONS_PAGE_SIZE } from '../../Details';
import { SelectLabel } from '../../styles';
import { _ } from '../../../../../util/translate';

const maxAbsoluteAmountsByVoucherType = {
  [VoucherTypeEnum.credits]: 10000,
  [VoucherTypeEnum.messages]: 10000,
  [VoucherTypeEnum.chatSeconds]: 10000 * 60,
  [VoucherTypeEnum.videos]: 100,
  [VoucherTypeEnum.galleries]: 100,
};

interface IProps {
  user: VXPagesUser;
  voucherType: VoucherTypeEnum;
}

const GiftSelectionDiv = styled.div`
  width: 100%;
  margin-top: 21px;
  ${BREAKPOINT_PHONE} {
    flex-wrap: wrap;
  }
`;

const GiftSelectionLabel = styled.label`
  width: 50%;
  color: ${DARK_GRAY};
  font-weight: 300;
  ${BREAKPOINT_PHONE} {
    width: 100%;
  }
`;

const GiftSelectionInput = styled.input`
  margin-top: 3px;
  margin-right: 8px;
`;

const ButtonRow = styled.div`
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
`;

const getVoucherAmountByType = (user: VXPagesUser, voucherType: VoucherTypeEnum): number => {
  switch (voucherType) {
    case VoucherTypeEnum.credits:
      return user.balance;
    case VoucherTypeEnum.messages:
      return user.freeMessages;
    case VoucherTypeEnum.chatSeconds:
      return user.freeChatSeconds;
    case VoucherTypeEnum.videos:
      return user.freeVideos;
    case VoucherTypeEnum.galleries:
      return user.freePictureAlbums;
    default:
      throw new Error('Unknow voucher type ' + voucherType);
  }
};

const ChangeVoucherAmount: FC<IProps> = ({ user, voucherType }) => {
  const { id } = user;

  const currentAmount = getVoucherAmountByType(user, voucherType);

  const [isGift, setIsGift] = useState<boolean>(true);
  const [reason, setReason] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [mutateVoucherAmount, { loading, error }] = useMutation(
    MUTATION_MODEL_VXPAGES_USER_UPDATE_VOUCHER_AMOUNT
  );

  const parsedAmount = parseInt(amount);
  const intAmount = !isNaN(parsedAmount) ? parsedAmount : 0;

  const maxAbsoluteAmount = maxAbsoluteAmountsByVoucherType[voucherType];
  const maxAllowedAmount = isGift ? maxAbsoluteAmount : Math.min(currentAmount, maxAbsoluteAmount);

  const amountInputError =
    amount === ''
      ? null
      : isNaN(parsedAmount)
      ? _('userManagement:details.voucherAmount.inputError.nan')
      : intAmount <= 0
      ? _('userManagement:details.voucherAmount.inputError.smallerThanZero')
      : intAmount > maxAllowedAmount
      ? isGift
        ? _('userManagement:details.voucherAmount.inputError.giftLimit', {
            sprintf: [maxAllowedAmount],
          })
        : _('userManagement:details.voucherAmount.inputError.subtractLimit', {
            sprintf: [maxAllowedAmount],
          })
      : null;

  const reasons = isGift
    ? [
        { label: _('userManagement:details.voucherAmount.reason.selectReason'), value: '' },
        { value: _('userManagement:details.voucherAmount.reason.gift.gift') },
        { value: _('userManagement:details.voucherAmount.reason.gift.other') },
      ]
    : [
        { label: _('userManagement:details.voucherAmount.reason.selectReason'), value: '' },
        { value: _('userManagement:details.voucherAmount.reason.subtract.fraud') },
        { value: _('userManagement:details.voucherAmount.reason.subtract.fetish') },
        { value: _('userManagement:details.voucherAmount.reason.subtract.purchases') },
      ];

  const onCloseModalClick = () => {
    if (!loading) setIsModalOpen(false);
  };

  const onOpenModalClick = () => {
    if (!loading) setIsModalOpen(true);
  };

  const onIsGiftRadioButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = isGift;
    const newValue = e.target.value === 'true';
    setIsGift(newValue);

    if (newValue !== currentValue) {
      setReason('');
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const onReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReason(e.target.value);
  };

  const updateVoucherAmount = async () => {
    if (loading) return;

    if (amountInputError) {
      throw new Error(amountInputError);
    }

    const { errors } = await mutateVoucherAmount({
      variables: {
        vxPagesUserId: id,
        voucherType,
        difference: isGift ? intAmount : -intAmount,
        comment: reason,
        transactionsLimit: TRANSACTIONS_PAGE_SIZE,
      },
    });

    if (!errors) {
      setAmount('');
      setReason('');
      setIsModalOpen(false);
    }
  };

  return (
    <Fragment>
      <GiftSelectionDiv>
        <GiftSelectionLabel>
          <GiftSelectionInput
            type="radio"
            value="true"
            checked={isGift}
            onChange={onIsGiftRadioButtonChange}
          />
          {_(`userManagement:details.voucherAmount.gift.${voucherType}`)}
        </GiftSelectionLabel>
        <GiftSelectionLabel>
          <GiftSelectionInput
            type="radio"
            value="false"
            checked={!isGift}
            onChange={onIsGiftRadioButtonChange}
          />
          {_(`userManagement:details.voucherAmount.subtract.${voucherType}`)}
        </GiftSelectionLabel>
      </GiftSelectionDiv>

      <SelectLabel>{_('userManagement:details.voucherAmount.selectReason')}</SelectLabel>
      <Select onChange={onReasonChange} options={reasons} value={reason} />

      <SelectLabel>{_('userManagement:details.voucherAmount.chooseAmount')}</SelectLabel>
      <InputField
        placeholder={_('userManagement:details.voucherAmount.enterAmount')}
        value={amount}
        onChange={onInputChange}
        errorMessage={amountInputError}
        css={{ width: 200 }}
        containerStyles={{ flexGrow: 0, flexShrink: 0 }}
      />

      <ButtonRow>
        <Button
          type={'button'}
          theme={'blue'}
          label={_(
            isGift
              ? `userManagement:details.voucherAmount.gift.${voucherType}`
              : `userManagement:details.voucherAmount.subtract.${voucherType}`
          )}
          onClick={onOpenModalClick}
          disabled={loading || !reason || !amount || !!amountInputError}
        />
      </ButtonRow>

      {isModalOpen && (
        <ModalVoucherAmount
          user={user}
          voucherType={voucherType}
          amount={intAmount}
          isGift={isGift}
          loading={loading}
          error={error}
          onClose={onCloseModalClick}
          onConfirm={updateVoucherAmount}
        />
      )}
    </Fragment>
  );
};

export default ChangeVoucherAmount;
