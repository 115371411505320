import axios from 'axios';
import { ApiLang } from '../../../graphql/VXModels/types';
import { getAuthTokenCookie } from '../../../util/cookies';
import { REACT_APP_API_HOST } from '../../../util/env';
import { log } from '../../../util/errorHandling';

const getGenericUploadUrl = async (
  umaId: number,
  type: number,
  fsk: number,
  query: string
): Promise<string> => {
  const endpoint = REACT_APP_API_HOST + '/graphql?lang=' + ApiLang.de;
  const headers = {
    'content-type': 'application/json',
    Authorization: 'Bearer ' + getAuthTokenCookie(),
  };

  const vars = umaId !== 0 ? { umaId: umaId, pictureType: type } : { umaSubType: type };
  vars.fsk = 18;
  if (fsk === 12 || fsk === 16) {
    vars.fsk = fsk;
  }

  const graphqlQuery = {
    query: query,
    variables: vars,
  };

  try {
    const response = await axios({
      url: endpoint,
      method: 'post',
      headers: headers,
      data: graphqlQuery,
    });

    return response.data?.data?.model?.media;
  } catch (err) {
    if (err.response) {
      log('error', 'POST: query ' + graphqlQuery + ' result code:' + err.response, {
        context: 'UploadManager',
      });
    } else if (err.request) {
      log('error', 'POST: query ' + graphqlQuery + ' get url request error:' + err.request, {
        context: 'UploadManager',
      });
    } else {
      log('error', 'POST: query ' + graphqlQuery + ' get url error:' + err, {
        context: 'UploadManager',
      });
    }
  }
};

export const getPictureUploadUrl = async (
  umaId: number,
  type: number,
  fsk: number
): Promise<string> => {
  const data = await getGenericUploadUrl(
    umaId,
    type,
    fsk,
    `query getPictureUploadUrl($umaId: Int!, $pictureType: Int!, $fsk: Int) {
        model {
          media {
            pictureUploadUrl(umaId: $umaId, pictureType: $pictureType, fsk: $fsk)
          }
        }
      }`
  );

  return data?.pictureUploadUrl;
};

export const getVideoUploadUrl = async (type: number): Promise<string> => {
  const data = await getGenericUploadUrl(
    0,
    type,
    0,
    `query getVideoUploadUrl($umaSubType: Int!) {
        model {
          media {
            videoUploadUrl(umaSubType: $umaSubType)
          }
        }
      }`
  );

  return data?.videoUploadUrl;
};
