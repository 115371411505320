import PropTypes from 'prop-types';

export const TYPE_VIDEO = 'video';
export const TYPE_PHOTO = 'photo';

export const STATUS_ACTIVE = 'active';
export const STATUS_EXPIRED = 'expired';
export const STATUS_PLANNED = 'planned';

export const PropContestType = PropTypes.oneOf([TYPE_VIDEO, TYPE_PHOTO]);
export const PropContestStatus = PropTypes.oneOf([STATUS_ACTIVE, STATUS_EXPIRED, STATUS_PLANNED]);

export const PropFilter = PropTypes.shape({
  type: PropTypes.arrayOf(PropContestType),
  status: PropContestStatus,
});
