import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { nameplate } from '../../../../../atoms/Icon/libraries/glyph';
import { hasScope, Scopes } from '../../../../../util/scope';

const CommonProfileItem: FC = () => {
  const hasScopeVXPages = hasScope([Scopes.VXPages]);

  return (
    <LeftMenuItem
      title={_(
        hasScopeVXPages ? 'navigation:visitx.profileCommon' : 'navigation:main.profile.common'
      )}
      icon={nameplate}
      uri="/onlineprofile/common"
    />
  );
};

export default CommonProfileItem;
