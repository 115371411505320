import React, { FC } from 'react';
import { _ } from '../../../../util/translate';
import { CheckMarkLabel } from './CheckMarkLabel';
import { VXPagesUser } from '../../../../graphql/VXModels/types';

interface IProps {
  user: Pick<VXPagesUser, 'isEmailVerified' | 'isSubscribedToNewsletter' | 'email'>;
  canSeeDirectUserData: boolean;
}

export const EmailLabel: FC<IProps> = ({ user, canSeeDirectUserData }) => {
  return (
    <>
      {canSeeDirectUserData ? (
        user.isEmailVerified ? (
          user.isSubscribedToNewsletter ? (
            user.email
          ) : (
            _('userManagement:email.unsubscribedFromNewsletter')
          )
        ) : (
          _('userManagement:email.notVerified')
        )
      ) : (
        <CheckMarkLabel check={user.isSubscribedToNewsletter} />
      )}
    </>
  );
};
