import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_VXPAGES_USER } from '../../../../graphql/VXModels/queries';
import { TRANSACTIONS_PAGE_SIZE } from '../Details';
import { QueryResult } from '@apollo/react-common';
import { Query } from '../../../../graphql/VXModels/types';

export const useVXPagesUser = (id: number): QueryResult<Query, { id: number }> =>
  useQuery(QUERY_MODEL_VXPAGES_USER, {
    variables: {
      id,
      transactionsLimit: TRANSACTIONS_PAGE_SIZE,
      transactionsOffset: 0,
    },
    fetchPolicy: 'network-only',
    skip: !id,
  });
