import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { _ } from '../../../../util/translate';
import { infoSign } from '../../../../atoms/Icon/libraries/glyph';

const HelpItem: FC = () => (
  <LeftMenuItem title={_('navigation:main.help')} icon={infoSign} uri="/help" />
);

export default HelpItem;
