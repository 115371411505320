import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {APP_BASE_PATH} from '../../util/env';

const HelpCenterSection = ({header, section: {articles}, category: {id: category_id}}) => {
  return (
    <div className="grid__box__row helpcenter-section">
      <div className="grid__column">
        {header && <h3 className="helpcenter-section__title">{header}</h3>}
        <ul className="helpcenter-section__ul">
          {articles.map(article => (
            <li key={article.id} className="helpcenter-section__li">
              <Link
                className="helpcenter-section__link"
                to={`${APP_BASE_PATH}/help/helpcenter/categories/${category_id}/articles/${
                  article.id
                  }`}
              >
                {article.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

HelpCenterSection.propTypes = {
  section: PropTypes.object.isRequired,
  header: PropTypes.string,
};
HelpCenterSection.defaultProps = {
  section: {articles: []},
  header: '',
};

export default HelpCenterSection;
