/* eslint-disable react/display-name */
import React from 'react';
import { Column } from 'material-table';
import { VXPagesUser } from '../../../graphql/VXModels/types';
import { _ } from '../../../util/translate';
import { Avatar, Box } from '@material-ui/core';
import { DARK_GRAY } from '../../../camtool-styles';
import {
  FavouriteCustomerRenderer,
  EmailLabel,
  CheckMarkLabel,
  StatusLabel,
} from '../components/ColumnRenders';
import { DateTimeFormatter } from '../../../atoms/I18n';

type Props = { canSeeDirectUserData: boolean; padding: number };

export const getColumnsConfig = ({
  canSeeDirectUserData,
  padding,
}: Props): Column<VXPagesUser>[] => [
  {
    title: _('userManagement:field.avatar'),
    align: 'left',
    render: ({ avatar }): unknown => <Avatar src={avatar} color={DARK_GRAY} />,
    sorting: false,
    width: 90 + padding,
  },
  {
    title: _('userManagement:field.note.regularCustomer'),
    align: 'center',
    editable: 'always',
    render: (rowData): unknown => <FavouriteCustomerRenderer {...rowData} />,
    sorting: false,
    width: 160,
  },
  {
    field: 'username',
    title: _('userManagement:field.username'),
    type: 'string',
    align: 'left',
    width: '',
  },
  {
    title: canSeeDirectUserData
      ? _('userManagement:field.email')
      : _('userManagement:field.newsletter'),
    align: 'center',
    width: 135 + padding,
    sorting: false,
    render: ({ isEmailVerified, isSubscribedToNewsletter, email }): unknown => (
      <EmailLabel
        canSeeDirectUserData={canSeeDirectUserData}
        user={{ email, isEmailVerified, isSubscribedToNewsletter }}
      />
    ),
  },
  {
    field: 'firstLogin',
    title: _('userManagement:field.firstLogin'),
    align: 'left',
    render: ({ firstLoginDate }): unknown => <DateTimeFormatter value={firstLoginDate} />,
    width: 115 + padding,
  },
  {
    field: 'lastLogin',
    title: _('userManagement:field.lastLogin'),
    type: 'datetime',
    align: 'left',
    render: ({ lastLoginDate }): unknown => <DateTimeFormatter value={lastLoginDate} />,
    width: 115 + padding,
  },
  {
    field: 'credits',
    title: _('userManagement:field.credits'),
    align: 'left',
    render: ({ balance }): unknown => (
      <span css={{ wordBreak: 'normal' }}>
        {_('userManagement:balance.value', { sprintf: [balance] })}
      </span>
    ),
    width: 100 + padding,
  },
  {
    field: 'hasAvs',
    title: _('userManagement:field.avs'),
    align: 'center',
    render: ({ hasAvs }): unknown => <CheckMarkLabel check={hasAvs} />,
    sorting: false,
    width: 135 + padding,
  },
  {
    title: _('userManagement:field.status'),
    align: 'center',
    render: ({ isOnline, isLocked }): unknown => (
      <Box display="flex" justifyContent="center">
        <StatusLabel user={{ isOnline, isLocked }} />
      </Box>
    ),
    sorting: false,
    width: 85 + padding,
  },
];
