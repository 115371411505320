import React from 'react';
import styled from '@emotion/styled';
import { WHITE, GRAY_2 } from '../../../camtool-styles';
import Other from '../../../atoms/Icon/DocumentTypes/Other';
import VAT from '../../../atoms/Icon/DocumentTypes/VAT';
import AddressProof from '../../../atoms/Icon/DocumentTypes/AddressProof';
import GEMA from '../../../atoms/Icon/DocumentTypes/GEMA';
import AccountOwnerChange from '../../../atoms/Icon/DocumentTypes/AccountOwnerChange';
import IdShot from '../../../atoms/Icon/DocumentTypes/IdShot';
import IdCard from '../../../atoms/Icon/DocumentTypes/IdCard';
import IconBase from '../../../atoms/Icon/IconBase';
import axios from 'axios';

const CustomIconBase = styled.div`
  border-radius: 50%;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 38px;
  height: 38px;
  color: ${WHITE};
  display: flex;
  font-size: 16px;
  background-color: ${(props) => (props.colorB ? props.colorB : GRAY_2)};
  background &::before {
    font-size: 1em;
  }
  &::after {
    font-size: 0.4em;
    color: ${(props) => (props.colorB ? props.colorB : '#fff')};
  }
`;

export const uploadDocuments = async (
  uploadedFile,
  successCallback,
  onErrorCallback,
  uploadUrl
) => {
  const formData = new FormData();
  formData.append('file', uploadedFile, uploadedFile.name);

  let response;
  try {
    response = await axios.post(uploadUrl, formData);
  } catch (error) {
    console.log(error);
  } finally {
    if (response?.request.readyState === 4 && response.status === 200) {
      if (response?.request.responseText.substr(0, 3) == 'OK:') {
        successCallback();
      } else {
        onErrorCallback();
      }
    }
  }
};

/**
 *
 * @param groupType
 * @param iconBackgroundColor
 * @returns {JSX.Element|string} Icon
 */
export function getGroupIcon(groupType, iconBackgroundColor) {
  switch (groupType) {
    case 'addressProof':
      return (
        <IconBase
          fontSize="38px"
          wrapInAvatar
          backgroundColor={iconBackgroundColor}
          iconElement={<AddressProof />}
          viewBox="0 0 38 38"
        />
      );
    case 'gemaNachweis':
      return (
        <IconBase
          fontSize="38px"
          wrapInAvatar
          backgroundColor={iconBackgroundColor}
          iconElement={<GEMA />}
          viewBox="0 0 38 38"
        />
      );
    case 'businnesRegistration':
      return <CustomIconBase className="fas fa-file-invoice" />;
    case 'vat':
      return (
        <IconBase
          fontSize="38px"
          wrapInAvatar
          backgroundColor={iconBackgroundColor}
          iconElement={<VAT />}
          viewBox="0 0 38 38"
        />
      );
    case 'identityProof':
      return (
        <IconBase
          fontSize="38px"
          wrapInAvatar
          backgroundColor={iconBackgroundColor}
          iconElement={<IdCard />}
          viewBox="0 0 38 38"
        />
      );
    case 'transferOwnership':
      return (
        <IconBase
          fontSize="38px"
          wrapInAvatar
          backgroundColor={iconBackgroundColor}
          iconElement={<AccountOwnerChange />}
          viewBox="0 0 38 38"
        />
      );
    case 'idShot':
      return (
        <IconBase
          fontSize="38px"
          wrapInAvatar
          backgroundColor={iconBackgroundColor}
          iconElement={<IdShot />}
          viewBox="0 0 38 38"
        />
      );
    case 'modelReleaseForm':
    case 'other':
      return (
        <IconBase
          fontSize="38px"
          wrapInAvatar
          backgroundColor={iconBackgroundColor}
          iconElement={<Other />}
          viewBox="0 0 38 38"
        />
      );
    case 'persoSnapshot':
      return <CustomIconBase className="icon-persoSnapshot" />;
    case 'salesContract':
      return <CustomIconBase className="icon-salesContract" />;
    case 'selfProtectionStatement':
      return <CustomIconBase className="far fa-meh" />;
    default:
      return '';
  }
}
