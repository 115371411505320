import React, { FC, useMemo } from 'react';
import { Paper, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { WHITE } from '../../../../../camtool-styles';
import { ProgressBar } from '../ProgressBar/';
import {
  CompetitionCongratulation,
  CompetitionPrompt,
  reArrangeMarksArray,
  CompetitionOverviewProps,
} from './';
import { VXGChallengeUserStatusEnum } from '../../../../../graphql/VXModels/types';

const CompetitionOverview: FC<CompetitionOverviewProps> = ({
  currentValue,
  targetValue,
  targetValueSteps,
  conditionText,
  status,
  unit,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const marksUI = useMemo(
    () => reArrangeMarksArray(isMobile, targetValueSteps, currentValue),
    [isMobile, targetValueSteps, currentValue]
  );
  const leftOverText =
    currentValue < targetValue ? `Noch ${(targetValue - currentValue).toFixed(2)} ${unit}` : '';
  const completed = status === VXGChallengeUserStatusEnum.complete;

  return (
    <Paper variant="elevation" style={{ backgroundColor: WHITE, padding: '40px', marginTop: 16 }}>
      <Box>
        {completed ? (
          <CompetitionCongratulation />
        ) : (
          <CompetitionPrompt leftOverText={leftOverText} conditionText={conditionText} />
        )}
        <Box mt={4} ml={3} mr={3}>
          <ProgressBar
            value={currentValue}
            max={targetValue}
            marks={marksUI}
            leftOver={leftOverText}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export { CompetitionOverview };
