import React, { memo } from 'react';

import { Main } from '../../atoms';
import { BLACK_1, BLACK_2, GRAY_2 } from '../../camtool-styles';
import { _ } from '../../util/translate';

const ComingSoon = memo(() => {
  return (
    <Main
      css={{
        backgroundColor: '#f3f3f3',
        flex: 1,
        color: BLACK_1,
      }}
    >
      <div
        css={{
          flexDirection: 'column',
          margin: 'auto 64px',
          paddingBottom: 118,
          textAlign: 'center',
        }}
      >
        <h1 css={{ fontSize: 40, fontWeight: 400, marginBottom: 8, color: BLACK_2 }}>
          {_('common:text.comingSoon')}
        </h1>
        <p css={{ fontSize: 24, color: GRAY_2 }}>{_('common:text.comingSoonInfo')}</p>
      </div>
    </Main>
  );
});

export default ComingSoon;
