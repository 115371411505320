import React, { FC } from 'react';
import { useMailingsRecipientsFilters } from '../../hooks/useMailingsRecipientsFilters';
import {
  MailingRecipientsGroupsEnum,
  MailingRecipientsGroupsFiltersEnum,
} from '../../../../graphql/VXModels/types';
import { Box, Divider, Grid } from '@material-ui/core';
import { SectionLabel } from '../styles';
import { _ } from '../../../../util/translate';

interface FilterCheckboxGroupProps {
  recipientsGroup: MailingRecipientsGroupsEnum;
  selected: MailingRecipientsGroupsFiltersEnum[];
  description?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FilterCheckboxGroup: FC<FilterCheckboxGroupProps> = ({
  description,
  recipientsGroup,
  selected,
  disabled = false,
  onChange,
}) => {
  const { recipientsFilters } = useMailingsRecipientsFilters(recipientsGroup);
  const options = recipientsFilters?.options || [];
  const hasOptions = options.length > 0;

  return hasOptions ? (
    <>
      {description && <SectionLabel> {description} </SectionLabel>}
      <Box p={1} style={{ display: 'block' }}>
        {options.map((option, idx) => (
          <label key={idx} css={{ marginBottom: 8 }}>
            <input
              type="checkbox"
              value={option.value}
              disabled={disabled}
              checked={selected.includes(option.value)}
              onChange={onChange}
              readOnly={!onChange}
            />
            <span css={{ marginLeft: 4 }}>{option.label}</span>
          </label>
        ))}
      </Box>
      <Divider style={{ marginBottom: 8 }} />
    </>
  ) : null;
};
