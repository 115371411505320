import React, { FC } from 'react';
import {
  VXGChallengeTypeEnum,
  VXGChallengeUserStatusEnum,
} from '../../../../graphql/VXModels/types';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ProgressBar } from '../../../../packages/VXGames/components/shared/index';
import gamesBackground from './background-universal.png';
import vxgamesLogo from './logo-universal.svg';
import NewButton from '../../../../atoms/Button/NewButton';
import {
  competitionImageMapping,
  VXGAMES_TYPOGRAPHY_H3,
  VXGAMES_TYPOGRAPHY_H5,
  VXGAMES_DARK_GREY,
} from '../../../../packages/VXGames/utils';
import { useHistory } from 'react-router-dom';
import { APP_BASE_PATH } from '../../../../util/env';
import { getFormattedAmount } from '../../../../util/Formatter';
import { useLang } from '../../../../util/AppState';
import { ApiLang } from '../../../../util/constants/ApiLang';

const PREFIX_LEFTOVER_TEXT = 'Noch ';

interface OwnProps {
  vxChallenge: {
    type: VXGChallengeTypeEnum;
    targetValue: number;
    userStatistic: { status: VXGChallengeUserStatusEnum; actualValue: number };
    texts: { title: string; unit: string };
  };
}

type Props = OwnProps;

function createFormattedLeftOverText(leftOverValue: number, unit: string, lang: ApiLang) {
  if (leftOverValue > 0) {
    if (unit === 'EUR') {
      return PREFIX_LEFTOVER_TEXT + getFormattedAmount(leftOverValue, lang);
    } else {
      return PREFIX_LEFTOVER_TEXT + Math.floor(leftOverValue) + ' ' + unit;
    }
  }
  return '';
}

function formatNumber(number: number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

const VXGamesBanner: FC<Props> = ({ vxChallenge }: OwnProps) => {
  const [lang] = useLang();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const texts = vxChallenge?.texts;
  const userStatistic = vxChallenge?.userStatistic;
  const targetValue = vxChallenge?.targetValue;
  const actualValue = userStatistic?.actualValue;
  const leftOverValue = formatNumber(targetValue) - formatNumber(actualValue);
  const leftOverText = createFormattedLeftOverText(leftOverValue, texts?.unit, lang);
  const isParticipating = userStatistic?.status !== VXGChallengeUserStatusEnum.notParticipated;
  const hasFinished =
    userStatistic?.status === VXGChallengeUserStatusEnum.complete ||
    userStatistic?.status === VXGChallengeUserStatusEnum.winner;

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2} justify="space-between" alignItems="stretch">
        <Box
          display="flex"
          width="100%"
          justifyContent="space-evenly"
          alignItems="center"
          flexWrap={'wrap'}
          color={VXGAMES_DARK_GREY}
          p={3}
          style={{
            backgroundImage: `url(${gamesBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <img src={vxgamesLogo} />
          <Box width={{ xs: '250px', md: '320px' }}>
            <Typography
              style={{
                ...(isMobile ? VXGAMES_TYPOGRAPHY_H5 : VXGAMES_TYPOGRAPHY_H3),
                ...{
                  color: VXGAMES_DARK_GREY,
                  whiteSpace: 'nowrap',
                },
              }}
            >{`${texts.title} Herausforderung`}</Typography>
            {isParticipating ? (
              <Box>
                <ProgressBar value={actualValue} max={targetValue} />
                {!hasFinished ? (
                  <Typography
                    style={{
                      color: VXGAMES_DARK_GREY,
                      transform: 'translateY(-7px)',
                    }}
                  >
                    {leftOverText}
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      color: VXGAMES_DARK_GREY,
                      transform: 'translateY(-7px)',
                    }}
                  >
                    Herausforderung abgeschlossen!
                  </Typography>
                )}
              </Box>
            ) : (
              <Typography
                style={{
                  color: VXGAMES_DARK_GREY,
                  paddingTop: 10,
                  transform: 'translateY(-7px)',
                }}
              >
                Nimm jetzt an der Herausforderung teil!
              </Typography>
            )}
          </Box>
          <Box display={{ xs: 'none', md: 'block' }}>
            {
              // livechat & new customer picture is not high enough, so size must be adjusted
              vxChallenge?.type === VXGChallengeTypeEnum.livechatProvision ||
              vxChallenge?.type === VXGChallengeTypeEnum.newCustomerProvision ? (
                <img
                  src={competitionImageMapping[vxChallenge?.type].bannerImage}
                  css={{ marginTop: '-35px', marginBottom: '-30px' }}
                  alt={'vxchallengeImage'}
                />
              ) : (
                <img
                  src={competitionImageMapping[vxChallenge?.type].bannerImage}
                  css={{ marginTop: '-15px', marginBottom: '-50px' }}
                  alt={'vxchallengeImage'}
                />
              )
            }
          </Box>
          <NewButton
            color="inherit"
            variant="outlined"
            onClick={() => history.push(`${APP_BASE_PATH}/vxgames`)}
          >
            {'Zu den VXGames'}
          </NewButton>
        </Box>
      </Grid>
    </Container>
  );
};

export default VXGamesBanner;
