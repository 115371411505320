import React, { FC, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { RED, WHITE_3 } from '../../../camtool-styles';
import { isVisible, isVisibleUpload } from './UploadManagerData';
import { _ } from '../../../util/translate';
import { alignCenter } from '../../../atoms/Icon/libraries/glyph';

interface DropZoneProps extends DropzoneOptions {
  acceptMessage: string;
  rejectMessage: string;
  isVisilble: boolean;
  mediaType: string;
}

type StyleProps = { isDragActive: boolean; isDragAccept: boolean };

const useStyles = makeStyles(() =>
  createStyles({
    root: ({ isDragActive, isDragAccept }: StyleProps) => ({
      flex: 1,
      outline: isDragActive ? (isDragAccept ? 'green dashed 3px' : 'red dashed 3px') : 'none',
      position: 'relative',
      // border: '1px solid red',
    }),
    overlay: () => ({
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: WHITE_3,
      zIndex: 10,
    }),
  })
);

export const DropZone: FC<DropZoneProps> = ({
  children,
  acceptMessage,
  rejectMessage,
  isVisilble = true,
  mediaType,
  ...dropzoneOptions
}) => {
  const dropzone = useDropzone(dropzoneOptions);
  const { getRootProps, getInputProps, isDragActive, isDragAccept } = dropzone;
  const { root, overlay } = useStyles({ isDragActive, isDragAccept });

  return (
    <>
      {isVisilble ? (
        <Grid container {...getRootProps()} classes={{ root }} item>
          <input {...getInputProps()} />

          {isDragActive && (
            <Box className={overlay}>
              <Box
                display={'flex'}
                flex={1}
                justifyContent={'center'}
                alignItems={'center'}
                height="100%"
              >
                {isDragAccept ? (
                  <Box minWidth={'50%'} textAlign={'center'}>
                    <Typography>{acceptMessage}</Typography>
                  </Box>
                ) : (
                  <Box minWidth={'50%'} textAlign={'center'}>
                    <Typography>{rejectMessage}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {typeof children == 'function' ? children({ ...dropzone }) : children}
        </Grid>
      ) : (
        <p css={{ color: RED, position: alignCenter }}>
          <span
            className="icon-warning-sign"
            css={{ fontSize: '35px', margin: '20px', display: 'flex', justifyContent: 'center' }}
          />
          <span css={{ textAlign: 'center', margin: '0 50px' }}>
            {mediaType === 'picture'
              ? _('mediamanagement:uploadCount.over200Pics.text')
              : _('mediamanagement:uploadCount.over200Videos.text')}
          </span>
        </p>
      )}
    </>
  );
};
