import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { heart } from '../../../../atoms/Icon/libraries/glyph';
import { SecurityRole } from '../../../../graphql/VXModels/types';
import { useHasRole, useUserData } from '../../../../util/UserData';
import { _ } from '../../../../util/translate';

const WelcomeItem: FC = () => {
  const hasRoleVXModels = useHasRole(SecurityRole.VX_USER_VXMODELS);
  const hasRoleVXPages = useHasRole(SecurityRole.VX_USER_VXPAGES);
  const isVXPagesOnly = !hasRoleVXModels && hasRoleVXPages;
  const userData = useUserData();
  // TODO Abfragen für welcome-seite anpassen
  const hasAllRequiredDocs = userData?.model?.documents?.hasAllRequiredDocuments;
  const isActorDocsUploaded = userData?.model?.documents?.allActorDocumentsUploaded;
  const isOriDocsUploaded = userData?.model?.documents?.allOriginatorDocumentsUploaded;
  const preview = isVXPagesOnly ? userData?.model?.avatar : userData?.model?.media.previewPicture16;
  const isPictureAccepted = preview?.picture?.isChecked;
  const ProfilePicAndDocsUploadedAndRP =
    isPictureAccepted && (hasAllRequiredDocs || isActorDocsUploaded || isOriDocsUploaded);

  return ProfilePicAndDocsUploadedAndRP ? null : (
    <LeftMenuItem title={_('navigation:main.welcome')} icon={heart} uri="/welcome" />
  );
};

export default WelcomeItem;
