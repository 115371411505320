import React, { FC, useState } from 'react';
import Formsy from 'formsy-react';

import {
  Button,
  ButtonGroup,
  Dropdown,
  GroupRow,
  Textareafield,
  Separator,
  Translation,
} from '../../../components';

import AlertsStore from '../../../stores/Alerts/AlertsStore';

import ServerUtils from '../../../util/ServerUtils';
import { Modal } from '../../../atoms';
import { _ } from '../../../util/translate';

const config = {
  subjects: ['finances', 'content', 'vxsoftware', 'privacy', 'tv-show', 'other'],
};

interface IProps {
  onBackgroundClick: () => void;
}

const ModalContactForm: FC<IProps> = ({ onBackgroundClick }) => {
  const [isSendButtonDisabled, setSendButtonDisabled] = useState(false);

  const postMail = (event: { subject: string; message: string }): void => {
    setSendButtonDisabled(true);

    const params = {
      data: JSON.stringify({
        subject: event.subject,
        message: event.message,
      }),
    };

    ServerUtils.request(
      'POST',
      '/v1/camtool/help/{userId}/mail',
      params,
      () => {
        setSendButtonDisabled(false);
        onBackgroundClick();
        AlertsStore.add({ type: 'info', message: _('help:mailform.reply.success') });
      },
      () => {
        setSendButtonDisabled(false);
        onBackgroundClick();
        AlertsStore.add({ type: 'error', message: _('help:mailform.reply.error') });
      }
    );
  };

  const getMailSubjects = () => {
    const data = [];
    for (const subject of config.subjects) {
      data.push({ value: subject, label: `help:mailform.subjects.${subject}` });
    }
    return data;
  };

  return (
    <Modal onBackgroundClick={onBackgroundClick}>
      <div
        css={{
          alignSelf: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          width: 600,
          height: 400,
        }}
      >
        <div className="modalgrid__box__header">
          <div className="icon-envelope" />
          <div>
            <Translation t={'help:mailform.title'} />
          </div>
          <div className="seperator" />
          <div className="icon-remove options" onClick={onBackgroundClick} />
        </div>

        <div className="modalgrid__box__content">
          <Formsy.Form className="modalgrid__box__content__column" onValidSubmit={postMail}>
            <GroupRow
              label={_('help:mailform.subject.label')}
              tooltipText={_('help:mailform.tooltip.topic')}
            >
              <Dropdown
                required
                requiredMessage={_('help:mailform.required')}
                name="subject"
                options={getMailSubjects()}
                placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                value={''}
              />
            </GroupRow>

            <GroupRow
              label={_('help:mailform.message.label')}
              tooltipText={_('help:mailform.tooltip.message')}
            >
              <Textareafield
                required
                requiredMessage={_('help:mailform.required')}
                name="message"
                maxLength={1000}
                value={''}
              />
            </GroupRow>

            <GroupRow tooltip="none">
              <Separator className="transparent" />
            </GroupRow>

            <ButtonGroup position="right">
              <Button
                className="button--blue"
                label={_('common:button.send')}
                type="submit"
                disabled={isSendButtonDisabled}
              />
            </ButtonGroup>
          </Formsy.Form>
        </div>
      </div>
    </Modal>
  );
};

export default ModalContactForm;
