import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Markdown from '../../../components/Markdown/Markdown';
import { FormatDate } from '../../../components/Formatter';
import { optimisticMarkReadResponse } from '../../../util/NotificationsHelper';
import { resolvePlaceholders } from '../../../util/StringUtils';

const TopBarNotificationsEntry = ({ id, type, params, date, title, text, onMarkRead }) => {
  const {
    action: { link },
    icon,
  } = type.meta;

  return (
    <div className="topbar__notification__entry">
      <Link to={resolvePlaceholders(link, params)} className="topbar__notification__entry__content">
        <div>
          <div className={icon + ' topbar__notification__entry__content__icon'} />
          <div className="topbar__notification__entry__content__textbox">
            <div className="topbar__notification__entry__content__titledate">
              <span className="--title">{title}</span>
              <FormatDate className="--date" value={date} />
            </div>
            <Markdown className="topbar__notification__entry__content__description" source={text} />
          </div>
        </div>
      </Link>
      {onMarkRead && (
        <div
          className="topbar__notification__entry__remove"
          key={id}
          onClick={() =>
            onMarkRead({
              variables: { notificationIds: id },
              optimisticResponse: optimisticMarkReadResponse([id]),
            })
          }
        />
      )}
    </div>
  );
};

TopBarNotificationsEntry.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.shape({
    name: PropTypes.string.isRequired,
    deletable: PropTypes.bool,
  }).isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  onMarkRead: PropTypes.func,
  meta: PropTypes.shape({
    icon: PropTypes.string,
    actions: PropTypes.shape({ link: PropTypes.string }),
  }),
};

TopBarNotificationsEntry.defaultProps = {
  deletable: true,
  title: '',
  text: '',
  onMarkRead: () => {},
  meta: { action: {}, icon: null },
};

export default TopBarNotificationsEntry;
