import { NotificationTypeMetaButton } from '../../graphql/VXModels/types';

export enum ButtonType {
  button = 'button',
  link = 'link',
}

export interface ButtonDefinition extends NotificationTypeMetaButton {
  type: ButtonType;
  unreadOnly?: boolean;
}
