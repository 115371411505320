import React, { PureComponent, ReactNode } from 'react';
import { getStyles, Size, Theme } from './styles';
import { SpinnerInline } from '../../components';

export interface ButtonProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  theme: Theme;
  size?: Size;
  type?: 'button' | 'submit' | 'reset';
  name?: string;
  href?: string;
  loading?: boolean;
}

/**
 * @deprecated Use NewButton Component instead
 */
class Button extends PureComponent<ButtonProps> {
  public static defaultProps: Partial<ButtonProps> = {
    children: [],
    className: '',
    disabled: false,
    href: '',
    label: '',
    name: '',
    theme: 'blue',
    size: 'md',
    type: 'button',
    loading: false,
  };

  public handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(event);
    }
  };

  public handleMouseEnter = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (typeof this.props.onMouseEnter === 'function') {
      this.props.onMouseEnter(event);
    }
  };

  public render() {
    const {
      children,
      className,
      disabled,
      label,
      theme,
      size,
      type,
      name,
      href,
      loading,
    } = this.props;

    const css = getStyles(theme, size);

    if (href) {
      return (
        <a className={className} css={css} href={href} target="_blank" rel="noopener noreferrer">
          {label || children}
        </a>
      );
    }

    return (
      <button
        name={name}
        onMouseEnter={this.handleMouseEnter}
        onClick={this.handleClick}
        type={type || 'button'}
        className={className}
        css={css}
        disabled={disabled}
      >
        {loading && <SpinnerInline size={'xs'} />}
        {label || children}
      </button>
    );
  }
}

/**
 * @deprecated Use NewButton Component instead
 */
export default Button;
