import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import 'vanilla-cookieconsent/src/cookieconsent.css';
import 'vanilla-cookieconsent/src/cookieconsent';
import { useLang } from './AppState';
import { APP_BASE_PATH } from './env';
import { getCookieDomain } from './cookies/CookieHelper';
import { setGtmConsent } from './GoogleTagManager';

export enum ConsentTypeEnum {
  necessary = 'necessary',
  functional = 'functional',
  analytics = 'analytics',
}

export const isCookieConsentSet = (type: ConsentTypeEnum): boolean => {
  const cookies = new Cookies();
  const cc = cookies.get('cc_cookie');

  if (!cc) return false;

  return cc.level.indexOf(type) > -1;
};

const CookieConsent = (): null => {
  const [lang] = useLang();
  const cookies = new Cookies();

  const urlDataProtection = `${APP_BASE_PATH}/legal/gdpr`; //'{/*link m="Info/Center" submod="Datenschutz"*/}'; // /legal/gdpr
  const descriptionDE =
    'Wir verwenden Cookies um Ihr Erlebnis auf unserer Website so angenehm wie möglich zu gestalten und um unser Produkt zu verbessern. Weitere Informationen über unsere Datenerfassungspraktiken finden Sie in unserer <a aria-label="Cookie policy" class="cc-link" href="' +
    urlDataProtection +
    '" target="_blank">Datenschutzerklärung</a>' +
    '<br/><br/>Sie können entscheiden welche Cookies Sie zulassen oder ablehnen. Klicken Sie auf "Einstellungen", um eine Liste aller Kategorien angezeigt zu bekommen. Durch Klicken auf "Alle Akzeptieren" erklären Sie sich mit der Verwendung der Cookies einverstanden.';

  const descriptionEN =
    'We use cookies to make your experience on our website as pleasant as possible and to improve our product. For more information on our data collection practices, see our <a aria-label="Cookie policy" class="cc-link" href="' +
    urlDataProtection +
    '" target="_blank">Data protection</a>' +
    '<br/><br/>You can decide which cookies you allow or reject. Click on "Settings" to get a list of all categories. By clicking on "Accept all" you agree to the use of cookies.';

  const blocksDE = [
    {
      title: 'Cookie Nutzung',
      description: 'Unsere Cookies fallen in folgende Kategorien',
    },
    {
      title: 'Essentielle Cookies',
      description:
        'Diese Cookies sind notwendig für den einwandfreien technischen Betrieb der Webseite.',
      toggle: {
        value: ConsentTypeEnum.necessary,
        enabled: true,
        readonly: true,
      },
    },
    {
      title: 'Funktionale Cookies',
      description:
        'Diese Cookies verbessern die Nutzererfahrung auf unserer Website, indem sie sich deine bevorzugten Einstellungen merken, wie zB. die Länderauswahl.',
      toggle: {
        value: ConsentTypeEnum.functional,
        enabled: false,
        readonly: false,
      },
    },
    {
      title: 'Analyse und Optimierung',
      description:
        'Diese Cookies werden verwendet um die Nutzung unserer Website mit zu analysieren und verschiedene Optimierungen zu testen, so dass sich unser Produkt stets weiter entwickelt.',
      toggle: {
        value: ConsentTypeEnum.analytics,
        enabled: false,
        readonly: false,
      },
    },
    {
      title: 'Mehr Informationen',
      description:
        'Für weitere Informationen zum Thema Datenschutz lies unsere <a class="cc-link" href="' +
        urlDataProtection +
        '">Datenschutzerklärung</a>.',
    },
  ];

  const blocksEN = [
    {
      title: 'Using cookies',
      description: 'Our cookies fall into the following categories',
    },
    {
      title: 'Necessary cookies',
      description: 'These cookies are necessary for the website to function properly.',
      toggle: {
        value: ConsentTypeEnum.necessary,
        enabled: true,
        readonly: true,
      },
    },
    {
      title: 'Functional cookies',
      description:
        'These cookies improve the user experience on our website by remembering your preferred settings, such as the country selection.',
      toggle: {
        value: ConsentTypeEnum.functional,
        enabled: false,
        readonly: false,
      },
    },
    {
      title: 'Analysis and optimization',
      description:
        'These cookies are used to analyze the use of our website and to test various optimizations so that our product continues to develop.',
      toggle: {
        value: ConsentTypeEnum.analytics,
        enabled: false,
        readonly: false,
      },
    },
  ];

  const languages = {
    de: {
      consent_modal: {
        title: 'Wir nutzen Cookies!',
        description: descriptionDE,
        primary_btn: {
          text: 'Alle Akzeptieren',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Einstellungen',
          role: 'settings', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie Einstellungen',
        save_settings_btn: 'Speichern',
        accept_all_btn: 'Alle akzeptieren',
        reject_all_btn: 'Alle ablehnen', // optional, [v.2.5.0 +]
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
          { col5: 'Type' },
        ],
        blocks: blocksDE,
      },
    },
    en: {
      consent_modal: {
        title: 'We use cookies!',
        description: descriptionEN,
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Settings',
          role: 'settings', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie settings',
        save_settings_btn: 'Save',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all', // optional, [v.2.5.0 +]
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
          { col5: 'Type' },
        ],
        blocks: blocksEN,
      },
    },
  };

  const updateConsent = (cookie: { level: string[] }) => {
    // functional cookies rejected
    if (cookie.level.indexOf(ConsentTypeEnum.functional) < 0) {
      window.localStorage.removeItem('cache.geoData');
      window.localStorage.removeItem('currentStep');
      window.localStorage.removeItem('i18nextLng');
      window.localStorage.removeItem('pubsub[session.init]');
      window.localStorage.removeItem('pubsub[session.update]');
      window.localStorage.removeItem('pubsub[session.close]');

      // session storage
      window.sessionStorage.removeItem('userdata');
      window.sessionStorage.removeItem('FOSP');
      // window.sessionStorage.removeItem('geoData.<countrycode>.<postalcode>');
    }

    // Google Tag Manager consent
    setGtmConsent({
      necessary_storage: cookie.level.indexOf(ConsentTypeEnum.necessary) < 0 ? 'denied' : 'granted',
      functional_storage:
        cookie.level.indexOf(ConsentTypeEnum.functional) < 0 ? 'denied' : 'granted',
      analytics_storage: cookie.level.indexOf(ConsentTypeEnum.analytics) < 0 ? 'denied' : 'granted',
    });
  };

  useEffect(() => {
    const cookieconsent = window.initCookieConsent();

    cookieconsent.run({
      autorun: true,
      current_lang: lang,
      autoclear_cookies: true,
      page_scripts: true,
      revision: 1, // increment after each adjustment

      // also fired upon rejecting
      onAccept: updateConsent,
      onChange: updateConsent,

      languages: languages,
    });

    window.cookieconsent = cookieconsent;
  }, [lang]);

  return null;
};

export default CookieConsent;
