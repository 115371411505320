import React, { ReactElement } from 'react';
import { WHITE } from '../../../camtool-styles';

const AddressProof = (): ReactElement => {
  return (
    <g id="Icon:_:_Adressnachweis" transform="translate(-3730.8 664.2)">
      <g>
        <path
          fill={WHITE}
          d="M3741.3-636.1c-0.2,0-0.3,0-0.5-0.1c-0.3-0.2-0.4-0.4-0.4-0.7v-12.5c0-0.2,0-0.3,0.1-0.5
			c0.1-0.1,0.2-0.2,0.4-0.3l3.6-1.5c0,0.2,0,0.5,0,0.7c0,0.3,0,0.7,0.1,1.1l-2.5,1.1v10.7l4.2-1.8v-6.4c0.5,0.7,1.1,1.4,1.7,2.1v4.3
			l4.2,1.8v-6.2c0.6-0.7,1.2-1.5,1.7-2.2v8.3l4.2-1.8v-10.7l-2.6,1.1c0.2-0.6,0.2-1.1,0.2-1.6c0,0,0-0.1,0-0.3l2.9-1.2
			c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0,0.5,0.1c0.3,0.2,0.4,0.4,0.4,0.7v12.5c0,0.2,0,0.3-0.1,0.5s-0.2,0.2-0.4,0.3l-5.9,2.5
			c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0-0.3-0.1l-5.5-2.4l-5.5,2.4C3741.5-636.1,3741.4-636.1,3741.3-636.1z M3750.1-644.1
			c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.3-0.3-0.6-0.6c-0.3-0.3-0.6-0.6-0.9-1c-0.3-0.3-0.6-0.7-0.9-1.2s-0.6-0.9-0.9-1.4
			c-0.4-0.8-0.6-1.6-0.6-2.4c0-0.4,0-0.7,0.1-1.1c0.1-0.4,0.2-0.7,0.4-1s0.4-0.6,0.6-0.8c0.3-0.3,0.5-0.5,0.8-0.7
			c0.3-0.2,0.6-0.3,1-0.4c0.4-0.1,0.7-0.2,1.1-0.2c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.3,0.9c0.4,0.4,0.6,0.8,0.9,1.3
			c0.2,0.5,0.3,1,0.3,1.6c0,0.7-0.2,1.5-0.7,2.4c-0.4,0.7-0.9,1.4-1.5,2.1c-0.6,0.7-1,1.3-1.4,1.7L3750.1-644.1z M3750-649.3
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.1,0.5-0.3s0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.5-0.2-0.9-0.5-1.3s-0.8-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3c0,0.3,0.1,0.6,0.2,0.9s0.4,0.5,0.7,0.7
			C3749.4-649.4,3749.7-649.3,3750-649.3z"
        />
      </g>
    </g>
  );
};

export default AddressProof;
