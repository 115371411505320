import React from 'react';
import { Mutation, Query } from '@apollo/react-components';
import InfiniteScroll from 'react-infinite-scroller';

import header from './banner-bg.jpg';
import {
  MUTATION_NOTIFICATIONS_MARKREAD,
  optimisticMarkReadResponse,
  QUERY_NEWSCENTER_NOTIFICATIONS,
  updateQuery,
  NOTIFICATIONS_COUNT_MENU,
} from '../../util/NotificationsHelper';
import Footer from '../../components/Footer/Footer';
import { hasScope, VXPAGES } from '../../util/scope';
import NotificationContent from '../../components/NewsCenter/NotificationContent';
import { EmptyContent, Spinner, T } from '../../components';
import { MessageContainer } from './styles';
import { _ } from '../../util/translate';
import { getTimezone } from '../../util/timezone';
import Navigation from './Navigation';

class NewsCenter extends React.Component {
  render() {
    const limit = 25;
    const offset = 0;
    const timezone = getTimezone();

    return (
      <main className="grid">
        <Navigation />

        <div className="grid__row">
          <div className="grid__column grid__box">
            <div
              className="--noselect"
              css={{
                display: 'block !important',
                width: '100%',
                height: 'auto',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <header
                css={{
                  color: '#000',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1 css={{ textTransform: 'uppercase', fontSize: '3.7vw', margin: 0 }}>
                  <T _={'notifications:newscenter.banner.title'} />
                  {/*News Center*/}
                </h1>
                <h1 css={{ fontSize: '1.15vw' }}>
                  {hasScope([VXPAGES]) ? (
                    <T _={'notifications:newscenter.bannerVXP.subtitle'} />
                  ) : (
                    <T _={'notifications:newscenter.banner.subtitle'} />
                  )}
                </h1>
              </header>
              <img css={{ width: '100%' }} src={header} alt="Postfach banner" />
            </div>

            <article
              css={{
                fontSize: 14,
                flexDirection: 'column',
                color: 'black',
              }}
            >
              {/*<div css={{ justifyContent: 'flex-end', alignItems: 'center', margin: '0 16px' }}>*/}
              {/*<div css={{ marginRight: 32, color: '#999' }}>*/}
              {/*<span className={cx('icon-sorting', css({ marginRight: 4 }))} />*/}
              {/*<p>NACH DATUM SORTIEREN</p>*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*<input*/}
              {/*css={{ border: '1px solid #ccc', padding: '4px 8px' }}*/}
              {/*type="text"*/}
              {/*placeholder="Suchen"*/}
              {/*/>*/}
              {/*</div>*/}
              {/*</div>*/}
              <Query
                query={QUERY_NEWSCENTER_NOTIFICATIONS}
                variables={{
                  notificationsLimit: limit,
                  notificationsOffset: offset,
                  timezone,
                }}
              >
                {({ loading, error, data, fetchMore }) => {
                  const queryLoading = loading;
                  const { items, count } =
                    data && data.model && data.model.newscenterNotifications
                      ? data.model.newscenterNotifications
                      : { items: [], count: 0 };

                  return (
                    <Mutation
                      mutation={MUTATION_NOTIFICATIONS_MARKREAD}
                      refetchQueries={[
                        { query: NOTIFICATIONS_COUNT_MENU, variables: { timezone } },
                      ]}
                    >
                      {(mutate) => {
                        const markRead = (id) => {
                          mutate({
                            variables: { notificationIds: id },
                            optimisticResponse: optimisticMarkReadResponse([id]),
                          });
                        };

                        return (
                          <div
                            className="grid__box__item__content"
                            css={{ marginBottom: loading ? 0 : 25 }}
                          >
                            <InfiniteScroll
                              className="grid__box__item__content"
                              initialLoad={false}
                              loadMore={() =>
                                fetchMore({
                                  variables: { notificationsOffset: items.length },
                                  updateQuery: updateQuery('newscenterNotifications'),
                                })
                              }
                              hasMore={items.length < count}
                              loader={
                                <div
                                  css={{ margin: 25, marginBottom: 0, flexDirection: 'column' }}
                                  key="loader"
                                >
                                  <Spinner key="loading" size="xs" inline={true} />
                                </div>
                              }
                            >
                              {queryLoading && <Spinner size="s" />}
                              {error && (
                                <EmptyContent
                                  icon="icon-bell"
                                  title={_('dashboard:app.notification.emptyContent.title')}
                                />
                              )}
                              {items.length > 0 &&
                                items.map((item) => (
                                  <MessageContainer key={item.id}>
                                    <NotificationContent notification={item} markRead={markRead} />
                                  </MessageContainer>
                                ))}
                              {!error && items.length === 0 && (
                                <EmptyContent
                                  icon="icon-bell"
                                  title={_('dashboard:app.notification.emptyContent.title')}
                                />
                              )}
                            </InfiniteScroll>
                          </div>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
            </article>
          </div>
        </div>

        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default NewsCenter;
