import { useEffect, useState } from 'react';

export enum ScriptStatus {
  LOADING = 'loading',
  IDLE = 'idle',
  READY = 'ready',
  ERROR = 'error',
}

interface ScriptAttributes {
  src: string;
  id?: string;
  async?: boolean;
  append?: 'head' | 'body';
}

const useScript = ({ src, id, async = true, append = 'head' }: ScriptAttributes): ScriptStatus => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState<ScriptStatus>(src ? ScriptStatus.IDLE : ScriptStatus.IDLE);

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus(ScriptStatus.IDLE);
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another instance of this hook
      let script = document.querySelector(`script[src="${src}"]`) as HTMLScriptElement;
      if (!script) {
        // Create script
        script = document.createElement('script') as HTMLScriptElement;
        if (id) {
          script.id = id;
        }
        script.src = src;
        script.async = async;
        script.setAttribute('data-status', ScriptStatus.LOADING);
        // Add script to document body
        document[append].appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: Event) => {
          script.setAttribute(
            'data-status',
            event.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR
          );
        };
        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        script &&
          setStatus((script.getAttribute('data-status') || ScriptStatus.LOADING) as ScriptStatus);
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: Event) => {
        setStatus(event.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR);
      };
      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );
  return status;
};

export default useScript;
