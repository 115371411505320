import {
  chats,
  studioAccount,
  computer,
  dollarHeart,
  VX,
  desktopAndMobile,
} from '../../../../atoms/Icon/libraries/svg';
import {
  asterisk,
  bullhorn,
  earphone,
  edit,
  film,
  group,
  headset,
  infoSign,
  listAlt,
  money,
  piggyBank,
  questionSign,
  user,
  webcam,
} from '../../../../atoms/Icon/libraries/glyph';
import { BLACK, BLUE, GREEN, RED, YELLOW } from '../../../../camtool-styles';

interface IMappingAttributes {
  icon: string;
  color?: string;
  svg?: boolean;
}

interface IMapping {
  [key: string]: IMappingAttributes;
}

export const iconMapping: IMapping = {
  // chats
  '360002382800': { icon: chats, svg: true }, // vxmodels
  // first steps
  '360002285439': { icon: asterisk, color: `${BLUE}` }, // vxmodels
  '360001655940': { icon: asterisk, color: `${BLUE}` }, // vxpages
  // pictures and videos
  '360002285499': { icon: film, color: `${BLUE}` }, // vxmodels
  '360001658700': { icon: film, color: `${BLUE}` }, // vxpages
  // Softare, Messenger, Splitter and Technical
  '360002286979': {
    // vxmodels
    icon: webcam,
    color: `${YELLOW}`,
  },
  '360001679039': {
    // vxpages
    icon: webcam,
    color: `${YELLOW}`,
  },
  // general information
  '360002263760': { icon: questionSign, color: `${BLUE}` }, // vxmodels
  // extra money
  '360001841999': { icon: piggyBank, color: `${GREEN}` }, // vxpages
  // hilfe von a bis z
  '360001665800': { icon: headset, color: `${YELLOW}` }, // vxpages
  // tips & tricks
  '360001679079': { icon: infoSign, color: `${RED}` }, // vxpages
  // our products
  '360002286959': {
    icon: desktopAndMobile,
    svg: true,
  }, // hardware recommendation
  '360003025499': { icon: computer, svg: true },
  // vxmodels
  '360001679099': {
    icon: VX,
    svg: true,
  }, // vxpages
  // studio account
  '360002685320': {
    icon: studioAccount,
    svg: true,
  }, // vxmodels
  // marketing
  '360002305039': { icon: bullhorn, color: `${RED}` }, // vxmodels
  '360001658160': { icon: bullhorn, color: `${RED}` }, // vxpages
  // creator
  '360001678159': { icon: edit, color: `${BLACK}` }, // vxpages
  // customers
  '360002238200': { icon: user, color: `${BLACK}` }, // vxpages
  // FAQ
  '360002263780': { icon: listAlt, color: `${BLACK}` }, // vxmodels
  '360001679059': { icon: listAlt, color: `${BLACK}` }, // vxpages
  // finances
  '360002260060': { icon: money, color: `${GREEN}` }, // vxmodels
  '360001679019': { icon: money, color: `${GREEN}` }, // vxpages
  // vxpages
  '360002263800': { icon: VX, svg: true }, // vxmodels
  // Hausordnung und Chat fairplay
  '360002371339': { icon: group, color: `${BLACK}` }, // vxmodels
  // Phone service
  '360002584379': { icon: earphone, color: BLACK }, // vxmodels
  // Abos
  '360003080059': { icon: dollarHeart, svg: true }, // vxpages
};
