import React, { FC } from 'react';
import { List } from '@material-ui/core';

import UploadQueueItem from './UploadQueueItem';
import { createStyles, makeStyles } from '@material-ui/styles';
import { getMimeTypeByMediaType } from '../util';
import { MediaTypeEnum } from '../types';
import { DropZone } from './DropZone';
import { useAddFiles, useFilteredQueue, useRemoveQueueItem, useCancelQueueItem } from '../hooks';
import { _ } from '../../../util/translate';
import { MAX_ELEMENTS, useCountPoolElements } from '../hooks/useCountPoolElements';

interface UploadQueueProps {
  mediaType: MediaTypeEnum;
  maxHeight?: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: ({ maxHeight }: UploadQueueProps) => ({
      maxHeight,
      overflowY: 'auto',
      '&:focus': { outline: 'none' },
    }),
  })
);

export const UploadManagerQueue: FC<UploadQueueProps> = (props) => {
  const { mediaType, children } = props;
  const { root } = useStyles(props);
  const queue = useFilteredQueue({ mediaType });
  const removeQueueItem = useRemoveQueueItem();
  const cancelQueueItem = useCancelQueueItem();

  const countElements = useCountPoolElements(mediaType);

  return (
    <DropZone
      accept={getMimeTypeByMediaType(mediaType)}
      acceptMessage={_(`UploadManager:uploadfield.${mediaType}.acceptMessage`)}
      rejectMessage={_(`UploadManager:uploadfield.${mediaType}.rejectMessage`)}
      onDrop={useAddFiles()}
      noClick
      isVisilble={countElements < MAX_ELEMENTS}
      mediaType={mediaType}
    >
      <List dense classes={{ root }}>
        {queue.length > 0
          ? queue.map((item) => (
              <UploadQueueItem
                key={item.id}
                onDelete={removeQueueItem}
                onCancel={cancelQueueItem}
                {...item}
              />
            ))
          : children}
      </List>
    </DropZone>
  );
};
