import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

import AbstractComponent from '../../AbstractComponent';
import Spinner from '../../Spinner/Spinner';
import Camera from '../../Camera/Camera';
import ButtonGroup from '../../ButtonGroup/ButtonGroup';
import Button from '../../Button/Button';
import Dropdown from '../../Form/SingleOption/Dropdown/Dropdown';

import { _ } from '../../../util/translate';

class Webcam extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLive: false,
      isSubmitDisabled: true,
      takeSnap: false,
      countdownActive: false,
      countdownTime: 0,
      countdownRest: '',
      urlData: '',
    };
  }

  onSnap(urlData) {
    this.setState({
      takeSnap: false,
      clearSnap: false,
      urlData: urlData,
      isSubmitDisabled: false,
    });
  }

  onClear() {
    this.setState({
      takeSnap: false,
      clearSnap: false,
      urlData: '',
      isSubmitDisabled: true,
    });
  }

  onLive() {
    this.setState({ isLive: true });
  }

  onError() {
    this.setState({ isLive: false });
  }

  onClickCreatePic() {
    if (this.state.countdownTime > 0) {
      this.state.countdownActive = true;
      let countdownRest = this.state.countdownTime;
      this.countdownInterval = setInterval(() => {
        countdownRest--;
        if (countdownRest === 0) {
          this.refs.camera.takeSnap();
          this.setState({
            countdownActive: false,
            countdownRest: '',
          });
          clearInterval(this.countdownInterval);
        } else {
          this.setState({ countdownRest: countdownRest });
        }
      }, 1000);
    } else {
      this.refs.camera.takeSnap();
    }
  }

  onChangeCountdown(event) {
    this.setState({ countdownTime: parseInt(event.target.value) });
  }

  onClickResetWebcam() {
    this.refs.camera.clear();
    this.setState({
      urlData: '',
      isSubmitDisabled: true,
      isLive: true,
      countdownActive: false,
    });
  }

  onSubmit() {
    this.setState({ isSubmitDisabled: true });
    this.props.onSubmit({
      source: 'webcam',
      selectionType: 'urlData',
      urlData: this.state.urlData,
    });
  }

  render() {
    this.isSubmitDisabled = this.state.isSubmitDisabled;
    this.countdownOptions = [
      { label: 'components:mediaManagement.mediaSelector.webcam.delay0sec', value: 0 },
      { label: 'components:mediaManagement.mediaSelector.webcam.delay2sec', value: 2 },
      { label: 'components:mediaManagement.mediaSelector.webcam.delay5sec', value: 5 },
      { label: 'components:mediaManagement.mediaSelector.webcam.delay10sec', value: 10 },
      { label: 'components:mediaManagement.mediaSelector.webcam.delay20sec', value: 20 },
    ];

    return (
      <div className="mediaselector__webcam spinner-container">
        {this.props.isLoading && <Spinner />}

        <Formsy.Form
          className="mediaselector__webcam__column"
          ref="profileForm"
          onValidSubmit={this.submitForm}
          onValid={this.onValid}
          onInvalid={this.onInvalid}
        >
          <Camera
            onSnap={this.onSnap}
            onClear={this.onClear}
            onLive={this.onLive}
            onError={this.onError}
            takeSnap={this.state.takeSnap}
            clearSnap={this.state.clearSnap}
            ref="camera"
          />

          {/*During countdown*/}
          {this.state.countdownActive && (
            <div className="mediaselector__webcam__countdown">
              {_('components:mediaManagement.mediaSelector.webcam.countdown')}{' '}
              {' ' + this.state.countdownRest}
            </div>
          )}

          <ButtonGroup position="center">
            <Button
              disabled={!this.state.isLive || this.state.urlData === ''}
              label={_('components:mediaManagement.mediaSelector.webcam.reset')}
              type="button"
              onClick={this.onClickResetWebcam}
            />
            <Button
              disabled={
                !this.state.isLive ||
                this.state.countdownActive === true ||
                this.state.urlData !== ''
              }
              label={_('components:mediaManagement.mediaSelector.webcam.takePhoto')}
              type="button"
              onClick={this.onClickCreatePic}
            />

            <Dropdown
              name="countdown"
              value={this.state.countdownTime}
              disabled={
                !this.state.isLive ||
                this.state.countdownActive === true ||
                this.state.urlData !== ''
              }
              options={this.countdownOptions}
              onChange={this.onChangeCountdown}
            />

            <Button
              disabled={this.isSubmitDisabled}
              label={_('components:mediaManagement.mediaSelector.webcam.acceptPhoto')}
              type="button"
              onClick={this.onSubmit}
            />
          </ButtonGroup>
        </Formsy.Form>
      </div>
    );
  }
}

Webcam.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

Webcam.defaultProps = {};

export default Webcam;
export { Webcam };
