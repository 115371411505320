import React, { FC } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import {
  HelpCenterArticle,
  HelpCenterCategory,
  HelpCenterSection,
} from '../../../../../../graphql/VXModels/types';
import { markSearchTerm } from '../utils';
import { BLUE, DARK_GRAY } from '../../../../../../camtool-styles';
import styled from '@emotion/styled';
import { APP_BASE_PATH } from '../../../../../../util/env';
import { useHistory } from 'react-router-dom';
interface Props {
  article: HelpCenterArticle;
  category: HelpCenterCategory;
  section: HelpCenterSection;
  searchTerm: string;
}

const Crumb = styled.span`
  color: ${DARK_GRAY};
  & > b {
    color: ${BLUE};
  }
  ${(props): string => (typeof props.onClick === 'function' ? 'cursor: pointer;' : '')};
`;

const ResultBreadcrumbs: FC<Props> = ({ article, category, section, searchTerm }) => {
  const history = useHistory();
  return (
    <Breadcrumbs separator="›">
      <Crumb
        onClick={(): void => history.push(`${APP_BASE_PATH}/help/helpcenter/`)}
        dangerouslySetInnerHTML={{ __html: markSearchTerm(category.name, searchTerm) }}
      />
      <Crumb
        onClick={(): void =>
          history.push(`${APP_BASE_PATH}/help/helpcenter/categories/${category.id}/articles`)
        }
        dangerouslySetInnerHTML={{ __html: markSearchTerm(section.name, searchTerm) }}
      />
      <Crumb
        onClick={(): void =>
          history.push(
            `${APP_BASE_PATH}/help/helpcenter/categories/${category.id}/articles/${article.id}`
          )
        }
        dangerouslySetInnerHTML={{ __html: markSearchTerm(article.name, searchTerm) }}
      />
    </Breadcrumbs>
  );
};

export default ResultBreadcrumbs;
