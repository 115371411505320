import React, { FC } from 'react';
import PhoneSexDescription from './PhoneSexDescription';
import { _ } from '../../../../util/translate';
import PhoneSexRequestSection from './PhoneSexRequestSection';
import ThreeBadgesBanner from '../../../../atoms/Banner/ThreeBadgesBanner';
import { checkmarkBadge, telephoneWebcam, moneyGray } from '../../../../atoms/Icon/libraries/svg';
import background from '../Assets/Rechteck 2021.jpg';
import AdvantagesList from '../../../../atoms/AdvantagesList/AdvantagesList';
import { WHITE_3 } from '../../../../camtool-styles';
import Section from '../../../../atoms/Grid/Section';
import { getPhoneSexArticleUrl } from '../../../../util/helpcenterLinks';
import { Service0900PhoneData } from '../types';

interface Props {
  storeData: Service0900PhoneData;
  onSuccess: () => void;
}

const PhoneSexInactive: FC<Props> = ({ storeData, onSuccess }) => {
  const scopedPhoneSexUrl = getPhoneSexArticleUrl();

  return (
    <Section>
      <div css={{ flexDirection: 'column' }}>
        <ThreeBadgesBanner
          background={background}
          primaryHeadline={_('service0900:PhoneSex.inactive.banner.headline')}
          secondaryHeadline={_('service0900:PhoneSex.inactive.banner.subHeadline')}
          leftBadge={checkmarkBadge}
          leftBadgePrimaryText={_('service0900:PhoneSex.inactive.banner.leftBadgePrimaryText')}
          leftBadgeSecondaryText={_('service0900:PhoneSex.inactive.banner.leftBadgeSecondaryText')}
          centerBadge={telephoneWebcam}
          centerBadgePrimaryText={_('service0900:PhoneSex.inactive.banner.middleBadgeText')}
          rightBadge={moneyGray}
          rightBadgePrimaryText={_('service0900:PhoneSex.inactive.banner.rightBadgeText')}
          primaryThemeColor={WHITE_3}
          secondaryThemeColor={WHITE_3}
        />
        <AdvantagesList
          listItemArray={[
            _('service0900:PhoneSex.inactive.advantages.firstArgument'),
            _('service0900:PhoneSex.inactive.advantages.secondArgument'),
            _('service0900:PhoneSex.inactive.advantages.thirdArgument'),
            _('service0900:PhoneSex.inactive.advantages.fourthArgument'),
            _('service0900:PhoneSex.inactive.advantages.fifthArgument'),
          ]}
          headline={_('service0900:PhoneSex.inactive.advantages.headline')}
        />
        <PhoneSexDescription scopedUrl={scopedPhoneSexUrl} />
        <PhoneSexRequestSection storeData={storeData} onSuccess={onSuccess} />
      </div>
    </Section>
  );
};

export default PhoneSexInactive;
