import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import { RequestTopicEnum, Websocket } from '../Websocket/Websocket';
import EventEmitter from 'events';
import merge from 'lodash/merge';
import AppUtils from '../../util/AppUtils';
import * as ActionTypes from '../../util/constants/ActionTypes';
import { publish, subscribe } from '../../util/PubSub';
import { APP_IS_DEVELOPMENT } from '../../util/env';
import { ConsentTypeEnum, isCookieConsentSet } from '../../util/CookieConsent';

const debug = false;

const CHANGE_EVENT = 'change';
const STORAGE_KEY = 'userdata';
const DEFAULT_DATA = {
  sessionId: '',
  connectionId: '',
  userId: 0,
  userName: '',
  spokenLangs: [],
  picturePoolUmaId: 0,
  user: {
    userId: 0,
    userName: '',
    email: '',
    roles: [],
    isService0900Allowed: false,
    hasAcceptedTermsAndConditions: true,
    hasAcceptedPrivacyPolicy: true,
  },
  messenger: {
    hasNewMessages: false,
  },
  media: {
    video: {
      hasUnusedVideos: false,
    },
  },
  gui: {
    lang: 'en-US',
  },
  profile: {
    spokenLangs: [],
    isLAProfileActive: false,
    isFLProfileActive: false,
  },
  faq: {},
  vxcash: {},
};

class SessionStoreClass extends EventEmitter {
  static debug = false;

  constructor() {
    super();

    AppDispatcher.register((payload) => {
      switch (payload.type) {
        case ActionTypes.WEBSOCKET_CONNECTED:
          SessionActionCreators.sendSessionRequest();
          break;
        case ActionTypes.SESSION_DATA_RECEIVE:
          //console.log(ActionTypes.SESSION_DATA_RECEIVE, payload.data)
          Websocket.sendMessage(RequestTopicEnum.USERSTORE_UPDATE_REQUEST);
          Websocket.sendMessagesFromQueue();
          SessionStore.update(payload.data);
          break;
        case ActionTypes.USERSTORE_DATA_RECEIVE:
          //console.log(ActionTypes.USERSTORE_DATA_RECEIVE, payload.data)
          SessionStore.update(payload.data);
          break;
        case ActionTypes.SESSION_ERROR:
          // console.error('session error', payload);
          SessionActionCreators.callLogout();
          break;
        case ActionTypes.SESSION_KILL:
          // console.info('session kill', payload);
          SessionActionCreators.callLogout();
          break;
      }
    });

    this.emitChange = this.emitChange.bind(this);
    this.verifySession = this.verifySession.bind(this);

    subscribe('session.init', this.verifySession);
    subscribe('session.update', this.emitChange);
  }

  verifySession(data) {
    if (SessionStoreClass.debug && APP_IS_DEVELOPMENT) {
      console.log(
        'SessionStore',
        'verifySession',
        'nextSessionId: ' + data.sessionId,
        'currentSessionId: ' + window.userSessionData.sessionId
      );
    }
    if (data.sessionId !== window.userSessionData.sessionId) {
      console.info(
        'Got new session, force reload to ensure all tabs with same session',
        'currentSessionId: ' + window.userSessionData.sessionId,
        'nextSessionId: ' + data.sessionId
      );
      AppUtils.forceReload();
    }
  }

  emitChange() {
    if (SessionStoreClass.debug && APP_IS_DEVELOPMENT) {
      console.log('SessionStore', 'emitChange');
    }
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  init(userData) {
    if (SessionStoreClass.debug && APP_IS_DEVELOPMENT) {
      console.log('SessionStore', 'init', STORAGE_KEY, userData);
    }
    publish('session.init', userData);
    if (isCookieConsentSet(ConsentTypeEnum.functional)) {
      // ConsentTypeEnum.functional
      window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(userData));
    }
  }

  update(nextUserData) {
    const currentUserData = this.get();
    // const userData = update(currentUserData, { $merge: nextUserData });
    const userData = merge(currentUserData, nextUserData);
    if (isCookieConsentSet(ConsentTypeEnum.functional)) {
      // ConsentTypeEnum.functional
      window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(userData));
    }
    publish('session.update', nextUserData);
    this.emitChange();
    APP_IS_DEVELOPMENT && debug && console.log('session update', userData);
  }

  get() {
    let userData;
    const userDataJson = window.sessionStorage.getItem(STORAGE_KEY);
    if (userDataJson) {
      userData = Object.assign(DEFAULT_DATA, JSON.parse(userDataJson));
    } else {
      userData = DEFAULT_DATA;
    }
    return userData;
  }

  clear() {
    window.sessionStorage.clear();
  }
}
SessionStoreClass.debug = false;

class SessionActionCreatorsClass {
  constructor() {
    subscribe('session.close', this.callLogout);
    this.authToken = '';
    this.sessionId = '';
  }

  setAuthToken(authToken, sessionId) {
    this.authToken = authToken;
    this.sessionId = sessionId;
  }

  sendSessionRequest() {
    if (!this.authToken || !this.sessionId) {
      throw new Error('Do not have authToken or sessionId yet');
    }

    Websocket.sendMessage(RequestTopicEnum.SESSION_REGISTER_REQUEST, {
      sessionId: this.sessionId,
      authToken: this.authToken,
    });
  }

  callLogout() {
    SessionStore.clear();
    AppUtils.forceLogout(true);
  }
}

const SessionActionCreators = new SessionActionCreatorsClass();
const SessionStore = new SessionStoreClass();

export { SessionActionCreators, SessionStore };
