import { Box, createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, ReactElement } from 'react';
import { GRAY_2, WHITE } from '../../../camtool-styles';
import { Markdown } from '../../../components';

type Props = {
  title: string;
  subtitle: string;
  icon: ReactElement;
  body: string;
  info: {
    title: string;
    body: string | HTMLElement | unknown;
  };
};

const useStyles = makeStyles(() =>
  createStyles({
    headerStyle: {
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 700,
      // lineHeight: '25px',
      letterSpacing: '0em',
    },

    subtitleStyle: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      // lineHeight: '22px',
      letterSpacing: '0em',
      color: GRAY_2,
    },

    bodyStyle: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      // lineHeight: '20px',
      letterSpacing: '0em',
    },

    infoTitleStyle: {
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 700,
      // lineHeight: '21px',
      letterSpacing: '0em',
    },
  })
);

const TextColumn: FC<Props> = ({ icon, title, body, subtitle, info }) => {
  const { headerStyle, subtitleStyle, infoTitleStyle, bodyStyle } = useStyles();

  return (
    <Box bgcolor={WHITE} p={4}>
      <Box textAlign={'center'} alignItems={'center'} mb={1}>
        {icon}
      </Box>
      <Box>
        <Typography variant={'h6'} align={'center'} classes={{ root: headerStyle }}>
          {title}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant={'subtitle1'} align={'center'} classes={{ root: subtitleStyle }}>
          {subtitle}
        </Typography>
      </Box>
      <Box minHeight={140} mb={3}>
        <Typography variant={'body1'} classes={{ root: bodyStyle }} component={'div'}>
          <Markdown>{body}</Markdown>
        </Typography>
      </Box>
      <Box minHeight={100}>
        <Typography variant={'h6'} classes={{ root: infoTitleStyle }}>
          {info.title}
        </Typography>
        <Typography variant={'body1'} classes={{ root: bodyStyle }} component={'div'}>
          <Markdown css={bodyStyle}>{info.body}</Markdown>
        </Typography>
      </Box>
    </Box>
  );
};

export { TextColumn };
