import React, { FC, ReactNode } from 'react';
import { TableCell, TableRow } from '../../../../atoms/Table';
import { ITableColumn } from '../../../../atoms/Table/types';
import styled from '@emotion/styled';
import { GRAY_ULTRATHIN, WHITE_2 } from '../../../../camtool-styles';
import FillRightTableMargin from '../Dropdown/FillRightTableMargin';
import { Spinner } from '../../../../components';
import { RowDataItem } from '../types';

const BORDER_COLOR = GRAY_ULTRATHIN;

const HideBorder = styled.div`
  display: block !important;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  right: 0;
  border-top: solid 1px ${WHITE_2};
  border-bottom: solid 1px ${WHITE_2};
`;

const StyledTableRow = styled(TableRow)`
  flex-basis: 51px;
  line-height: 1.5;

  &:hover ${HideBorder} {
    border-color: ${BORDER_COLOR};
    z-index: 1;
  }
`;

interface StatisticsRowProps {
  columns: ITableColumn[];
  data: RowDataItem[];
  label: string;
  loadingCustom: boolean;
}

const getDataCells = (
  columns: ITableColumn[],
  data: RowDataItem[],
  startDataIndex: number,
  startColumnIndex: number,
  length: number,
  loadingCustom: boolean
): ReactNode =>
  new Array(length).fill(0).map((value, index) => {
    const dataIndex = startDataIndex + index;
    const columnIndex = startColumnIndex + index;
    const cellData = data?.[dataIndex];

    return (
      <TableCell key={dataIndex} columns={columns} index={columnIndex}>
        {dataIndex === data.length - 1 ? (
          <FillRightTableMargin>
            {loadingCustom ? <Spinner size="xs" /> : cellData}
          </FillRightTableMargin>
        ) : (
          cellData
        )}
      </TableCell>
    );
  });

const StatisticsRow: FC<StatisticsRowProps> = ({ columns, data, label, loadingCustom }) => (
  <StyledTableRow borderColor={BORDER_COLOR}>
    <TableCell columns={columns} index={0}>
      {label}
    </TableCell>
    {getDataCells(columns, data, 0, 1, data.length - 1, loadingCustom)}
    <TableCell columns={columns} index={data.length} />
    <TableCell columns={columns} index={data.length + 1}>
      <HideBorder />
    </TableCell>
    {getDataCells(columns, data, data.length - 1, data.length + 2, 1, loadingCustom)}
  </StyledTableRow>
);

export default StatisticsRow;
