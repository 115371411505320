import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import ServerUtils from '../../util/ServerUtils';
import ActionTypes from '../../util/constants/ActionTypes';

const STORE_ACCOUNT_NAV = 'nav';
const STORE_PERSONAL_DATA = 'personaldata';
const STORE_PAYOUT_DATA = 'payoutdata';
// const STORE_DOCUMENTS = 'documents';
const STORE_PASSWORDS = 'passwords';

class AccountActionCreatorsClass {
  getNavSettings() {
    ServerUtils.request('GET', '/v1/camtool/user/{userId}/account/nav', null, function (response) {
      AccountActionCreators.receiveData(STORE_ACCOUNT_NAV, response);
    });
  }

  getPersonalData() {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/account/personaldata',
      null,
      function (response) {
        AccountActionCreators.receiveData(STORE_PERSONAL_DATA, response);
      }
    );
  }

  sendNewVerificationCode(verificationMode) {
    let data = { verificationMode: verificationMode };
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/account/personaldata/sendnewpin',
      { data: JSON.stringify(data) },
      function (response) {
        AccountActionCreators.receiveData(STORE_PERSONAL_DATA, response);
      }
    );
  }

  checkVerificationCode(verificationMode, verificationCode) {
    let data = { verificationMode: verificationMode, verificationCode: verificationCode };
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/account/personaldata/checkpin',
      { data: JSON.stringify(data) },
      function (response) {
        AccountActionCreators.receiveData(STORE_PERSONAL_DATA, response);
      }
    );
  }

  saveEmailOrPhone(verificationMode, newData) {
    let data = { verificationMode: verificationMode, newData: newData };
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/account/personaldata/saveemailphone',
      { data: JSON.stringify(data) },
      function (response) {
        AccountActionCreators.receiveData(STORE_PERSONAL_DATA, response);
      }
    );
  }

  savePersonalData(data) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/account/personaldata/saveaddress',
      { data: JSON.stringify(data) },
      function (response) {
        AccountActionCreators.receiveData(STORE_PERSONAL_DATA, response);
      }
    );
  }

  saveContactData(data) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/account/personaldata/savecontact',
      { data: JSON.stringify(data) },
      function (response) {
        AccountActionCreators.receiveData(STORE_PERSONAL_DATA, response);
      }
    );
  }

  getPayoutData() {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/account/payoutdata',
      null,
      function (response) {
        AccountActionCreators.receiveData(STORE_PAYOUT_DATA, response);
      }
    );
  }

  newPayoutDataRequest(data) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/account/payoutdata',
      { data: JSON.stringify(data) },
      function (response) {
        AccountActionCreators.receiveData(STORE_PAYOUT_DATA, response);
      }
    );
  }

  getPasswordsData() {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/account/passwords',
      null,
      function (response) {
        AccountActionCreators.receiveData(STORE_PASSWORDS, response);
      }
    );
  }

  saveNewPassword(data, callback) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/account/passwords',
      { data: JSON.stringify(data) },
      function (response) {
        AccountActionCreators.receiveData(STORE_PASSWORDS, response);
        if (typeof callback === 'function') callback(response);
      }
    );
  }

  receiveData(store, data) {
    AppDispatcher.dispatch({
      type: ActionTypes.ACCOUNT_RECEIVE_DATA,
      store: store,
      data: data,
    });
  }
}

const AccountActionCreators = new AccountActionCreatorsClass();

export { AccountActionCreators };
