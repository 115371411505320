import React, { Fragment, useEffect, useState } from 'react';
import { EmptyContent, Spinner } from '../../../../../components';
import { Button, Select } from '../../../../../atoms';
import {
  LocalizedText,
  Maybe,
  MediaAlbum,
  MediaStatus,
  VXPagesUser,
} from '../../../../../graphql/VXModels/types';
import styled from '@emotion/styled';
import { RED } from '../../../../../camtool-styles';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_VXPAGES_USER_GIFT_ALBUM } from '../../../../../graphql/VXModels/mutations';
import { SelectLabel } from '../../styles';
import { useLang } from '../../../../../util/AppState';
import { _ } from '../../../../../util/translate';

interface IProps {
  user: VXPagesUser;
  video: boolean;
  contentData?: object;
  contentLoading?: boolean;
  contentError?: any;
}

const ButtonRow = styled.div`
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
`;

const PlaceholderContainer = styled.div`
  margin-top: 21px;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
`;

const MutationSpinnerContainer = styled.div`
  margin-top: 30px;
  flex-direction: column;
`;

const MutationError = styled.div`
  display: block !important;
  margin-top: 30px;
  color: ${RED};
  text-align: center;
  font-size: 1.33333rem;
`;

const CheckMark = styled.span`
  margin-right: 5px;
  align-items: center;

  &::before {
    font-size: 0.85rem;
  }
`;

const getTitleForAlbumWithExactLanguage = (album: MediaAlbum, language: string): string | null => {
  const match = album.titles.find(
    (localizedText: Maybe<LocalizedText>) => localizedText && localizedText.language === language
  );

  return (match && match.text) || null;
};

const getTitleForAlbum = (album: MediaAlbum, language: string): string => {
  const exactMatch = getTitleForAlbumWithExactLanguage(album, language);
  if (exactMatch) return exactMatch;

  const englishTitle = getTitleForAlbumWithExactLanguage(album, 'en');
  if (englishTitle) return englishTitle;

  const germanTitle = getTitleForAlbumWithExactLanguage(album, 'de');
  if (germanTitle) return germanTitle;

  const firstEntry = album.titles.find((localizedText: Maybe<LocalizedText>) => !!localizedText);
  return firstEntry && firstEntry.text ? firstEntry.text : '';
};

const isUserHasAlreadyBoughtAlbumError = (error: any): boolean =>
  error &&
  typeof error.message === 'string' &&
  error.message.indexOf('The user has already bought the album') !== -1;

const getVideos = (data: object): MediaAlbum[] => {
  const videos: MediaAlbum[] =
    (data && data.model && data.model.videos && data.model.videos.albums) || [];
  return videos.filter(
    (video: MediaAlbum) =>
      video.status === MediaStatus.online &&
      video.video &&
      video.video.status === MediaStatus.online
  );
};

const getPhotoAlbums = (data: object): MediaAlbum[] => {
  const photoAlbums: MediaAlbum[] =
    (data && data.model && data.model.photoAlbums && data.model.photoAlbums.albums) || [];
  return photoAlbums.filter((photoAlbum: MediaAlbum) => photoAlbum.status === MediaStatus.online);
};

const getContent = (albums: MediaAlbum[], language: string) =>
  albums.map((album) => ({
    label: getTitleForAlbum(album, language),
    value: String(album.id),
  }));

const GiftContent: React.FC<IProps> = ({
  user: { id: userId },
  video,
  contentData,
  contentError,
  contentLoading,
}) => {
  const [lang] = useLang();

  const [selectedAlbumId, setSelectedAlbumId] = useState<number>(0);
  const [hideMutationSuccessStatus, setHideMutationSuccessStatus] = useState<boolean>(false);
  const [
    mutateGiftAlbum,
    { loading: mutationLoading, error: mutationError, called: mutationCalled },
  ] = useMutation(MUTATION_MODEL_VXPAGES_USER_GIFT_ALBUM);

  const options = contentData
    ? getContent(video ? getVideos(contentData) : getPhotoAlbums(contentData), lang)
    : [];

  useEffect(() => {
    const selectedAlbumInOptions =
      options.findIndex(({ value }) => value === selectedAlbumId) !== -1;

    if (!selectedAlbumInOptions) {
      setSelectedAlbumId(options[0] ? options[0].value : 0);
    }
  }, [contentData, video]);

  useEffect(() => {
    setHideMutationSuccessStatus(true);
  }, [video]);

  const onGiftClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setHideMutationSuccessStatus(false);

    return mutateGiftAlbum({
      variables: {
        vxPagesUserId: userId,
        albumId: selectedAlbumId,
      },
    }).then(
      () => {
        setHideMutationSuccessStatus(false);
      },
      (err) => {
        setHideMutationSuccessStatus(false);
        throw err;
      }
    );
  };

  const onSelectedAlbumIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAlbumId(parseInt(e.target.value));
    setHideMutationSuccessStatus(true);
  };

  return contentError ? (
    <PlaceholderContainer>
      <EmptyContent
        icon="icon-warning-sign"
        title={_('userManagement:details.section.giftContent.queryError')}
      />
    </PlaceholderContainer>
  ) : contentLoading ? (
    <PlaceholderContainer>
      <Spinner inline={true} size={'m'} noBackground={true} />
    </PlaceholderContainer>
  ) : options.length === 0 ? (
    <PlaceholderContainer>
      <EmptyContent
        icon="icon-film"
        title={_(
          video
            ? 'userManagement:details.section.giftContent.noVideos'
            : 'userManagement:details.section.giftContent.noPictureAlbums'
        )}
      />
    </PlaceholderContainer>
  ) : (
    <Fragment>
      <SelectLabel>
        {video
          ? _('userManagement:details.section.giftContent.selectVideo')
          : _('userManagement:details.section.giftContent.selectPictureAlbum')}
      </SelectLabel>
      <Select
        onChange={onSelectedAlbumIdChange}
        options={options}
        value={String(selectedAlbumId)}
      />
      {mutationLoading ? (
        <MutationSpinnerContainer>
          <Spinner inline={true} size={'m'} noBackground={true} />
        </MutationSpinnerContainer>
      ) : mutationError && !hideMutationSuccessStatus ? (
        <MutationError>
          {isUserHasAlreadyBoughtAlbumError(mutationError)
            ? video
              ? _('userManagement:details.section.giftContent.userOwnsVideo')
              : _('userManagement:details.section.giftContent.userOwnsPictureAlbum')
            : _('userManagement:details.section.giftContent.mutationError')}
        </MutationError>
      ) : null}
      <ButtonRow>
        <Button theme={'blue'} disabled={mutationLoading} onClick={onGiftClick}>
          {mutationCalled && !mutationLoading && !mutationError && !hideMutationSuccessStatus ? (
            <span>
              <CheckMark className="icon-ok" />
              {_('userManagement:details.section.giftContent.giftedSuccessfully')}
            </span>
          ) : (
            _('userManagement:details.section.giftContent.doGift')
          )}
        </Button>
      </ButtonRow>
    </Fragment>
  );
};

export default GiftContent;
