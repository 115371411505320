import React, { ReactElement } from 'react';
import { WHITE } from '../../../camtool-styles';

const IdCard = (): ReactElement => {
  return (
    <g transform="translate(-3730.8 664.2)">
      <g>
        <path
          fill={WHITE}
          d="M3760.1-650.9v11.9c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5h-3.5v-0.9c0-0.1,0-0.2-0.1-0.2
			c-0.1-0.1-0.1-0.1-0.2-0.1h-0.6c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2v0.9h-7.5v-0.9c0-0.1,0-0.2-0.1-0.2
			c-0.1-0.1-0.1-0.1-0.2-0.1h-0.6c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2v0.9h-3.5c-0.4,0-0.8-0.2-1.1-0.5
			c-0.3-0.3-0.5-0.7-0.5-1.1v-11.9c0-0.4,0.2-0.8,0.5-1.1s0.7-0.5,1.1-0.5h16.9c0.4,0,0.8,0.2,1.1,0.5
			C3760-651.7,3760.1-651.3,3760.1-650.9z M3749.7-641.6c0.2-0.3,0.4-0.6,0.4-1c0-0.4,0-0.8-0.1-1.2c-0.1-0.3-0.2-0.7-0.3-1
			c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.3c0,0-0.1,0.1-0.3,0.2c-0.2,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.2
			c-0.1,0.1-0.3,0.1-0.4,0.1s-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.3-0.1-0.3-0.2
			c-0.1,0-0.2-0.1-0.4-0.2c-0.2-0.1-0.3-0.2-0.3-0.2c-0.4,0-0.7,0.1-1,0.3s-0.5,0.4-0.6,0.8c-0.1,0.3-0.2,0.7-0.3,1
			c-0.1,0.3-0.1,0.7-0.1,1.2c0,0.4,0.1,0.8,0.4,1c0.2,0.3,0.5,0.4,0.9,0.4h5C3749.2-641.2,3749.5-641.3,3749.7-641.6z M3747.9-646.2
			c0.4-0.4,0.7-1,0.7-1.6c0-0.6-0.2-1.1-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7s-1.1,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.6
			c0,0.6,0.2,1.1,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7S3747.5-645.7,3747.9-646.2z M3757.6-647.8v-0.6c0-0.1,0-0.2-0.1-0.2
			c-0.1-0.1-0.1-0.1-0.2-0.1h-5.7c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2v0.6c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1
			h5.7c0.1,0,0.2,0,0.2-0.1S3757.6-647.7,3757.6-647.8z M3757.6-645.3v-0.5c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1h-5.6
			c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h5.6c0.1,0,0.2,0,0.3-0.1
			C3757.6-645.1,3757.6-645.2,3757.6-645.3z M3757.6-642.7v-0.6c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.2-0.1h-5.7c-0.1,0-0.2,0-0.2,0.1
			c-0.1,0.1-0.1,0.1-0.1,0.2v0.6c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1h5.7c0.1,0,0.2,0,0.2-0.1
			C3757.6-642.6,3757.6-642.6,3757.6-642.7z"
        />
      </g>
    </g>
  );
};

export default IdCard;
