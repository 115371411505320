import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { APP_BASE_PATH } from '../../util/env';
import { Translation } from '../index';

const HelpCenterBreadcrumbs = ({ article, category, section, className }) => {
  let breadcrumbs = [
    <Link key={0} to={`${APP_BASE_PATH}/help/helpcenter/categories`}>
      <Translation _={'help:navigation.helpcenter'} />
    </Link>,
  ];

  if (category && category.id !== '') {
    breadcrumbs.push(
      <span key={1} className="helpcenter-breadcrumbs__seperator">
        {'>'}
      </span>
    );
    if (section.id !== '') {
      const categoryLink = `${APP_BASE_PATH}/help/helpcenter/categories/${category.id}/articles`;
      breadcrumbs.push(
        <Link key={2} to={categoryLink}>
          {' '}
          {category.name}{' '}
        </Link>
      );

      if (article.id !== '') {
        breadcrumbs.push(
          <span key={3} className="helpcenter-breadcrumbs__seperator">
            {'>'}
          </span>
        );
        breadcrumbs.push(
          <Link key={4} to={categoryLink}>
            {section.name}
          </Link>
        );
        breadcrumbs.push(
          <span key={5} className="helpcenter-breadcrumbs__seperator">
            {'>'}
          </span>
        );
        breadcrumbs.push(<span key={6}>{article.name}</span>);
      } else {
        breadcrumbs.push(<span key={7}>{section.name}</span>);
      }
    } else {
      breadcrumbs.push(<span key={8}>{category.name}</span>);
    }
    return <div className={'helpcenter-breadcrumbs ' + className}>{breadcrumbs}</div>;
  }
  return null;
};

HelpCenterBreadcrumbs.propTypes = {
  category: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  section: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  article: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  className: PropTypes.string,
};

HelpCenterBreadcrumbs.defaultProps = {
  category: { id: '', name: '' },
  section: { id: '', name: '' },
  article: { id: '', name: '' },
  classname: '',
};

export default HelpCenterBreadcrumbs;
