import React from 'react';
import { Redirect, Switch } from 'react-router';
import { stripSlash } from '../../util/urlHelper';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';

import Email from './Email';
import Telegram from './Telegram/Telegram';
import Messenger from './Messenger';

import { useHasRole } from '../../util/UserData';
import { SecurityRole } from '../../graphql/VXModels/types';

const Mailings = ({ match: { url } }) => {
  const hasVXPagesHomepage = useHasRole(SecurityRole.VX_USER_VXPAGES_HP);
  const hasVXPagesLandingPage = useHasRole(SecurityRole.VX_USER_VXPAGES_LP);

  const path = stripSlash(url);
  const indexRoute = `${path}/email`;

  return (
    <Switch>
      <Redirect exact from={`${path}/`} to={indexRoute} />
      <ProtectedRoute
        path={`${path}/email`}
        component={Email}
        verify={() => hasVXPagesHomepage}
        // fallbackComponent={MaintenanceWork}
      />
      <ProtectedRoute
        path={`${path}/messenger`}
        component={Messenger}
        verify={() => hasVXPagesHomepage || hasVXPagesLandingPage}
        // fallbackComponent={ComingSoon}
      />
      <ProtectedRoute
        path={`${path}/telegram`}
        component={Telegram}
        //fallbackComponent={ComingSoon}
      />
      <Redirect to={indexRoute} />
    </Switch>
  );
};

export default Mailings;
