import React, { FC, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { VXPagesUserTransactionsResult } from '../../../../../../graphql/VXModels/types';
import { Table } from '../../../../../../atoms';
import { ITableColumn } from '../../../../../../atoms/Table/types';
import { TRANSACTIONS_PAGE_SIZE } from '../../../Details';
import getColumns from './columns';
import TransactionRow from './TransactionRow';
import Loader from './Loader';

interface IProps {
  transactions: VXPagesUserTransactionsResult;
  loadMore: (page: number) => Promise<void> | void;
}

const useOnTransactionsReset = (
  transactions: VXPagesUserTransactionsResult,
  onReset: () => void
): void => {
  useEffect(() => {
    if (transactions && transactions.items.length <= TRANSACTIONS_PAGE_SIZE) {
      // Transactions have changed, but not due to loadMore (in that case: items.length > TRANSACTIONS_PAGE_SIZE)
      // Happens e.g. when gifting credits to the user
      onReset();
    }
  }, [transactions]);
};

const TransactionsTable: FC<IProps> = ({ transactions, loadMore }) => {
  const [columns] = useState<ITableColumn[]>(getColumns); // Performance optimization: Use the same array on every render

  const [infiniteScrollResetCounter, setInfiniteScrollResetCounter] = useState<number>(0);
  const tableBodyRef = useRef<HTMLTableElement>(null);

  useOnTransactionsReset(transactions, () => {
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTop = 0;
    }

    setInfiniteScrollResetCounter((counter) => counter + 1);
  });

  return (
    <Table columns={columns} css={{ maxHeight: 500 }} tableBodyRef={tableBodyRef}>
      <InfiniteScroll
        key={infiniteScrollResetCounter}
        hasMore={transactions.items.length < transactions.total}
        loadMore={loadMore}
        initialLoad={false}
        useWindow={false}
        loader={<Loader key="loader" />}
        css={{ flexDirection: 'column' }}
      >
        {transactions.items.map((transaction, index) => (
          <TransactionRow transaction={transaction} columns={columns} key={index} />
        ))}
      </InfiniteScroll>
    </Table>
  );
};

export default TransactionsTable;
