import React, { Fragment } from 'react';
import { IRowDefinition, RowDataItem, TimeframeKey } from '../types';
import StatisticsRow from './StatisticsRow';
import { ITableColumn } from '../../../../atoms/Table/types';
import { _ } from '../../../../util/translate';

interface IProps<OriginalDataType extends any> {
  columns: ITableColumn[];
  definitions: Array<IRowDefinition<OriginalDataType>>;
  getDataCallback: (
    callback: (data: OriginalDataType, timeframeKey: TimeframeKey) => RowDataItem
  ) => RowDataItem[];
  loadingCustom: boolean;
}

const StatisticsRows = <OriginalDataType extends any>({
  columns,
  definitions,
  getDataCallback,
  loadingCustom,
}: IProps<OriginalDataType>) => (
  <Fragment>
    {definitions.map(({ label, callback }, index) => (
      <StatisticsRow
        key={index}
        columns={columns}
        data={getDataCallback(callback)}
        label={_(label)}
        loadingCustom={loadingCustom}
      />
    ))}
  </Fragment>
);

export default StatisticsRows;
