import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { VXPagesUser } from '../../../../../graphql/VXModels/types';
import { Section } from '../../../../../atoms';
import { WHITE_3, DARK_GRAY, BREAKPOINT_PHONE } from '../../../../../camtool-styles';
import { useUserData } from '../../../../../util/UserData';
import {
  FieldsContainer,
  UsernameField,
  LanguageField,
  StatusField,
  EmailField,
  AvsField,
  NewsletterField,
} from './Fields';
import { _ } from '../../../../../util/translate';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Favorite from '@material-ui/icons/Favorite';
import AlertsStore from '../../../../../stores/Alerts/AlertsStore';
import { useSaveNote, useUpdateTopUser } from '../../../components/utils';
import { makeStyles } from '@material-ui/styles';
import { MUICheckbox, MUICheckboxFormControlLabel } from '../../../../../components/MUI';
import { log } from '../../../../../util';

interface IProps {
  user: VXPagesUser;
}

const FormField = styled.div`
  margin-bottom: 20px;
  flex-direction: column;
`;

const sectionStyles = css`
  padding: 40px;
  background-color: ${WHITE_3};
  flex-direction: row;
  ${BREAKPOINT_PHONE} {
    flex-wrap: wrap;
  }
`;

const FieldStyles = styled(FieldsContainer)`
  padding: 40px;
  background-color: ${WHITE_3};
  flex-direction: row;
  ${BREAKPOINT_PHONE} {
    padding: 0;
  }
`;

const LeftColumnDiv = styled.div`
  width: 170px;
  flex-direction: column;
  align-items: center;

  -webkit-box-flex: 0 0 125px;
  -moz-box-flex: 0 0 125px;
  -webkit-flex: 0 0 125px;
  -ms-flex: 0 0 125px;
  flex: 0 0 125px;
  ${BREAKPOINT_PHONE} {
    margin-bottom: 20px;
  }
`;

const AvatarImage = styled.img`
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
  object-position: 50% 50%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 14px;
`;

const useStyles = makeStyles({
  root: {
    color: DARK_GRAY,
    '&.Mui-disabled': {
      color: DARK_GRAY,
    },
  },
  label: {
    '&.Mui-disabled': {
      color: DARK_GRAY,
    },
    whiteSpace: 'nowrap',
  },
});

const SectionGeneral: FC<IProps> = ({ user }: IProps) => {
  const { id, note: initialNote, avatar } = user;
  const classes = useStyles();
  const [isTopUser, setIsTopUser] = useState(() => initialNote.isRegularCustomer);

  const [updateTopUser, { loading, error }] = useUpdateTopUser(id, initialNote);

  const getOnChangeFn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    updateTopUser(isChecked);
    setIsTopUser(isChecked);
    const alertMessage = isChecked
      ? _('userManagement:field.note.regularCustomer')
      : _('userManagement:field.note.noRegularCustomer');

    AlertsStore.add({
      type: 'info',
      message: alertMessage,
    });
  };

  const canSeeDirectUserData = !!useUserData().model?.vxpages?.info?.canSeeDirectUserData;

  if (error) {
    log('notice', error.message, { context: 'Usernotes', error });
  }

  return (
    <Section title={_('userManagement:details.section.general.title')} styles={sectionStyles}>
      <LeftColumnDiv>
        <AvatarImage src={avatar} />
        <FormField>
          <MUICheckboxFormControlLabel
            classes={{
              label: classes.label,
            }}
            disabled={loading}
            checked={isTopUser}
            control={
              <MUICheckbox
                classes={{
                  root: classes.root,
                }}
                onChange={getOnChangeFn}
                icon={
                  <FavoriteBorder
                    classes={{
                      root: classes.root,
                    }}
                    fontSize="large"
                  />
                }
                disabled={loading}
                checkedIcon={<Favorite color={'error'} fontSize="large" />}
                name={_('userManagement:field.note.regularCustomer')}
              />
            }
            label={
              isTopUser
                ? _('userManagement:field.note.regularCustomer')
                : _('userManagement:field.note.noRegularCustomer')
            }
          />
        </FormField>
      </LeftColumnDiv>
      <FieldStyles>
        <UsernameField user={user} />
        <LanguageField user={user} />
        <StatusField user={user} />
        <EmailField user={user} canSeeDirectUserData={canSeeDirectUserData} />
        <AvsField user={user} />
        <NewsletterField user={user} />
      </FieldStyles>
    </Section>
  );
};

export default SectionGeneral;
